import TCell from '@containers/common/Table/components/TCell';
import { deleteVendorByIdThunk } from '@features/vendors/actions';
import { useAppDispatch } from '@features/app/hooks';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteAction';

import {
  VendorIdLink,
  StyledVendorTableRow,
} from '../styled';
import {
  EditLink,
  ActionsWrapper,
  VerticalLine,
} from './styled';

interface VendorItemInListProps {
  id: string;
  name: string;
  carrierUsed: string;
  excludeFromPOReport: boolean;
  handleAllVendorsRequest: () => void;
}

const VendorItemInList = ({
  id,
  name,
  carrierUsed,
  excludeFromPOReport,
  handleAllVendorsRequest,
}: VendorItemInListProps) => {
  const dispatch = useAppDispatch();

  const handleDeleteRequest = () => {
    dispatch(deleteVendorByIdThunk(id))
      .unwrap()
      .then(() => {
        handleAllVendorsRequest();
      });
  };

  return (
    <StyledVendorTableRow>
      <TCell width="410px">
        <VendorIdLink to={`/cms/vendors/edit/${id}`}>
          {name}
        </VendorIdLink>
      </TCell>
      <TCell text={carrierUsed} width="410px" />
      <TCell text={excludeFromPOReport ? 'Yes' : 'No'} width="192px" />
      <TCell width="138px">
        <ActionsWrapper>
          <EditLink to={`/cms/vendors/edit/${id}`}>Edit</EditLink>
          <VerticalLine>
            <span />
          </VerticalLine>
          <DeleteBtn
            deleteAction={() => handleDeleteRequest()}
            questionText="Are you sure you want to delete this vendor?"
          />
        </ActionsWrapper>
      </TCell>
    </StyledVendorTableRow>
  );
};

export default VendorItemInList;
