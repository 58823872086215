import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getAllAdminLogs } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  total: 0,
};

const AdminLogsSlice = createSlice({
  name: 'adminLogs',
  initialState,
  reducers: {
    setAdminLogs: (state, { payload }) => {
      state.data = payload;
    },
    resetAdminLogs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAdminLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllAdminLogs.fulfilled, (state, { payload }) => {
      const { adminLoginLogs, total } = payload;

      state.data = adminLoginLogs;
      state.total = total;
      state.isLoading = false;
    });
    builder.addCase(getAllAdminLogs.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setAdminLogs, resetAdminLogs } = AdminLogsSlice.actions;

export default AdminLogsSlice.reducer;
