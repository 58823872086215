import { memo } from "react";

import { priceFormatting } from "@utils/helpers";
import { useFormContext } from "react-hook-form";

import { GrayBackground, Wrapper, Text } from './styled';

const AttributePrices = ({ jobIndex, isEditable }: {jobIndex: number; isEditable: boolean }) => {
  const { watch } = useFormContext();
  const job = watch(`jobs[${jobIndex}]`);

  return (
    <GrayBackground>
        <Wrapper>
          <Text>Subtotal:</Text>
          <p>{priceFormatting(isEditable ? job.editablePrice : job.price)}</p>
        </Wrapper>
        <Wrapper>
          <Text>Tax:</Text>
          <p>{priceFormatting(isEditable ? job.editableTax : job.tax)}</p>
        </Wrapper>
        <Wrapper>
          <Text>Total:</Text>
          <p>{priceFormatting(isEditable ? job.editableTotal : job.total)}</p>
        </Wrapper>
    </GrayBackground>
  );
};

export default memo(AttributePrices);
