import { memo, useCallback, useEffect, useState } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { reorderSubcategories, searchSubcategories } from '@features/subcategories/actions';
import Loader from '@containers/common/Loader';
import { selectSubcategories } from '@features/subcategories/selectors';
import EmptyState from '@containers/common/EmptyState';
import { setSubcategories } from '@features/subcategories/slice';
import queryString from 'query-string';
import useMount from '@customHooks/useMount';
import { getAllCategories, getCategoriesHavingSubcategories } from '@features/categories/actions';
import { selectCategories } from '@features/categories/selectors';
import PageTitle from '@containers/common/PageTitle';
import { useLocation } from 'react-router-dom';
import { nestedDragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import { ISubcategoriesSearchInfo } from '@features/subcategories/types';
import Box from '@mui/material/Box';
import { GlobalQueryString } from '@utils/globalTypes';
import ToggleHoc from '@containers/common/ToggleUtils/ToggleHoc';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './Tabs/BasicInfo/components/SearchSection';
import { IFiltersForm } from './Tabs/BasicInfo/components/SearchSection/helpers';
import TableRow from './Tabs/BasicInfo/components/TableRow';
import { useToggleLogic } from '../../common/ToggleUtils/useToggleLogic';

const ProductCategories = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { data: subcategories, isLoading } = useAppSelector(selectSubcategories);
  const { allData, isLoading: categoryLoading } = useAppSelector(selectCategories);
  const [localLoader, setLocalLoader] = useState<string | null>(null);
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;

  const { searchTerm = '', visibleOnSite: visibleOnSiteQuery = '', category = '' } = params as IFiltersForm;
  const isSearchTerm = searchTerm || visibleOnSiteQuery || category;

  const { toggledItems, handleUpdateToggle, handleResetToggle } =
      useToggleLogic<ISubcategoriesSearchInfo>({ data: subcategories, isSearchTerm: !!isSearchTerm });

  const fetchData = useCallback(() => {
    const query = {
      searchTerm,
      visibleOnSite: visibleOnSiteQuery,
      category,
    };

    dispatch(searchSubcategories(query));
  }, [searchTerm, visibleOnSiteQuery, category, dispatch]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, visibleOnSiteQuery, category],
  );

  useMount(() => {
    dispatch(getCategoriesHavingSubcategories());
    dispatch(getAllCategories());
  });

  const reOrderingData = (result: DropResult, id: string) => {
    setLocalLoader(id);

    const { sortedData, items } = nestedDragSort(result, subcategories as ISubcategoriesSearchInfo[], 'subCategory');

    dispatch(reorderSubcategories(sortedData))
      .unwrap()
      .then(() => {
        dispatch(setSubcategories(items));
        setLocalLoader(null);
      })
      .catch(() => fetchData());
  };

  if (isLoading || categoryLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Product Categories"
        btnName="Add Product Category"
        path={PAGE_ROUTES.ADD_PRODUCT_CATEGORIES}
        isShowBtn={!!allData?.length}
      />

      {
        allData?.length ? (
          <>
            {(isSearchTerm || !!subcategories?.length) && <SearchSection onReset={() => handleResetToggle()} />}
            {subcategories?.length ? (subcategories as ISubcategoriesSearchInfo[])
              .map(({
                id: catId,
                subCategory,
                title: catTitle,
              }) => {
                const status = !!(toggledItems && toggledItems[catId]);

                return (
                  <Box key={catId}>
                    <ToggleHoc
                      id={catId}
                      title={catTitle}
                      status={status}
                      localLoader={localLoader}
                      handleUpdateToggle={handleUpdateToggle}
                    >
                      <DndContainer reordingData={(e) => reOrderingData(e, catId)}>
                        <StyledTable headCells={headCells}>
                          {subCategory?.map((item, idx) => (
                            <TableRow key={item.id} {...item} idx={idx} fetchData={fetchData} />
                          ))}
                        </StyledTable>
                      </DndContainer>
                    </ToggleHoc>
                  </Box>
                );
              }) : (
                <EmptyState
                  text={isSearchTerm ? 'No search results found' : 'You don’t have any product categories'}
                  isAdd={!searchTerm}
                />
            )}
          </>
        ) : (
          <EmptyState text="You don’t have any categories" isAdd />
        )
      }
    </>
  );
};

export default memo(ProductCategories);
