import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getAllSendAFile } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
};

const sendAFileSlice = createSlice({
  name: 'sendAFile',
  initialState,
  reducers: {
    setSendAFile: (state, { payload }) => {
      state.data = payload;
    },
    resetSendAFile: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSendAFile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSendAFile.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllSendAFile.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setSendAFile, resetSendAFile } = sendAFileSlice.actions;

export default sendAFileSlice.reducer;
