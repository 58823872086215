import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const FirstView = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
}));

export const JobTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  padding: '10px 16px',
  background: COLORS.blue.light,
  border: `1px solid ${COLORS.grey[500]}`,
}));

export const JobTitle = styled(Typography)(() => ({
  color: '#06152B',
  fontFamily: "DM Sans",
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
}));

export const DownloadWrapper = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: COLORS.blue.dark,

  p: {
    textDecoration: 'underline',
  },
}));

// \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ LEFT SECtION \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\

export const Content = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}));

export const Left = styled(Box)(() => ({
  padding: '10px',
}));

export const Right = styled(Box)(() => ({
  padding: '10px',
  borderLeft: '1px solid #ddd',
  borderRight: '1px solid #ddd',
}));

export const StaticStructure = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '120px 1fr',
  marginBottom: '10px',
}));

export const DynamicStructure = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '120px 1fr 100px',
}));

// \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ LEFT SECTION END \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\

// \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ RIGHT SECTION \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\

export const Container = styled(Box)(() => ({
  borderLeft: `1px solid ${COLORS.grey[500]}`,
  borderRight: `1px solid ${COLORS.grey[500]}`,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'max-content 1fr',
}));

export const JobStatusAndVendorWrapper = styled(Box)(() => ({
  padding: '10px',
}));

export const JobStatusWrapper = styled(Box)(() => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '107px 1fr',
}));

export const Proof = styled(Box)(() => ({

}));

export const ProofTitle = styled(Typography)(() => ({
  padding: '10px',
  background: '#FAFAFA',
  borderTop: `1px solid ${COLORS.grey[500]}`,
  borderBottom: `1px solid ${COLORS.grey[500]}`,

  color: '#06152B',
  fontFamily: "DM Sans",
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '120%',
  textTransform: 'uppercase',
}));

// \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ RIGHT SECTION END \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\ \\\

// ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ###

export const FirstViewTitles = styled(Box)<{customborder?: number}>(({ customborder }) => {
  const defaultStyle = {
    background: COLORS.grey[700],
    fontSize: '12px',
    height: '54px',
    padding: '20px 16px',
  };

  if (customborder) {
    return ({
      ...defaultStyle,
      borderTop: `1px solid ${COLORS.grey[500]}`,
      borderBottom: `1px solid ${COLORS.grey[500]}`,
    });
  }

  return ({
    ...defaultStyle,
    border: `1px solid ${COLORS.grey[500]}`,
  });
});

export const FirstViewBody = styled(Box)<{ padding?: number }>(({ padding }) => ({
  border: `1px solid ${COLORS.grey[500]}`,
  borderTop: 'none',
  padding: padding === undefined ? '20px 16px' : padding,
}));

export const MailingServiceFiled = styled(Box)(() => ({
  padding: '20px 16px',
  maxWidth: '500px',
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '.MuiInputBase-root': {
    width: '300px',
  },
}));

export const MailingFileUploader = styled(Box)(() => ({
  padding: '20px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '425px',

  button: {
    width: '157px',
  },
}));

// ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ###
