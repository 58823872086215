import { memo } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import { useFormContext } from 'react-hook-form';
import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '@features/app/hooks';
import { deleteProductsQuantity, getAllProductsQuantities } from '@features/products/productsQuantity/actions';
import { useParams } from 'react-router-dom';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';

interface IQuantityTable {
  idx: number;
}

const QuantityTable = ({ idx }: IQuantityTable) => {
  const dispatch = useAppDispatch();
  const { watch } = useFormContext();
  const { id } = useParams();

  const { quantity, id: quantityId } = watch(`quantities[${idx}]`);
  const deleteAction = () => {
    dispatch(deleteProductsQuantity({ productId: id as string, quantityId })).unwrap().then(() => {
      dispatch(getAllProductsQuantities(id as string)).unwrap().then(() => {}).catch(() => { });
    }).catch(() => {});
  };

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant="body3">
          {quantity}
        </Typography>
      </StyledTableCell>
      <DeleteBtn
        deleteAction={() => deleteAction()}
        questionText="Are you sure you want to delete this quantity ?"
      />
    </StyledTableRow>
  );
};

export default memo(QuantityTable);
