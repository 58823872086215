import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import Select from '@containers/common/Select';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { constructQueryString } from '@utils/helpers';
import SearchBtn from '@containers/common/SearchSection/SearchBtn';
import { GlobalQueryString } from '@utils/globalTypes';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm, visibilityOptions } from './helpers';

const SearchSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;

  const { searchTerm = '', visibleOnSite = '' } = params;

  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema),
    defaultValues: { searchTerm, visibleOnSite },
  });

  const { handleSubmit, register } = methods;

  const onSubmit = (data: IFiltersForm) => {
    const queryParams = constructQueryString({ ...data });

    navigate(`${PAGE_ROUTES.MENU_CATEGORIES}?${queryParams}`);
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Search"
            />
            <Select
              label=""
              name="visibleOnSite"
              placeholderText="Search by visible on site"
              options={visibilityOptions}
            />
            <SearchBtn path={PAGE_ROUTES.MENU_CATEGORIES} />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
