import { memo } from 'react';

import Typography from '@mui/material/Typography';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Stack from '@mui/material/Stack';
import AddTextBtn from '@containers/common/Table/components/AddTextBtn';
import { useFormContext } from 'react-hook-form';
import RowTitle from '@containers/common/Table/components/RowTitle';

const BillingAddress = () => {
  const { watch } = useFormContext();
  const { billingInfo = {}, id: customerId } = watch();
  const { id, address1, state, country, city, zipCode, company } = billingInfo;
  const handleMap = () => {};

  return (
    <RowComponent label="Billing Address">
      {id && (
      <Stack direction="row" alignItems="center" gap="16px">
        <Stack>
          <Typography>{address1}</Typography>
          <Typography>{company}</Typography>
          <Typography>{city}</Typography>
          <Typography>{state}</Typography>
          <Typography>{country}</Typography>
          <Typography>{zipCode}</Typography>
        </Stack>

        <RowTitle title="Edit" path="BILLING_ADDRESS" params={{ billingId: id, customerId }} />
        <AddTextBtn text="Map it" handleAdd={handleMap} />
      </Stack>
      )}

    </RowComponent>
  );
};

export default memo(BillingAddress);
