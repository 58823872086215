'use client';

import { COLORS } from '@customTypes/global/theme/colors';
import { Box, styled } from '@mui/material';

export const UploadContainer = styled(Box)(() => ({
  background: '#f3f3f2',
  padding: '18.5px 20px',
}));

export const StyledOl = styled('ol')(() => ({
   marginTop: '16px',
}));

export const StyledLi = styled('li')(({ theme }) => ({
  display: 'block',
  gap: '5px',
  color: COLORS.grey[500],

  a: {
    marginRight: '10px',
    display: 'inline',
    color: COLORS.blue.dark,
    textDecoration: 'underline',
  },
}));

export const SubTitle = styled('h3')(() => ({
  width: '100%',
  whiteSpace: 'nowrap',
}));

export const StyledDescription = styled('p')(() => ({
  color: COLORS.grey[500],
}));

export const TitleWrapper = styled('p')(() => ({
  display: "flex",
  gap: '20px',
  flexDirection: 'row-reverse',
  justifyContent: "space-between",
}));

export const DragAndDropContainer = styled('p')(() => ({
   display: 'flex',
  gap: '35px',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '16px',

  '.or': {
    color: COLORS.grey[500],
  },
}));
