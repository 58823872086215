import { Theme, alpha } from '@mui/material';

export const gettingColor = (color: string, theme: Theme) => {
  const colorObj:Record<string, string> = {
    red: theme.palette.error.dark,
    blue: theme.palette.primary.dark,
    grey: theme.palette.grey[300],
    green: theme.palette.custom.green[200],
    placeholderText: alpha(theme.palette.grey[300], 0.4),
  };

  return colorObj[color] || '';
};
