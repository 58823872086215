import { createRequiredTextWidthValidation, createTextWidthValidation } from '@utils/schemas';
import * as yup from 'yup';
import { ISelectOptions } from '@utils/globalTypes';

import { canadianStateOptions, nonUsStateOptions, usStateOptions } from './optionsList';

export interface IAddDataForm {
  id?: string;
  created: string;
  updated: string;
  deleted: string;
  company: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string | null;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  phone: string;
  isDefault: boolean;
  customerId: string;
}

export const AddDataSchema = yup.object().shape({
  firstName: createRequiredTextWidthValidation('First name', 255),
  lastName: createRequiredTextWidthValidation('Last name', 255),
  address1: createRequiredTextWidthValidation('Address 1', 255),
  city: createRequiredTextWidthValidation('City', 255),
  country: createRequiredTextWidthValidation('Country', 255),
  state: createRequiredTextWidthValidation('State', 255),
  zipCode: createRequiredTextWidthValidation('Zip code', 10, 4),
  address2: createTextWidthValidation(255),
  company: createTextWidthValidation(255),
  phone: createRequiredTextWidthValidation('Phone', 255),
});

export const gettingStateOptions = (country: string): ISelectOptions[] => {
  const stateObj:Record<string, ISelectOptions[]> = {
    US: usStateOptions,
    CA: canadianStateOptions,
    BE: nonUsStateOptions,
  };

  return stateObj[country] || [];
};
