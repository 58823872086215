import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IBlockedIps } from './types';

const prefix = '/blocked-ips';

export const getAllBlockedIps = createAsyncThunk<IBlockedIps[], void, { rejectValue: AxiosData }>(
  'blockedIps/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IBlockedIps[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const unblockBlockedIp = createAsyncThunk<void, string, { rejectValue: AxiosData }>(
  'blockedIps/unblock',
  async (ip, thunkAPI) => {
    try {
      await http.delete(`${prefix}/unblock`, { data: { IP: ip } });
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const blockBlockedIp = createAsyncThunk<void, string, { rejectValue: AxiosData }>(
  'blockedIps/unblock',
  async (ip, thunkAPI) => {
    try {
      await http.post(`${prefix}/block`, { IP: ip });
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
