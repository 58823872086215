import moment from 'moment';
import { DATE_FORMAT, priceFormatting } from '@utils/helpers';

import { PrepareInvoiceDataProps } from "./type";

/**
 *  It is little bit confusing snippet.
 *  This 'prepareInvoiceData' function working for create and update invoice flows.
 *
 *  If working create flow preparing only order data. Another case only invoice
 * * */

export const prepareInvoiceData = ({ invoice, order }: PrepareInvoiceDataProps) => {
  let filledData = {
    total: 'You’ll see the Total once the Item Price and the Tax fields are filled.',
    orderNumber: `${order.orderNumber}`,
    paymentDetails: order?.paymentDetails,
    itemDescription: '',
    orderDate: moment(order?.created).format(`${DATE_FORMAT}, hh:mm A`),
    customer: `${order?.customer?.firstName} ${order?.customer?.lastName}`,
    emailInvoiceToCustomer: invoice?.emailInvoiceToCustomer || false,
  } as Record<string, any>;

  if (invoice && Object.keys(invoice).length) {
    filledData = {
      created: invoice.created ? moment(invoice?.created).format(`${DATE_FORMAT}, hh:mm A`) : null,
      tax: invoice.tax,
      subtotal: `${priceFormatting(invoice.subtotal)}`,
      orderNumber: `${invoice.orderNumber}`,
      invoiceID: invoice.invoiceNumber,
      invoiceStatus: invoice.invoiceStatus,
      itemDescription: invoice.itemDescription,
      itemPrice: invoice.itemPrice,
      orderStatus: invoice.orderStatus,
      billingStatus: invoice.billingStatus,
      paymentDetails: invoice?.paymentDetails || `You’ll see the transaction ID once it is paid.`,
      paymentMethod: invoice.paymentMethod,
      paymentURL: invoice.paymentURLForCustomer,
      customer: `${invoice?.customer?.firstName || ''} ${invoice?.customer?.lastName || ''}`,
      emailInvoiceToCustomer: invoice?.emailInvoiceToCustomer || false,
    };
  }

  return filledData;
};
