import { OrderStatusesEnum, ShipmentStatusesEnum } from "../order/enums";

export enum OpenOrdersRequestTypeEnum {
  simple = "simple",
  loadMore = "loadMore",
}

export interface Ids {
  orderId: string;
  jobId: string;
}

export interface IState {
  data: ISet[] | [];
  total: number;
  isLoading: boolean;
  actionLoading: boolean;
  loadMoreLoading: boolean;
  errorMessage: string | null;
  activeActionsRows: string[];
  isHiddenLoadMoreButton: boolean;
  type: OpenOrdersRequestTypeEnum;
  openJobsSelectedRows: string[];
  ids: Ids[];
}

export interface IOrder {
  id: string;
  orderId: string;
  jobNumber: string;
  date: string;
  billTo: string;
  hasOpenTasks: boolean;
  cost: number;
  status: OrderStatusesEnum;
  mailPo: string;
  vendorId: string;
  vendorName: string;
  vendorJob: string;
  shipments: Shipment[];
  itemFile: string;
  backArtWork: string;
  frontArtWork: string;
  comment: string;
}

export interface ISet {
  orderId: string;
  jobId: string;
  shipmentId: string;
  setId: string;
  setNumber: string;
  frontArtWork: any;
  backArtWork: any;
  status: OrderStatusesEnum;
  date: string;
  billTo: string;
  vendorJob: any;
  vendorId: any;
  vendorName: any;
  cost: string;
  mailPo: any;
  hasOpenTasks: boolean;
  isEDDMJob: boolean;
  orderWithComment: boolean;
  isYellow: boolean;
  trackingNumber: any;
  trackingCarrier: any;
}

export interface Shipment {
  id: string;
  trackingNumber: number;
  trackingCarrier: ShipmentStatusesEnum;
}

export interface StatusOptions {
  value: string;
  optionName: string;
}
