import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import { getCustomEstimateById } from '@features/customEstimates/actions';
import { ICustomEstimateById } from '@features/customEstimates/types';
import { selectCustomEstimates } from '@features/customEstimates/selectors';
import { getAllAttributeCategoriesBySubs } from '@features/attributeCategories/actions';

import InputsTable from './InputsTable';
import { getCurrentPage, getPath } from '../helpers';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { isLoading } = useAppSelector(selectCustomEstimates);
  const { id = '' } = useParams();
  const pageName = getCurrentPage(pathname);
  const path = getPath(pageName, true);
  const [editData, setEditData] = useState<ICustomEstimateById | null>(null);
  const [attributeCategoryLoading, setAttributeCategoryLoading] = useState(false);

  useMount(() => {
    dispatch(getCustomEstimateById(id)).unwrap().then((data) => {
      setEditData(data);
      if (data.attributes.length) {
        setAttributeCategoryLoading(true);
        dispatch(getAllAttributeCategoriesBySubs(data.subCategoryId));
      }
    }).catch(() => navigate(path))
      .finally(() => setAttributeCategoryLoading(false));
  });

  if (isLoading || attributeCategoryLoading) {
    return <Loader />;
  }

  return (
    <>
      {editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
