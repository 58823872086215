import { memo } from 'react';

import Typography from '@mui/material/Typography';
import RowComponent from '@containers/common/Table/components/RowComponent';

interface IRowWithText {
  text: string;
  label: string;
}

const RowWithText = ({ text, label }: IRowWithText) => (
  <RowComponent label={label}>
    <Typography variant="body3">{ text}</Typography>
  </RowComponent>
);

export default memo(RowWithText);
