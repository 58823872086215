import { useState } from 'react';

import { Box } from '@mui/material';
import { Job } from '@features/orders/order/types';
import { useFormContext } from 'react-hook-form';

import Shipment from './Shipment';
import {
  FirstView,
  Content,
} from './styled';
import JobRightSection from './JobRight';
import JobLeftSection from './JobLeft';
import TopSection from './TopSection';
import EmailUpdate from './Email update';
import { IVendorOptions } from '../../utils/types';

interface IMainJobInformation {
  job: Job;
  jobIndex: number;
  paymentMethod: string;
  vendorsList: IVendorOptions[];
}

const MainJobInformation = ({
  job,
  jobIndex,
  paymentMethod,
  vendorsList,
}: IMainJobInformation) => {
  const isEditable = !job?.isEDDM && paymentMethod === 'payment_terms';

  const keyByIndex = `jobs[${jobIndex}]`;
  const [toggledShipments, setToggledShipments] = useState<string[] | []>([]);
  const { shipments } = job;

  return (
    <>
      <Box sx={{ marginTop: "24px" }}>
        <FirstView>
          <TopSection
            job={job}
          />
          <Content>
            <JobLeftSection
              job={job}
              jobIndex={jobIndex}
              isEditable={isEditable}
              keyByIndex={keyByIndex}
            />
            <JobRightSection
              job={job}
              vendorsList={vendorsList}
              keyByIndex={keyByIndex}
            />
          </Content>
        </FirstView>
      </Box>
      <Shipment
        jobIndex={jobIndex}
        shipmentsList={shipments}
        toggledShipments={toggledShipments}
        setToggledShipments={setToggledShipments}
      />
      <EmailUpdate
        jobIndex={jobIndex}
        keyByIndex={keyByIndex}
      />
    </>
  );
};

export default MainJobInformation;
