import { getCDNImagePath } from "@utils/helpers";
import defaultArtwork from '@assets/images/default-artwork.png';

export interface IUploadFileProps {
  file: FileStateType;
  url: string;
}

export interface ICreateAWSLinkResponse {
  putUrl: string;
  path: string;
}

export type FileStateType = FileList | null;

export const imageTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/postscript',
  'application/pdf',
  'application/x-photoshop', // .psd type
  'image/vnd.adobe.photoshop',
];

export const imgExtensions = [
  '.png',
  '.jpg',
  '.jpeg',
  '.ai',
  '.pdf',
  '.indd',
  '.eps',
  '.psd',
  '.cdr',
];

export const getMimeType = (filename: string): string => {
  const extension = filename.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'webp':
      return 'image/webp';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream'; // Fallback type
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = async (file: any, filePath: any) => {
  try {
    const mimeType = getMimeType(filePath.path);

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType,
      },
      withCredentials: false,
      body: file,
    };

    await fetch(filePath.putUrl, options);
  } catch (error) {
    console.log(error);
  }
};

export const getUploadUrl = async (file: File) => {
  const fileDetails = [file].map(({ name }) => {
    const idx = name.lastIndexOf('.');
    const fileName = name.substring(0, idx).replace(/[ #+?%\\]/g, '');
    const extension = name.substring(idx + 1);

    return {
      name: fileName,
      extension,
    };
  });

  let token = localStorage.getItem('ACCESS_TOKEN') || '';

  token = token?.replace(/"/g, '');

  const response = await fetch(
    process.env.NEXT_PUBLIC_S3_UPLOAD_API_URL || '',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(fileDetails),
    },
  );

  const { data } = await response.json();

  return { fileList: data };
};

export const isValidFileExtension = (file: File) => {
  if (file.name.endsWith('.indd') || file.name.endsWith('.cdr')) {
    return true;
  }

  return imageTypes.includes(file.type);
};

export const requestToArtwork = (artwork: string) => {
  // If uploaded file pdf or something like that and we cant show preview we just show default image

  if (!artwork) {
    return '';
  }

  const path = getCDNImagePath(artwork);

  return /(jpeg|jpg|png|webp)$/i.test(path.split('.').pop() || '') ? path : defaultArtwork;
};
