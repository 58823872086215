import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { StyledLink } from '@containers/Orders/Order/components/common/LinkedString/styled';
import { DATE_FORMAT, getCDNImagePath } from '@utils/helpers';
import { handleWithoutFormRequest } from '@containers/common/FileUploader';
import { useAppDispatch } from '@features/app/hooks';
import deleteSetNestedItemsCross from '@assets/images/delete-set-nested-items-cross.svg';
import { Box } from '@mui/material';

import {
  Content,
  StyledUnderlined,
  TitleText,
  ArtWorkWrapper,
  ImageWrapper,
} from './styled';

interface ArtworkProps {
  jobIndex: number;
  setIndex: number;
  shipmentIndex: number;
  created: string;
}

const Artwork = ({
  jobIndex,
  setIndex,
  created,
  shipmentIndex,
} : ArtworkProps) => {
  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}]`;
  const { setValue, watch } = useFormContext();
  const dispatch = useAppDispatch();

  const backArtWork = watch(`${defaultKey}.backArtWork`);
  const frontArtWork = watch(`${defaultKey}.frontArtWork`);

  return (
    <Content>
      <ArtWorkWrapper>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
          <span>Front:</span>
          {
            frontArtWork ? (
              <StyledUnderlined>
                <StyledLink
                  target="_blank"
                  width="220px"
                  to={getCDNImagePath(frontArtWork)}
                >
                  {frontArtWork}
                </StyledLink>
              </StyledUnderlined>
            ) : ' - '
          }
          </Box>
          <TitleText>
            {frontArtWork && moment(created).format(`${DATE_FORMAT}, hh:mm A`)}
          </TitleText>
        </Box>
        <ImageWrapper>
          {frontArtWork && (
            <img
              src={deleteSetNestedItemsCross}
              alt=""
              onClick={() => {
                setValue(`${defaultKey}.frontArtWork`, '');
                handleWithoutFormRequest({
                  dispatch,
                  watch,
                  fieldPath: `${defaultKey}.frontArtWork`,
                  updateLogicType: 'sets',
                });
              }}
            />
            )}
        </ImageWrapper>
      </ArtWorkWrapper>
      <ArtWorkWrapper>
        <Box>
          <Box
            sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
          <span>Back:</span>
          {
            backArtWork ? (
              <StyledUnderlined>
                <StyledLink
                  target="_blank"
                  width="220px"
                  to={getCDNImagePath(backArtWork)}
                >
                  {backArtWork}
                </StyledLink>
              </StyledUnderlined>
            ) : ' - '
          }
          </Box>
          <TitleText>
            {backArtWork && moment(created).format(`${DATE_FORMAT}, hh:mm A`)}
          </TitleText>
        </Box>
        <ImageWrapper>
          {
            backArtWork && (
              <img
                src={deleteSetNestedItemsCross}
                alt=""
                onClick={() => {
                  setValue(`${defaultKey}.backArtWork`, '');
                  handleWithoutFormRequest({
                    dispatch,
                    watch,
                    fieldPath: `${defaultKey}.backArtWork`,
                    updateLogicType: 'sets',
                  });
                }}
              />
              )
            }
        </ImageWrapper>
      </ArtWorkWrapper>
    </Content>
  );
};

export default Artwork;
