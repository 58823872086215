import { memo } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@containers/common/Select';
import { useFormContext } from 'react-hook-form';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { getCDNImagePath } from '@utils/helpers';
import Link from '@mui/material/Link';

import { isOptions } from './helpers';

interface IInputAfterText {
  name: string;
  label: string;
}

const InputAfterText = ({ name, label }: IInputAfterText) => {
  const { watch, formState: { errors } } = useFormContext();
  const { resellerCertificate, nonProfitCertificate } = watch();

  return (
    <RowComponent label={label} isRequired>
      <Stack direction="row" gap="16px">
        <Select
          width="120px"
          name={name}
          options={isOptions}
          errorMessage={errors?.[name]?.message as string}
        />
        <Stack height="35px" direction="row" gap="3px" alignItems="center">
          <Typography variant="body3">Certificate:</Typography>
          <Typography variant="body3">
            {(name === 'isReseller' && resellerCertificate)
              ? <Link href={getCDNImagePath(resellerCertificate)} target="_blank">File.pdf</Link>
              : ((name === 'isNonProfit' && nonProfitCertificate)
                ? <Link href={getCDNImagePath(nonProfitCertificate)} target="_blank">File.pdf</Link>
                : 'Not Uploaded')}
          </Typography>
        </Stack>
      </Stack>
    </RowComponent>
  );
};

export default memo(InputAfterText);
