import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const Wrapper = styled(Box)(() => ({
  maxWidth: '400px',
  height: 'max-content',
  borderLeft: '1px solid #CFD9E0',
  padding: '0px 24px 24px',
}));

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '18.23px',
  color: COLORS.grey[300],
  width: '340px',
  maxWidth: '340px',
  marginBottom: '25px',
}));

export const SubTitle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '14.4px',
  color: COLORS.black.light,
}));

export const AssignedAttribute = styled(Box)<{ selected: boolean}>(({ selected }) => ({
  display: 'flex',
  padding: '9px',
  borderRadius: '4px',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  whiteSpace: 'nowrap',
  border: '1px solid #CFD9E0',
  cursor: 'pointer',
  background: selected ? 'rgba(207, 217, 224, 1)' : 'white',

  span: {
    color: 'rgba(120, 139, 154, 1)',
  },
}));

export const ListWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px 10px',
  marginTop: '8px',
  marginBottom: '25px',
}));
