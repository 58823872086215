import { memo } from 'react';

import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import StyledTable from '@containers/common/Table';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectBanners } from '@features/banners/selectors';
import { setBanners, setSilders } from '@features/banners/slice';
import Loader from '@containers/common/Loader';
import { deleteBanner, getAllBanners, reorderBanners } from '@features/banners/actions';
import RowTitle from '@containers/common/Table/components/EditCell';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import DndContainer from '@containers/common/Table/components/DndContainer';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import Typography from '@mui/material/Typography';
import TCell from '@containers/common/Table/components/TCell';

import { headBannerCells, headSliderCells } from './helpers';

interface IBannersProps {
  isSlider?: boolean;
}

const Banners = ({ isSlider }: IBannersProps) => {
  const dispatch = useAppDispatch();
  const deleteAction = (id: string) => {
    dispatch(deleteBanner(id)).unwrap().then(() => {
      dispatch(getAllBanners());
    }).catch(() => {});
  };

  const { banners, sliders, isLoading } = useAppSelector(selectBanners);

  const data = isSlider ? [...sliders] : [...banners];

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, data);

    dispatch(reorderBanners(sortedData)).unwrap().then(() => {
      dispatch(isSlider ? setSilders(items) : setBanners(items));
    }).catch(() => dispatch(getAllBanners()));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant="h5" mb="16px">{isSlider ? 'Sliders' : 'Banner'}</Typography>
      <DndContainer reordingData={reordingData}>
        <StyledTable headCells={isSlider ? headSliderCells : headBannerCells}>
          {data.map(({ title, displayOnSite, id = '' }, index) => (
            <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 173px  140px 70px">
              {({ providedDraggable }) => (
                <>
                  <RowTitle title={title} path="EDIT_BANNER" params={{ id }} />
                  <TCell width="173px" text={displayOnSite ? 'Yes' : 'No'} />
                  <DndBtn providedDraggable={providedDraggable} />
                  <DeleteBtn
                    deleteAction={() => deleteAction(id)}
                    questionText="Are you sure you want to delete this banner ?"
                  />
                </>
              ) }
            </ReusableDragRow>
          ))}
        </StyledTable>
      </DndContainer>
    </>
  );
};

export default memo(Banners);
