import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledSearchContainer = styled(Box)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.grey[700],
  border: `1px solid ${theme.palette.grey[500]}`,
  marginBottom: '24px',
}));

export const StyledSearchRows = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',

  '.MuiInputBase-root': {
    width: '200px',
  },
}));

export const StyledDatePicker = styled(Stack)(() => ({
  '.MuiFormControl-root': {
    width: '153px',
  },
}));
