import { memo } from 'react';

import Box from '@mui/material/Box';
import Input from '@containers/common/Input';
import StyledTable from '@containers/common/Table';
import TableCell from '@mui/material/TableCell';
import Button from '@containers/common/Button';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTableRow } from '@containers/common/Table/styled';
import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';

import { StyledTypography } from './styles';
import { useGrandFormatDiscountsLogic } from './useGrandFormatDiscountsLogic';

const GrandFormatDiscounts = () => {
  const {
    collection,
    options,
    validMessages,
    handleAdd,
    handleRemove,
    setCollection,
  } = useGrandFormatDiscountsLogic();

  return (
    <Box mt="24px">
      <StyledTable
        tableTitle="GRAND FORMAT VOLUME PRICING DISCOUNTS"
        colSpan={2}
      >
        <StyledTableRow key="Quantity">
          <StyledTableCell>
            Quantity
          </StyledTableCell>
          <TableCell
            sx={{
              display: 'flex',
              gap: '15px',
              justifyContent: 'space-between',
            }}
          >
            <Input
              width="124px"
              placeholder="Quantity"
              value={collection.quantity}
              onChange={({ target: { value } }) => setCollection((prev) => ({ ...prev, quantity: value }))}
              errorMessage={validMessages.quantity}
            />
            <Input
              width="124px"
              placeholder="Discount Percent"
              value={collection.discountPercent}
              onChange={({ target: { value } }) => setCollection((prev) => ({ ...prev, discountPercent: value }))}
              errorMessage={validMessages.discountPercent}
            />
            <Button type="button" onClick={handleAdd}>Add</Button>
          </TableCell>
        </StyledTableRow>
        {
          options?.length ? (
            <StyledTableRow key="Active discounts">
              <StyledTableCell>
                Active discounts
              </StyledTableCell>
              <TableCell>
                {options?.map((item, index) => (
                  <StyledTypography
                    key={item.quantity}
                  >
                    Qty:
                    <span>
                      {item.quantity}
                      {' '}
                      +
                    </span>
                    Discount:
                    <span>
                      {item.discountPercent}
                      %
                    </span>
                    <span onClick={() => handleRemove(index)}>
                      <CloseIcon />
                    </span>
                  </StyledTypography>
                ))}
              </TableCell>
            </StyledTableRow>
          ) : null
        }
      </StyledTable>
    </Box>
  );
};

export default memo(GrandFormatDiscounts);
