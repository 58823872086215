import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const RadioButtons = styled(Box)(() => ({
  padding: '2px 0px 0px 0px',
  display: 'flex',
  gap: '15px',
  justifyContent: 'space-between',
}));

export const RadioLabel = styled('label')(() => ({
  gap: '5px',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SpecialBox = styled('label')(() => ({
  '.MuiStack-root': {
    maxHeight: '22px !important',
    height: '22px  !important',

    "> *": {
      maxHeight: '22px  !important',
      height: '22px  !important',
    },

    input: {
      padding: '0px 12px !important',
      maxHeight: '22px  !important',
      height: '19px  !important',
    },
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '11px !important',
}));
