import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';
import { Link } from 'react-router-dom';

export const FirstViewBodyException = styled(Box)<{ padding?: number }>(({ padding }) => ({
  gap: '0',
  display: 'flex',
  borderTop: 'none',
  flexDirection: 'column',
}));

export const StyledWrapper = styled(Box)(() => ({
  marginTop: '16px',
  gap: '16px',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '120px 1fr  100px',
  fontSize: '12px',

  label: {
    textTransform: 'capitalize',
    fontWeight: '500',
  },

  '> div:first-of-type': {
    fontWeight: '500',
  },

  input: {
    width: '100%',
  },
}));

export const StyledWrapperDynamically = styled(Box)(() => ({
  gap: '16px',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '100px 1fr 100px',
  marginTop: '0px',
  fontSize: '12px',

  label: {
    textTransform: 'capitalize',
    fontWeight: '500',
  },

  input: {
    width: '100%',
     borderRadius: '4px',
    border: "1px solid #AEBECA",
    background: '#FFF',
    padding: '6px 12px',
  },

  div: {
    input: {
      marginLeft: '10px',
      width: '70px',
      borderRadius: '4px',
      border: "1px solid #AEBECA",
      background: '#FFF',
      padding: '6px 12px',
    },
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StatusWrapperDesign = styled(Box)(() => ({
  gap: '16px',
  marginTop: '16px',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 316px  100px',
  fontSize: '12px',

  label: {
    textTransform: 'capitalize',
    fontWeight: '500',
  },
}));

export const StyledLink = styled(Link)(() => ({
  color: COLORS.blue.main,
}));

export const RedLabel = styled('label')(() => ({
  color: COLORS.red.dark,
  textTransform: 'capitalize',
  fontWeight: '500',
}));
