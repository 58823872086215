import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import { IAddPromoCodePayload, IPromoCode } from './types';

const prefix = '/promo-code';

export const addPromoCode = createAsyncThunk<void, IAddPromoCodePayload, { rejectValue: AxiosData}>(
  'promoCodes/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddPromoCodePayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllPromoCodes = createAsyncThunk<IPromoCode[], void, { rejectValue: AxiosData}>(
  'promoCodes/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IPromoCode[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getPromoCodeById = createAsyncThunk<IPromoCode, string, { rejectValue: AxiosData}>(
  'promoCodes/getPromoCode',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IPromoCode>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editPromoCode = createAsyncThunk<void, IAddPromoCodePayload, { rejectValue: AxiosData}>(
  'promoCodes/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddPromoCodePayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deletePromoCode = createAsyncThunk<void, string, {
  rejectValue: AxiosData;
}>(
  'promoCodes/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const reorderPromoCodes = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'promoCodes/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchPromoCodes = createAsyncThunk<IPromoCode[], Filters, {
  rejectValue: AxiosData;
}>(
  'promoCodes/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<IPromoCode[]>>(
        `${prefix}/search?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
