import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { StyledContainer } from '@containers/NotFound/styled';

const AccessDenied = () => (
  <StyledContainer>
    <Typography variant="h2">
      Access Denied
    </Typography>
  </StyledContainer>
);

export default memo(AccessDenied);
