import { memo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { StyledEmptyContainer } from '../../styles';

interface IFileLoadingComponent {
  isMultiple?: boolean;
}

const FileLoadingComponent = ({ isMultiple }: IFileLoadingComponent) => (
  <StyledEmptyContainer isMultiple={isMultiple}>
    <CircularProgress size="20px" color="primary" />
  </StyledEmptyContainer>
);

export default memo(FileLoadingComponent);
