import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  marginLeft: '-200px',

  [theme.breakpoints.down('xl')]: {
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: '0px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
  },
}));
