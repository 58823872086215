import { memo } from 'react';

import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import Box from '@mui/material/Box';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import EditCell from '@containers/common/Table/components/EditCell';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import useMount from '@customHooks/useMount';
import TCell from '@containers/common/Table/components/TCell';
import { deleteCharity, getAllCharities, reorderCharities } from '@features/giveBack/actions';
import { selectCharities } from '@features/giveBack/selectors';
import { setCharities } from '@features/giveBack/slice';
import { getCDNImagePath } from '@utils/helpers';
import { usePrevScroll } from "@utils/prevState";

import { headCells } from './helpers';

const GiveBackPage = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(getAllCharities());
  });

  const deleteAction = (id: string) => {
    dispatch(deleteCharity(id)).unwrap().then(() => dispatch(getAllCharities()));
  };

  const { data: charitiesList, isLoading } = useAppSelector(selectCharities);

  const reorderData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, charitiesList);

    if (sortedData) {
      dispatch(reorderCharities(sortedData)).unwrap().then(() => dispatch(setCharities(items)));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Give Back" btnName="Add new" path={PAGE_ROUTES.ADD_GIVE_BACK} />
      {charitiesList?.length
        ? (
          <Box mb="32px">
            <DndContainer reordingData={reorderData}>
              <StyledTable headCells={headCells || []}>
                { charitiesList.map(({ name, id, logo, link }, index) => (

                  <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="128px auto  140px 70px">
                    {({ providedDraggable }) => (
                      <>
                        <TCell width="128px" text="logo">
                          <img
                            src={getCDNImagePath(logo)}
                            alt="logo"
                            width={120}
                            height={46}
                            style={{ objectFit: 'cover', cursor: 'pointer' }}
                            onClick={() => window.location.href = link}
                          />
                        </TCell>

                        <EditCell title={name} path="EDIT_GIVE_BACK" params={{ id }} />
                        <DndBtn providedDraggable={providedDraggable} />
                        <DeleteBtn
                          deleteAction={() => deleteAction(id)}
                          questionText="Are you sure you want to delete this template ?"
                        />

                      </>
                    )}
                  </ReusableDragRow>
                ))}
              </StyledTable>
            </DndContainer>
          </Box>
        )
        : (
          <EmptyState
            text="You don’t have any Charity"
          />
        )}
    </>
  );
};

export default memo(GiveBackPage);
