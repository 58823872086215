import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import { IAddEmailTemplatePayload, IEmailTemplate, ISendEmailTemplatePayload } from './types';

const prefix = '/email-templates';

export const addEmailTemplate = createAsyncThunk<void, IAddEmailTemplatePayload, { rejectValue: AxiosData}>(
  'emailTemplate/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddEmailTemplatePayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getEmailTemplateById = createAsyncThunk<IEmailTemplate, string, { rejectValue: AxiosData}>(
  'emailTemplate/getTemplateCategories',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IEmailTemplate>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editEmailTemplate = createAsyncThunk<void, IAddEmailTemplatePayload, { rejectValue: AxiosData}>(
  'emailTemplate/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddEmailTemplatePayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteEmailTemplate = createAsyncThunk<void, string, {
  rejectValue: AxiosData;
}>(
  'emailTemplate/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchEmailTemplates = createAsyncThunk<IEmailTemplate[], Filters, {
  rejectValue: AxiosData;
}>(
  'emailTemplate/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<IEmailTemplate[]>>(
        `${prefix}?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getOrderEmailsHistory = createAsyncThunk<
  any,
  {id: string},
  { rejectValue: AxiosData }
>("orders-emails/list", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IEmailTemplate[]>>(`${prefix}/orders/${id}/email-history`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const sendEmailTemplate = createAsyncThunk<void, ISendEmailTemplatePayload, { rejectValue: AxiosData}>(
  'emailTemplate/send',
  async (body, thunkAPI) => {
    try {
      await http.post<ISendEmailTemplatePayload>(`${prefix}/send`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
