import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IAddMailingServiceFeePayload, IEditMailingServiceFeePayload, IMailingServiceFee } from './types';

const prefix = '/mailing-fees';

export const addMailingServiceFee = createAsyncThunk<void, IAddMailingServiceFeePayload, { rejectValue: AxiosData}>(
  'mailingServiceFee/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddMailingServiceFeePayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllMailingServiceFees = createAsyncThunk<IMailingServiceFee[], void, { rejectValue: AxiosData}>(
  'mailingServiceFee/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IMailingServiceFee[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getMailingServiceFeeById = createAsyncThunk<IMailingServiceFee, string, { rejectValue: AxiosData}>(
  'mailingServiceFee/get-user',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IMailingServiceFee>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editMailingServiceFee = createAsyncThunk<void, IEditMailingServiceFeePayload, { rejectValue: AxiosData}>(
  'mailingServiceFee/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddMailingServiceFeePayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteMailingServiceFee = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'mailingServiceFee/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
