import { memo } from 'react';

// import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import { StyledTableRow } from '@containers/common/Table/styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';
import { IProductsSetPrice } from '@features/products/setPrice/types';
import Input from '@containers/common/Input';
import TableCell from '@mui/material/TableCell';
import { Radio, Box } from '@mui/material';
import { priceFormatting } from '@utils/helpers';

import AttributeCell from '../AttributeCell';

interface ITableRow {
  rowIdx: number;
}

const TableRow = ({ rowIdx }: ITableRow) => {
  const { watch, register, formState: { errors }, setValue } = useFormContext();
  const { quantity, attributeCategories, marginType, size, originalPrice } = watch(`quantities[${rowIdx}]`);

  const showTotal = () => {
    if (Number.isNaN(+originalPrice) || Number.isNaN(+size)) {
      return null;
    }

    // there is basePrice but didn't use anymoregit
    let total = 0;

    if (marginType === 'percent') {
      total = +originalPrice + (originalPrice * +size / 100);
    } else {
      total = +originalPrice + +size;
    }

    return priceFormatting(Math.round(total));
  };

  return (
    <StyledTableRow>
      <TableCell width="100px" sx={{ padding: '10px!important' }}>
        <Stack gap="8px" direction="column" alignItems="flex-start">
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body3"
            >
              Quantity:
              {' '}
              {quantity}
            </Typography>
            <Input
              width="100px"
              {...register(`quantities[${rowIdx}].originalPrice`)}
              placeholder="Base Price"
              errorMessage={(errors as any)?.quantities?.[rowIdx]?.originalPrice?.message}
            />
          </Box>

          <Box>
            Margin
          </Box>
          <Box display="flex" gap="15px">
            <label>
              <Radio
                sx={{ padding: '5px 5px 5px 0px !important' }}
                size="small"
                value="percent"
                name="dollar-percent"
                checked={marginType === 'percent'}
                onChange={() => {
                  setValue(`quantities[${rowIdx}].marginType`, 'percent');
                }}
              />
              %
            </label>
            <label>
              <Radio
                sx={{ padding: '5px 5px 5px 0px !important' }}
                size="small"
                value="dollar"
                name="dollar-percent"
                checked={marginType === 'dollar'}
                onChange={() => {
                  setValue(`quantities[${rowIdx}].marginType`, 'dollar');
                }}
              />
              $
            </label>
          </Box>
          <Input
            type="number"
            width="100px"
            {...register(`quantities[${rowIdx}].size`)}
            placeholder="Size"
            errorMessage={(errors as any)?.quantities?.[rowIdx]?.size?.message}
          />
          Total:
          {' '}
          {showTotal()}
        </Stack>
      </TableCell>
      {attributeCategories.map((_:IProductsSetPrice, attrIdx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <AttributeCell key={attrIdx} rowIdx={rowIdx} attrIdx={attrIdx} />
      ))}
    </StyledTableRow>
  );
};

export default memo(TableRow);
