import { ReactNode, memo } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface IInputAfterText {
  children: ReactNode;
  label?: string;
}

const InputAfterText = ({ children, label }: IInputAfterText) => (
  <Stack direction="row" gap="16px">
    {children}
    <Stack height="35px" justifyContent="center">
      <Typography variant="body3">{label}</Typography>
    </Stack>
  </Stack>
);

export default memo(InputAfterText);
