export const headCells = [
  {
    label: 'Menu Category',
  },
  {
    label: 'VISIBLE ON SITE',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
