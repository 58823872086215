import { Content } from '../../styled';

const ProofUpload = () => {
  return (
    <Content>
      Proof Upload
    </Content>
  );
};

export default ProofUpload;
