import { Navigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import ProcurementCustomers from '@containers/Customers/ProcurementCustomers';
import Customers from '@containers/Customers/Customers';
import EditCustomers from '@containers/Customers/Customers/EditCustomers';
import AddTask from '@containers/Administration/Tasks/AddComponent';
import EditTask from '@containers/Administration/Tasks/EditComponent';
import EditBillingInfo from '@containers/Customers/Customers/BillingInfo';
import Requests from '@containers/Customers/Requests';
import ContactFormMessage from '@containers/Customers/ContactFormMessage';

const CustomersRoutes = {
  path: PAGE_ROUTES.CUSTOMERS,
  children: [
    { element: <Navigate to={PAGE_ROUTES.CUSTOMERS_CUSTOMERS} />, index: true },
    { path: PAGE_ROUTES.CUSTOMERS_CUSTOMERS,
      children: [
        { element: <Customers />, index: true },
        { path: PAGE_ROUTES.EDIT_CUSTOMER, element: <EditCustomers /> },
        { path: PAGE_ROUTES.ADD_CUSTOMERS_TASK, element: <AddTask /> },
        { path: PAGE_ROUTES.EDIT_CUSTOMERS_TASK, element: <EditTask /> },
        { path: PAGE_ROUTES.BILLING_ADDRESS, element: <EditBillingInfo /> },
      ],
    },
    { path: PAGE_ROUTES.REQUESTS,
      children: [
        { element: <Requests />, index: true },
      ] },
    { path: PAGE_ROUTES.PROCUREMENT_CUSTOMERS, element: <ProcurementCustomers /> },
    { path: PAGE_ROUTES.CONTACT_FORM_MESSAGE, element: <ContactFormMessage /> },
  ],
};

export default CustomersRoutes;
