import Table from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import TCell from '@containers/common/Table/components/TCell';
import { IOrder } from '@features/orders/order/types';
import { priceFormatting } from '@utils/helpers';

import { TableWrapper } from './styled';

const SubTotal = ({ order }: {order: IOrder }) => {
  const isEditable = order.paymentMethod === 'payment_terms'; // !order?.isEDDM &&

  const discountAmount = order?.discountAmount ? `${order?.discountAmount}` : '';

  const formatDiscount = (value: string) => {
    const fixedValue = Number(value).toFixed(2);

    return +value < 0 ? `- $ ${Math.abs(+fixedValue)}` : `$ ${fixedValue}`;
  };

  return (
    <TableWrapper>
      <Table
        title=""
        headCells={[
          { label: 'SUBTOTAL' },
          { label: 'TAX' },
          { label: 'SHIPPING' },
          { label: 'ORDER DISCOUNT' },
          { label: 'TOTAL' },
          { label: 'GIVE BACK' },
        ]}
      >
        <StyledTableRow>
          <TCell text={isEditable ? priceFormatting(order.editableSubtotal) : priceFormatting(order.subTotal)} />
          <TCell text={isEditable ? priceFormatting(order.editableTax) : priceFormatting(order.tax)} />
          <TCell text={isEditable ? priceFormatting(order.editableShipping) : priceFormatting(order.shipping)} />
          <TCell text={formatDiscount(discountAmount)} />
          <TCell text={isEditable ? priceFormatting(order.editableTotal) : priceFormatting(order.total)} />
          <TCell text={order.charity} />
        </StyledTableRow>
      </Table>
    </TableWrapper>
  );
};

export default SubTotal;
