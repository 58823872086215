import { memo } from 'react';

import Typography from '@mui/material/Typography';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Stack from '@mui/material/Stack';
import AddTextBtn from '@containers/common/Table/components/AddTextBtn';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from '@features/app/hooks';
import { blockBlockedIp, unblockBlockedIp } from '@features/customers/blockedIps/actions';

const IPRow = () => {
  const dispatch = useAppDispatch();
  const { watch, setValue } = useFormContext();
  const { banned } = watch();
  const { IPAddress } = watch();

  const handleBlock = () => {
    dispatch(banned ? unblockBlockedIp(IPAddress) : blockBlockedIp(IPAddress)).then(() => {
      setValue('banned', !banned);
      setValue('status', !banned ? 'disabled' : 'active');
    }).catch(() => {});
  };

  return (
    <RowComponent label="IP Address When Account Created">
      <Stack direction="row" alignItems="center" gap="16px">
        <Typography variant="body3">{IPAddress}</Typography>
        <AddTextBtn text={`${banned ? 'Unblock' : 'Block'} this IP`} handleAdd={handleBlock} />
      </Stack>
    </RowComponent>
  );
};

export default memo(IPRow);
