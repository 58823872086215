import { memo } from 'react';

import { ICustomer } from '@features/customers/customers/types';

import InputsTable from './InputsTable';

interface IEditComponent {
  editData: ICustomer | null;
}

const EditComponent = ({ editData }:IEditComponent) => {
  return (
    <>
      {editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
