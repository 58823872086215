import { integerSchema } from '@utils/schemas';
import * as yup from 'yup';

export const defaultValues = {
  rate0: '',
  rate1: '',
  rate2: '',
  rate3: '',
  rate4: '',
  rate5: '',
  rate6: '',
  rate7: '',
  rate8: '',
  rate9: '',
  rate10: '',
};

const rateSchema = integerSchema
  .required('Rate is required')
  .max(100, 'Should be a maximum of 100');

export const Schema = {
  rate0: rateSchema,
  rate1: rateSchema,
  rate2: rateSchema,
  rate3: rateSchema,
  rate4: rateSchema,
  rate5: rateSchema,
  rate6: rateSchema,
  rate7: rateSchema,
  rate8: rateSchema,
  rate9: rateSchema,
  rate10: rateSchema,
};

export const EditDataSchema = yup.object().shape({
  ...Schema,
});

export const AddDataSchema = yup.object().shape({
  ...Schema,
});
