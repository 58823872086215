import { memo } from 'react';

import StyledTable from '@containers/common/Table';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import RowComponent from '@containers/common/Table/components/RowComponent';

import { valCases } from './helpers';
import FromToStack from './components/FromToStack';
import { StyledInput } from './styles';
import FromToErrorMessage from './components/FromToErrorMessage';

const GrandFormatOptions = () => {
  const { formState: { errors, isSubmitted }, register, watch } = useFormContext();

  const { grandFormatOptions: { minWidth, maxWidth, minHeight, maxHeight } } = watch();

  const isGreaterThanWidth = isSubmitted && minWidth && maxWidth && (+minWidth > +maxWidth);

  const isGreaterThanHeight = isSubmitted && minHeight && maxHeight && (+minHeight > +maxHeight);

  return (
    <Box mt="24px">
      <StyledTable tableTitle="GRAND FORMAT PRODUCT OPTIONS" colSpan={2}>
        <RowComponent label="Width Limit (Inches)" isRequired>
          <FromToStack text="">
            <StyledInput
              placeholder="Min width (in)"
              {...register('grandFormatOptions.minWidth')}
              errorMessage={(errors as any)?.grandFormatOptions?.minWidth?.message}
              showError={isGreaterThanWidth}
            />
            <StyledInput
              placeholder="Max width (in)"
              {...register('grandFormatOptions.maxWidth')}
              errorMessage={(errors as any)?.grandFormatOptions?.maxWidth?.message}
              showError={isGreaterThanWidth}
            />
          </FromToStack>
          {isGreaterThanWidth && <FromToErrorMessage message={valCases.isGreaterThanWidth} />}
        </RowComponent>
        <RowComponent label="Height Limit (Inches)" isRequired>
          <FromToStack text="">
            <StyledInput
              placeholder="Min height (in)"
              {...register('grandFormatOptions.minHeight')}
              errorMessage={(errors as any)?.grandFormatOptions?.minHeight?.message}
              showError={isGreaterThanHeight}
            />
            <StyledInput
              placeholder="Max height (in)"
              {...register('grandFormatOptions.maxHeight')}
              errorMessage={(errors as any)?.grandFormatOptions?.maxHeight?.message}
              showError={isGreaterThanHeight}
            />
          </FromToStack>
          {isGreaterThanHeight && <FromToErrorMessage message={valCases.isGreaterThanHeight} />}
        </RowComponent>
      </StyledTable>
    </Box>
  );
};

export default memo(GrandFormatOptions);
