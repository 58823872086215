import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { Box, Checkbox } from '@mui/material';

interface IAttributeItem {
  isAssigned?: boolean;
  attributeIdx: number;
  attrCategoryIdx: number;
}

const AttributeItem = ({ isAssigned, attributeIdx, attrCategoryIdx }: IAttributeItem) => {
  const { setValue, watch } = useFormContext();
  const keyInState = `attributesList[${attrCategoryIdx}].attributes[${attributeIdx}]`;

  const { nickname, isSelected } = watch(keyInState);

  return isAssigned && !isSelected ? null : (
    <Box
      sx={{
        gap: '10px',
        display: 'flex',
        alignItems: 'center',

        span: {
          padding: 0,
        },
      }}
    >
      <Checkbox
        name={nickname}
        checked={isSelected}
        onClick={() => {
          setValue(`${keyInState}.isSelected`, !isSelected);
        }}
      />
      <Typography variant="body3" noWrap>{nickname}</Typography>
    </Box>
  );
};

export default memo(AttributeItem);
