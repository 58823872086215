import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Textarea from '@containers/common/Textarea';

export const Section = styled(Box)(() => ({

}));

export const MessagesList = styled(Box)(() => ({
  maxHeight: '440px',
  height: 'max-content',
  width: '100%',
  'overflow-y': 'auto',
  scrollbarColor: '#777 #D9D9D9',
  scrollbarWidth: 'thin',
  paddingRight: '8px',
}));

export const LeftMessage = styled(Box)(() => ({
  width: '100%',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'start',
  wordBreak: 'break-word',

  p: {
    padding: '10px',
    background: '#FAFAFA',
    maxWidth: '90%',
    width: 'max-content',

    span: {
      display: 'block',
    },
  },
}));

export const RightMessage = styled(Box)(() => ({
  width: '100%',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'end',
  wordBreak: 'break-word',

  p: {
    textAlign: 'right',
    padding: '10px',
    background: '#E8F2FF',
    maxWidth: '90%',
    width: 'max-content',

    span: {
      display: 'block',
      textAlign: 'right',
    },
  },
}));

export const LoaderWrapper = styled(Typography)(() => ({
  maxHeight: '440px',
  height: '440px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTextarea = styled(Textarea)(() => ({
  marginTop: '16px',
  height: '65px !important',
  maxHeight: '65px !important',
  minHeight: '48px !important',
}));

export const SendEmailWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '5px',
  marginTop: '12px',
  marginBottom: '24px',
}));

export const MissingMessage = styled(Box)(() => ({
  background: '#FAFAFA',
  textAlign: 'center',
  color: '#788B9A',
  fontSize: '12px',
  padding: '10px',
}));
