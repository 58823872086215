import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledTitleBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '28px',
  flexWrap: 'wrap',
  gap: '16px',
}));
