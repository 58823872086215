import { memo } from 'react';

import Stack from '@mui/material/Stack';
import Textarea from '@containers/common/Textarea';
import { useFormContext } from 'react-hook-form';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';

const InternalOrderNotes = () => {
  const location = useLocation();
  const { pathname } = location;
  const { register, formState: { errors } } = useFormContext();

  return (
    <Stack>
      <Textarea
        label="Internal Order Notes"
        placeholder="Internal Order Notes"
        {...register('internalOrderNotes')}
        errorMessage={errors?.internalOrderNotes?.message as string}
      />
      <Link href={`${pathname}/view-email-log`} mt="16px" fontSize="12px">View Custom Estimate Email Log</Link>
    </Stack>
  );
};

export default memo(InternalOrderNotes);
