import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import PAGE_ROUTES from '@routes/routingEnum';
import { getBillingInfoById } from '@features/customers/billingInfo/actions';
import { IBillingInfo } from '@features/customers/billingInfo/types';
import { selectBillingInfo } from '@features/customers/billingInfo/selectors';

import InputsTable from './InputsTable';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { billingId } = useParams();
  const [editData, setEditData] = useState<IBillingInfo | null>(null);
  const { isLoading } = useAppSelector(selectBillingInfo);

  useMount(() => {
    dispatch(getBillingInfoById(billingId as string)).unwrap().then((data) => {
      setEditData(data);
    }).catch(() => navigate(PAGE_ROUTES.CUSTOMERS));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
