import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Input from '@containers/common/Input';

import { StyledStack } from './styles';

interface IDimensionsRow {
  label: string;
  width: string;
  height: string;
}

const DimensionsRow = ({ label, width, height }: IDimensionsRow) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <RowComponent label={label}>
      <StyledStack>
        <Stack direction="row" alignItems="center">
          <Typography variant="body3" mr="8px">Width</Typography>
          <Input
            {...register(width)}
            errorMessage={errors?.[width]?.message as string}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="body3" mr="8px">Height</Typography>
          <Input
            {...register(height)}
            errorMessage={errors?.[height]?.message as string}
          />
        </Stack>
      </StyledStack>
    </RowComponent>
  );
};

export default memo(DimensionsRow);
