import { Job } from '@features/orders/order/types';
import dropDownArrowDown from '@assets/images/dropDownArrowDown.svg';

import {
  JobTitleWrapper,
  JobTitle,
} from './styled';

interface TopSectionProps {
  job: Job;
}

const TopSection = ({ job }: TopSectionProps) => {
  return (
    <JobTitleWrapper>
      <JobTitle>
        #
        {job?.jobNumber}
      </JobTitle>
    </JobTitleWrapper>
  );
};

export default TopSection;
