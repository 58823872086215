import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { addShippingZip, editShippingZip, getAllShippingZips, getShippingZipById } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
};

const shippingZipsSlice = createSlice({
  name: 'shippingZip',
  initialState,
  reducers: {
    setShippingZips: (state, { payload }) => {
      state.data = payload;
    },
    resetShippingZips: () => initialState,

  },
  extraReducers: (builder) => {
    builder.addCase(addShippingZip.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addShippingZip.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addShippingZip.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editShippingZip.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editShippingZip.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editShippingZip.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllShippingZips.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllShippingZips.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllShippingZips.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getShippingZipById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getShippingZipById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getShippingZipById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setShippingZips, resetShippingZips } = shippingZipsSlice.actions;

export default shippingZipsSlice.reducer;
