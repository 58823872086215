import { SpecificStateOfFieldEnum } from "@containers/Orders/Sets/enum";

interface GetPriorityProps {
  isYellow: boolean;
  isEDDMJob: boolean;
  hasOpenTasks: boolean;
  orderWithComment: boolean;
}

export const getPriority = ({
  hasOpenTasks,
  orderWithComment,
  isEDDMJob,
  isYellow,
}: GetPriorityProps) => {
  const classes = [];

  if (hasOpenTasks) {
    classes.push('open-task');
  }

  if (isEDDMJob) {
    classes.push('eddm');
  }

  if (orderWithComment) {
    classes.push('with-comment');
  }

  if (isYellow) {
    classes.push('rush');
  }

  return classes.join(' ');
};

export const findIsEditable = <T, >(value: T, extraString: string = '') => {
  return value === SpecificStateOfFieldEnum.isEditable ? extraString || "" : value;
};
