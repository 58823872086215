import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import { FontFamilyNames } from '@customTypes/global/theme/fonts';

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'height',
})<{height?: string}>(({ height }) => ({
  width: '100%',
  borderRadius: 4,
  padding: 0,

  '& textarea': {
    height: height || '72px',
    minHeight: height || '72px',
    maxHeight: height || '72px',
  },
}));

export const StyledTextareaAutosize = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  vtresize: 1 | 0;
  error?: boolean;
}>(({ theme, error, vtresize }) => ({
  width: '100%',
  borderRadius: 4,
  padding: '10px 12px',
  outline: 'none',
  border: `1px solid ${error ? theme.palette.error.dark : theme.palette.grey[500]}`,
  fontSize: '12px',
  fontFamily: FontFamilyNames.DmSansRegular,
  marginBottom: error ? '4px' : '',
  overflow: 'auto  !important',
  height: '100px',
  resize: vtresize ? 'vertical' : 'none',

  '&::placeholder': {
    color: '#ced5da',
    border: 'none',
  },

  '&:hover': {
    borderColor: error ? theme.palette.error.dark : theme.palette.common.black,
  },

  '&:disabled': {
    background: 'none',
    color: '#b1b1b1',
  },

  '&:focus': {
    borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
  },

  '&:hover:focus': {
    borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
  },
}));
