import { memo } from 'react';

import TableCell from '@mui/material/TableCell';

import RowTitle, { IRowTitle } from '../RowTitle';

interface IEditCell extends IRowTitle{
  width?: string;
}

const EditCell = (props: IEditCell) => {
  const { width } = props;

  return (
    <TableCell width={width}>
      <RowTitle {...props} />
    </TableCell>
  );
};

export default memo(EditCell);
