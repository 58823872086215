import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { addTask, editTask, getAllCustomersTasks, getAllTasks, getTaskById } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  customersTasks: null,
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks: (state, { payload }) => state.data = payload,
    resetTasks: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCustomersTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCustomersTasks.fulfilled, (state, { payload }) => {
      state.customersTasks = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCustomersTasks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTasks.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllTasks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addTask.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addTask.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addTask.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editTask.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editTask.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editTask.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getTaskById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTaskById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTaskById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setTasks, resetTasks } = tasksSlice.actions;

export default tasksSlice.reducer;
