import { styled } from '@mui/material/styles';
import Button from '@containers/common/Button';
import { Typography } from '@mui/material';

export const StyledButton = styled(Button)(() => ({
  position: 'relative',
  width: '165px',
  height: '40px',
  padding: '11px 50px 11px 51px',
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: '20px',
}));

export const StyledRedirectText = styled(Typography)(() => ({
  color: '#1D73E3',
  cursor: 'pointer',
  textDecoration: 'underline',
}));
