export const headCells = [
  {
    label: 'ORDER ID',
  },
  {
    label: 'INVOICE ID',
  },
  {
    label: 'DATE',
  },
  {
    label: 'STATUS',
  },
  // {
  //   label: 'BILLING STATUS',
  // },
  {
    label: 'TOTAL',
  },
  {
    label: 'PAYMENT METHOD',
  },
  {
    label: 'DATE BILLED',
  },
];
