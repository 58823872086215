import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const ShipmentContainer = styled(Box)(() => ({

}));

export const TitleWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  cursor: 'pointer',
  borderTop: 'none',
  alignItems: 'center',
  padding: '8.5px 16px',
  background: COLORS.grey[500],
  justifyContent: 'start',
  border: `1px solid ${COLORS.grey[500]}`,

  svg: {
    marginLeft: '5px',
    fontSize: '20px',
  },
}));

export const StyledTitle = styled(Typography)(() => ({
  display: 'flex',
  fontSize: '12px',
  fontWeight: 500,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ShipmentContent = styled(Box)(() => ({
  border: `1px solid ${COLORS.grey[500]}`,
  borderTop: 'none',

  display: 'grid',
  gap: '48px',
  padding: '32px 16px',
  gridTemplateColumns: '340px 290px 290px',
}));

export const ShipmentAndSetsWrapper = styled(Box)<{toggle: number}>(({ toggle }) => ({
  height: toggle ? '0px' : 'auto',
  overflow: toggle ? 'hidden' : 'auto',
  border: `1px solid ${COLORS.grey[500]}`,
  borderTop: 'none',
}));

export const InsideContent = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
}));

export const ContentWrapper = styled(Box)(() => ({
}));

export const TopContainer = styled(Box)(() => ({
  borderBottom: `1px solid ${COLORS.grey[500]}`,
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
}));

export const StyledBox = styled(Box)(() => ({
  padding: '10px',
  borderLeft: `1px solid ${COLORS.grey[500]}`,

  '&:first-of-type': {
    borderLeft: `none`,
  },
}));

export const Underlined = styled(Box)(() => ({
  textDecoration: 'underline',
  color: '#1D73E3',
  marginLeft: '5px',
  cursor: 'pointer',
}));

export const StyledTypography = styled(Typography)(({ color }: { color?: 'red' }) => ({
  fontSize: '12px',
  fontWeight: 600,
  color,
}));
