import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Markup % for Quantities 0 - 100',
    field: 'rate0',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 101 - 499',
    field: 'rate1',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 500 - 999',
    field: 'rate2',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 1000 - 4999',
    field: 'rate3',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 5000 - 9999',
    field: 'rate4',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 10000 - 14999',
    field: 'rate5',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 25000 - 39999',
    field: 'rate6',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 40000 - 59999',
    field: 'rate7',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 60000 - 74999',
    field: 'rate8',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: 'Markup % for Quantities 75000 - 999999',
    field: 'rate9',
    type: InputTypes.text,
    isRequired: false,
  },
  {
    label: '4over Shipping Rate Markup',
    field: 'rate10',
    type: InputTypes.text,
    isRequired: false,
  },
];
