import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { editBillingInfo, getBillingInfoById } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: [],
  errorMessage: null,
  total: 0,
};

const billingInfoSlice = createSlice({
  name: 'billingInfo',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(editBillingInfo.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editBillingInfo.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editBillingInfo.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getBillingInfoById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBillingInfoById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBillingInfoById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default billingInfoSlice.reducer;
