import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Input from '@containers/common/Input';
import Checkbox from '@containers/common/Checkbox';
import StyledTypography from '@containers/common/StyledTypography';
import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';

const UnitWeight = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <StyledTableRow>
      <StyledTableCell>
        Unit Weight (1): *
        <StyledTypography color="red" variant="body3" display="block">
          Make sure this unit price is correct (Multipled by quantity) or else UPS will not work
        </StyledTypography>
      </StyledTableCell>
      <TableCell>
        <Stack maxWidth="400px">
          <Stack direction="row" alignItems="center" gap="24px">
            <Input
              placeholder="Unit Weight"
              width="165px"
              {...register('unitWeight')}
              errorMessage={errors?.unitWeight?.message as string}
            />
            <Stack direction="row" alignItems="center">
              <Checkbox
                name="itemDoesNotShip"
              />
              <StyledTypography ml="-10px" variant="body3" color="grey">Item Does Not Ship</StyledTypography>
            </Stack>

          </Stack>
        </Stack>
      </TableCell>
    </StyledTableRow>
  );
};

export default memo(UnitWeight);
