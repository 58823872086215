import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Stack from '@mui/material/Stack';
import Select from '@containers/common/Select';
import Input from '@containers/common/Input';
import { editCustomer } from '@features/customers/customers/actions';
import Link from '@mui/material/Link';
import Textarea from '@containers/common/Textarea';
import { resetCustomers } from '@features/customers/customers/slice';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { selectCustomers } from '@features/customers/customers/selectors';
import { getRequestsCount } from '@features/sidebarCounts/actions';
import { InputTypes } from '@utils/globalTypes';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';
import { Box, Typography } from '@mui/material';

import { AddDataSchema, IAddDataForm, formattedPayload, statusOptions } from './helpers';
import { IInputsTable } from './types';
import InputAfterText from './components/InputAfterText';
import { inputsRows } from './tableData';
import RowWithText from './components/RowWithText';
import IPRow from './components/IPRow';
import BillingAddress from './components/BillingAddress';
import { StyledStack } from './styled';

const InputsTable = ({ editData }: IInputsTable) => {
  const { actionLoading } = useAppSelector(selectCustomers);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData,
  });

  const { handleSubmit, register, setError, formState: { errors }, watch } = methods;
  const { id: UUID, firstName, lastName, affiliateRegistrationLink, whereYouHear } = watch();

  const onSubmit = (data: IAddDataForm) => {
    const payload = formattedPayload(data);

    dispatch(editCustomer(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

        if (!editData) {
          dispatch(resetCustomers());
          dispatch(getRequestsCount());
          navigate(PAGE_ROUTES.CUSTOMERS);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Customer with the provided email already exists!') {
          setError('email', { message: e.message });
        } else if (e.message === 'Additional email should not match with your personal email') {
          setError('additionalEmail', { message: e.message });
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Stack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
      >
        <StyledTable tableTitle="CUSTOMER INFO" colSpan={2}>
          <IPRow />
          <RowWithText text={`${firstName} ${lastName}`} label="Customer Name" />
          <RowComponent label="Customer Status" isRequired>
            <Select
              width="120px"
              name="status"
              errorMessage={(errors as any)?.status?.message}
              options={statusOptions}
            />
          </RowComponent>
          <RowComponent label="Email Address" isRequired>
            <Input
              placeholder="Email Address"
              {...register('email')}
              errorMessage={errors?.email?.message as string}
            />
          </RowComponent>
          <RowWithText label="UUID" text={UUID} />

          <RowComponent label="Affiliate Registration Link">
            <Link href={affiliateRegistrationLink} target="_blank">{affiliateRegistrationLink}</Link>
          </RowComponent>
          <RowWithText label="Where did you hear about PRU?" text={whereYouHear} />
          <BillingAddress />
          <RowComponent label="Payment Terms">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <ReusableFields
                label=""
                field="activatePaymentTerms"
                type={InputTypes.checkbox}
              />
              <Typography>Enable Payment Terms</Typography>
            </Box>
          </RowComponent>
          <InputAfterText name="isReseller" label="Is a Reseller?" />
          <InputAfterText name="isNonProfit" label="Is a Non-Profit?" />

          {inputsRows.map((item) => (
            <RowComponent key={item.label} {...item}>
              <ReusableFields {...item} />
            </RowComponent>
          ))}
        </StyledTable>
        <StyledStack>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </Stack>
    </FormProvider>
  );
};

export default memo(InputsTable);
