/* eslint-disable react/no-array-index-key */
import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { ICustomEstimate } from '@features/customEstimates/types';
import { editCustomEstimate } from '@features/customEstimates/actions';
import { resetCustomEstimates } from '@features/customEstimates/slice';
import { selectCustomEstimates } from '@features/customEstimates/selectors';
import Stack from '@mui/material/Stack';
import { getEstimatesCount } from '@features/sidebarCounts/actions';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { AddDataSchema, IAddDataForm, formattedPaload, setErrorsIds } from './helpers';
import FirstTable from './components/FirstTable';
import SecondTable from './components/SecondTable';
import InternalOrderNotes from './components/InternalOrderNotes';
import { StyledDivider } from './styles';
import SendMail from './components/SendMail';
import { getCurrentPage, getPath } from '../../helpers';
import Reactivate from './components/Reactivate';

interface IInputsTable{
  editData?: ICustomEstimate;
}

const InputsTable = ({ editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const { actionLoading } = useAppSelector(selectCustomEstimates);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData,
  });

  const { handleSubmit, watch, setError } = methods;
  const { estimateSizeQuotes } = watch();
  const pageName = getCurrentPage(pathname);
  const path = getPath(pageName, true);
  const { attributes } = watch();

  const onSubmit = (data: IAddDataForm) => {
    const payload = formattedPaload(data);

    dispatch(editCustomEstimate(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

        if (!editData) {
          dispatch(getEstimatesCount());
          dispatch(resetCustomEstimates());
          navigate(PAGE_ROUTES[path]);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Date Expire must be later than current date') {
          setError('dateExpire', { message: e.message });
        } else {
          setErrorsIds(e.message, attributes, setError);
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Stack onSubmit={handleSubmit(onSubmit)} component="form">
        <TitlesWithBackButton
          title="View Estimate"
          path={path}
          rightButtons={pageName === 'reactivated' && <Reactivate />}
        >
          <StyledStack>
            <Stack gap="24px">
              <FirstTable />
              {estimateSizeQuotes.map((_, idx) => (
                <SecondTable key={idx} tableIdx={idx} />
              ))}
              <StyledDivider />
              <InternalOrderNotes />
              <SendMail />
            </Stack>
            {pageName !== 'reactivated' && <SubmitBtn actionLoading={actionLoading} />}
            <GlobalUpdatesMessage {...isSuccessMsg} />
          </StyledStack>

        </TitlesWithBackButton>
      </Stack>
    </FormProvider>
  );
};

export default memo(InputsTable);
