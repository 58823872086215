import { integerSchema, numberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IAddDataForm {
  quantity: number;
  levelPrice: number;
  perUnit: number;
  waferLevel: number;
}

export const defaultMailingServiceData = {
  quantity: 0,
  levelPrice: 0,
  perUnit: 0,
  waferLevel: 0,
};
export const AddDataSchema = yup.object().shape({
  quantity: integerSchema.required('Quantity is required').max(999999999, 'The maximum number of digits is 9'),
  levelPrice: numberSchema.required('Level price is required').max(999999999, 'The maximum number of digits is 9'),
  perUnit: numberSchema.required('Per unit is required').max(999999999, 'The maximum number of digits is 9'),
  waferLevel: numberSchema.required('Wafer level is required').max(999999999, 'The maximum number of digits is 9'),
});
