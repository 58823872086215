import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  editCustomEstimate, getAllCustomEstimates, getCustomEstimateById, getEmailLogs, searchCustomEstimates,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  total: 0,
};

const customEstimatesSlice = createSlice({
  name: 'customEstimate',
  initialState,
  reducers: {
    setCustomEstimates: (state, { payload }) => state.data = payload,
    resetCustomEstimates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(editCustomEstimate.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editCustomEstimate.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editCustomEstimate.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllCustomEstimates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCustomEstimates.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCustomEstimates.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCustomEstimateById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomEstimateById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCustomEstimateById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchCustomEstimates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchCustomEstimates.fulfilled, (state, { payload }) => {
      const { customEstimates, total } = payload;

      state.data = customEstimates;
      state.total = total;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchCustomEstimates.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(getEmailLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEmailLogs.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getEmailLogs.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setCustomEstimates, resetCustomEstimates } = customEstimatesSlice.actions;

export default customEstimatesSlice.reducer;
