import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import StyledTable from '@containers/common/Table';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Typography from '@mui/material/Typography';
import RowTitle from '@containers/common/Table/components/RowTitle';
import { formattedDate, getCDNImagePath } from '@utils/helpers';
import DatePicker from '@containers/common/DatePicker';
import Textarea from '@containers/common/Textarea';
import Link from '@mui/material/Link';
import { getCurrentPage } from '@containers/Orders/CustomEstimates/helpers';
import { useLocation } from 'react-router-dom';

import { customEstimateReactivatedStatusOptions, customEstimateStatusOptions, inputsRows } from './helpers';
import Attributes from './Attributes';
import DimensionsRow from './DimensionsRow';
import UnitWeight from './UnitWeight';

const FirstTable = () => {
  const location = useLocation();
  const { pathname } = location;
  const pageName = getCurrentPage(pathname);
  const { watch, formState: { errors }, register } = useFormContext();

  const { estimateId, customer: { firstName, lastName },
    customerId, dateRequested, frontSideArtwork, backSideArtwork } = watch();

  return (
    <StyledTable tableTitle="ESTIMATE DETAILS" colSpan={2}>
      <RowComponent label="Estimate ID">
        <Typography variant="body3">{estimateId}</Typography>
      </RowComponent>
      <RowComponent label="Customer">
        <RowTitle title={`${firstName} ${lastName}`} path="EDIT_CUSTOMER" params={{ id: customerId }} />
      </RowComponent>
      {inputsRows.map((item) => (
        <RowComponent key={item.label} {...item}>
          <ReusableFields
            {...item}
            selectList={[{
              field: 'status',
              options: pageName === 'reactivated'
                ? customEstimateReactivatedStatusOptions : customEstimateStatusOptions,
            }]}
          />
        </RowComponent>
      ))}
      <RowComponent label="Date Requested" isRequired>
        <Typography variant="body3">{formattedDate(dateRequested)}</Typography>
      </RowComponent>
      <RowComponent label="Date Expire" isRequired>
        <DatePicker
          width="120px"
          name="dateExpire"
          inputProps={{ placeholder: 'Date Expire' }}
          errorMessage={errors?.dateExpire?.message as string}
        />
      </RowComponent>
      <UnitWeight />
      <DimensionsRow label="Flat Size" width="flatSizeWidth" height="flatSizeHeight" />
      <DimensionsRow label="Final Size" width="finalSizeWidth" height="finalSizeHeight" />
      <Attributes />
      <RowComponent label="Customer Comments">
        <Textarea
          placeholder="Customer Comments"
          {...register('customerComments')}
          disabled
        />
      </RowComponent>
      <RowComponent label="File Uploads">
        {!backSideArtwork && !frontSideArtwork ? (
          <Typography variant="body3">No files uploaded</Typography>
        ) : (
          <>
            {frontSideArtwork && <Link href={getCDNImagePath(frontSideArtwork)} target="_blank">Front Side</Link>}
            {backSideArtwork && <Link href={getCDNImagePath(backSideArtwork)} target="_blank">Back Side</Link>}
          </>
        )}
      </RowComponent>
    </StyledTable>
  );
};

export default memo(FirstTable);
