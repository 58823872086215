import { memo } from 'react';

import imagePlaceholder from '@assets/images/image-placeholder.webp';
import { imgExtensions } from '@utils/imageService';
import { getCDNImagePath } from '@utils/helpers';
import { Box } from '@mui/material';

import {
  FileInputLabel,
  StyledImage,
  StyledImageWrapper,
  StyledLine,
  StyledWrongFile,
  SuccessfullyUploaded,
  UploadedContentStyled,
} from './styled';
import { CertificateEnumType, ProcessState } from '../..';

interface UploadedContentProps {
  file: string;
  keyType: CertificateEnumType;
  process: ProcessState;
  onDrop: (...rest: any) => {}; // onDropType;
  onChange: (...rest: any) => {}; // onChangeType;
}

const UploadedContent = ({
  file,
  keyType,
  process,
  onDrop,
  onChange,
}: UploadedContentProps) => {
  const acceptedExtension = imgExtensions;

  const loadingProcess = process.isLoading && process.type === keyType;
  const isImage = /\.(jpeg|jpg|png)$/.test(file.toLowerCase());

  return (
    <UploadedContentStyled>
      <StyledImageWrapper>
        {loadingProcess ? (
          <span>Loading...</span>
        ) : (
          <Box
            sx={{ height: '100%' }}
            onDrop={(d) => onDrop(d, keyType)}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              accept={acceptedExtension.join(', ')}
              style={{ display: 'none' }}
              disabled={!!loadingProcess}
            />
            <StyledImage
              width="160"
              height="160"
              src={isImage ? getCDNImagePath(file) : imagePlaceholder}
              alt="false pdf"
            />
          </Box>
        )}
      </StyledImageWrapper>
      <div>
        <SuccessfullyUploaded>
          {loadingProcess ? '...' : 'Your file has been successfully uploaded!'}
        </SuccessfullyUploaded>
        <StyledLine />
        <input
          type="file"
          id={keyType}
          accept={acceptedExtension.join(', ')}
          style={{ display: 'none' }}
          onChange={(e) => onChange(e, keyType)}
        />
        <StyledWrongFile>
          <p>Wrong file? </p>
          <FileInputLabel htmlFor={keyType}> Upload new file</FileInputLabel>
        </StyledWrongFile>
      </div>
    </UploadedContentStyled>
  );
};

export default memo(UploadedContent);
