import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { Box } from '@mui/material';

// This is used in product categories page too
export const StyledDivider = styled(Divider)(() => ({
  margin: '40px 0',
  maxWidth: '660px',
}));

export const LocalStyledStack = styled(StyledStack)(() => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  width: 'auto',
}));

export const SectionWrapper = styled(Box)(({ theme }) => ({
  marginLeft: '-200px',

  [theme.breakpoints.down('xl')]: {
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: '0px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
  },
}));
