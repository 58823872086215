import { OrderStatusesEnum } from "@features/orders/order/enums";
import PAGE_ROUTES from "@routes/routingEnum";

export const accessibilityList = [
  PAGE_ROUTES.COMPLETED_ORDERS,
  PAGE_ROUTES.CANCELLED_ORDERS,
  PAGE_ROUTES.OPEN_ORDERS,
];

export type urlType =
  | PAGE_ROUTES.COMPLETED_ORDERS
  | PAGE_ROUTES.CANCELLED_ORDERS;
export type KindType = OrderStatusesEnum.Complete | OrderStatusesEnum.Canceled;

export const ordersUrlToTypeCollection = {
  [PAGE_ROUTES.COMPLETED_ORDERS]: OrderStatusesEnum.Complete,
  [PAGE_ROUTES.CANCELLED_ORDERS]: OrderStatusesEnum.Canceled,
};

export const ordersTypeToUrlCollection = {
  [OrderStatusesEnum.Complete]: [PAGE_ROUTES.COMPLETED_ORDERS],
  [OrderStatusesEnum.Canceled]: [PAGE_ROUTES.CANCELLED_ORDERS],
};

export const getOrderKindByUrl = (url: unknown) => {
  return ordersUrlToTypeCollection[url as urlType];
};

export const getOrderLinkByKind = (kind: KindType) => {
  return ordersTypeToUrlCollection[kind];
};

export const renderOrdersPageTitle = (
  kind: OrderStatusesEnum,
  total: number,
) => {
  if (kind === OrderStatusesEnum.Complete) {
    return `Completed Jobs (${total})`;
  }

  if (kind === OrderStatusesEnum.Canceled) {
    return `Cancelled Jobs (${total})`;
  }

  return `Open Jobs (${total})`;
};

export const isItOpenJobs = (kind: OrderStatusesEnum | "") => {
  return [OrderStatusesEnum.Complete, OrderStatusesEnum.Canceled, ""].includes(
    kind,
  );
};
