export const headCells = [
  {
    label: 'NAME',
  },
  {
    label: 'CONTACT INFO',
  },
  {
    label: 'ADDRESS',
  },
  {
    label: 'SIGN UP DATE',
  },
];
