import Typography from '@mui/material/Typography';
import { ConfirmOptions } from 'material-ui-confirm';

import Button from '../Button';

interface INoticeDialog {
  title: string;
  desc: string[];
  btnText?: string;
}

const noticeCategoryDialog = ({ title, desc, btnText = 'Cancel' }: INoticeDialog): ConfirmOptions => {
  return {
    title: '',
    content: (
      <div>
        <Typography variant="subtitle2" align="center">
          {title}
        </Typography>
        <Typography
          align="center"
          sx={{ whiteSpace: 'pre-wrap', marginTop: '24px' }}
        >
          {desc.join('\n')}
        </Typography>
      </div>
    ),
    dialogProps: {
      maxWidth: 'xs',
      fullWidth: true,
      BackdropProps: {
        style: {
          backgroundColor: '#06152B40',
        },
      },
      PaperProps: {
        sx: {
          maxWidth: '308px',
          padding: '40px 32px 32px',
          margin: 0,
          borderRadius: '10px',
          '.MuiDialogContent-root': {
            padding: 0,
            marginBottom: '24px',
          },
          '.MuiDialogActions-root': {
            display: 'flex',
            padding: 0,
            justifyContent: 'center',
            button: {
              width: '100%',
              padding: 0,

              ':last-child': {
                display: 'none',
              },
            },
          },
        },
      },
    },
    cancellationText: <Button isOutlined>{btnText}</Button>,
  };
};

export default noticeCategoryDialog;
