import { memo, useCallback } from 'react';

import StyledTable from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import TCell from '@containers/common/Table/components/TCell';
import { selectSendAFile } from '@features/sendAFile/selectors';
import { deleteSendAFile, getAllSendAFile } from '@features/sendAFile/actions';
import { resetSendAFile } from '@features/sendAFile/slice';
import { formattedDate, getCDNImagePath } from '@utils/helpers';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headCells } from './helpers';
import { StyledLink } from './styled';

const SendAFile = () => {
  const dispatch = useAppDispatch();

  const { data: sendAFiles, isLoading } = useAppSelector(selectSendAFile);
  const deleteAction = useCallback((id: string) => {
    dispatch(deleteSendAFile(id)).unwrap().finally(() => dispatch(getAllSendAFile()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    dispatch(getAllSendAFile());

    return () => dispatch(resetSendAFile());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={`Send a File (${sendAFiles?.length || 0})`} isShowBtn={false} />

      {sendAFiles?.length ? (
        <StyledTable headCells={headCells}>
          { sendAFiles.map(({ id, created, email, phone, name, project, file, company }) => (
            <StyledTableRow key={id}>
              <TCell text={formattedDate(created)} />
              <TableCell>
                <Stack>
                  <Typography variant="body3">{`${name},`}</Typography>
                  <Typography variant="body3">{company}</Typography>
                </Stack>
              </TableCell>
              <TCell text={email} />
              <TCell text={phone} />
              <TCell text={project} />
              <TableCell width="88px">
                <StyledLink href={getCDNImagePath(file)}>
                  Download
                </StyledLink>
              </TableCell>
              <DeleteBtn
                deleteAction={() => deleteAction(id)}
                questionText="Are you sure you want to delete this file ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text="You don’t have any file" />
      )}
    </>
  );
};

export default memo(SendAFile);
