import { InvoiceStatusEnum } from "@features/invoices/types";
import { InputTypes, ValidFieldNames } from "@utils/globalTypes";

export const emailInvoiceToCustomerField = {
  label: 'emailInvoiceToCustomer',
  field: 'emailInvoiceToCustomer',
  type: InputTypes.checkbox,
  isRequired: true,
};

export const invoiceStatuses = [
  {
    field: 'invoiceStatus',
    options: [
      {
        optionName: InvoiceStatusEnum.PAID,
        value: InvoiceStatusEnum.PAID,
      },
      {
        optionName: InvoiceStatusEnum.UNPAID,
        value: InvoiceStatusEnum.UNPAID,
      },
      {
        optionName: InvoiceStatusEnum.CANCELLED,
        value: InvoiceStatusEnum.CANCELLED,
      },
    ],
  },
];

export const defaultValues = {
  tax: '',
  itemPrice: '',
  orderStatus: '',
  itemDescription: '',
  invoiceStatus: '',
  emailInvoiceToCustomer: false,
};

export const addInvoiceInputsRows: ValidFieldNames[] = [
  {
    label: 'Order ID',
    field: 'orderNumber',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Customer',
    field: 'customer',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Item Description',
    field: 'itemDescription',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Item Price',
    field: 'itemPrice',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Tax',
    field: 'tax',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Total',
    field: 'total',
    type: InputTypes.filled,
    isRequired: false,
  },
];

export const ediInvoiceInputsRows: ValidFieldNames[] = [
  {
    label: 'Invoice ID',
    field: 'invoiceID',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Invoice Status',
    field: 'invoiceStatus',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Order ID',
    field: 'orderNumber',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Date Created',
    field: 'created',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Customer',
    field: 'customer',
    type: InputTypes.filled,
    isRequired: false,
  },
  {
    label: 'Item Description',
    field: 'itemDescription',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Item Price',
    field: 'itemPrice',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Tax',
    field: 'tax',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Total',
    field: 'subtotal',
    type: InputTypes.filled,
  },

  {
    label: 'Payment Details',
    field: 'paymentDetails',
    type: InputTypes.filled,
  },
  {
    label: 'Payment Method:',
    field: 'paymentMethod',
    type: InputTypes.filled,
  },
  {
    label: 'Payment URL for customer',
    field: 'paymentURL',
    type: InputTypes.filled,
  },
];
