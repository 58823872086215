import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const StyledStackWrapper = styled(Stack)<{ component: string }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',

  [theme.breakpoints.down(1440)]: {
    flexDirection: 'column',
  },
}));
