import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IProductTypes } from './types';

const prefix = '/product-types';

export const getProductTypesBySubcategoryId = createAsyncThunk<IProductTypes[], string, { rejectValue: AxiosData}>(
  'productTypes/getProductTypes',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IProductTypes[]>>(`${prefix}/sub-category/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const reorderProductTypes = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'productTypes/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
