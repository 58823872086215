import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Task Name',
    field: 'name',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Follow Up',
    field: 'followUp',
    type: InputTypes.calendar,
    isRequired: true,
  },
  {
    label: 'Status',
    field: 'status',
    type: InputTypes.select,
    isRequired: true,
    selectprops: {
      width: '120px',
    },
  },
];
