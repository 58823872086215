import { memo, useEffect } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import { StyledTableRow } from '@containers/common/Table/styled';
import TCell from '@containers/common/Table/components/TCell';
import { selectCustomEstimates } from '@features/customEstimates/selectors';
import { deleteCustomEstimate, searchCustomEstimates } from '@features/customEstimates/actions';
import { resetCustomEstimates } from '@features/customEstimates/slice';
import { formattedDate } from '@utils/helpers';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import EditCell from '@containers/common/Table/components/EditCell';
import { paginationLimit } from '@utils/constants';
import Pagination from '@containers/common/Table/components/Pagination';
import { getEstimatesCount } from '@features/sidebarCounts/actions';
import PAGE_ROUTES from '@routes/routingEnum';

import { getCurrentPage, getPath, getTitle, headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const CustomEstimates = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const pageName = getCurrentPage(pathname);

  const { data: customEstimates, isLoading, total } = useAppSelector(selectCustomEstimates);

  const { search } = useLocation();

  const params = queryString.parse(search) as GlobalQueryString;
  const {
    searchTerm = '',
    status = pageName,
    limit = paginationLimit, offset = 0, dateFrom = '', dateTo = '',
  } = params as IFiltersForm;

  const isSearchTerm = searchTerm || dateFrom || dateTo;

  const query = { searchTerm, status, limit, offset, dateFrom, dateTo };

  const fetchData = () => {
    dispatch(searchCustomEstimates(query)).unwrap().then((data) => {
      if (data.total && !data.customEstimates.length) {
        const path = getPath(pageName, true);

        navigate(PAGE_ROUTES[path]);
      }
    }).catch(() => {});
  };

  useUnMount(() => dispatch(resetCustomEstimates()));

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, limit, offset, dateFrom, dateTo],
  );

  const deleteAction = (id: string) => {
    dispatch(deleteCustomEstimate(id)).unwrap().then(() => {
      fetchData();
      dispatch(getEstimatesCount());
    }).catch(() => {});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={`${getTitle(pageName)} Estimates(${total})`} isShowBtn={false} />
      { (isSearchTerm || !!customEstimates?.length) && <SearchSection pageName={pageName} /> }
      {customEstimates?.length ? (
        <>
          <StyledTable headCells={headCells}>
            {customEstimates.map(({ id, jobName, customer: { firstName, lastName }, dateRequested, estimateId }) => (
              <StyledTableRow key={id}>
                <TCell text={estimateId} />
                <EditCell
                  title={jobName}
                  path={getPath(pageName)}
                  params={{ id }}
                />
                <TCell text={`${firstName} ${lastName}`} />
                <TCell text={formattedDate(dateRequested)} />
                <DeleteBtn
                  deleteAction={() => deleteAction(id)}
                  questionText="Are you sure you want to delete this estimate ?"
                />
              </StyledTableRow>
            ))}
          </StyledTable>
          {total > paginationLimit && (
          <Pagination
            total={total}
            path={getPath(pageName, true)}
          />
          )}
        </>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found'
            : `You don’t have any ${getTitle(pageName).toLowerCase()} estimates`}
        />
      )}
    </>
  );
};

export default memo(CustomEstimates);
