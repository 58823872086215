import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import Select from '@containers/common/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import sampleFile from '@assets/files/sample.xlsx';
import { selectSubcategories } from '@features/subcategories/selectors';
import { getOptionsArray } from '@utils/helpers';
import { getProductsBySubcategory, importPricing } from '@features/products/basicInfo/actions';
import { resetProducts } from '@features/products/basicInfo/slice';
import { Product } from '@features/products/basicInfo/types';

import { AddDataSchema, IAddDataForm, defaultValues } from './helpers';
import FileUploader from './FileUploader';

const Inputs = () => {
  const dispatch = useAppDispatch();
  const { dataHavingProducts, actionLoading } = useAppSelector(selectSubcategories);
  const subcategoriesList = getOptionsArray(dataHavingProducts);
  const [productsList, setProductList] = useState<Product[]>([]);

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues,
  });

  const { handleSubmit, watch, formState: { errors, isSubmitted }, setValue, setError, reset } = methods;
  const { subCategoryId } = watch();

  useEffect(() => {
    if (!subCategoryId) {
      setValue('productId', '', { shouldValidate: isSubmitted });
      setProductList([]);

      return;
    }

    dispatch(getProductsBySubcategory(subCategoryId)).unwrap().then((data) => {
      setProductList(data);
    }).catch(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryId]);

  const onSubmit = (data: IAddDataForm) => {
    dispatch(importPricing(data)).unwrap().then(() => {
      dispatch(resetProducts());
      reset();
    }).catch((e) => {
      if (e.message === 'Product does not exist!') {
        setError('productId', { message: e.message });
      }

      if (e.message === 'Subcategory does not exist!') {
        setError('subCategoryId', { message: e.message });
      }
    });
  };

  return (
    <TitlesWithBackButton title="Import Product Pricing">
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          width="352px"
        >
          <Stack gap="16px" mb="32px">
            <Select
              label="Choose product category"
              name="subCategoryId"
              options={subcategoriesList}
              errorMessage={errors?.subCategoryId?.message as string}
            />
            <Select
              label="Choose the product you would like to update with this pricing"
              name="productId"
              options={getOptionsArray(productsList, 'name')}
              errorMessage={errors?.productId?.message as string}
            />
          </Stack>
          <Stack mb="32px">
            <Typography variant="body3" mb="6px">Browse and upload the XLS file</Typography>
            <FileUploader
              name="file"
              errorMessage={errors?.file?.message as string}
              fileExtensions={['.csv', '.xls', '.xlsx']}
            />
          </Stack>
          <Typography variant="body3" mb="16px">Sample Files:</Typography>
          <Link href={sampleFile} target="_blank" fontSize="12px">
            Postcards
          </Link>
          <SubmitBtn width="250px" actionLoading={actionLoading} />
        </Stack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(Inputs);
