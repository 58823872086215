/* eslint-disable max-len */
import {
  createRequiredTextWidthValidation, dateSchema, integerSchema,
  createTextWidthValidation, numberSchema, numberOptionalSchema,
} from '@utils/schemas';
import { UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';
import { IAttribute, ICustomEstimateById, ICustomer, IEstimateSizeQuote } from '@features/customEstimates/types';

export interface IAddDataForm {
  id: string;
  created: string;
  updated: string;
  deleted: string;
  subCategoryId: string;
  estimateId: string;
  customerId: string;
  jobName: string;
  status: string;
  dateRequested: string;
  dateExpire: string;
  unitWeight: string;
  itemDoesNotShip: boolean;
  flatSizeWidth: number;
  flatSizeHeight: number;
  finalSizeWidth: number;
  finalSizeHeight: number;
  customerComments: string;
  frontSideArtwork: string;
  backSideArtwork: string;
  internalOrderNotes: string;
  sendEmailToCustomer: boolean;
  customer: ICustomer;
  attributes: IAttribute[];
  estimateSizeQuotes: IEstimateSizeQuote[];
}

const estimateSizeQuotesSchema = yup.object({
  runSize: integerSchema.required('Run Size is required').max(999999999, 'The maximum number of digits is 9'),
  printingEstimate: numberOptionalSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  mailingServiceEstimate: numberOptionalSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  notes: createTextWidthValidation(500),
  status: createRequiredTextWidthValidation('Status', 255),
});

const attributeSchema = yup.object().shape({});

export const AddDataSchema = yup.object().shape({
  jobName: createRequiredTextWidthValidation('Job Name', 255),
  status: createRequiredTextWidthValidation('Status', 255),
  dateExpire: dateSchema('Date Expire', true),
  unitWeight: numberSchema.required('Unit Weight  is required')
    .max(999999999, 'The maximum number of digits is 9'),
  flatSizeWidth: numberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  flatSizeHeight: numberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  finalSizeWidth: numberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  finalSizeHeight: numberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
  estimateSizeQuotes: yup.array().of(estimateSizeQuotesSchema),
  internalOrderNotes: createTextWidthValidation(500),
  attributes: yup.array().of(attributeSchema).required('Attributes field is required'),

});

export const formattedPaload = (data: ICustomEstimateById) => {
  const attributes = data.attributes.map((item) => item.id).filter((item) => item);

  return { ...data, attributes };
};

export const setErrorsIds = (
  message: string,
  attributes: ICustomEstimateById['attributes'],
  setError: UseFormSetError<IAddDataForm>,
) => {
  if (message.startsWith('Attributes are unavailable for a custom estimate or selected subcategory does not include these attribute IDs:')) {
    const colonIndex = message.indexOf(':');

    if (colonIndex === -1) {
      return;
    }

    const idsPart = message.substring(colonIndex + 1);

    const ids = idsPart.split(',').map((id) => id.trim());

    const matchedIndexes = ids.map((id) =>
      attributes.findIndex((attr) => attr.id === id)).filter((index) => index !== -1);

    matchedIndexes.forEach((index) => {
      setError(`attributes.${index}.id` as const, { message: 'This attribute is unavailable or not included in the selected subcategory' });
    });
  }
};
