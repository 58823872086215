'use client';

import { COLORS } from '@customTypes/global/theme/colors';
import { styled } from '@mui/material';

export const UploadedContentStyled = styled('div')`
  display: flex;
  width: 100%;

  > div:last-child {
    width: 100%;
    padding: 20px;
  }
`;

export const StyledImageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f3f3f2;
  border: 1px dashed ${COLORS.blue.dark};
  padding: 5px;

  width: 100%;
  max-width: 166px;
  height: 166px;
`;

export const StyledImage = styled('img')`
  width: 120px;
  height: auto;
  aspect-ratio: auto 3/4;
  height: 100%;
  object-fit: contain;
`;

export const StyledLine = styled('div')`
  margin-top: 30px;

  width: 100%;
  height: 27px;
`;

export const StyledWrongFile = styled('div')`
  margin-top: 13px;
  display: flex;
  gap: 7;
`;

export const FileInputLabel = styled('label')`
  cursor: pointer;
  color: ${COLORS.blue.dark};
  font-weight: 800;
  text-decoration: underline;
  margin-left: 10px;
`;

export const SuccessfullyUploaded = styled('p')`
  font-size: 12px;
`;
