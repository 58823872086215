import { memo } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppSelector } from '@features/app/hooks';
import { selectRequests } from '@features/requests/selectors';

import { headCells } from '../tableData';
import TableRow from './TableRow';

const RequestsTable = () => {
  const { data: requests } = useAppSelector(selectRequests);

  return (
    <StyledTable headCells={headCells}>
      {requests?.map((item) => (
        <TableRow key={item.id} {...item} />
      ))}
    </StyledTable>
  );
};

export default memo(RequestsTable);
