import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Hide General Products',
    field: 'hideGeneralProducts',
    type: InputTypes.checkbox,
  },
  {
    label: 'Can Send a File',
    field: 'canSendFile',
    type: InputTypes.checkbox,
  },
  {
    label: 'Additional Email',
    field: 'additionalEmail',
    type: InputTypes.text,
  },
  {
    label: 'Comments/Notes (Internal Use Only)',
    field: 'commentsOrNotes',
    type: InputTypes.textarea,
  },
  {
    label: 'Reset Password',
    field: 'password',
    type: InputTypes.text,
  },
];
