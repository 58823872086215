export const headCells = [
  {
    label: 'TASK NAME',
  },
  {
    label: 'FOR',
  },
  {
    label: 'TASK DATE',
  },
  {
    label: 'ORDER ID',
  },
  {
    label: 'DEALER ORDER',
  },
  {
    label: 'CUSTOMER',
  },
  {
    label: 'STATUS',
  },
  {
    label: 'CREATED BY',
  },
  {
    label: 'DATE CREATED',
  },
  {
    label: 'DATE COMPLETED',
  },
];
