import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const FirstContentWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: '18px',
  marginBottom: '5px',
  gridTemplateColumns: '100px 1fr',
}));

export const TrackingNumberFirstContentWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: '18px',
  marginBottom: '5px',
  alignItems: 'center',
  gridTemplateColumns: '100px 1fr 150px',

  input: {
    padding: '6.5px 12px !important',
  },
}));

export const FirstContentTypographyTitles = styled(Typography)<{ color?: string }>(({ color }) => ({
  fontWeight: 500,
  fontSize: '12px',
  color: color || 'inherit',
}));

export const CarrierOverride = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
}));

export const RadioAndLabelWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

export const ContentValue = styled(Box)<{color?: string}>(({ color }) => ({
  fontWeight: 400,
  fontSize: '12px',
  color: color || 'auto',
}));

export const Underlined = styled(Link)(() => ({
  color: '#1D73E3',
  fontFamily: "DM Sans",
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  textDecorationLine: 'underline',
}));
