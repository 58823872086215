import { DeveloperTaskPriority, DeveloperTaskStatus } from '@features/customers/developerTasks/types';
import * as yup from 'yup';

export interface IFiltersForm {
  title?: string;
  searchTerm?: string;
  dateCreated?: string;
  dateCompleted?: string;
  status?: string;
  userId?: string;
  priority?: string;
}

export const FiltersSchema = yup.object().shape({
});

export const statusOptions = [
  {
    optionName: DeveloperTaskStatus.OPEN,
    value: DeveloperTaskStatus.OPEN,
  },
  {
    optionName: DeveloperTaskStatus.IN_PROGRESS,
    value: DeveloperTaskStatus.IN_PROGRESS,
  },
  {
    optionName: DeveloperTaskStatus.AWAITING_REVIEW,
    value: DeveloperTaskStatus.AWAITING_REVIEW,
  },
  {
    optionName: DeveloperTaskStatus.CLOSED,
    value: DeveloperTaskStatus.CLOSED,
  },
];

export const statusPriority = [
  {
    optionName: DeveloperTaskPriority.LOW,
    value: DeveloperTaskPriority.LOW,
  },
  {
    optionName: DeveloperTaskPriority.MEDIUM,
    value: DeveloperTaskPriority.MEDIUM,
  },
  {
    optionName: DeveloperTaskPriority.HIGH,
    value: DeveloperTaskPriority.HIGH,
  },
];
