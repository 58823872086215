import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Button from '@containers/common/Button';

export const OrderDetailsContainer = styled(Box)(() => ({
}));

export const StyledTypography = styled(Typography)(() => ({
  margin: '24px 0 12px 0',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20.83px',
  marginBottom: "16px",
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "20px",
  maxWidth: '475px',
  textAlign: 'center',
  margin: '40px auto 0 auto',
}));
