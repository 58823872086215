import { ReactNode, memo } from 'react';

import Typography from '@mui/material/Typography';
import { StyledTitleBox } from '@containers/common/PageTitle/styled';
import Stack from '@mui/material/Stack';

interface IStyledPageTitle {
  title: string;
  children: ReactNode;
}

const PageTitle = ({ title, children }: IStyledPageTitle) => (
  <StyledTitleBox>
    <Typography variant="h2">{title}</Typography>
    <Stack direction="row" gap="10px" flexWrap="wrap">
      {children}
    </Stack>
  </StyledTitleBox>
);

export default memo(PageTitle);
