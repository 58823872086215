import { memo } from 'react';

import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import PageTitle from '@containers/common/PageTitle';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { usePrevScroll } from '@utils/prevState';

import { IFiltersForm } from './components/SearchSection/helpers';
import { VendorsListSection, SearchSection } from './components';

const Vendors = () => {
  usePrevScroll();

  const { search } = useLocation();
  const params = queryString.parse(search);
  const { carrier = '', POReport = '', searchTerm = '' } = params as IFiltersForm;
  const isSearchTerm = carrier || POReport || searchTerm || searchTerm;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle
          title="Vendors"
          btnName="Add new"
          path={PAGE_ROUTES.ADD_VENDORS}
        />
      </Box>
      <SearchSection />
      <VendorsListSection isSearchTerm={isSearchTerm} />
    </>
  );
};

export default memo(Vendors);
