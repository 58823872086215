import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { constructQueryString, getOptionsArray } from '@utils/helpers';
import Select from '@containers/common/Select';
import { useAppSelector } from '@features/app/hooks';
import { selectTemplateCategories } from '@features/templateCategories/selectors';
import SearchBtn from '@containers/common/SearchSection/SearchBtn';
import { GlobalQueryString } from '@utils/globalTypes';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm } from './helpers';

const SearchSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { dataHavingTemplates } = useAppSelector(selectTemplateCategories);

  const templateCategoriesList = getOptionsArray(dataHavingTemplates, 'name');

  const { searchTerm = '', templateCategory = '' } = params;

  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema),
    defaultValues: { searchTerm, templateCategory },
  });

  const { handleSubmit, register, formState: { errors } } = methods;

  const onSubmit = (data: IFiltersForm) => {
    const queryParams = constructQueryString({ ...data });

    navigate(`${PAGE_ROUTES.TEMPLATES}?${queryParams}`);
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Search"
              errorMessage={errors?.searchTerm?.message}
            />
            <Select
              label=""
              name="templateCategory"
              placeholderText="Search by template category"
              options={templateCategoriesList}
            />
            <SearchBtn path={PAGE_ROUTES.TEMPLATES} />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
