import { IUserInfo } from '@features/users/types';

interface IIsSelectedUsers extends IUserInfo {
  isSelected: boolean;
}
export const addingIsSelectedFlag = (
  users: IUserInfo[] | null,
  selectedList: string[],
) => (
  users?.map((item) => ({
    ...item,
    isSelected: selectedList.includes(item.id),
  })));

export const filterSelectedUserIds = (id: string, usersCheckboxes?: IIsSelectedUsers[]) => {
  const newUserIds = usersCheckboxes?.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        isSelected: !item.isSelected,
      };
    }

    return item;
  });

  const filteredIds = newUserIds?.filter((item) => item.isSelected).map((item) => item.id);

  return filteredIds;
};
