import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14.4px',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',

  span: {
    margin: '5px',
  },

  svg: {
    cursor: 'pointer',
    fill: COLORS.blue.dark,
  },
}));
