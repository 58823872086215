// import Image from 'next/image';
import { memo } from 'react';

// import {
//   onChangeType,
//   onDropType,
// } from '@/app/(layoutWithAuth)/profile/(account)/types';
import { imgExtensions } from '@utils/imageService';
import dropDownArrowUp from '@assets/images/dropDownArrowUp.svg';
import dropDownHorizLine from '@assets/images/dropDownHorizLine.svg';

import {
  ButtonsWrapper,
  DragAndDropText,
  EmptyDropDownElement,
  LocalLoader,
  NotUploadedContentStyled,
  StyledSecondaryButton,
  StyledSvgTogether,
} from './styled';
import { CertificateEnumType, ProcessState } from '../..';

interface NotUploadedContentProps {
  mobileButton: string;
  keyType: CertificateEnumType;
  process: ProcessState;
  onDrop: (...rest: any) => {}; //  onDropType;
  onChange: (...rest: any) => {}; //  onChangeType;
}

const NotUploadedContent = ({
  keyType,
  process,
  mobileButton,
  onDrop,
  onChange,
}: NotUploadedContentProps) => {
  const acceptedExtension = imgExtensions;
  const loadingProcess = process.isLoading && process.type === keyType;

  return (
    <NotUploadedContentStyled>
      <EmptyDropDownElement
        onDrop={(e: any) => onDrop(e, keyType)}
        onDragOver={(e: any) => e.preventDefault()}
      >
        {loadingProcess ? (
          <LocalLoader>Loading</LocalLoader>
        ) : (
          <>
            <input
              type="file"
              id={keyType}
              accept={acceptedExtension.join(', ')}
              style={{ display: 'none' }}
              disabled={!!loadingProcess}
              onChange={(e) => onChange(e, keyType)}
            />
            <StyledSvgTogether>
              <img src={dropDownHorizLine} alt="Drop Down Horiz Line" />
              <img src={dropDownArrowUp} alt="Drop Down Arrow Up" />
            </StyledSvgTogether>
            <DragAndDropText>Drag and Drop a file here...</DragAndDropText>
          </>
        )}
      </EmptyDropDownElement>
      <div className="or">or</div>
      <ButtonsWrapper $hasButton={mobileButton}>
        {mobileButton && (
          <StyledSecondaryButton
            type="button"
            className="isMobile"
            $hasButton={mobileButton}
          >
            <label htmlFor={keyType}>{mobileButton}</label>
          </StyledSecondaryButton>
        )}
        <div
          onDrop={(e) => onDrop(e, keyType)}
          onDragOver={(e) => e.preventDefault()}
        >
          <input
            type="file"
            id={keyType}
            accept={acceptedExtension.join(', ')}
            style={{ display: 'none' }}
            disabled={!!loadingProcess}
            onChange={(e) => onChange(e, keyType)}
          />
          <StyledSecondaryButton $hasButton={mobileButton} type="button">
            <label htmlFor={keyType}>Browse From Computer</label>
          </StyledSecondaryButton>
        </div>
      </ButtonsWrapper>
    </NotUploadedContentStyled>
  );
};

export default memo(NotUploadedContent);
