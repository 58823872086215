import { memo } from 'react';

import ReusableFields from '@containers/common/Table/components/ReusableFields';
// import Input from '@containers/common/Input';
import RowComponent from '@containers/common/Table/components/RowComponent';
// import InputAfterText from '@containers/common/Table/components/InputAfterText';
// import { useFormContext } from 'react-hook-form';

import { inputsRows2 } from '../../tableData';

const SecondRows = () => {
  // const { register, formState: { errors } } = useFormContext();

  return (
    <>
      {inputsRows2.map((item) => (
        <RowComponent key={item.label} {...item}>
          <ReusableFields {...item} />
        </RowComponent>
      ))}
      {/* <RowComponent label="4over Turnaround Code">
        <InputAfterText label="(i.e. 224, 223, etc)">
          <Input
            placeholder="4over Turnaround Code"
            {...register('fouroverTurnaroundCode')}
            errorMessage={errors?.fouroverTurnaroundCode?.message as string}
          />
        </InputAfterText>
      </RowComponent> */}
    </>
  );
};

export default memo(SecondRows);
