import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const reusableStyles = (isMultiple: boolean = false) => ({
  padding: '8px',
  borderRadius: '4px',
  cursor: 'pointer',
  minHeight: isMultiple ? '75px' : '90px',
  width: isMultiple ? '179px' : '100%',
});

export const StyledUploadContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'width' && prop !== 'isMultiple',
})<{ error?: boolean; width: string; isMultiple?: boolean }>(({ theme, error, isMultiple }) => ({
  ...reusableStyles(isMultiple),
  display: 'grid',
  gridTemplateColumns: `${isMultiple ? '58px' : '74px'} 1fr 20px`,
  border: `1px solid ${error ? theme.palette.error.dark : theme.palette.grey[500]}`,
  gridColumnGap: '16px',
  alignItems: 'center',
}));

export const StyledEmptyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isMultiple',
})<{ error?: boolean; isMultiple?: boolean }>(({ theme, error, isMultiple }) => ({
  ...reusableStyles(isMultiple),
  display: 'flex',
  border: `1px dashed ${error ? theme.palette.error.dark : theme.palette.grey[500]}`,
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: error ? '6px' : '',
}));

export const StyledImgContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMultiple' && prop !== 'isFile',
})<{isMultiple?: boolean; isFile?: boolean}>(({ theme, isMultiple, isFile }) => ({
  width: isMultiple ? 58 : 74,
  minWidth: isMultiple ? 58 : 74,
  height: isMultiple ? 58 : 74,
  background: isFile ? theme.palette.grey[500] : '',
  borderRadius: isFile ? '4px' : '',

  img: {
    width: '100%',

    height: '100%',
    objectFit: 'cover',
  },
}));
export const StyledTitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '8px',
}));
