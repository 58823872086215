import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { addEmailTemplate, editEmailTemplate } from '@features/emailTemplate/actions';
import { resetEmailTemplates } from '@features/emailTemplate/slice';
import { IEmailTemplate } from '@features/emailTemplate/types';
import { selectEmailTemplates } from '@features/emailTemplate/selectors';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { AddDataSchema, IAddDataForm, inputsRows, defaultValues } from './helpers';

interface IInputsTable{
  editData?: IEmailTemplate;
}

const InputsTable = ({ editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectEmailTemplates);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData ?? defaultValues,
  });

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const { handleSubmit, watch, setError, formState: { errors }, setValue } = methods;

  console.log('Errors: ', errors);

  const { enableButton } = watch();
  const onSubmit = (data: IAddDataForm) => {
    dispatch(editData ? editEmailTemplate(data) : addEmailTemplate(data))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

        if (!data?.enableButton) {
          setValue('buttonLink', '');
          setValue('buttonText', '');
        }

        if (!editData) {
          dispatch(resetEmailTemplates());
          navigate(PAGE_ROUTES.EMAIL_TEMPLATES);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Email template with the provided title already exists!') {
          setError('title', { message: e.message });
        } else {
          navigate(PAGE_ROUTES.EMAIL_TEMPLATES);
        }
      });
  };

  return (
    <TitlesWithBackButton
      title={editData ? 'Edit Status Email Template' : 'Add Status Email Template'}
      path="EMAIL_TEMPLATES"
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="Email Template" colSpan={2}>
            {inputsRows.map((item) => {
              if (item.field === 'buttonLink' || item.field === 'buttonText') {
                if (!enableButton) {
                  return null;
                }

                return (
                  <RowComponent key={item.label} {...item}>
                    <ReusableFields {...item} />
                  </RowComponent>
                );
              }

              return (
                <RowComponent key={item.label} {...item}>
                  <ReusableFields {...item} />
                </RowComponent>
              );
            })}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
