import { IProductsQuantityPayload } from '@features/products/productsQuantity/types';
import { numberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IAddDataForm {
  quantities: IProductsQuantityPayload[];
}

export const AddDataSchema = yup.object().shape({
  quantities: yup.array().of(
    yup.object({
      basePrice: numberSchema.required('Base price is required').max(999999999, 'The maximum number of digits is 9'),
    }),
  ),
});

export const formattingPayload = ({ quantities }: IAddDataForm) => {
  return { quantities };
};
