import { SyntheticEvent, createElement, memo, useState } from 'react';

import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { StyledDivider, StyledTab } from '@containers/Products/Products/components/Tabs/styles';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import useMount from '@customHooks/useMount';
import { getCustomerById } from '@features/customers/customers/actions';
import PAGE_ROUTES from '@routes/routingEnum';
import { ICustomer } from '@features/customers/customers/types';
import { selectCustomers } from '@features/customers/customers/selectors';
import Loader from '@containers/common/Loader';
import { getAllCustomersTasks } from '@features/customers/tasks/actions';

import { StyledTabs } from './styles';
import EditPageTitle from './components/EditPageTitle';
import tabsOptions from './helpers';

const InputsTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { tab } = queryString.parse(search);
  const { isLoading } = useAppSelector(selectCustomers);
  const { id = '' } = useParams();

  const [value, setValue] = useState((tab && +tab) ? +tab : 0);

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    navigate(`${pathname}?tab=${newValue}`);
    if (newValue === 1) {
      dispatch(getAllCustomersTasks(id));
    }
  };

  const [editData, setEditData] = useState<ICustomer | null>(null);

  useMount(() => {
    dispatch(getCustomerById(id)).unwrap().then((data) => {
      setEditData(data);
    }).catch(() => navigate(PAGE_ROUTES.CUSTOMERS));
    if (value === 1) {
      dispatch(getAllCustomersTasks(id));
    }
  });

  const options = tabsOptions(editData);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <EditPageTitle firstName={editData?.firstName} lastName={editData?.lastName} />
      <StyledStack>
        <StyledDivider>
          <StyledTabs value={value} onChange={handleChange}>
            {
                options.map(({ label }) => (
                  <StyledTab key={label} label={label} disableRipple />
                ))
            }
          </StyledTabs>
        </StyledDivider>
      </StyledStack>
      {createElement(options[value].component, options[value].props)}
    </>
  );
};

export default memo(InputsTable);
