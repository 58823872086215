import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectCategories } from '@features/categories/selectors';
import { getOptionsArray } from '@utils/helpers';
import { addSubcategory, editSubcategory } from '@features/subcategories/actions';
import { useNavigate } from 'react-router-dom';
import { selectSubcategories } from '@features/subcategories/selectors';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { resetCategories } from '@features/categories/slice';
import { selectShippingZips } from '@features/shippingZip/selectors';
import { resetShippingZips } from '@features/shippingZip/slice';
import { ISelectOptions } from '@utils/globalTypes';
import { getAdminAttributesInk } from '@features/attributes/actions';
import ErrorMessage from '@containers/common/ErrorMessage';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { defaultValues, formattedData, formattingPayload, printTypeValues } from './helpers';
import StaticShipping from './StaticShipping';
// import SEO from './SEO';
import { inputsRows1, inputsRows2 } from './tableData';
import ProductTypes from './ProductTypes';
import { AddSubcategorySchema } from './schema';
import { IAddSubcategoryForm, IInputsTable } from './types';

const InputsTable = ({ editData }: IInputsTable) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { allData: categories } = useAppSelector(selectCategories);
  const { data: shippingZips } = useAppSelector(selectShippingZips);
  const { actionLoading } = useAppSelector(selectSubcategories);
  const categoriesList = getOptionsArray(categories);
  const [
    defaultInkInEstimatorValues,
    setDefaultInkInEstimatorValues,
  ] = useState<ISelectOptions[]>([] as ISelectOptions[]);

  const methods = useForm<IAddSubcategoryForm>({
    resolver: yupResolver(AddSubcategorySchema as any), // TODO: add typing
    defaultValues: formattedData(editData ?? defaultValues, shippingZips),
  });

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const [gPSubProductsMessage, setGPSubProductsMessage] = useState('');
  const [newNameError, setNewNameError] = useState('');
  const { handleSubmit, setError, watch, setValue, formState: { isSubmitted } } = methods;
  const { makeCategoryAsProduct, productTypes, useGrandFormatSQFtTemplate, printType } = watch();

  useEffect(() => {
    dispatch(getAdminAttributesInk())
      .unwrap()
      .then((response) => {
        const usersList = getOptionsArray(response, 'name', 'nickname');

        setDefaultInkInEstimatorValues(usersList);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (makeCategoryAsProduct) {
      setValue('newName', '');
    }

    if (!makeCategoryAsProduct) {
      setValue('description', '');
      setValue('specifications', '');
    }
  }, [makeCategoryAsProduct, setValue]);

  useEffect(() => {
    if (isSubmitted) {
      if (!productTypes?.length && !makeCategoryAsProduct) {
        setNewNameError('Product Type is required');
      } else {
        setNewNameError('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, makeCategoryAsProduct, productTypes?.length]);

  useEffect(() => {
    setGPSubProductsMessage('');
    if (printType !== 'grand_format_products') {
      setValue('useGrandFormatSQFtTemplate', false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printType, useGrandFormatSQFtTemplate]);

  const onSubmit = (data: IAddSubcategoryForm) => {
    const payload = formattingPayload(data);

    dispatch(editData ? editSubcategory(payload) : addSubcategory(payload))
      .unwrap()
      .then(() => {
        setGPSubProductsMessage('');
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

        if (!editData) {
          dispatch(resetCategories());
          dispatch(resetShippingZips());
          navigate(PAGE_ROUTES.PRODUCT_CATEGORIES);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });
        // eslint-disable-next-line max-len
        if (e.message === `Subcategory contains products and cannot be modified 'Use Grand Format SQ. Ft. Template' or 'Print Type' in this way.`) {
          setGPSubProductsMessage(
            // eslint-disable-next-line max-len
            `Subcategory contains products and cannot be modified 'Use Grand Format SQ. Ft. Template' or 'Print Type' in this way.`,
          );
        } else if (e.message === 'Subcategory with the provided title already exists in this category!') {
          setError('title', { message: e.message });
        // eslint-disable-next-line max-len
        } else if (e.message === 'You have already chose the banners in the category, please disable one of them to proceed.') {
          setError('displayAsCardInHeader', { message: e.message });
        } else {
          // setError('productTypes' as any, { message: e.message });
          setIsSuccessMsg({ msg: 'Something went wrong.', status: 400 });
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <StyledStack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
      >
        <StyledTable tableTitle={editData ? 'Product Category Info' : 'Product Category'} colSpan={2}>
          {inputsRows1.map((item) => {
            if (
              !makeCategoryAsProduct && item.field === 'description' ||
              !makeCategoryAsProduct && item.field === 'specifications'
            ) {
              return null;
            }

            if (
              item.field === 'useGrandFormatSQFtTemplate' &&
              printType !== 'grand_format_products'
            ) {
              return (
                <RowComponent
                  key={item.label}
                  {...item}
                >
                  <ReusableFields
                    {...item}
                    grandDisable
                    selectList={[{
                      field: 'categoryId',
                      options: categoriesList,
                    }, {
                      field: 'printType',
                      options: printTypeValues,
                    }, {
                      field: 'defaultInkInEstimator',
                      options: defaultInkInEstimatorValues,
                    }]}
                  />
                </RowComponent>
              );
            }

            return (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[{
                    field: 'categoryId',
                    options: categoriesList,
                  }, {
                    field: 'printType',
                    options: printTypeValues,
                  }, {
                    field: 'defaultInkInEstimator',
                    options: defaultInkInEstimatorValues,
                  }]}
                />
              </RowComponent>
            );
          })}
          <StaticShipping />
          {!makeCategoryAsProduct && <ProductTypes newNameError={newNameError} />}
          {inputsRows2.map((item) => (
            <RowComponent key={item.label} {...item}>
              <ReusableFields {...item} />
            </RowComponent>
          ))}
        </StyledTable>
        {/* <SEO /> */}
        <SubmitBtn actionLoading={actionLoading} />
        <GlobalUpdatesMessage {...isSuccessMsg} />

        {gPSubProductsMessage && (
          <ErrorMessage
            message={gPSubProductsMessage}
          />
        )}
      </StyledStack>
    </FormProvider>
  );
};

export default memo(InputsTable);
