import { memo, useCallback } from 'react';

import StyledTable from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectBlockedIps } from '@features/customers/blockedIps/selectors';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import EmptyState from '@containers/common/EmptyState';
import Typography from '@mui/material/Typography';
import { getAllBlockedIps, unblockBlockedIp } from '@features/customers/blockedIps/actions';
import { formattedDate } from '@utils/helpers';
import { resetBlockedIps } from '@features/customers/blockedIps/slice';
import TCell from '@containers/common/Table/components/TCell';

import BlockIpSection from './components/BlockIpSection';
import { headCells } from './helpers';

const BannedIps = () => {
  const dispatch = useAppDispatch();

  const { data: ips, isLoading } = useAppSelector(selectBlockedIps);

  const unBlockAction = useCallback((ip: string) => {
    dispatch(unblockBlockedIp(ip))
      .unwrap()
      .finally(() => dispatch(getAllBlockedIps()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    dispatch(getAllBlockedIps());

    return () => dispatch(resetBlockedIps());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant="h2" mb={5}>
        Banned IPs
        {ips?.length ? (` (${ips?.length})`) : null}
      </Typography>
      <BlockIpSection />
      {ips?.length ? (
        <StyledTable headCells={headCells}>
          {ips.map(({ id, IPAddress, created }) => (
            <StyledTableRow key={id}>
              <TCell text={formattedDate(created)} />
              <TCell text={IPAddress} />
              <DeleteBtn
                width="100px"
                text="Unblock IP"
                deleteAction={() => {
                  unBlockAction(IPAddress);
                }}
                questionText="Are you sure you want to unblock this ip ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text="You don’t have any banned IP" />
      )}
    </>
  );
};

export default memo(BannedIps);
