export const headCells = [
  {
    label: 'SKU',
    width: '18%',
  },
  {
    label: 'Q CODE',
    width: '10%',
  },
  {
    label: '4OVER',
    width: '10%',
  },
  {
    label: 'PRODUCT NAME',
    width: '18%',
  },
  {
    label: 'VISIBLE',
    width: '1%',
  },
  {
    label: 'TYPE',
    width: '14%',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
    width: '15%',
  },
];
