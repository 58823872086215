import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import {
  IAddSubcategoriesPayload, IDefaultAttributePayload, ISubcategoriesByCategoryId,
  ISubcategoriesInfo, ISubcategoriesSearchInfo, ISubcategoryAttribues, ISubcategoryAttribuesPayload,
} from './types';

const prefix = '/sub-categories';

export const addSubcategory = createAsyncThunk<void, IAddSubcategoriesPayload, { rejectValue: AxiosData}>(
  'subcategories/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddSubcategoriesPayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllSubcategories = createAsyncThunk<ISubcategoriesInfo[], void, { rejectValue: AxiosData}>(
  'subcategories/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoriesInfo[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getSubcategoryById = createAsyncThunk<ISubcategoriesInfo, string, { rejectValue: AxiosData}>(
  'subcategories/get-subcategory',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoriesInfo>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editSubcategory = createAsyncThunk<void, IAddSubcategoriesPayload, { rejectValue: AxiosData}>(
  'subcategories/edit',
  async (body, thunkAPI) => {
    try {
      await http.patch<IAddSubcategoriesPayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteSubcategory = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'subcategories/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const reorderSubcategories = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'subcategories/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const searchSubcategories = createAsyncThunk<ISubcategoriesSearchInfo[], Filters, {
  rejectValue: AxiosData;
}>(
  'subcategories/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<ISubcategoriesSearchInfo[]>>(
        `${prefix}/search?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const getSubcategoriesByCategoryId = createAsyncThunk<ISubcategoriesByCategoryId[], string, {
  rejectValue: AxiosData;
}>(
  'subcategories/getSubcategories',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoriesByCategoryId[]>>(
        `${prefix}/category/${id}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllSubcategoriesProducts = createAsyncThunk<ISubcategoriesInfo[], void, { rejectValue: AxiosData}>(
  'subcategories/allSubcategoriesHavingProducts',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoriesInfo[]>>(`${prefix}/product`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getDefaultAttributesBySubcategoryId = createAsyncThunk<ISubcategoryAttribues, string, {
  rejectValue: AxiosData;
}>(
  'subcategories/defaultAttributesBySubId',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoryAttribues>>(`${prefix}/${id}/attributes`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllAttributesBySubcategoryId = createAsyncThunk<ISubcategoryAttribues, string, {
  rejectValue: AxiosData;
}>(
  'subcategories/attributesBySubId',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ISubcategoryAttribues>>(`${prefix}/${id}/attributes`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const addSubcategoryAttributes = createAsyncThunk<void, {body: ISubcategoryAttribuesPayload; id: string}, {
  rejectValue: AxiosData;
}>(
  'subcategories/addAttributes',
  async ({ body, id }, thunkAPI) => {
    try {
      await http.put<ISubcategoryAttribuesPayload>(`${prefix}/${id}/attributes`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const defaultAttributeOfSubcategory = createAsyncThunk<void, IDefaultAttributePayload, {
  rejectValue: AxiosData;
}>(
  'subcategories/defaultAttr',
  async ({ subcategoryId, attributeId, isDefault }, thunkAPI) => {
    try {
      await http.patch(`${prefix}/${subcategoryId}/attributes/default`, { attributeId, isDefault });
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
