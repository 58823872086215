import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const OrderContainer = styled(Box)(() => ({
}));

export const OrderNumber = styled(Box)(() => ({
}));

export const BottomButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TopButtons = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Navbar = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

export const TopSection = styled(Box)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: "300px 1fr 200px",
}));

export const StatusesSection = styled(Box)(() => ({
  gap: '20px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const StyledStatusTypography = styled(Typography)<{bgcolor: string}>(({ bgcolor }) => ({
  background: bgcolor,
  height: "max-content",
  padding: '1px 10px',
  borderRadius: '20px',
}));
