import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData } from "@utils/globalTypes";
import { AxiosResponse } from "axios";
import {
  Filters,
  constructQueryStringExceptionWithOffer,
} from "@utils/helpers";

import { IInvoice, IInvoiceResponse } from "./types";

const prefix = "/invoices";

export const getInvoicesThunk = createAsyncThunk<
  IInvoiceResponse,
  Filters,
  { rejectValue: AxiosData }
>("invoices/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const {
      data: { data },
    } = await http.get<AxiosResponse<IInvoiceResponse>>(
      `${prefix}?${queryParams}`,
    );

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
