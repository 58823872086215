import { integerSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IDiscountSchema {
  quantity: string;
  discountPercent: string;
}

export const DiscountSchemaNullable = yup.object().shape({
  quantity: integerSchema
    .nullable()
    .min(1, 'Max Quantity must not be less than 1')
    .max(999999999, 'The maximum number of digits is 9'),
  discountPercent: integerSchema
    .nullable()
    .min(1, 'Max Discount must not be less than 1')
    .max(100, 'Discount must be 100% or less for percentage type'),
});

export const DiscountSchemaRequired = yup.object().shape({
  quantity: integerSchema
    .required('Quantity is a required field')
    .min(0, 'Max Quantity must not be less than 1')
    .max(999999999, 'The maximum number of digits is 9'),
  discountPercent: integerSchema
    .required('Discount Percent is a required field')
    .min(1, 'Max Discount must not be less than 1')
    .max(100, 'Discount must be 100% or less for percentage type'),
});
