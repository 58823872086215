import { memo } from 'react';

const ProcurementReport = () => {
  return (
    <>
      ProcurementReport
    </>
  );
};

export default memo(ProcurementReport);
