import { Dispatch, SetStateAction, useContext, memo } from 'react';

import { Box } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IShipment } from '@features/orders/order/types';

import { DropShipShipToContext } from '../../../utils/context';
import { DropShipShipToEnum } from '../../../utils/types';
import { Sets, ShipmentInfo } from './components';
import {
  ShipmentContainer,
  StyledTitle,
  TitleWrapper,
  ShipmentAndSetsWrapper,
  TopContainer,
  StyledBox,
  Underlined,
  StyledTypography,
} from './styled';

interface ShipmentProps {
  jobIndex: number;
  shipmentsList: IShipment[];
  toggledShipments: string[];
  setToggledShipments: Dispatch<SetStateAction<string[]>>;
}

const Shipment = ({
  jobIndex,
  shipmentsList,
  toggledShipments,
  setToggledShipments,
}: ShipmentProps) => {
  const { handleOpen, setShippingLink } = useContext(DropShipShipToContext);

  return (
    <>
      {shipmentsList.map((shipment, shipmentIndex) => {
        const isOpen = toggledShipments.includes(shipment.id);
        const shippingLink = `${jobIndex}-${shipmentIndex}`;

        return (
          <Box
            key={shipment.id}
          >
            <ShipmentContainer>
              <TitleWrapper
                onClick={() => {
                  setToggledShipments((prev) => {
                    if (prev.includes(shipment.id)) {
                      return [...prev.filter((item) => item !== shipment.id)];
                    }

                    return [...prev, shipment.id];
                  });
                }}
              >
                <StyledTitle>
                  SHIPMENT
                  {' '}
                  {shipmentIndex + 1}
                </StyledTitle>
                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
              </TitleWrapper>
              <ShipmentAndSetsWrapper toggle={isOpen ? 0 : 1}>
                <TopContainer>
                  <StyledBox>
                    <ShipmentInfo
                      jobIndex={jobIndex}
                      shipment={shipment}
                      shipmentIndex={shipmentIndex}
                    />
                  </StyledBox>
                  <StyledBox>
                    {
                      shipment.shipTo ? (
                        <div>
                          <Box
                            sx={{
                              display: 'flex',
                            }}
                          >
                              <h3>SHIPPING ADDRESS</h3>
                              <Underlined
                                onClick={() => {
                                  handleOpen(DropShipShipToEnum.SHIPPING);
                                  setShippingLink(`${shippingLink}-shipTo`);
                                }}
                              >
                                Edit
                              </Underlined>
                          </Box>
                          <div>
                            <StyledTypography>{shipment?.shipTo?.country}</StyledTypography>
                            <StyledTypography>
                              {shipment?.shipTo?.state}
                              {', '}
                              {shipment?.shipTo?.city}
                            </StyledTypography>
                            <StyledTypography>{shipment?.shipTo?.address1}</StyledTypography>
                            <StyledTypography>
                              {shipment?.shipTo?.zipCode}
                            </StyledTypography>
                          </div>
                        </div>
                      ) : null
                    }
                  </StyledBox>
                  <StyledBox>
                    {shipment?.dropShip && (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            h3: {
                              color: 'red',
                            },
                          }}
                        >
                          <h3>DROP SHIP</h3>
                          <Underlined
                            onClick={() => {
                              handleOpen(DropShipShipToEnum.DROP);
                              setShippingLink(`${shippingLink}-dropShip`);
                            }}
                          >
                            Edit
                          </Underlined>
                        </Box>
                        <Box>
                          <StyledTypography color="red">{shipment?.dropShip?.country}</StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.state}
                            {', '}
                            {shipment?.dropShip?.city}
                          </StyledTypography>
                          <StyledTypography color="red">{shipment?.dropShip?.address1}</StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.zipCode}
                          </StyledTypography>
                        </Box>
                      </>
                    )}
                  </StyledBox>
                </TopContainer>
                <Sets
                  jobIndex={jobIndex}
                  setsList={shipment.sets}
                  shipmentIndex={shipmentIndex}
                />
              </ShipmentAndSetsWrapper>
            </ShipmentContainer>
          </Box>
        );
      })}
    </>
  );
};

export default memo(Shipment);
