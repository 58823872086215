import { ChangeEvent, DragEvent, MouseEvent, memo, useState } from 'react';

import Box from '@mui/material/Box';
import { FieldValues, UseFormWatch, useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import FileUploaderInput from '@containers/common/FileUploader/components/FileUploaderInput/index';
import { useDispatch } from 'react-redux';
import { updateJobMailingFile, updateJobSetInfo } from '@features/orders/order/actions';

import { getUploadUrl, uploadFile } from './requests';
import { isValidFileExtension } from './helpers';
import FileDisplay from './components/FileDisplay';
import EmptyImage from './components/EmptyImage';
import FileLoadingComponent from './components/FileLoadingComponent';
import ErrorMessage from '../ErrorMessage';

interface IImageUpload {
  name: string;
  errorMessage?: string;
  fileExtensions?: string[];
  isMultiple?: boolean;
  btnProps?: Record<string, any>;
  outOfFormSystem?: 'sets' | 'mailingFile';
}

interface HandleWithoutFormRequestProps {
  dispatch: any;
  fieldPath: string;
  updateLogicType?: 'sets' | 'mailingFile';
  watch: UseFormWatch<FieldValues>;
}

export const handleWithoutFormRequest = ({
  dispatch,
  watch,
  fieldPath,
  updateLogicType,
} : HandleWithoutFormRequestProps) => {
  // eslint-disable-next-line no-useless-escape
  const route = fieldPath.split(/\.(?=[^\.]+$)/);

  if (updateLogicType === 'sets') {
    const [setPath, updatedField] = route;
    const setCollection = watch(setPath);
    const { id } = setCollection;

    const value = setCollection[updatedField];

    // null can be for Artwork delete
    dispatch && dispatch(updateJobSetInfo({ id, payload: { [updatedField]: value || null } }));
  } else if (updateLogicType === 'mailingFile') {
    const [jobPath] = route;
    const jobCollection = watch(jobPath);

    const { id, mailingFile } = jobCollection;

    dispatch && dispatch(updateJobMailingFile({ id, mailingFile }));
  }
};

const FileUploader = ({
  name,
  btnProps,
  errorMessage,
  fileExtensions,
  outOfFormSystem,
  isMultiple = false,
}: IImageUpload) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean | number>(false);
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const uploadedImg = watch(name);
  const [fileData, setFileData] = useState<FileList | null>(null);

  const uploadToS3 = async (file: FileList, idx?:number) => {
    try {
      setLoading(idx !== undefined ? idx : true);
      if (file) {
        const { fileList } = await getUploadUrl(file);

        await uploadFile(file, fileList);

        const img = fileList.map(({ path }) => path);

        if (idx !== undefined) {
          const newImages = uploadedImg?.map((item: string, i: number) => {
            if (i === idx) {
              return img[0];
            }

            return item;
          });

          const isFileData = fileData ? Array.from(fileData) : fileData;

          const newFileData = isFileData?.map((item: File, i: number) => {
            if (i === idx) {
              return file[0];
            }

            return item;
          }) as any;

          setValue(name, newImages, { shouldValidate: isSubmitted });
          setFileData(newFileData);

          if (outOfFormSystem) {
            handleWithoutFormRequest({ dispatch, watch, fieldPath: name, updateLogicType: outOfFormSystem });
          }
        } else {
          const newImages = uploadedImg ? (isMultiple ? [...uploadedImg, ...img]
            : img[0]) : (isMultiple ? img : img[0]);

          const newFileData = fileData?.length ? [...(fileData as any), ...(file as any)] : file;

          setValue(name, newImages, { shouldValidate: isSubmitted });
          setFileData(newFileData as FileList);

          if (outOfFormSystem) {
            handleWithoutFormRequest({ dispatch, watch, fieldPath: name, updateLogicType: outOfFormSystem });
          }
        }
      }
    } catch { } finally {
      setLoading(false);
    }
  };

  const handleFiles = async (files: FileList, idx?: number) => {
    if (Array.from(files).every((file) => isValidFileExtension(file, fileExtensions))) {
      await uploadToS3(files, idx);
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>, idx?: number) => {
    const { files } = event.target;

    files && await handleFiles(files, idx);
  };

  const onDrop = async (event: DragEvent<HTMLDivElement>, idx?: number) => {
    event.preventDefault();

    const { files } = event.dataTransfer;

    files && await handleFiles(files, idx);
  };

  const handleDeleteImg = (event: MouseEvent<SVGSVGElement>, idx?: number) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    if (isMultiple) {
      const newUploadedImg = uploadedImg.filter((_: File, i: number) => idx !== i);

      const newFileList = (fileData)
        ? Array.from(fileData).filter((_: File, i: number) => idx !== i) : null as any;

      setFileData(newFileList);
      setValue(name, newUploadedImg, { shouldValidate: isSubmitted });
    } else {
      setFileData(null);
      setValue(name, null, { shouldValidate: isSubmitted });
    }

    setLoading(false);
  };

  const renderContent = () => {
    return isMultiple ? (
      <Stack direction="row" flexWrap="wrap" gap="8px">
        {uploadedImg?.map((item: string, idx: number) => (loading === idx ? (
          <FileLoadingComponent isMultiple key={item} />
        )
          : (
            <FileUploaderInput
              key={item}
              onDrop={(e: DragEvent<HTMLDivElement>) => onDrop(e, idx)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, idx)}
              loading={!!loading}
            >
              <FileDisplay
                fileData={fileData?.[idx]}
                errorMessage={errorMessage}
                handleDeleteImg={(e) => handleDeleteImg(e, idx)}
                path={item}
                isMultiple={isMultiple}
                isFile={!!fileExtensions}
              />
            </FileUploaderInput>
          )
        ))}
        <EmptyImage
          errorMessage={errorMessage}
          onDrop={onDrop}
          onChange={onChange}
          isMultiple={isMultiple}
          loading={!!loading}
        />
      </Stack>
    ) : (
      uploadedImg
        ? (
          <FileUploaderInput onDrop={onDrop} onChange={onChange} fileExtensions={fileExtensions} loading={!!loading}>
            <FileDisplay
              fileData={fileData?.[0]}
              errorMessage={errorMessage}
              handleDeleteImg={handleDeleteImg}
              path={uploadedImg}
              isFile={!!fileExtensions}
            />
          </FileUploaderInput>
        )
        : (
          <EmptyImage
            errorMessage={errorMessage}
            fileExtensions={fileExtensions}
            onDrop={onDrop}
            onChange={onChange}
            isMultiple={isMultiple}
            loading={!!loading}
          />
        )
    );
  };

  if (btnProps) {
    return (
      <FileUploaderInput
        onDrop={onDrop}
        onChange={onChange}
        fileExtensions={fileExtensions}
        loading={!!loading}
        btnProps={btnProps}
      />
    );
  }

  if (loading && !isMultiple) {
    return (
      <FileLoadingComponent />
    );
  }

  return (
    <Box>
      {renderContent()}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Box>
  );
};

export default memo(FileUploader);
