import PAGE_ROUTES from '@routes/routingEnum';

export const headCells = [
  {
    label: 'ESTIMATE ID',
  },
  {
    label: 'JOB NAME',
  },
  {
    label: 'CUSTOMER',
  },
  {
    label: 'DATE',
  },
  {
    label: 'ACTIONS',
  },
];
export const getCurrentPage = (pathname: string) => {
  if (pathname.includes('open-estimates')) {
    return 'received';
  }

  if (pathname.includes('complete-estimates')) {
    return 'complete';
  }

  if (pathname.includes('reactivated-estimates')) {
    return 'reactivated';
  }

  return '';
};

export const getPath = (pageName: string, isMain?: boolean) => {
  const obj: Record<string, keyof typeof PAGE_ROUTES> = {
    received: isMain ? 'OPEN_ESTIMATES' : 'VIEW_OPEN_ESTIMATE',
    complete: isMain ? 'COMPLETE_ESTIMATES' : 'VIEW_COMPLETE_ESTIMATE',
    reactivated: isMain ? 'REACTIVATED_ESTIMATES' : 'VIEW_REACTIVATED_ESTIMATE',
  };

  return obj[pageName];
};

export const getTitle = (pageName: string) => {
  const obj: Record<string, string> = {
    received: 'Open',
    complete: 'Complete',
    reactivated: 'Reactivated',
  };

  return obj[pageName];
};
