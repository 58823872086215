import { Dispatch, SetStateAction } from "react";

import { useRequestTasksAndInvoices } from "../../Hooks/useTaskAndInvoicesInitialCallHook";
import { switcherCollection } from "../..";
import {
  Line,
  Point,
  MainLine,
  ButtonVia,
  ButtonTaskVia,
  ButtonsWrapper,
  SwitcherContainer,
} from './styled';

interface SwitcherProps {
  switcherStatus: number;
  setSwitcherStatus: Dispatch<SetStateAction<number>>;
}

const Switcher = ({
  switcherStatus,
  setSwitcherStatus,
}: SwitcherProps) => {
  const Component = switcherCollection[`${switcherStatus}`].component;

  const { areThereOpenInvoices, areThereOpeTasks } = useRequestTasksAndInvoices();

  return (
    <SwitcherContainer>
      <ButtonsWrapper>
        {
          Object.keys(switcherCollection).map((k) => {
            const key = +k as number;
            const item = switcherCollection[key];

            if (
              item.key === 'invoices' && areThereOpenInvoices ||
              item.key === 'tasks' && areThereOpeTasks
            ) {
              return (
                <ButtonTaskVia
                  key={item.key}
                  onClick={() => setSwitcherStatus(key)}
                >
                  {switcherCollection[key].title}
                  <Line
                    active={key === switcherStatus ? 1 : 0}
                    redline={1}
                  />
                  <Point />
                </ButtonTaskVia>
              );
            }

            return (
              <ButtonVia
                key={item.key}
                switcher={key === switcherStatus ? 1 : 0}
                onClick={() => setSwitcherStatus(key)}
              >
                {switcherCollection[key].title}
                <Line active={key === switcherStatus ? 1 : 0} />
              </ButtonVia>
            );
          })
        }
      </ButtonsWrapper>
      <MainLine />
      <Component />
    </SwitcherContainer>
  );
};

export default Switcher;
