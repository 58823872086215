import { memo } from 'react';

import InputsTable from '../components/InputsTable';

const AddBanner = () => {
  return (
    <InputsTable />
  );
};

export default memo(AddBanner);
