import { useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch } from "@features/app/hooks";
import { IEmailTemplate } from "@features/emailTemplate/types";
import { useFormContext } from "react-hook-form";
import { searchEmailTemplates } from "@features/emailTemplate/actions";
import { EMAIL_REGEXP } from "@utils/regexp";
// import { useDebounce } from "@features/hooks/useDebounce";

export const useEmailUpdate = (keyByIndex: string, jobIndex: number) => {
  const { watch, register, setError, setValue, formState: { errors } } = useFormContext();

  // @ts-ignore
  const textErrorMessage = errors?.jobs?.[jobIndex]?.textTemplate?.message;
  // @ts-ignore
  const emailsErrorMessage = errors?.jobs?.[jobIndex]?.emails?.message;
  // @ts-ignore
  const templateErrorMessage = errors?.jobs?.[jobIndex]?.emailTemplateId?.message;

  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');

  const [options, setOptions] = useState<{optionName: string; value: string}[] | []>([]);
  const [templates, setTemplates] = useState<IEmailTemplate[] | null>(null);
  const inputRef = useRef(null);
  const tempString = useMemo(() => templates?.map((i) => i.textTemplate), [templates]);
  const emails = watch(`${keyByIndex}.emails`);
  const sendEmail = watch(`${keyByIndex}.sendEmail`);

  const templatesRequest = async () => {
    try {
      const templatesResponse = await dispatch(searchEmailTemplates({}))
      .unwrap();

      const opt = templatesResponse.map(({ title, id }) => ({
        value: id,
        optionName: title,
      }));

      setOptions(opt);
      setTemplates(templatesResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddEmail = () => {
    if (EMAIL_REGEXP.test(email)) {
      const found = emails?.find((e: string) => e === email);

      if (found) {
        setError(`${keyByIndex}.emails`, { message: 'The email already exists.' });

        return;
      }

      emailsErrorMessage && setError(`${keyByIndex}.emails`, { message: '' });
      setValue(`${keyByIndex}.emails`, [...emails, email]);
      setEmail('');
      // @ts-ignore
      inputRef?.current?.focus();
    } else {
      if (!email) {
        setError(`${keyByIndex}.emails`, { message: "" });

        return;
      }

      setError(`${keyByIndex}.emails`, { message: "Invalid Email" });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ',' || e.key === ';') {
      e.preventDefault();
      handleAddEmail();
    }
  };

  // const addWithDebounce = useDebounce(handleAddEmail, 1500);

  const handleDelete = (index: number) => {
    const newData = [...emails];

    newData.splice(index, 1);

    setValue(`${keyByIndex}.emails`, newData);
  };

  const textTemplate = watch(`${keyByIndex}.textTemplate`);
  const emailTemplateId = watch(`${keyByIndex}.emailTemplateId`);

  useEffect(() => {
    if (!email) {
      setError(`${keyByIndex}.textTemplate`, { message: '' });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (!sendEmail) {
      setError(`${keyByIndex}.textTemplate`, { message: '' });
    }

    if (!email) {
      setError(`${keyByIndex}.email`, { message: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmail, email]);

  useEffect(() => {
    if (emailTemplateId) {
      const found = templates?.find((template) => template.id === emailTemplateId);

      if (found) {
        setValue(`${keyByIndex}.textTemplate`, found.textTemplate);
        setError(`${keyByIndex}.textTemplate`, { message: '' });
      }
    } else {
      setValue(`${keyByIndex}.textTemplate`, '');
      setError(`${keyByIndex}.textTemplate`, { message: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateId]);

  useEffect(() => {
    templatesRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    textTemplate,
    emailTemplateId,
    textErrorMessage,
    emailsErrorMessage,
    email,
    errors,
    inputRef,
    emails,
    templateErrorMessage,
    setEmail,
    watch,
    options,
    templates,
    handleKeyDown,
    register,
    handleDelete,
  };
};
