/* eslint-disable max-len */
import { SVGProps } from 'react';

const FileIcon = (props: SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <g clipPath="url(#clip0_5786_8975)">
      <path d="M7.99967 2.66797C6.53301 2.66797 5.34634 3.86797 5.34634 5.33464L5.33301 26.668C5.33301 28.1346 6.51967 29.3346 7.98634 29.3346H23.9997C25.4663 29.3346 26.6663 28.1346 26.6663 26.668V11.7746C26.6663 11.068 26.3863 10.388 25.8797 9.89464L19.4397 3.45464C18.9463 2.94797 18.2663 2.66797 17.5597 2.66797H7.99967ZM17.333 10.668V4.66797L24.6663 12.0013H18.6663C17.933 12.0013 17.333 11.4013 17.333 10.668Z" fill="#788B9A" />
    </g>
    <defs>
      <clipPath id="clip0_5786_8975">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default FileIcon;
