import { dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  searchTerm?: string;
  limit?: string;
  offset?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: string;
}

export const FiltersSchema = yup.object().shape({
  dateFrom: dateSchema('Date from is required', false),
  dateTo: dateSchema('Date to is required', false),
});
