import { IAddTaskPayload, ITask } from '@features/customers/tasks/types';

export const formattedData = (data: ITask): IAddTaskPayload => {
  const { id, name, users, followUp, status, notes } = data;
  const userIds = users.map((item) => item.id);

  return {
    id, name, userIds, followUp, status, notes,
  };
};
