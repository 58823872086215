import { JobAttributes } from "@features/orders/order/types";

export function orderingArrayByArray(
  jobAttributes: JobAttributes[],
  orderArray: string[],
): JobAttributes[] {
  const jobAttributesCopy = [...jobAttributes];

  return jobAttributesCopy.sort((a, b) => {
    const indexA = orderArray.indexOf(a.attributeCategoryName);
    const indexB = orderArray.indexOf(b.attributeCategoryName);

    // If the value isn't found in the orderArray, we push it to the end
    const orderA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
    const orderB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

    return orderA - orderB;
  });
}

export const orderingArrayByObject = (
  input: [string, string][],
  order: string[],
) => {
  return input.sort((a, b) => {
    const indexA = order.indexOf(a[0]);
    const indexB = order.indexOf(b[0]);

    if (indexA === -1 && indexB === -1) {
      return 0;
    }

    if (indexA === -1) {
      return 1;
    }

    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
};
