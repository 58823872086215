import { memo } from 'react';

import { useConfirm } from 'material-ui-confirm';

import StyledTypography from '../../../../StyledTypography';
import confirmOptionsDialog from '../../../../Confirm';

export interface IDeleteAction {
  text?: string;
  questionText: string;
  deleteAction: () => void;
  warningText?: string;
}

const DeleteBtn = ({ text = 'Delete', questionText, deleteAction, warningText }: IDeleteAction) => {
  const confirm = useConfirm();
  const handleDelete = async () => {
    try {
      await confirm(confirmOptionsDialog({ questionText, warningText }));
      await deleteAction();
    } catch { }
  };

  return (
    <StyledTypography
      aria-disabled
      onClick={handleDelete}
      color="blue"
      cursor="pointer"
      variant="body3"
    >
      {text}
    </StyledTypography>
  );
};

export default memo(DeleteBtn);
