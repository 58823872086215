import { memo } from 'react';

import TableCell from '@mui/material/TableCell';
import EditCell from '@containers/common/Table/components/EditCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import { formattedDate } from '@utils/helpers';
import { IPromoCode } from '@features/promoCodes/types';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import TCell from '@containers/common/Table/components/TCell';

import { StyledCheckIcon } from './styles';
import { DiscountTypesEnum } from './helpers';

interface ITableRow extends IPromoCode {
  deleteAction:(id: string) => void;
}

const TableRow = ({
  id,
  promoCode,
  discountType,
  maxDiscount,
  dealOfTheDay,
  products,
  orderAmount,
  discount,
  startDate,
  endDate,
  oneTimeUse,
  deleteAction,
}: ITableRow) => (
  <StyledTableRow>
    <EditCell title={promoCode} path="EDIT_PROM_CODE" params={{ id }} />
    <TCell text={discountType === DiscountTypesEnum.PERCENT ? 'Discount in %' : 'Discount in $'} />
    <TCell text={products.length ? 'Yes' : 'No'} />
    <TCell text={discount} />
    <TCell text={orderAmount} />
    <TCell text={`${formattedDate(startDate)}-${formattedDate(endDate)}`} />
    <TCell text={maxDiscount} />
    <TableCell align="center">
      {oneTimeUse && <StyledCheckIcon />}
    </TableCell>
    <TableCell align="center">
      {dealOfTheDay && <StyledCheckIcon />}
    </TableCell>
    <DeleteBtn
      deleteAction={() => deleteAction(id)}
      questionText="Are you sure you want to delete this promotional code ?"
    />
  </StyledTableRow>
);

export default memo(TableRow);
