export const headCells = [
  {
    label: 'LOCATION NAME',
  },
  {
    label: 'ZIP CODES',
  },
  {
    label: 'ACTIONS',
  },
];
