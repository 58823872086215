import { dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  searchTerm?: string;
  dateCreated?: string;
  dateCompleted?: string;
  status?: string;
  userId?: string;
}

export const FiltersSchema = yup.object().shape({
  dateCreated: dateSchema('Date created is required', false),
  dateCompleted: dateSchema('Date completed is required', false),
});
export const statusOptions = [
  {
    optionName: 'Open',
    value: 'open',
  }, {
    optionName: 'Closed',
    value: 'closed',
  },
];
