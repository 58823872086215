import { memo } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';
import EditCell from '@containers/common/Table/components/EditCell';
import { formattedDate } from '@utils/helpers';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IBillingInfo } from '@features/customers/billingInfo/types';
import { IRequest } from '@features/requests/types';
import TCell from '@containers/common/Table/components/TCell';

const TableRow = ({ id, firstName, lastName, email, created, billingInfo = {} }: IRequest) => {
  const { phone = '', address1 = '' } = billingInfo as IBillingInfo;

  return (
    <StyledTableRow>
      <EditCell title={`${firstName} ${lastName}`} path="EDIT_CUSTOMER" params={{ id }} />
      <TableCell>
        <Stack>
          <Typography variant="body3">
            {email}
          </Typography>
          <Typography variant="body3">
            {phone}
          </Typography>
        </Stack>
      </TableCell>
      <TCell text={address1} />
      <TCell text={formattedDate(created)} />
    </StyledTableRow>
  );
};

export default memo(TableRow);
