import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectTemplates } from '@features/templates/selectors';
import { addTemplate, editTemplate } from '@features/templates/actions';
import { selectSubcategories } from '@features/subcategories/selectors';
import { getOptionsArray } from '@utils/helpers';
import { selectTemplateCategories } from '@features/templateCategories/selectors';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { resetTemplates } from '@features/templates/slice';
import { resetTemplateCategories } from '@features/templateCategories/slice';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { AddDataSchema, IAddDataForm, inputsRows, defaultValues, formattedPayload } from './helpers';
import { IInputsTable } from './types';

const InputsTable = ({ templatesData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectTemplates);
  const { allData: subcategories } = useAppSelector(selectSubcategories);
  const { allData: templateCategories } = useAppSelector(selectTemplateCategories);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const subcategoriesList = getOptionsArray(subcategories);
  const templateCategoriesList = getOptionsArray(templateCategories, 'name');
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema),
    defaultValues: templatesData ? {
      ...templatesData,
      templateCategoryId: templatesData.templateCategoryId || '',
    } : defaultValues,
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = (data: IAddDataForm) => {
    const payload = formattedPayload(data);

    dispatch(templatesData ? editTemplate(payload) : addTemplate(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

        if (!templatesData) {
          dispatch(resetTemplates());
          dispatch(resetTemplateCategories());
          navigate(PAGE_ROUTES.TEMPLATES);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Template with the provided name, categoryId and templateId already exists!') {
          setError('name', { message: e.message });
        } else {
          navigate(PAGE_ROUTES.TEMPLATES);
        }
      });
  };

  return (
    <TitlesWithBackButton
      title={templatesData ? 'Edit Template' : 'Add Template'}
      path="TEMPLATES"
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="TEMPLATE" colSpan={2}>
            {inputsRows.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[{
                    field: 'subCategoryId',
                    options: subcategoriesList,
                  }, {
                    field: 'templateCategoryId',
                    options: templateCategoriesList,
                  }]}
                />
              </RowComponent>
            ))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />

        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
