import React, { memo } from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ToggleTitleWrapper,
  ToggleContainer,
  StyledLoaderWrapper,
  StyledTypography,
} from './styles';

interface ToggleHocProps {
    id: string;
    title: string;
    status: boolean;
    localLoader: string | null;
    children: React.ReactNode;
    handleUpdateToggle: (id: string) => void;
}

const ToggleHoc = ({ children, status, title, id, localLoader, handleUpdateToggle }: ToggleHocProps) => {
  return (
    <>
      <ToggleTitleWrapper onClick={() => handleUpdateToggle(id)}>
        {status ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        <StyledTypography variant="h5">{title}</StyledTypography>
      </ToggleTitleWrapper>
      <ToggleContainer status={status}>
        {localLoader === id && (
        <StyledLoaderWrapper>
          <CircularProgress />
        </StyledLoaderWrapper>
        )}
        {children}
      </ToggleContainer>
    </>
  );
};

export default memo(ToggleHoc);
