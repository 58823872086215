import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import { IRequest, IRequestCount, IRequestSearchPayload } from './types';

const prefix = '/requests';

export const getRequestsCount = createAsyncThunk<IRequestCount, void, { rejectValue: AxiosData}>(
  'requests/count',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IRequestCount>>(`${prefix}/count`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchRequests = createAsyncThunk<IRequest[], IRequestSearchPayload, {
  rejectValue: AxiosData;
}>(
  'requests/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData as Filters);

      const { data: { data } } = await http.get<AxiosResponse<IRequest[]>>(
        `${prefix}?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
