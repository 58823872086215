import { memo } from 'react';

import { Job } from '@features/orders/order/types';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Input from '@containers/common/Input';
import { Box } from '@mui/material';

import EditableAttributes from '../components/EditableAttributes';
import UneditableAttributes from '../components/UneditableAttributes';
import { StyledWrapperDynamically } from '../components/EditableAttributes/styled';
import { StyledLinkNew } from '../../../../styled';
import { Left, StaticStructure } from './styled';
import AttributePrices from '../components/AttributePrices';

interface JobLeftSectionProps {
  job: Job;
  jobIndex: number;
  keyByIndex: string;
  isEditable: boolean;
}

const JobLeftSection = ({
  keyByIndex,
  job,
  jobIndex,
  isEditable,
}: JobLeftSectionProps) => {
  const { watch, register, formState: { errors } } = useFormContext();

  return (
    <Box
      sx={{
        borderLeft: '1px solid #ddd',
        overflow: 'hidden',
      }}
    >
      <Left>
        <StaticStructure>
          <Typography variant="body3">Job Name/PO:</Typography>
          <Typography variant="body3">{job.name}</Typography>
        </StaticStructure>
        <StaticStructure>
          <Typography variant="body3">Item:</Typography>
          <Typography variant="body3">{job?.item?.item}</Typography>
        </StaticStructure>
        <StyledWrapperDynamically>
            <Typography variant="body3">
              Quantity:
            </Typography>
            <Typography variant="body3">{job?.item?.quantity}</Typography>
            <Box
              sx={{ gap: 1 }}
            >
              <p>$</p>
              {
                isEditable ? (
                  <Input
                    type="text"
                    onlyBorder
                    orderDesignStyle
                    {...register(`${keyByIndex}.quantityPrice`)}
                    label=""
                    placeholder="Enter"
                    // @ts-ignore
                    errorMessage={errors?.jobs?.[jobIndex]?.quantityPrice?.message}
                  />
                )
                : <p>{watch(`${keyByIndex}.quantityPrice`)}</p>
              }
            </Box>
        </StyledWrapperDynamically>
        {
          job.customEstimateId && (
            <StyledWrapperDynamically>
              <Typography variant="body3">
                Custom Estimate:
              </Typography>
              <StyledLinkNew to={`/orders/open-estimates/edit/${job.customEstimateId}`}>
                View custom estimate
              </StyledLinkNew>
            </StyledWrapperDynamically>
          )
        }
        {
          isEditable
          ? (<EditableAttributes job={job} jobIndex={jobIndex} />)
          : (<UneditableAttributes job={job} jobIndex={jobIndex} />)
        }
      </Left>
      <AttributePrices jobIndex={jobIndex} isEditable={isEditable} />
    </Box>
  );
};

export default memo(JobLeftSection);
