/* eslint-disable max-len */
import { ProofStatusEnum } from "@containers/Orders/Order/components/Switcher/components/BasicInfo/OrderDetails/utils/enums";

import { NotApprovedAndPendingTypography, ApprovedTypography } from './styled'; // DeleteButton

interface StatusesProps {
  status: ProofStatusEnum;
}

const Statuses = ({ status }: StatusesProps) => {
  if (status === ProofStatusEnum.NEED_PROOF) {
    return (
      <NotApprovedAndPendingTypography>
        Not Approved
      </NotApprovedAndPendingTypography>
    );
  }

  if (status === ProofStatusEnum.ACCEPTED) {
    return (
      <ApprovedTypography>
        Approved
      </ApprovedTypography>
    );
  }

  if (status === ProofStatusEnum.REJECTED) {
    return (
      <NotApprovedAndPendingTypography>
        Rejected
      </NotApprovedAndPendingTypography>
    );
  }

  return '';
};

export default Statuses;
