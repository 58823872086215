import { memo } from 'react';

const ProcurementCustomers = () => {
  return (
    <>
      ProcurementCustomers
    </>
  );
};

export default memo(ProcurementCustomers);
