import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  title: string;
  description: string;
  displayInHeader?: boolean;
  visibleOnSite?: boolean;
}

export const defaultValues = {
  title: '',
  description: '',
  displayInHeader: false,
  visibleOnSite: false,
};

export const AddDataSchema = yup.object().shape({
  title: createRequiredTextWidthValidation('Title', 255),
  description: createRequiredTextWidthValidation('Description', 500),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Title',
    field: 'title',
    type: InputTypes.text,
  },
  {
    label: 'Description',
    field: 'description',
    type: InputTypes.textarea,
  },
  {
    label: 'Display in Header',
    field: 'displayInHeader',
    type: InputTypes.checkbox,
  },
  {
    label: 'Visible on Site',
    field: 'visibleOnSite',
    type: InputTypes.checkbox,
  },
];
