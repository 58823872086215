import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { addPromoCode, editPromoCode, getAllPromoCodes, getPromoCodeById, searchPromoCodes } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
};

const promoCodeSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    resetPromoCodes: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addPromoCode.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addPromoCode.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addPromoCode.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editPromoCode.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editPromoCode.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editPromoCode.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllPromoCodes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllPromoCodes.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllPromoCodes.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getPromoCodeById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPromoCodeById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPromoCodeById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchPromoCodes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchPromoCodes.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchPromoCodes.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });
  },
});

export const { resetPromoCodes } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
