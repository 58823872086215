import { memo, useEffect } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import {
  deleteTemplateCategory, reorderTemplateCategories, searchTemplateCategories,
} from '@features/templateCategories/actions';
import { selectTemplateCategories } from '@features/templateCategories/selectors';
import EditCell from '@containers/common/Table/components/EditCell';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { useLocation } from 'react-router-dom';
import { resetTemplateCategories, setTemplateCategories } from '@features/templateCategories/slice';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const TemplateCategories = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { data: templateCategories, isLoading } = useAppSelector(selectTemplateCategories);

  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '' } = params as IFiltersForm;
  const query = { searchTerm };

  const fetchData = () => {
    dispatch(searchTemplateCategories(query));
  };

  useUnMount(() => dispatch(resetTemplateCategories()));

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm],
  );

  const deleteAction = (id: string) => {
    dispatch(deleteTemplateCategory(id)).unwrap().then(() => {
      fetchData();
    }).catch(() => {});
  };

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, templateCategories);

    dispatch(reorderTemplateCategories(sortedData)).unwrap().then(() => {
      dispatch(setTemplateCategories(items));
    }).catch(() => fetchData());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Template Categories" btnName="Add Template Category" path={PAGE_ROUTES.ADD_TEMPLATE_CATEGORY} />
      { (searchTerm || !!templateCategories?.length) && <SearchSection /> }
      {templateCategories?.length ? (
        <DndContainer reordingData={reordingData}>
          <StyledTable headCells={headCells}>
            {templateCategories.map(({ name, id }, index) => (
              <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 140px  70px">
                {({ providedDraggable }) => (
                  <>
                    <EditCell title={name} path="EDIT_TEMPLATE_CATEGORY" params={{ id }} />
                    <DndBtn providedDraggable={providedDraggable} />
                    <DeleteBtn
                      deleteAction={() => deleteAction(id)}
                      questionText="Are you sure you want to delete this template category ?"
                      warningText="All templates will be deleted as well."
                    />
                  </>
                )}
              </ReusableDragRow>
            ))}
          </StyledTable>
        </DndContainer>
      ) : (
        <EmptyState
          text={searchTerm ? 'No search results found' : 'You don’t have any template categories'}
          isAdd={!searchTerm}
        />
      )}
    </>
  );
};

export default memo(TemplateCategories);
