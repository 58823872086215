import { memo, useEffect, useState } from 'react';

import TitlesWithBackButton from "@containers/common/TitlesWithBackButton";
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@features/app/hooks';
import { getInvoiceById, getOrderDetails } from '@features/orders/order/actions';
import Table from '@containers/common/Table';
import TCell from '@containers/common/Table/components/TCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import Loader from '@containers/common/Loader';
import { EXTRA_DATE_FORMAT, priceFormatting } from '@utils/helpers';
import { isValid, format } from 'date-fns';
import { IInvoice } from '@features/invoices/types';

import { ColoredText, StyledTCell } from './styles';

const InvoiceView = () => {
  const dateFormat = EXTRA_DATE_FORMAT;
  const { orderId = '', jobId = '', invoiceId } = useParams();
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [orderCreatedDate, setOrderCreatedDate] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!invoiceId) {
      return;
    }

    Promise.all([
      dispatch(getOrderDetails({ id: orderId })).unwrap(),
      dispatch(getInvoiceById({ id: invoiceId })).unwrap(),
    ]).then(([orderResponse, invoiceResponse]) => {
      setOrderCreatedDate(orderResponse.created);
      setInvoice(invoiceResponse);
    }).catch((error) => {
      console.log('Error: ', error);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (invoice === null) {
    return <Loader />;
  }

  return (
    <TitlesWithBackButton
      path="ORDER"
      params={{ orderId, jobId }}
      title={`Invoice ${invoice.invoiceNumber}`}
    >
      <Table>
        <StyledTableRow>
          <StyledTCell>Invoice ID</StyledTCell>
          <TCell>{invoice.invoiceNumber}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Invoice Status:</StyledTCell>
          <TCell>
            {
              invoice.invoiceStatus === 'paid' ? (
                <ColoredText>
                  {invoice.invoiceStatus}
                </ColoredText>
              ) : invoice.invoiceStatus
            }
          </TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Date Billed:</StyledTCell>
          <TCell>{isValid(new Date(invoice.dateBilled)) && format(new Date(invoice.dateBilled), dateFormat)}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Order ID</StyledTCell>
          <TCell>{invoice.orderNumber}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Order Date</StyledTCell>
          <TCell>{isValid(new Date(orderCreatedDate)) && format(new Date(orderCreatedDate), dateFormat)}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Customer</StyledTCell>
          <TCell>
            {invoice?.customer?.firstName || ''}
            {' '}
            {invoice?.customer?.lastName || ''}
          </TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Item Description: </StyledTCell>
          <TCell>{invoice?.itemDescription}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Item Price: </StyledTCell>
          <TCell>{priceFormatting(invoice?.itemPrice || 0)}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Tax:</StyledTCell>
          <TCell>{priceFormatting(invoice?.tax || '')}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Total:</StyledTCell>
          <TCell>{priceFormatting(invoice?.subtotal || '')}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Payment Details:</StyledTCell>
          <TCell>{invoice?.paymentDetails || 'You’ll see the transaction ID once it is paid. '}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Payment Method:</StyledTCell>
          <TCell>{invoice?.paymentMethod}</TCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTCell>Payment URL for customer:</StyledTCell>
          <TCell>{invoice?.paymentURLForCustomer}</TCell>
        </StyledTableRow>
      </Table>
    </TitlesWithBackButton>

  );
};

export default memo(InvoiceView);
