import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import assignedAttributeCloseIcon from '@assets/images/assignedAttributeCloseIcon.svg';
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { useParams } from "react-router-dom";
import {
  getDefaultAttributesByProductId,
  setProductsDefaultAttribute,
} from "@features/products/productsAttributes/actions";
import { selectProductsAttributes } from "@features/products/productsAttributes/selectors";
import ErrorMessage from "@containers/common/ErrorMessage";
import {
  defaultAttributeOfSubcategory,
  getDefaultAttributesBySubcategoryId,
} from "@features/subcategories/actions";
import { selectSubcategories } from "@features/subcategories/selectors";

import {
  Wrapper,
  SubTitle,
  ListWrapper,
  StyledTitle,
  AssignedAttribute,
} from "./styles";
import { IAddDataForm } from "../InputsTable/helpers";

interface SummaryProps {
  type: 'category' | 'product';
}

const Summary = ({ type }: SummaryProps) => {
  const specialProductMessage = 'The provided product does not contain the specified attribute';
  const specialCategoryMessage = 'The provided subCategory does not contain the specified attribute';

  // change variable name
  const { id: productId } = useParams();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const { setValue, watch } = useFormContext<IAddDataForm>();
  const { defaultAttributeIds } = useAppSelector(selectSubcategories);
  const productAttributes = useAppSelector(selectProductsAttributes);

  // here did not update view
  const defaultAttrState = type === 'category'
    ? (defaultAttributeIds || [])
    : productAttributes.defaultAttributes;

  const attributesList = watch('attributesList');

  const handleDeleteByUpdatingByIsSelected = (firstId: string, secondId: string) => {
    const firstIndex = attributesList.findIndex((item) => item.id === firstId);
    const secondIndex = attributesList[firstIndex]?.attributes?.findIndex((item) => item.id === secondId);

    // attributesList[firstIndex]?.attributes[secondIndex].isSelected;

    // @ts-ignore
    setValue(`attributesList[${firstIndex}].attributes[${secondIndex}].isSelected`, false);
  };

  const handleSetDefaultAttribute = async (attributeId: string) => {
    setErrorMessage('');

    if (!productId) {
      return null;
    }

    try {
      if (type === 'category') {
        const found = attributesList
          .find((item) => item.attributes.find((i) => i.id === attributeId))
          ?.attributes
          .find((i) => i.id === attributeId);

        if (!found) {
          return null;
        }

        await dispatch(defaultAttributeOfSubcategory({
          subcategoryId: productId,
          attributeId,
          isDefault: !found?.isDefault,
        }))
          .unwrap();
        await dispatch(getDefaultAttributesBySubcategoryId(productId));

        return;
      }

      const isDefault = !defaultAttrState.includes(attributeId);

      await dispatch(setProductsDefaultAttribute({ body: { attributeId, isDefault }, id: productId })).unwrap();
      await dispatch(getDefaultAttributesByProductId(productId)).unwrap();
    } catch (error: any) {
      if (
        typeof error?.message === 'string' && error?.message === specialProductMessage ||
      typeof error?.message === 'string' && error?.message === specialCategoryMessage
      ) {
        setErrorMessage(attributeId);
      }
    }
  };

  return (
    <Wrapper>
      <StyledTitle variant="body2">
        Summary of assigned attributes (click an
        attribute’s name here to set it as default)
      </StyledTitle>
      {
        attributesList.map(({ id, name, attributes }) => {
          const attrList = [...attributes].filter((attr) => attr.isSelected);

          if (!attrList?.length) {
            return null;
          }

          const localErrorMessage = attrList?.map((i) => i.id)?.includes(errorMessage);

          return (
            <Box key={id}>
              <SubTitle>{name}</SubTitle>
              {
                localErrorMessage && (
                  <ErrorMessage
                    message={
                      localErrorMessage
                        ? `You cannot select a default attribute for an item that hasn't been saved.`
                        : ''
                    }
                  />
                )
              }
              <ListWrapper>
                {attrList.map((attribute) => {
                  const isDefault = defaultAttrState.includes(attribute.id);

                  return (
                    <AssignedAttribute
                      key={attribute.id}
                      selected={isDefault}
                      onClick={() => handleSetDefaultAttribute(attribute.id)}
                    >
                      {attribute?.nickname}
                      {isDefault ? <span>{` (Default) `}</span> : null}
                      <img
                        src={assignedAttributeCloseIcon}
                        alt="Assigned Attribute Close Icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteByUpdatingByIsSelected(id, attribute.id);
                        }}
                      />
                    </AssignedAttribute>
                  );
                })}
              </ListWrapper>
            </Box>
          );
        })
      }
    </Wrapper>
  );
};

export default Summary;
