import { memo } from 'react';

import moment from 'moment';
import { IEmailLog } from '@features/customEstimates/types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const LogItem = ({ userType, link, time, customerFirstName, customerLastName }: IEmailLog) => {
  return (
    <Stack pb="24px">
      <Stack>
        <Typography variant="body2" mb="16px">
          {`Sent: ${moment(time).format('MM/DD/YY hh:mm A')}`}
        </Typography>
      </Stack>
      <Stack>
        <Typography mb="16px" variant="body3">
          {userType === 'customer' ? `A new estimate has been submitted by ${customerFirstName} ${customerLastName}.`
            : 'Your estimate has been updated by PostcardsRUs'}
        </Typography>
      </Stack>
      <Stack direction="row" gap="5px">
        <Link href={link} fontSize="12px" target="_blank">Click here</Link>
        <Typography variant="body3">to view it in the account.</Typography>
      </Stack>
    </Stack>
  );
};

export default memo(LogItem);
