import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const NotApprovedAndPendingTypography = styled(Typography)(() => ({
  fontSize: '12px',
  color: COLORS.red.dark,
}));

export const ApprovedTypography = styled(Typography)(() => ({
  fontSize: '12px',
  color: COLORS.green[200],
}));
