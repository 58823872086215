import { styled } from '@mui/material/styles';
import { StyledTableRow } from '@containers/common/Table/styled';
import Table from '@containers/common/Table';
import { COLORS } from '@customTypes/global/theme/colors';
import Button from '@containers/common/Button';
import { Link } from 'react-router-dom';

export const StyledTable = styled(Table)(() => ({
}));

export const StyledVendorTableRow = styled(StyledTableRow)(() => ({
  position: 'relative',
}));

export const LoadButton = styled(Button)(() => ({
  maxWidth: 300,
  marginTop: '48px',
}));

export const VendorIdLink = styled(Link)(() => ({
  textDecoration: 'underline',
  color: COLORS.blue.main,
}));
