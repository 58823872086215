import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  addMailingServiceFee, editMailingServiceFee, getAllMailingServiceFees, getMailingServiceFeeById,
} from './actions';

const initialState: IState = {
  isLoading: true,
  data: null,
  actionLoading: false,
};

const mailingServiceFeesSlice = createSlice({
  name: 'mailingServiceFees',
  initialState,
  reducers: {
    resetMailingServiceFees: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMailingServiceFees.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllMailingServiceFees.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllMailingServiceFees.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getMailingServiceFeeById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMailingServiceFeeById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMailingServiceFeeById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editMailingServiceFee.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editMailingServiceFee.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editMailingServiceFee.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(addMailingServiceFee.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addMailingServiceFee.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addMailingServiceFee.rejected, (state) => {
      state.actionLoading = false;
    });
  },
});

export const { resetMailingServiceFees } = mailingServiceFeesSlice.actions;
export default mailingServiceFeesSlice.reducer;
