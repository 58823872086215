import { memo, useState } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import { IShippingZip } from '@features/shippingZip/types';
import { getShippingZipById } from '@features/shippingZip/actions';
import { selectShippingZips } from '@features/shippingZip/selectors';

import InputsTable from '../InputsTable';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [editData, setEditData] = useState<IShippingZip | null>(null);
  const { isLoading } = useAppSelector(selectShippingZips);

  useMount(() => {
    dispatch(getShippingZipById(id as string))
      .unwrap()
      .then((data) => {
        setEditData(data);
      })
      .catch(() => navigate(PAGE_ROUTES.SHIPPING_ZIPS));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      { editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
