import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';
import { Filters, constructQueryString } from '@utils/helpers';

import { IAdminLogPayload } from './types';

const prefix = '/login-logs';

export const getAllAdminLogs = createAsyncThunk<IAdminLogPayload, Filters, { rejectValue: AxiosData }>(
  'adminLogs/all',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<IAdminLogPayload>>(`${prefix}?${queryParams}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteAdminLog = createAsyncThunk<void, string, { rejectValue: AxiosData }>(
  'adminLogs/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
