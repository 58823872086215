import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  name: string;
}

export const defaultValues = {
  name: '',
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Template Name',
    field: 'name',
    type: InputTypes.text,
  },
];
