import { memo } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import { selectTasks } from '@features/customers/tasks/selectors';

import { headCells } from './tableData';
import TableRow from './components/TableRow';

const Tasks = () => {
  const { customersTasks, isLoading } = useAppSelector(selectTasks);

  if (isLoading) {
    return <Loader />;
  }

  return customersTasks?.length ? (
    <StyledTable headCells={headCells}>
      {customersTasks.map((item) => (
        <TableRow key={item.id} {...item} />
      ))}
    </StyledTable>
  ) : (
    <EmptyState text="You don’t have any tasks" isAdd />
  );
};

export default memo(Tasks);
