import { dateSchema, positiveNumberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  searchTerm?: string;
  startDate?: string;
  endDate?: string;
  tab?: number;
  pendingNonProfit?: string;
  pendingReseller?: string;
}

export const FiltersSchema = yup.object().shape({
  startDate: dateSchema('Date From is required', false),
  endDate: dateSchema('Date To is required', false),
  maxDiscount: positiveNumberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
});
