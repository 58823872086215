import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const BasicInfoContainer = styled(Box)(() => ({
}));

export const StyledTypography = styled(Typography)<{component:string}>(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20.83px',
  marginBottom: "16px",
}));

export const TableWrapper = styled(Box)(() => ({
  marginTop: 20,
}));
