import { memo } from 'react';

import Stack from '@mui/material/Stack';
import Input from '@containers/common/Input';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@containers/common/Checkbox';
import Typography from '@mui/material/Typography';

interface IStyledSTack {
  rowIdx: number;
  attrIdx: number;
  attrRowIdx: number;
}

const StyledSTack = ({ rowIdx, attrIdx, attrRowIdx }: IStyledSTack) => {
  const { watch, register, formState: { errors } } = useFormContext();
  const { attributes, name: catName, turnAround } = watch(`quantities[${rowIdx}].attributeCategories[${attrIdx}]`);

  const { nickname } = attributes[attrRowIdx];

  return (
    <Stack>
      <Typography variant="body3">
        {nickname}
      </Typography>
      <Stack direction="row" alignItems="center">
        {turnAround && (
          <Checkbox
            name={`quantities[${rowIdx}].attributeCategories[${attrIdx}].attributes[${attrRowIdx}].isVisible`}
          />
        )}
        {
            catName === 'Postage' ? (
              <Input
                disabled
                width={turnAround ? '73px' : '100px'}
                placeholder="Price"
                ref={register(
                  `quantities[${rowIdx}].attributeCategories[${attrIdx}].attributes[${attrRowIdx}].price`,
                ).ref}
              />
            ) : (
              <Input
                width={turnAround ? '73px' : '100px'}
                placeholder="Price"
                {...register(`quantities[${rowIdx}].attributeCategories[${attrIdx}].attributes[${attrRowIdx}].price`)}
                errorMessage={(errors as any)
                  ?.quantities?.[rowIdx]?.attributeCategories?.[attrIdx]?.attributes?.[attrRowIdx]?.price?.message}
              />
            )
        }
      </Stack>
    </Stack>
  );
};

export default memo(StyledSTack);
