import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)(() => ({
  fontsize: '16px',
  fontWeight: '500',
  marginBottom: '16px',
  textAlign: 'center',
  color: '#06152B',
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  justifyContent: 'center',
}));
