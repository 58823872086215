import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const SwitcherContainer = styled(Box)(() => ({
  marginTop: '24px',
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  marginTop: "24px",
  justifyContent: 'flex-start',
}));

export const ButtonVia = styled(Box)(() => ({
  position: 'relative',
  width: '165px',
  height: '40px',
  padding: '11px 50px 11px 51px',
  textAlign: 'center',
  cursor: 'pointer',
}));

export const ButtonTaskVia = styled(Box)(() => ({
  position: 'relative',
  gap: '15px',
  display: 'flex',
  alignItems: 'center',
  width: '165px',
  color: 'red',
  height: '40px',
  cursor: 'pointer',
  textAlign: 'center',
  padding: '11px 50px 11px 51px',
}));

export const Point = styled('span')(() => ({
  width: '6px',
  height: '6px',
  borderRadius: '5px',
  background: COLORS.red.dark,
}));

export const MainLine = styled(Box)(() => ({
  background: COLORS.grey[500],
  height: '1px',
  marginTop: '12px',
  width: "100%",
  marginBottom: '24px',
}));

export const Line = styled(Box)<{ active: number }>(({ active }) => ({
  position: 'absolute',
  background: COLORS.blue.dark,
  height: '2px',
  borderRadius: '55px',
  top: 'calc(100% + 11px)',
  width: "100%",
  left: 0,
  display: active ? "block" : 'none',
  borderBottom: active ? '1px solid blue' : 'none',
}));
