import { FC, useEffect, useState } from "react";

// import ApprovedGreen from "@assets/images/approved-green.svg";
// import RejectRed from "@assets/images/reject-red.svg";
import { useAppDispatch } from "@features/app/hooks";
import { updateOrderAutoEmail } from "@features/orders/order/actions";
import ToggleSwitch from "@containers/common/ToggleSwitch";

import { Wrapper } from "./styled";

interface EmailAutoProps {
  checked: boolean;
  orderId: string;
}

const EmailAuto: FC<EmailAutoProps> = ({ checked, orderId }) => {
  const [switchState, setSwitchState] = useState(checked);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateOrderAutoEmail({ orderId, autoEmail: switchState })).unwrap();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchState]);

  return (
    <Wrapper>
      <ToggleSwitch
        switchState={switchState}
        setSwitchState={setSwitchState}
      />
    </Wrapper>
  );
};

export default EmailAuto;
