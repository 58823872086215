import { styled } from '@mui/material/styles';
import { StyledTableRow } from '@containers/common/Table/styled';
import { Box, Select } from '@mui/material';
import Table from '@containers/common/Table';
import { COLORS } from '@customTypes/global/theme/colors';
import Button from '@containers/common/Button';
import { Link } from 'react-router-dom';
import TCell from '@containers/common/Table/components/TCell';

export const StyledTable = styled(Table)(() => ({
}));

export const StyledOrderTableRow = styled(StyledTableRow)(() => ({
  position: 'relative',

  // following by this priority
  // Blue > Red > Yellow
  '&.open-task': {
    '> td:first-of-type': {
      background: COLORS.red.light,
    },
  },
  '&.eddm': {
    // Yellow
    background: COLORS.blue.light,
  },
  '&.with-comment': {
    // Red
    background: COLORS.red.exLight,
  },
  '&.rush': {
    // Blue
    background: COLORS.white.dark,
  },

  td: {
    '&:nth-of-type(3)': {
      textAlign: 'center',
    },
    padding: '3px 10px',

    '.MuiStack-root > *': {
      height: '30px',
      maxHeight: '30px',
    },

    '.MuiInputBase-root': {
      height: '30px',
      maxHeight: '30px',
    },

    input: {
      padding: '7px 12px !important',
    },
  },
}));

export const StyledSelect = styled(Select)(() => ({
  width: '100%',
  maxHeight: '33px',
  background: 'white',

  '&.red-statuses > .MuiSelect-select': {
    color: 'red',
  },
  div: {
    padding: '5px 16px',
  },
}));

export const LoadButton = styled(Button)(() => ({
  maxWidth: 300,
  marginTop: '48px',
}));

export const OrderIdLink = styled(Link)(() => ({
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  color: COLORS.blue.main,
}));

export const StyledTCell = styled(TCell)(() => ({
  padding: '12px 16px !important',

  td: {
    padding: '12px 16px !important',
  },
}));

export const StyleWrapper = styled(Box)(() => ({
  table: {
    minWidth: '1250px !important',
  },
  thead: {
    tr: {
      padding: '0px !important',
      th: {
        span: {
          whiteSpace: 'nowrap',
        },
      },
      'th:first-of-type': {
        padding: '0px !important',
      },
    },
  },
  tbody: {
    tr: {
      'td:first-of-type': {
        padding: '0px !important',
      },
    },
  },
}));
