import { integerSchema, numberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IAddDataForm {
  id?: string;
  quantity: number | null;
  productId: string;
  basePrice: number | null;
}

export const defaultValues = {
  quantity: null,
  productId: null,
  basePrice: 0,
};

export const AddDataSchema = yup.object().shape({
  quantity: integerSchema.required('Quantity is required').max(999999999, 'The maximum number of digits is 9'),
  basePrice: numberSchema.required('Base price is required'),
});

export const formattingPayload = (data: IAddDataForm) => {
  return { ...data };
};
