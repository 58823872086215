import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectOrderDetails } from "@features/orders/order/selectors";
import { Box } from "@mui/material";
import { getOrderDetails } from "@features/orders/order/actions";
import { useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import Table from "@containers/common/Table";
import TCell from "@containers/common/Table/components/TCell";
import { formattedDate } from "@utils/helpers";
import { StyledTableRow } from "@containers/common/Table/styled";
import moment from "moment";
import Loader from "@containers/common/Loader";
import { loginAsCustomers } from "@features/customers/customers/actions";
import { WEB_API_URL } from "@config/index";
import { getConversationLastMessageSelector } from "@features/conversation/selectors";
import { getConversationLastMessage } from "@features/conversation/actions";

import { headCells } from "../../../../columns";
import OrderDetails from "./OrderDetails";
import { StyleWrapper, StyledLink, StyledWrapper } from "./styled";
import EmailAuto from "./EmailAuto";
import Conversation from "./Conversation";
// import EmailAuto from './EmailAuto';

const BasicInfo = () => {
  const { orderId, jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const lastMessage = useAppSelector(getConversationLastMessageSelector);
  const state = useAppSelector(selectOrderDetails);
  const [lastUpdated, setLastUpdated] = useState("");
  const { order, isLoading } = state;

  useEffect(() => {
    if (!order.activateConversation || !orderId) {
      return;
    }

    dispatch(getConversationLastMessage({ id: orderId }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.activateConversation, orderId]);

  useEffect(() => {
    const updatedDates = order?.jobs
      ?.map((job) => job.updated)
      .filter((date) => date);

    const result = updatedDates?.reduce((prev: string, current: string) => {
      if (prev) {
        return moment(prev).isAfter(current) ? prev : current;
      }

      return current;
    }, "");

    setLastUpdated(result || "");
  }, [order?.jobs]);

  useEffect(() => {
    try {
      if (!orderId) {
        navigate(PAGE_ROUTES.OPEN_ORDERS);

        return;
      }

      dispatch(getOrderDetails({ id: orderId })).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, jobId]);

  if (isLoading || !Object.keys(order || {})?.length) {
    return <Loader />;
  }

  const handelLogin = () => {
    if (!order?.customer?.id) {
      console.log("Customer id is missing", order?.customer);

      return null;
    }

    dispatch(loginAsCustomers(order?.customer?.id))
      .unwrap()
      .then(({ refreshToken }) => {
        const url = `${WEB_API_URL}/auth/login-as-admin/${refreshToken}`;

        window.open(url, "_blank", "noopener,noreferrer");
      })
      .catch(() => {});
  };

  return (
    <Box sx={{ maxWidth: "1156px" }}>
      <StyleWrapper>
        <Table title="" headCells={headCells}>
          <StyledTableRow>
            <TCell text={order?.created ? formattedDate(order?.created) : ""} />
            <TCell text={lastUpdated ? formattedDate(lastUpdated) : ""} />
            <TCell>
              <StyledLink onClick={handelLogin}>
                {order?.customer.firstName}
                {' '}
                {order?.customer.lastName}
              </StyledLink>
            </TCell>
            <TCell text={order.customer?.IPAddress} />
            <TCell
              text={
                order?.paymentMethod === "payment_terms"
                  ? "Payment Terms"
                  : order?.paymentMethod
              }
            />
            <TCell width="198">
              {
                order.activateConversation && (
                  <StyledWrapper>
                    <span>Last message</span>
                    <p>{lastMessage}</p>
                    <Conversation />
                  </StyledWrapper>
                )
              }
            </TCell>
            <TCell>
              <EmailAuto checked={order.autoEmails} orderId={order.id} />
            </TCell>
          </StyledTableRow>
        </Table>
      </StyleWrapper>
      <OrderDetails />
    </Box>
  );
};

export default BasicInfo;
