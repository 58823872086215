export enum ShipmentStatusesEnum {
  UPS = 'UPS',
  FEDEX = "FEDEX",
  isEditable = 'isEditable'
}

export enum OrderTypesEnum {
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OrderListUpdateRequestFieldKeys {
  status = 'status',
  vendorId = 'vendorId',
  vendorJob = 'vendorJob',
  mailingPO = 'mailingPO',
  mailPo = 'mailPo',
}

// updated by backend requirements
export enum OrderStatusesEnum {
  AwaitingArtwork = 'Awaiting Artwork',
  Received = 'Received',
  ProofRejected = 'Proof Rejected',
  IssueWithYourArt = 'Issue with your art',
  BleedRequiredForRigidSigns = 'Bleed Required for Rigid Signs',
  PrintingBleed = 'Printing Bleed',
  ArtReminder = 'Art Reminder',
  OnHold = 'On Hold',

  FilePreview = 'File Preview',
  PreFlightArtwork = 'Pre-Flight Artwork',
  AwaitingProofApproval = 'Awaiting Proof Approval',
  OneSideApprovedOnly = 'One Side Approved Only',
  DataNeeded = 'Data Needed',
  StillAwaitingProofApproval = 'Still Awaiting Proof Approval',
  AdjustedProofNotice = 'Adjusted Proof Notice',

  Printing = 'Printing',
  Approved = 'Approved',
  Processing = 'Processing',
  BatchImposition = 'Batch Imposition',
  Plated = 'Plated',
  Finishing = 'Finishing',
  Bindery = 'Bindery',

  QualityControlReprint = 'Quality Control Reprint',

  MailingShipping = 'Mailing/Shipping',
  Shipping = 'Shipping',
  MailingServicesAddressing = 'Mailing Services Addressing',
  Fulfillment = 'Fulfillment',

  Complete = 'Complete',
  Canceled = 'Canceled',
}

// old version, still need to keep
// export enum OrderStatusesEnum {
//   Canceled = 'Canceled',
//   Received = 'Received',
//   AwaitingArtwork = 'Awaiting Artwork',
//   PreFlightArtwork = 'Pre-Flight Artwork',
//   AwaitingProofApproval = 'Awaiting Proof Approval',
//   Approved = 'Approved',
//   BatchImposition = 'Batch Imposition',
//   Plated = 'Plated',
//   Printing = 'Printing',
//   Finishing = 'Finishing',
//   Bindery = 'Bindery',
//   MailingServicesAddressing = 'Mailing Services Addressing',
//   Shipping = 'Shipping',
//   Complete = 'Complete',
//   QualityControlReprint = 'Quality Control Reprint',
//   ProofRejected = 'Proof Rejected',
//   IssueWithYourArt = 'Issue with your art',
//   Note = 'Note',
//   OneSideApprovedOnly = 'One Side Approved Only',
//   ArtReminder = 'Art Reminder',
//   StillAwaitingProofApproval = 'Still Awaiting Proof Approval',
//   OnHold = 'On Hold',
//   NewRegistration = 'New Registration',
//   AdjustedProofNotice = 'Adjusted Proof Notice',
//   DataNeeded = 'Data Needed',
//   BleedRequiredForRigidSigns = 'Bleed Required for Rigid Signs',
//   OffsetPrintingBleed = 'Offset Printing Bleed',
//   FeedbackComplete = 'Feedback Complete',
//   ShippingAddressNeeded = 'Shipping Address Needed',
//   ArtEdits = 'Art Edits',
//   EDDMIndiciaAdjustment = 'EDDM Indicia Adjustment',
// }
