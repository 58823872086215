import { useMemo } from "react";

import { OrderStatusesEnum } from "@features/orders/order/enums";

export const getOrderStatusesOptions = () => {
  const statusOptions = useMemo(() => {
    const colorArrayManual: { optionName: string; value: string }[] = [];

    for (const key in OrderStatusesEnum) {
      if (OrderStatusesEnum.hasOwnProperty(key)) {
        colorArrayManual.push({
          optionName: key.replace(/([A-Z])/g, ' $1').trim(),
          value: OrderStatusesEnum[key as keyof typeof OrderStatusesEnum],
        });
      }
    }

    colorArrayManual.sort((a, b) => a.optionName.localeCompare(b.optionName));

    return colorArrayManual;
  }, []);

  return statusOptions;
};
