import { memo, useCallback, useEffect } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import { deletePromoCode, searchPromoCodes } from '@features/promoCodes/actions';
import { selectPromoCodes } from '@features/promoCodes/selectors';
import { resetPromoCodes } from '@features/promoCodes/slice';
import useUnMount from '@customHooks/useUnMount';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './tableData';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';
import TableRow from './components/TableRow';

const PromoCodes = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', maxDiscount = '', oneTimeUse = '', startDate = '', endDate = '' } = params as IFiltersForm;
  const isSearchTerm = searchTerm || maxDiscount || oneTimeUse || startDate || endDate;

  const fetchData = useCallback(() => {
    const query = { searchTerm, maxDiscount, oneTimeUse, startDate, endDate };

    dispatch(searchPromoCodes(query));
  }, [searchTerm, dispatch, maxDiscount, oneTimeUse, startDate, endDate]);

  useUnMount(() => dispatch(resetPromoCodes()));
  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, maxDiscount, oneTimeUse, startDate, endDate],
  );

  const { data: promoCodes, isLoading } = useAppSelector(selectPromoCodes);
  const deleteAction = useCallback((id: string) => {
    const query = { searchTerm };

    dispatch(deletePromoCode(id)).unwrap().finally(() => dispatch(searchPromoCodes(query)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Promotional Codes"
        btnName="Add Promotional Code"
        path={PAGE_ROUTES.ADD_PROMO_CODE}
      />
      { (isSearchTerm || !!promoCodes?.length) && <SearchSection /> }
      {promoCodes?.length ? (
        <StyledTable headCells={headCells}>
          {promoCodes.map((item) => (
            <TableRow key={item.id} {...item} deleteAction={deleteAction} />
          ))}
        </StyledTable>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any promotional code'}
          isAdd={!isSearchTerm}
        />
      )}
    </>
  );
};

export default memo(PromoCodes);
