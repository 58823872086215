export enum DefaultInkInEstimator {
  INK_1 = '1 Color (Black ONLY) Front Side / Blank Back',
  INK_2 = '1 Color Both Sides (Black ONLY)',
  INK_3 = '2 Colors Front Side / Blank Back',
  INK_4 = '2 Colors Front Side / 1 Color Back',
  INK_5 = '2 Colors Both Sides',
  INK_6 = 'Full Color Front Side / Blank Back',
  INK_7 = 'Full Color Both Sides',
  INK_8 = 'Full Color Both Sides + Aqueous',
}

export enum PrintType {
  OFFSET_PRODUCTS = 'offset_products',
  GRAND_FORMAT_PRODUCTS = 'grand_format_products',
  PROMO_PRODUCTS = 'promo_products',
}
