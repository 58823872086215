import { memo, useState } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import { useAppDispatch } from '@features/app/hooks';
import TableCell from '@mui/material/TableCell';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@containers/common/Button';
import Stack from '@mui/material/Stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMailingServiceFee, getAllMailingServiceFees } from '@features/mailingServiceFees/actions';

import { AddDataSchema, IAddDataForm, defaultMailingServiceData } from './helpers';
import { fieldNames } from '../helpers';

const FirstRow = () => {
  const dispatch = useAppDispatch();
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: defaultMailingServiceData,
  });

  const { register, formState: { errors }, handleSubmit, setError, reset } = methods;

  const [isAdd, setIsAdd] = useState(false);
  const handleAddClick = () => {
    setIsAdd((prev) => !prev);
  };

  const handleCancel = () => {
    setIsAdd(false);
    reset();
  };

  const onSubmit = (data: IAddDataForm) => {
    dispatch(addMailingServiceFee(data)).unwrap().then(() => {
      dispatch(getAllMailingServiceFees());
    }).catch((e) => {
      if (e.message === 'Mailing fee with the provided quantity already exists!') {
        setError('quantity', { message: e.message });
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <StyledTableRow>
        {fieldNames.map(({ label, field }) => (
          <TableCell key={field}>
            <Input
              placeholder={label}
              width="120px"
              {...register(field as any)}
              errorMessage={(errors as any)?.[field]?.message}
              disabled={!isAdd}
            />
          </TableCell>
        ))}
        <TableCell colSpan={2}>
          {!isAdd ? (<Button onClick={handleAddClick}>Add new +</Button>) : (
            <Stack direction="row" gap="12px">
              <Button onClick={handleCancel} isOutlined>Cancel</Button>
              <Button type="submit" onClick={handleSubmit(onSubmit)}>Save</Button>
            </Stack>
          )}
        </TableCell>
      </StyledTableRow>
    </FormProvider>
  );
};

export default memo(FirstRow);
