export const headSliderCells = [
  {
    label: 'NAME',
  },
  {
    label: 'VISIBLE ON SITE',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];

export const headBannerCells = [
  {
    label: 'NAME',
  },
  {
    label: 'VISIBLE ON SITE',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
