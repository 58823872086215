import { memo, useCallback } from 'react';

import StyledTable from '@containers/common/Table';
import PAGE_ROUTES from '@routes/routingEnum';
import { StyledTableRow } from '@containers/common/Table/styled';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import EditCell from '@containers/common/Table/components/EditCell';
import { selectShippingZips } from '@features/shippingZip/selectors';
import { deleteShippingZip, getAllShippingZips } from '@features/shippingZip/actions';
import { resetShippingZips } from '@features/shippingZip/slice';
import TCell from '@containers/common/Table/components/TCell';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';

const ShippingZips = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();

  const { data: shippingZips, isLoading } = useAppSelector(selectShippingZips);
  const deleteAction = useCallback((id: string) => {
    dispatch(deleteShippingZip(id)).unwrap().finally(() => dispatch(getAllShippingZips()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    dispatch(getAllShippingZips());

    return () => dispatch(resetShippingZips());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Shipping Zips" btnName="Add Shipping Zip" path={PAGE_ROUTES.ADD_SHIPPING_ZIP} />

      {shippingZips?.length ? (
        <StyledTable headCells={headCells}>
          { shippingZips.map(({ id, name, zipCode }) => (
            <StyledTableRow key={id}>
              <EditCell title={name} path="EDIT_SHIPPING_ZIP" params={{ id }} />
              <TCell text={zipCode} />
              <DeleteBtn
                deleteAction={() => deleteAction(id)}
                questionText="Are you sure you want to delete this shipping zip ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text="You don’t have any shipping zips" isAdd />
      )}
    </>
  );
};

export default memo(ShippingZips);
