import { Dispatch, SetStateAction, useEffect } from 'react';

import { getOrderTasks } from '@features/orders/order/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectOrderTasks } from '@features/orders/order/selectors';
import PAGE_ROUTES from '@routes/routingEnum';
import Table from '@containers/common/Table';
import TCell from '@containers/common/Table/components/TCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import { formattedDate } from '@utils/helpers';

import { headCells } from './tableData';
import { TasksContainer, EditTaskIdLink } from './styled';
import Users from './Users';

const Tasks = () => {
  const { orderId, jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tasksList = useAppSelector(selectOrderTasks);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderTasks({ id: orderId }));

      return;
    }

    navigate(PAGE_ROUTES.OPEN_ORDERS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <TasksContainer>
      <Table
        title=""
        headCells={headCells}
      >
        {tasksList.map((item) => {
          return (
            <StyledTableRow
              key={item.id}
            >
              <TCell>
                <EditTaskIdLink to={`/orders/${orderId}/${jobId}/edit/task/${item.id}`}>
                  {item.name}
                </EditTaskIdLink>
              </TCell>
              <TCell>
                <Users users={item?.users} />
              </TCell>
              <TCell text={formattedDate(item.followUp)} />
              <TCell text={item.orderNumber} />
              <TCell text="" />
              <TCell text={`${item?.customer?.firstName || ""} ${item?.customer?.lastName || ''}`} />
              <TCell text={item.status} />
              <TCell text={item.creator.firstName} />
              <TCell text={item?.created && formattedDate(item.created)} />
              <TCell text={item?.completedDate && formattedDate(item.completedDate)} />
            </StyledTableRow>
          );
        })}
      </Table>
    </TasksContainer>
  );
};

export default Tasks;
