import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import TableCell from '@mui/material/TableCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { StyledBox } from './styled';
import { IIsSelectedShippingZip } from '../types';

const StaticShipping = () => {
  const { watch, setValue, formState: { isSubmitted } } = useFormContext();
  const { shippingZipsAll, shippingZips } = watch();
  const handleAllChange = () => {
    setValue('shippingZipsAll', !shippingZipsAll);

    const newShippingZips = shippingZips.map((item: IIsSelectedShippingZip) => (
      { ...item, isSelected: !shippingZipsAll }));

    setValue('shippingZips', newShippingZips, { shouldValidate: isSubmitted });
  };

  const handleSingleCheckboxChange = (id: string) => {
    const newShippingZipsIds = shippingZips.map((item: IIsSelectedShippingZip) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      }

      return item;
    });

    const isAllSelected = newShippingZipsIds?.every((item: IIsSelectedShippingZip) => item.isSelected);

    setValue('shippingZips', newShippingZipsIds, { shouldValidate: isSubmitted });
    setValue('shippingZipsAll', isAllSelected);
  };

  return (
    <StyledTableRow>
      <StyledTableCell>Static Shipping Facility:</StyledTableCell>
      <TableCell>
        <StyledBox>
          { !!shippingZips.length && (
          <FormControlLabel
            control={(
              <Checkbox
                disableRipple
                checked={watch('shippingZipsAll')}
                onChange={handleAllChange}
              />
)}
            label={<Typography variant="body3">All Facilities</Typography>}
          />
          )}
          {shippingZips?.map(({ id, name }: IIsSelectedShippingZip, idx: number) => {
            return (
              <FormControlLabel
                key={id}
                control={(
                  <Checkbox
                    disableRipple
                    checked={shippingZips[idx].isSelected}
                    onChange={() => handleSingleCheckboxChange(id)}
                  />
)}
                label={<Typography variant="body3">{name}</Typography>}
              />
            );
          })}
        </StyledBox>
      </TableCell>
    </StyledTableRow>
  );
};

export default memo(StaticShipping);
