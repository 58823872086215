import React, { Dispatch, SetStateAction } from "react";

import { switcherCollection } from "../helpers";
import {
  Line,
  MainLine,
  ButtonVia,
  ButtonsWrapper,
  SwitcherContainer,
} from './styled';

interface SwitcherProps {
  switcherStatus: number;
  children: React.ReactNode;
  setSwitcherStatus: Dispatch<SetStateAction<number>>;
}

const Switcher = ({
  children,
  switcherStatus,
  setSwitcherStatus,
}: SwitcherProps) => {
  return (
    <SwitcherContainer>
      <ButtonsWrapper>
        {
          Object.keys(switcherCollection).map((key) => {
            const number = +key as number;

            return (
              <ButtonVia
                key={key}
                onClick={() => setSwitcherStatus(+key)}
              >
                {switcherCollection[+key].title}
                <Line active={number === switcherStatus ? 1 : 0} />
              </ButtonVia>
            );
          })
        }
      </ButtonsWrapper>
      <MainLine />
      {children}
    </SwitcherContainer>
  );
};

export default Switcher;
