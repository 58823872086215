import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const JobContainer = styled(Box)(() => ({
}));

export const OrderNumber = styled(Box)(() => ({
}));

export const BottomButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const TopButtons = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  cursor: 'pointer',
}));

export const Navbar = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '17px',
}));

export const TopSection = styled(Box)(() => ({
  width: '100%',
  display: 'grid',
  gap: '30px',
  gridTemplateColumns: "max-content max-content 1fr max-content",
}));
