import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';
import { AttributeInk } from '@containers/Products/ProductCategories/Tabs/BasicInfo/components/InputsTable/types';

import { IAddAttributePayload, IAttribute } from './types';

const prefix = '/attributes';

export const getAllAttributes = createAsyncThunk<IAttribute[], void, { rejectValue: AxiosData}>(
  'attributes/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IAttribute[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const addAttribute = createAsyncThunk<void, IAddAttributePayload, { rejectValue: AxiosData}>(
  'attributes/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddAttributePayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAttributeById = createAsyncThunk<IAddAttributePayload, string, { rejectValue: AxiosData}>(
  'attributes/get-attribute',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IAddAttributePayload>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editAttribute = createAsyncThunk<void, IAddAttributePayload, { rejectValue: AxiosData}>(
  'attributes/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddAttributePayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteAttribute = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'attributes/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const reorderAttributes = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'attributes/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchAttributes = createAsyncThunk<IAttribute[], Filters, { rejectValue: AxiosData}>(
  'attributes/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<IAttribute[]>>(
        `${prefix}/search?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAdminAttributesInk = createAsyncThunk<AttributeInk[], void, { rejectValue: AxiosData}>(
  'attributes/ink',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<AttributeInk[]>>(`${prefix}/ink`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
