import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { editCustomer, getAllCustomers, getCustomerById, searchCustomers } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  total: 0,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, { payload }) => state.data = payload,
    resetCustomers: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(editCustomer.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editCustomer.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editCustomer.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCustomers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCustomerById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCustomerById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchCustomers.fulfilled, (state, { payload }) => {
      const { customers, total } = payload;

      state.data = customers;
      state.total = total;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchCustomers.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });
  },
});

export const { setCustomers, resetCustomers } = customersSlice.actions;

export default customersSlice.reducer;
