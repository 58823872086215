import { IProductTypes } from '@features/productTypes/types';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const seoFields: ValidFieldNames[] = [
  {
    label: 'Meta Tag Title',
    field: 'metaTagTitle',
    type: InputTypes.text,
  },
  {
    label: 'Meta Tag Description',
    field: 'metaTagDescription',
    type: InputTypes.text,
  },
];

export const allItemsHaveId = (products: IProductTypes[]): boolean => {
  return !products.some((product) => !product.id);
};
