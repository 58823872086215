import { Box } from "@mui/material";

import ErrorMessage from "../ErrorMessage";
// import { StyledBox } from "./styles";

export interface GlobalUpdatesMessageProps { msg: string; status: number | null }

const GlobalUpdatesMessage = ({ msg, status }: GlobalUpdatesMessageProps) => {
  if (status === null) {
    return null;
  }

  if (status !== null && ![200, 201].includes(status)) {
    return (
      <ErrorMessage message={msg} />
    );
  }

  return (
    <Box
      sx={{
        color: 'hsl(177.14deg 48.84% 42.16%)',
        fontWeight: 600,
        marginTop: '5px',
        textAlign: 'center',
      }}
    >
      {msg}
    </Box>
  );
};

export default GlobalUpdatesMessage;
