import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const Underlined = styled(Typography)(() => ({
  cursor: 'pointer',
  color: COLORS.blue.dark,
  textDecoration: 'underline',
}));

export const Wrapper = styled(Typography)(() => ({
  gap: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
