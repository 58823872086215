import { memo } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { useFormContext } from 'react-hook-form';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { ISelectOptions } from '@utils/globalTypes';
import { isNonNullExpression } from 'typescript';

import StyledTypography from '../StyledTypography';
import { StyledFormControl, StyledSelect } from './styled';
import { StyledInputBox, StyledInputLabel } from '../Input/styled';
import ErrorMessage from '../ErrorMessage';
import OptionsList from './OptionsList';

export interface ISelectProps extends SelectProps {
  errors?: any;
  label?: string;
  options: ISelectOptions[];
  width?: string;
  name: string;
  errorMessage?: string;
  marginBottom?: number;
  isMultiple?: boolean;
  grandDisable?: boolean;
  placeholderText?: string;
  orderDesignStyle?: boolean;
  onlyBorder?: boolean;
  withoutSelect?: boolean;
}

const Select = ({
  name,
  label,
  errors,
  options,
  width,
  errorMessage,
  marginBottom,
  isMultiple = false,
  grandDisable = false,
  placeholderText,
  orderDesignStyle,
  onlyBorder,
  withoutSelect,
  ...restProps
}: ISelectProps) => {
  const { watch, setValue, formState: { isSubmitted } } = useFormContext();

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;

    if (isMultiple && (value as Array<string>).includes('')) {
      setValue(name, [], { shouldValidate: isSubmitted });

      return;
    }

    if (name) {
      setValue(name, value, { shouldValidate: isSubmitted });
    }
  };

  const selectedOptions = watch(name);

  const renderSelectedValues = (selected: unknown) => {
    const placeholder = (
      <StyledTypography color="placeholderText" variant="body3">
        {placeholderText || 'Select...'}
      </StyledTypography>
    );

    if (isMultiple) {
      if (Array.isArray(selected) && selected.length > 0) {
        return (
          <Typography variant="body3">
            { selected.map((value) => {
              const option = options.find((o) => o.value === value);

              return option ? option.optionName : value;
            }).join(', ')}
          </Typography>
        );
      }

      return placeholder;
    }

    const option = options.find((o) => o.value === selected);

    return option ? <Typography variant="body3">{option.optionName}</Typography> : placeholder;
  };

  return (
    <StyledInputBox
      marginBottom={marginBottom}
      orderdesignstyle={orderDesignStyle ? 1 : 0}
    >
      { label && <StyledInputLabel shrink>{label}</StyledInputLabel>}
      <StyledFormControl width={width} error={!!errorMessage}>
        <StyledSelect
          id={name}
          labelId={name}
          value={watch(name)}
          onChange={handleSelectChange}
          {...restProps}
          displayEmpty
          multiple={isMultiple}
          renderValue={renderSelectedValues}
          disabled={grandDisable}
        >
          {
            !withoutSelect ? (
              <MenuItem value="">
                <Typography variant="body3">Select</Typography>
              </MenuItem>
            ) : null
          }

          {options.map(({ optionName, value }) => (
            <MenuItem key={value} value={value}>
              <Typography variant="body3">{optionName}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
      {!onlyBorder && errorMessage && <ErrorMessage message={errorMessage} />}
      {isMultiple && !!selectedOptions.length && (
        <OptionsList options={options} name={name} selectedOptions={selectedOptions} />
      )}
    </StyledInputBox>
  );
};

export default memo(Select);
