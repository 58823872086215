import * as yup from 'yup';
import { EMAIL_REGEXP, NO_SPACE_REGEXP, isNumberRegex } from '@utils/regexp';

export const PasswordSchema = yup
  .string()
  .required('Password is required')
  .matches(NO_SPACE_REGEXP, 'Password can’t include space')
  .min(8, 'Password must be at least 8 characters')
  .max(16, 'Password must be at most 16 characters');

export const EmailSchema = yup
  .string()
  .required('Email is required')
  .matches(EMAIL_REGEXP, 'Email must be a valid email')
  .max(255, 'The maximum length is 255 characters');

export const validateOptionalURL = yup.string().optional().test(
  'url-validation',
  'URL is invalid',
  (value: string | undefined) => {
    if (!value) {
      return true;
    }

    return yup.string().url().isValidSync(value);
  },
);

export const optionalPasswordValidation = () =>
  yup
    .string()
    .optional()
    .test('required', 'Password is required', (value: string | undefined) => {
      if (!value) {
        return true;
      }

      return !!value;
    })
    .test('noSpace', 'Password can’t include space', (value:
      string | undefined) => !value || NO_SPACE_REGEXP.test(value))
    .test('minLength', 'Password must be at least 8 characters', (value:
      string | undefined) => !value || value.length >= 8)
    .test('maxLength', 'Password must be at most 16 characters', (value:
      string | undefined) => !value || value.length <= 16)
    .test('customPasswordSchema', 'Invalid password format', (value: string | undefined) => {
      if (!value) {
        return true;
      }

      return PasswordSchema && PasswordSchema ? PasswordSchema.isValidSync(value) : true;
    });

export const optionalEmailValidation = () =>
  yup
    .string()
    .nullable()
    .optional()
    .test('required', 'Email is required', (value: string | undefined | null) => {
      if (value === null || !value) {
        return true;
      }

      return !!value;
    })
    .test('validEmail', 'Email must be a valid email', (value: string | undefined | null) =>
      value === null || !value || EMAIL_REGEXP.test(value))
    .test('maxLength', 'The maximum length is 255 characters', (value: string | undefined | null) =>
      value === null || !value || (value.length <= 255))
    .test('customEmailSchema', 'Invalid email format', (value: string | undefined | null) => {
      if (value === null || !value) {
        return true;
      }

      return EmailSchema ? EmailSchema.isValidSync(value) : true;
    });

export const optionalEmailValidationTwo = () =>
  yup
    .string()
    .optional()
    .test('required', 'Email is required', (value: string | undefined | null) => {
      if (value === null || !value) {
        return true;
      }

      return !!value;
    })
    .test('validEmail', 'Email must be a valid email', (value: string | undefined | null) =>
      value === null || !value || EMAIL_REGEXP.test(value))
    .test('maxLength', 'The maximum length is 255 characters', (value: string | undefined | null) =>
      value === null || !value || (value.length <= 255))
    .test('customEmailSchema', 'Invalid email format', (value: string | undefined | null) => {
      if (value === null || !value) {
        return true;
      }

      return EmailSchema ? EmailSchema.isValidSync(value) : true;
    });

export const valiadationOptionalPassword = yup.string().optional().test({
  test(value: string | undefined) {
    if (!value) {
      return true;
    }

    return yup
      .string()
      .required('Password is required')
      .matches(NO_SPACE_REGEXP, 'Password can’t include space')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be at most 16 characters')
      .isValidSync(value);
  },
});

export const createTextWidthValidation = (maxLength: number) => {
  return yup.string().nullable().optional()
    .test(
      'is-not-whitespace',
      'The text is only whitespace',
      (value?: string | null) => {
        return value === null || value === '' || (value?.trim().length ?? 0) > 0;
      },
    )
    .test(
      'max-length-check',
      `The maximum length is ${maxLength} characters`,
      (value?: string | null) => {
        return value === null || value === '' || (value?.trim().length ?? 0) <= maxLength;
      },
    );
};

export const createRequiredTextWidthValidation = (
  fieldName: string,
  maxLength: number,
  minLength?: number,
  requiredMessage?: string,
) => {
  let validator = yup.string()
    .required(requiredMessage || `${fieldName} is required`)
    .max(maxLength, `${fieldName} must be at most ${maxLength} characters`)
    .test(
      'is-not-whitespace',
      'The text is only whitespace',
      (value) => value?.trim().length > 0,
    );

  if (minLength !== undefined) {
    validator = validator.min(minLength, `${fieldName} must be at least ${minLength} characters`);
  }

  return validator;
};

export const createOptionalTextWidthValidation = (fieldName: string, maxLength: number, minLength?: number) => {
  let validator = yup
    .string()
    .max(maxLength, `${fieldName} must be at most ${maxLength} characters`)
    .test('is-only-whitespace', 'The text is only whitespace', (value) => {
      if (value) {
        return value.trim() !== '';
      }

      return true;
    });

  if (minLength !== undefined) {
    validator = validator
      // .min(minLength, `${fieldName} must be at least ${minLength} characters`)
      .test('min-length-if-value-exists', `${fieldName} must be at least ${minLength} characters`, (value) => {
        if (value && minLength !== undefined) {
          return value.length >= minLength;
        }

        return true;
      });
  }

  return validator;
};

export const numberValidation = yup
  .string()
  .optional()
  .test(
  'valid-number',
  'Number is invalid',
  (value: string | undefined) => {
    if (!value) {
      return true;
    }

    return yup.string().matches(
      isNumberRegex,
    )
      .isValidSync(value);
  },
);

export const positiveNumberSchema = yup
  .number()
  .typeError('Number is invalid')
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value;
  })
  .positive('Number is not positive');

export const integerSchema = yup
  .number()
  .typeError('Number is invalid')
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value;
  })
  .integer('Integer is invalid')
  .positive('Number is not positive');

export const integerOptionalSchema = yup
  .number()
  .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
  .typeError('Number is invalid')
  .integer('Integer is invalid')
  .positive('Number is not positive')
  .nullable()
  .optional();

export const numberOptionalSchema = yup
  .number()
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value;
  })
  .typeError('Number is invalid')
  .min(0, 'Number is negative')
  .nullable()
  .optional();

export const numberSchema = yup
  .number()
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value;
  })
  .typeError('Number is invalid')
  .min(0, 'Number is negative');

// Date validation schema
const maxYear = 2150;
const maxDate = new Date(maxYear, 11, 31);

export const dateSchema = (fieldName: string, isRequired: boolean) => {
  let schema = yup.date()
    .nullable()
    .max(maxDate, `${fieldName}  is after  ${maxYear + 1}`)
    .transform((value, originalValue) =>
      (originalValue === '' || originalValue === null ? null : value));

  if (isRequired) {
    schema = schema.required(`${fieldName} is required`);
  }

  return schema as yup.DateSchema<Date | undefined, Record<string, any>, undefined>;
};
