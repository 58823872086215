import { createRequiredTextWidthValidation } from '@utils/schemas';
import * as yup from 'yup';

export interface IAddDataForm {
  subCategoryId: string;
  productId: string;
  file: File | null;
}

export const defaultValues = {
  subCategoryId: '',
  productId: '',
  file: null,
};

export const AddDataSchema = yup.object().shape({
  subCategoryId: createRequiredTextWidthValidation('Product category', 255),
  productId: createRequiredTextWidthValidation('Product', 255),
  file: yup.mixed().required('File is required'),
});
