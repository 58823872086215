import { IBaseInputProps } from '@containers/common/Input';
import { AxiosResponse } from 'axios';

export enum InputTypes {
  filled = 'filled',
  text = 'text',
  textarea = 'textarea',
  checkbox = 'checkbox',
  select = 'select',
  image = 'image',
  colorPicker = 'colorPicker',
  calendar = 'calendar',
}

export type ValidFieldNames = {
  label: string;
  field:string;
  type: InputTypes;
  placeholder?: string;
  isRequired?: boolean;
  multiple?: boolean;
  afterText?: string;
  extensions?: string[];
  inputprops?: IBaseInputProps;
  selectprops?: any;
  textareaprops?: any;
};

export type AxiosData = AxiosResponse['data']
export enum REQUEST_STATUS {
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED'
}

export type ErrorType = {
  error: string;
  message: string;
  statusCode: number;
}

export interface IReordering {
    id: string;
    sort: number;
}
export interface IReorderPayload {
  orders: IReordering[];
}

export interface ISelectOptions {
  optionName: string;
  value: any;
}

export interface GlobalQueryString {
  [key: string]: string | undefined;
}
