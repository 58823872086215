import Attributes from './Attributes';
import BasicInfo from './BasicInfo/components/InputsTable';

export const tabsOptions = [
  {
    component: BasicInfo,
    label: 'Basic Info',
  },
  {
    component: Attributes,
    label: 'Attributes',
  },
];
