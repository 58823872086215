import { useContext } from 'react';

import Loader from '@containers/common/Loader';
import { Box, Checkbox } from '@mui/material';
import {
  UpdateShipmentPayload,
} from '@features/orders/order/types';
import EmptyState from '@containers/common/EmptyState';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum } from '@features/orders/order/enums';
import Pagination from '@containers/common/Table/components/Pagination';
import { ordersPaginationLimit } from '@utils/constants';

import { headCells } from '../../tableData';
import SetItemInList from './SetItemInList';
import { useSetsListLogic } from './useSetsListLogic';
import { StyledTable, StyleWrapper } from './styled';
import { OrderKindsContext } from '../..';

const OpenSetsList = ({ isSearchTerm }: { isSearchTerm: string }) => {
  const jobsKind = useContext(OrderKindsContext);
  const {
    data: setsList,
    total,
    isLoading,
    vendorsList,
    validMessageState,
    statusOptions,
    openJobsSelectedRows,
    handleAllCheckbox,
    executeWithDebounceSimpleChanges,
    executeWithDebounceShipmentChanges,
  } = useSetsListLogic();

  if (isLoading) {
    return <Loader />;
  }

  let missingOrdersMessage = 'You don’t have any Orders';

  if (jobsKind === OrderStatusesEnum.Canceled) {
    missingOrdersMessage = 'You don’t have any Cancelled Orders';
  } else if (jobsKind === OrderStatusesEnum.Complete) {
    missingOrdersMessage = 'You don’t have any Completed Orders';
  }

  return (
    <Box>
      {
        isSearchTerm && !setsList?.length ? (
          <EmptyState text={isSearchTerm ? 'No search results found' : missingOrdersMessage} />
        ) : setsList?.length
          ? (
            <StyleWrapper className="david-111">
              <StyledTable
                title=""
                headCells={headCells}
                checkboxVia={(
                  <Checkbox
                    name="all"
                    checked={setsList.length === openJobsSelectedRows.length}
                    onClick={handleAllCheckbox}
                  />
              )}
              >
                {setsList.map((set) => {
                  const {
                    setId,
                    status,
                    vendorId,
                    mailPo,
                    jobId,
                    vendorJob,
                  } = set;

                  const alreadyExistsCollection = {
                    status,
                    vendorId,
                    vendorJob,
                    mailingPO: mailPo,
                  };

                  const beginningDebounceForEachChange = (key: OrderListUpdateRequestFieldKeys, value: string) => {
                    executeWithDebounceSimpleChanges({ jobId, setId, key, value, alreadyExistsCollection });
                  };

                  const beginningDebounceForShipmentChanges = (args: UpdateShipmentPayload) => {
                    executeWithDebounceShipmentChanges(args);
                  };

                  const eachItemCollection = {
                    vendorsList,
                    statusOptions,
                    validMessageState,
                    beginningDebounceForEachChange,
                    beginningDebounceForShipmentChanges,
                    ...set,
                  };

                  return (
                    <SetItemInList
                      key={setId}
                      {...eachItemCollection}
                    />
                  );
                })}
              </StyledTable>
            </StyleWrapper>

          ) : <EmptyState text={missingOrdersMessage} />
      }
      { total > ordersPaginationLimit && (
        <Pagination
          total={total}
          ordersPaginationLimit={ordersPaginationLimit}
          path={
            jobsKind === OrderStatusesEnum.Complete
            ? 'COMPLETED_ORDERS'
            : jobsKind === OrderStatusesEnum.Canceled ? 'CANCELLED_ORDERS' : 'OPEN_ORDERS'
          }
        />
      )}
    </Box>
  );
};

export default OpenSetsList;
