import { useEffect, useState } from 'react';

import { PrevState } from '@utils/prevState';

interface Identifiable {
  id: string;
}

interface ToggleLogicProps<T extends Identifiable> {
  data: T[] | null;
  // Optional parameter to control whether toggles should open after a search
  isSearchTerm?: boolean;
}

export const useToggleLogic = <T extends Identifiable>({
  data,
  isSearchTerm = false,
}: ToggleLogicProps<T>) => {
  const [toggledItems, setToggledItems] = useState<Record<string, boolean>>({});

  // Restore state from localStorage on mount
  useEffect(() => {
    const savedState = PrevState.getByKey('toggledItems');

    if (savedState) {
      setToggledItems(savedState);
    }
  }, []);

  const handleUpdateToggle = (catId: string) => {
    setToggledItems((prev) => {
      const updatedState = { ...prev, [catId]: !prev[catId] };

      // Save the updated state to localStorage
      PrevState.set({ toggledItems: updatedState });

      return updatedState;
    });
  };

  const handleResetToggle = () => {
    const resetState = data?.reduce((acc, item) => {
      acc[item.id] = false; // Set all items to closed

      return acc;
    }, {} as Record<string, boolean>) || {};

    setToggledItems(resetState);
    // Save the reset state to localStorage
    PrevState.set({ toggledItems: resetState });
  };

  useEffect(() => {
    if (!data?.length) {
      return;
    }

    if (isSearchTerm) {
      // Open all items if there is a search term
      const collected = data.reduce((acc, item) => {
        acc[item.id] = true; // Open all items when search is active

        return acc;
      }, {} as Record<string, boolean>);

      setToggledItems(collected);
      // Save the state to localStorage
      PrevState.set({ toggledItems: collected });
    } else {
      // Reset state to all closed if no search term
      const savedState = PrevState.getByKey('toggledItems') || {};
      const allClosedState = data.reduce((acc, item) => {
        acc[item.id] = savedState[item.id] || false; // Restore state from localStorage or default to closed

        return acc;
      }, {} as Record<string, boolean>);

      setToggledItems(allClosedState);
      // Save the initial state to localStorage
      PrevState.set({ toggledItems: allClosedState });
    }
  }, [data, isSearchTerm]);

  return {
    toggledItems,
    handleResetToggle,
    handleUpdateToggle,
  };
};
