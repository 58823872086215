import { createRequiredTextWidthValidation, createTextWidthValidation, dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export const defaultValues = {
  name: '',
  status: '',
  notes: '',
  followUp: '',
  userIds: [],
};

export const Schema = {
  name: createRequiredTextWidthValidation('Name', 255),
  followUp: dateSchema('Follow up', true),
  notes: createTextWidthValidation(1000),
  userIds: yup.array().of(yup.string().required('User Ids is required'))
    .min(1, 'At least one user is required'),
};

export const EditDataSchema = yup.object().shape({
  ...Schema,
  status: createRequiredTextWidthValidation('Status', 255),
});

export const AddDataSchema = yup.object().shape({
  ...Schema,
});

export const statusOptions = [
  {
    value: 'open',
    optionName: 'Open',
  },
  {
    value: 'closed',
    optionName: 'Closed',
  },
];
