import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAppSelector } from '@features/app/hooks';
import { selectUsers } from '@features/users/selectors';
import Box from '@mui/material/Box';
import RowComponent from '@containers/common/Table/components/RowComponent';
import ErrorMessage from '@containers/common/ErrorMessage';
import StyledTypography from '@containers/common/StyledTypography';

import { addingIsSelectedFlag, filterSelectedUserIds } from './helpers';

const TaskFor = () => {
  const { data: users } = useAppSelector(selectUsers);

  const { watch, setValue, formState: { errors, isSubmitted } } = useFormContext();

  const userIds = watch('userIds');
  const usersCheckboxes = addingIsSelectedFlag(users, userIds);

  const handleChange = (id: string) => {
    const newUserIds = filterSelectedUserIds(id, usersCheckboxes);

    setValue('userIds', newUserIds, { shouldValidate: isSubmitted });
  };

  return (
    <RowComponent label="Task For" isRequired>
      <Box>
        {usersCheckboxes?.map(({ id, firstName }, idx) => (
          <FormControlLabel
            key={id}
            control={(
              <Checkbox
                disableRipple
                checked={usersCheckboxes[idx].isSelected}
                onChange={() => handleChange(id)}
              />
            )}
            label={(
              <StyledTypography variant="body3">
                {firstName}
              </StyledTypography>
          )}
          />
        ))}
      </Box>
      {errors?.userIds?.message && <ErrorMessage message={errors?.userIds?.message as string} />}
    </RowComponent>
  );
};

export default memo(TaskFor);
