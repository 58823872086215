import { memo } from 'react';

import EditCell from '@containers/common/Table/components/EditCell';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import { SubCategory } from '@features/subcategories/types';
import { deleteSubcategory } from '@features/subcategories/actions';
import { useAppDispatch } from '@features/app/hooks';
import TCell from '@containers/common/Table/components/TCell';
import { useConfirm } from 'material-ui-confirm';
import noticeDialog from '@containers/common/NoticeDialog';

import { printTypeName } from '../InputsTable/helpers';

interface ITableRow extends SubCategory {
  fetchData: () => void;
  idx: number;
}

const TableRow = ({ id, title, printType, visibleOnSite, fetchData, idx }: ITableRow) => {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const deleteAction = () => {
    dispatch(deleteSubcategory(id)).unwrap()
      .then(() => {
        fetchData();
      }).catch(async (error) => {
        if (typeof error?.message === 'string') {
          await confirm(noticeDialog({ message: error?.message || '' })).then(() => {}).catch(() => {});
        }
      });
  };

  return (
    <ReusableDragRow
      id={id}
      index={idx}
      gridTemplateColumns="auto 282px 138px 140px 70px"
    >
      {({ providedDraggable }) => (
        <>
          <EditCell title={title} path="EDIT_PRODUCT_CATEGORIES" params={{ id }} />
          <TCell width="282px" text={printTypeName(printType)} />
          <TCell width="138px" text={visibleOnSite ? 'Yes' : 'No'} />
          <DndBtn providedDraggable={providedDraggable} />
          <DeleteBtn
            deleteAction={deleteAction}
            warningText="All products will be deleted as well."
            questionText="Are you sure you want to delete this subcategory ?"
          />
        </>
      )}
    </ReusableDragRow>
  );
};

export default memo(TableRow);
