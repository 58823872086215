import { createRequiredTextWidthValidation, createTextWidthValidation, numberSchema } from '@utils/schemas';
import * as yup from 'yup';
import { GrandFormatOptions, IProductsPayload } from '@features/products/basicInfo/types';

import { GrandFormatOptionsSchema } from '../GrandFormatOptions/helpers';

export interface IAddDataForm {
  id?: string;
  name: string;
  description?: string;
  productSKU: string;
  subCategoryId: string;
  categoryId?: string;
  weight: number | string | null;
  visibleOnSite?: boolean;
  isDiscountable?: boolean;
  quarterhouseProductCode?: string;
  fouroverProdCode?: string;
  fouroverTurnaroundCode?: string;
  grandFormatOptions: GrandFormatOptions | null;
  productTypeId?: string | null;
  templateId?: string;
}

export const defaultValues = {
  name: '',
  description: '',
  productSKU: '',
  categoryId: '',
  subCategoryId: '',
  weight: null, // null,
  visibleOnSite: false,
  isDiscountable: false,
  quarterhouseProductCode: '',
  fouroverProdCode: '',
  fouroverTurnaroundCode: '',
  isGrandFormat: false,
  grandFormatOptions: null,
  productTypeId: '',
};

export const defaultGrandFormatValues = {
  widthFrom: null,
  widthTo: null,
  heightFrom: null,
  heightTo: null,
  maxHeight: null,
  maxWidth: null,
  grandFormatDiscounts: null,
};

export const AddDataSchema = yup.object().shape({
  templateId: yup.string().nullable().optional(),
  name: createRequiredTextWidthValidation('Product name', 255),
  productSKU: createRequiredTextWidthValidation('Product SKU', 255),
  subCategoryId: createRequiredTextWidthValidation('Subcategory', 255),
  categoryId: createRequiredTextWidthValidation('Category', 255),
  weight: numberSchema
    .nullable()
    .test({
      name: 'greaterThanZero',
      exclusive: true,
      message: 'Value must be greater than 0',
      test: (value) => {
        if (value) {
          return /^(0*[1-9]\d*(\.\d+)?|0+\.\d*[1-9]\d*)$/.test(value.toString());
        }

        return true;
      },
    }),
  grandFormatOptions: GrandFormatOptionsSchema,
  // hidden by task
  // metaTagDescription: createTextWidthValidation(250),
  // metaTagTitle: createTextWidthValidation(250),
  // metaTagKeywords: createTextWidthValidation(250),
  description: createTextWidthValidation(500),
  fouroverTurnaroundCode: createTextWidthValidation(250),
  quarterhouseProductCode: createTextWidthValidation(250),
  fouroverProdCode: createTextWidthValidation(250),
});

export const transformValuesToNumbers = (values: GrandFormatOptions) => {
  const { minWidth, maxWidth, minHeight, maxHeight, grandFormatDiscounts } = values;

  return {
    ...values,
    minWidth: minWidth ? +minWidth : null,
    maxWidth: maxWidth ? +maxWidth : null,
    minHeight: minHeight ? +minHeight : null,
    maxHeight: maxHeight ? +maxHeight : null,
    grandFormatDiscounts: grandFormatDiscounts?.map(({ quantity, discountPercent }) => ({
      quantity: quantity ? +quantity : null,
      discountPercent: discountPercent ? +discountPercent : null,
    })),
  };
};

export const formattingPayload = (data: IAddDataForm) => {
  const { grandFormatOptions, productTypeId } = data;
  const payload = {
    ...data,
    productTypeId: productTypeId || null,
  };

  if (grandFormatOptions) {
    const grandFormatOptionsData = transformValuesToNumbers(grandFormatOptions);

    const { minWidth, maxWidth, minHeight, maxHeight } = grandFormatOptionsData;

    if ((minHeight && maxHeight && minHeight > maxHeight) || (minWidth && maxWidth && minWidth > maxWidth)) {
      return null;
    }

    payload.grandFormatOptions = grandFormatOptionsData;
  }

  return payload;
};

export const formattingData = (data: IProductsPayload) => ({
  ...data,
  productTypeId: data.productTypeId || '',
});
