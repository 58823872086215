import { StyledLink } from "./styled";

interface LinkedStringProps {
  text: string;
  href: string;
  target?: string;
  width?: string;
}

const LinkedString = ({ href, text, ...restProps }: LinkedStringProps) => {
  return (
    <StyledLink to={href} {...restProps}>
      {text}
    </StyledLink>
  );
};

export default LinkedString;
