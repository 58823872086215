import { Wrapper } from './styled';

const Users = ({ users }: { users: Record<string, any>[] }) => {
  return (
    <Wrapper>
      {
          users.map((user: any, index) => {
            return (
              <div key={user.id}>
                {`${user?.firstName} ${user?.lastName}${(users.length - 1) === index ? '' : ','}`}
              </div>
            );
          })
        }
    </Wrapper>
  );
};

export default Users;
