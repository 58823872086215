import Typography from '@mui/material/Typography';
import { ConfirmOptions } from 'material-ui-confirm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { StyledWrapper, StyledTypography } from './styled';
import Button from '../Button';

interface ICreatedSuccessOptionsDialog {
  text: string;
  maxWidth?: string;
}

const createdSuccessOptionsDialog = ({ text, maxWidth = '308px' }: ICreatedSuccessOptionsDialog): ConfirmOptions => {
  return {
    title: '',
    content: (
      <StyledWrapper>
        <StyledTypography variant="subtitle2" align="center">
          <CheckCircleIcon />
          Success
        </StyledTypography>
        <Typography variant="subtitle2" align="center">
          {text}
        </Typography>
      </StyledWrapper>
    ),
    dialogProps: {
      maxWidth: 'xs',
      fullWidth: true,
      BackdropProps: {
        style: {
          backgroundColor: '#06152B40',
        },
      },
      PaperProps: {
        sx: {
          maxWidth,
          padding: '40px 32px 32px',
          margin: 0,
          borderRadius: '10px',

          '.MuiDialogContent-root': {
            padding: 0,
          },

          '.MuiDialogActions-root': {
            display: 'flex',
            padding: 0,
            marginTop: '20px',
            justifyContent: 'center',
            button: {
              width: '120px',
              padding: 0,
            },
          },
        },
      },
    },
    hideCancelButton: true,
    confirmationText: <Button isOutlined>Close</Button>,
  };
};

export default createdSuccessOptionsDialog;
