import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getVendorsThunk } from './actions';

const initialState: IState = {
  data: null,
  isLoading: true,
  actionLoading: false,
  errorMessage: null,
  loadMoreLoading: false,
  isHiddenLoadMoreButton: false,
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    resetVendors: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorsThunk.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(getVendorsThunk.fulfilled, (state, { payload }) => {
      state.actionLoading = false;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getVendorsThunk.rejected, (state) => {
      state.actionLoading = false;
    });
  },
});

export const { resetVendors } = vendorsSlice.actions;
export default vendorsSlice.reducer;
