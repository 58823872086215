import { dateSchema, positiveNumberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  searchTerm?: string;
  maxDiscount?: string;
  oneTimeUse?: string;
  startDate?: string;
  endDate?: string;
}

export const FiltersSchema = yup.object().shape({
  startDate: dateSchema('Start date is required', false),
  endDate: dateSchema('End date is required', false),
  maxDiscount: positiveNumberSchema.max(999999999, 'The maximum number of digits is 9').nullable(),
});
export const oneTimeUseOptions = [
  {
    optionName: 'Yes',
    value: 'true',
  }, {
    optionName: 'No',
    value: 'false',
  },
];
