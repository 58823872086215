import { dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  searchTerm?: string;
  limit?: string;
  offset?: string;
  startDate?: string;
  endDate?: string;
}

export const FiltersSchema = yup.object().shape({
  startDate: dateSchema('Start date is required', false),
  endDate: dateSchema('End date is required', false),
});
