export const headCells = [
  {
    label: 'PROMO CODE',
  },
  {
    label: 'DICSCOUNT TYPE',
  },
  {
    label: 'DICSCOUNT ON PRODUCT',
  },
  {
    label: 'X',
  },
  {
    label: 'Y',
  },
  {
    label: 'ACTIVE',
  },
  {
    label: 'MAX DISCOUNT',
  },
  {
    label: 'ONE TIME USE',
  },
  {
    label: 'DEAL OF DAY',
  },
  {
    label: 'ACTIONS',
  },
];
