import { IProductsAttribues } from '@features/products/productsAttributes/types';
import * as yup from 'yup';
import orderBy from 'lodash/orderBy';

export interface IAttribute {
  id: string;
  name: string;
  isSelected?: boolean;
  isDefault?: boolean;
}

export interface IAssignedAttributes {
  id: string;
  name: string;
  attributes: IAttribute[];
}
export interface IAddDataForm {
  attributesList: IAssignedAttributes[];
}

export const AddDataSchema = yup.object().shape({

});
export const formattingPayload = (data: IAddDataForm) => {
  const selectedAttributeIds = data.attributesList
    .map((category) =>
      category.attributes
        .filter((attribute) => attribute.isSelected)
        .map((attribute) => attribute.id))
    .flat();

  return { attributeIds: selectedAttributeIds };
};

export const addingIsSelectedFlag = (
  attributeCategories: IAssignedAttributes[] | null,
  attributeIds: IProductsAttribues['attributes'] | null,
  defaultAttributeIds: string[] | null,
) => {
  const dataWithAddedFlags = attributeCategories?.map((item) => ({
    ...item,
    attributes: item.attributes.map((attr) => {
      return {
        ...attr,
        isSelected: attributeIds?.includes(attr.id),
        isDefault: defaultAttributeIds?.includes(attr.id),
      };
    }),
  }));

  const sortedData = dataWithAddedFlags?.map((item) => {
    const sortedAttributes = orderBy(item.attributes, (att) => {
      return att.isDefault;
    }, ['desc']);

    return {
      ...item,
      attributes: sortedAttributes,
    };
  });

  return sortedData;
};
