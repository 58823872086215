import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';
import { Filters, constructQueryString } from '@utils/helpers';

import { IAddRequestPayload, IAddTaskPayload, ISearchTasksPayload, ITask } from './types';

const prefix = '/tasks';

export const getAllCustomersTasks = createAsyncThunk<ITask[], string, { rejectValue: AxiosData}>(
  'tasks/allCustomersTasks',
  async (customerId, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ITask[]>>(`${prefix}/customer/${customerId}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

// export const getAllTasks = createAsyncThunk<ITask[], void, { rejectValue: AxiosData}>(
//   'tasks/all',
//   async (_, thunkAPI) => {
//     try {
//       const { data: { data } } = await http.get<AxiosResponse<ITask[]>>(prefix);

//       return data;
//     } catch (error) {
//       const errorInfo = customErrorHandling(error);

//       return thunkAPI.rejectWithValue(errorInfo);
//     }
//   },
// );

export const getAllTasks = createAsyncThunk<ITask[], ISearchTasksPayload, { rejectValue: AxiosData}>(
  'tasks/all',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData as Filters);

      const { data: { data } } = await http.get<AxiosResponse<ITask[]>>(
        `${prefix}?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const addTask = createAsyncThunk<void, IAddTaskPayload, { rejectValue: AxiosData}>(
  'tasks/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddTaskPayload>(`${prefix}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getTaskById = createAsyncThunk<ITask, string, { rejectValue: AxiosData}>(
  'tasks/getTask',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ITask>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editTask = createAsyncThunk<void, IAddRequestPayload, { rejectValue: AxiosData}>(
  'tasks/edit',
  async ({ id, body }, thunkAPI) => {
    try {
      await http.put<IAddTaskPayload>(`${prefix}/${id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
