export const headCells = [
  {
    label: 'DATE',
  },
  {
    label: 'NAME',
  },
  {
    label: 'EMAIL',
  },
  {
    label: 'SUBJECT',
  },
  {
    label: 'MESSAGE',
  },
  {
    label: 'ACTONS',
  },
];
