import { SyntheticEvent, memo, useCallback, useEffect, useState } from 'react';

import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { StyledDivider, StyledTab } from '@containers/Products/Products/components/Tabs/styles';
import PageTitle from '@containers/common/PageTitle';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { GlobalQueryString } from '@utils/globalTypes';
import { getRequestsCount, searchRequests } from '@features/requests/actions';
import { selectRequests } from '@features/requests/selectors';
import EmptyState from '@containers/common/EmptyState';
import Loader from '@containers/common/Loader';
import Stack from '@mui/material/Stack';
import StyledTypography from '@containers/common/StyledTypography';
import { usePrevScroll } from "@utils/prevState";

import { StyledTabs } from '../Customers/EditCustomers/styles';
import { tabsOptions } from './helpers';
import SearchSection from './SearchSection';
import RequestsTable from './RequestsTable';
import { IFiltersForm } from './SearchSection/helpers';

const Requests = () => {
  usePrevScroll();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', tab = '', startDate = '', endDate = '' } = params as IFiltersForm;

  const { data: requests, isLoading, resellerCount, nonProfitCount, requestsCount } = useAppSelector(selectRequests);

  const [value, setValue] = useState((tab && +tab) ? +tab : 0);

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };

  const isSearchTerm = searchTerm || startDate || endDate;

  const fetchData = useCallback(() => {
    const query = { searchTerm, startDate, endDate } as IFiltersForm;

    if (value === 1) {
      query.pendingNonProfit = 'true';
    } else {
      query.pendingReseller = 'true';
    }

    dispatch(searchRequests(query));
    dispatch(getRequestsCount());
  }, [searchTerm, dispatch, startDate, endDate, value]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, value, startDate, endDate],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title={`Requests(${requestsCount})`}
        isShowBtn={false}
      />
      { (isSearchTerm || !!requests?.length) && <SearchSection /> }

      <StyledStack>
        <StyledDivider>
          <StyledTabs value={value} onChange={handleChange}>
            {
                tabsOptions.map(({ v, label }) => (
                  <StyledTab
                    key={v}
                    label={(
                      <Stack direction="row">
                        <StyledTypography
                          mr="2px"
                          color={v === value ? 'blue' : 'grey'}
                        >
                          {label}
                        </StyledTypography>
                        <StyledTypography color="red">
                          {`(${v === 1 ? nonProfitCount : resellerCount})`}
                        </StyledTypography>
                      </Stack>
                    )}
                    disableRipple
                  />
                ))
            }
          </StyledTabs>
        </StyledDivider>
      </StyledStack>
      {requests?.length ? (
        <RequestsTable />
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found'
            : 'You don’t have any requests'}
        />
      )}
    </>
  );
};

export default memo(Requests);
