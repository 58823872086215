import { memo } from 'react';

import useMount from '@customHooks/useMount';
import { getAllAttributeCategoriesProducts } from '@features/attributeCategories/actions';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import EmptyState from '@containers/common/EmptyState';
import Loader from '@containers/common/Loader';
import { useParams } from 'react-router-dom';
import { getAllAttributesBySubcategoryId } from '@features/subcategories/actions';
import { selectSubcategories } from '@features/subcategories/selectors';

import InputsTable from './components/InputsTable';

const Attributes = () => {
  const dispatch = useAppDispatch();
  const { attributesLoading } = useAppSelector(selectSubcategories);
  const { productAttributeCategories, isLoading } = useAppSelector(selectAttributeCategories);
  const { id = '' } = useParams();

  useMount(() => {
    dispatch(getAllAttributeCategoriesProducts()).unwrap().then((data) => {
      if (data.length) {
        dispatch(getAllAttributesBySubcategoryId(id));
      }
    });
  });

  if (isLoading || attributesLoading) {
    return <Loader />;
  }

  return (
    <>
      {productAttributeCategories?.length ? <InputsTable /> : (
        <EmptyState text="You don’t have any attribute categories" isAdd />
      )}
    </>
  );
};

export default memo(Attributes);
