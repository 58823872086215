import { memo } from 'react';

import LinkedString from '@containers/Orders/Order/components/common/LinkedString';
import { getCDNImagePath } from '@utils/helpers';
import { Box, Typography } from '@mui/material';
import { handleWithoutFormRequest } from '@containers/common/FileUploader';
import { useAppDispatch } from '@features/app/hooks';
import { useFormContext } from 'react-hook-form';
import deleteSetNestedItemsCross from '@assets/images/delete-set-nested-items-cross.svg';

import {
  UploadedArtworkProofWrapper,
  LinkedTextWrapper,
} from './styled';
import Statuses from '../Statuses';

const EachProof = ({
  text,
  proof,
  proofStatus,
  defaultKey,
  deleteKey,
}: any) => {
  const { watch, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const handleDeleteRequest = () => {
    const madeKey = `${defaultKey}.${deleteKey}`;

    setValue(madeKey, null);
    handleWithoutFormRequest({
      watch,
      dispatch,
      fieldPath: madeKey,
      updateLogicType: 'sets',
    });
  };

  return (
    <UploadedArtworkProofWrapper>
        <LinkedTextWrapper>
          <div>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography>{text}</Typography>
              {
                proof ? (
                    <LinkedString
                      width="210px"
                      target="_blank"
                      href={getCDNImagePath(proof)}
                      text={proof}
                    />
                )
                : `${' '} - `
              }
            </Box>
            {proof && (<Statuses status={proofStatus} />)}
          </div>
          {
            proof && (
               <img
                 src={deleteSetNestedItemsCross}
                 alt=""
                 onClick={() => {
                  handleDeleteRequest();
                }}
               />
            )
          }
        </LinkedTextWrapper>
    </UploadedArtworkProofWrapper>
  );
};

export default memo(EachProof);
