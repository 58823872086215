import { styled } from '@mui/material/styles';
import MuiInput from "@mui/material/Input";
import { Box } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const StyledLabel = styled(Box)(() => ({
  fontWright: 500,
  color: COLORS.black.light,
  display: 'flex',
  whiteSpace: "pre",
  alignItems: 'center',
  justifyContent: 'flex-start',

  span: {
    marginLeft: '5px',
    marginTop: '5px',
  },
}));

export const StyledInputWrapper = styled(MuiInput)<{redesign?:number}>(({ redesign }) => {
  const style = {
    border: `1px solid ${COLORS.grey[600]}`,
    borderRadius: '4px',
    width: '100%',
    height: '35px',

    ':before': {
      display: 'none',
    },
    ':after': {
      display: 'none',
    },
  };

  if (redesign) {
    return ({
      ...style,

      height: 'unset !important',

      '.MuiInputBase-input': {
        width: '100% !important',
        maxWidth: 'unset !important',
        minWidth: 'unset !important',
        padding: '3px 12px !important',
        fontSize: '12px !important',
        height: 'unset',
      },
    });
  }

  return ({
    ...style,

    input: {
      width: '100%',
      minWidth: 'auto',
      fontSize: '12px',
      padding: '10px 12px',
    },
  });
});

export const ErrorMessageWrapper = styled(Box)<{errorMessage: boolean}>(({ errorMessage }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.MuiInputBase-root': {
    border: errorMessage ? '1px solid red' : '1px solid #AEBECA',
  },
}));
