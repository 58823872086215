import { useEffect, useState } from "react";

import { Input } from "@containers/Orders/Order/components/common";
import Typography from '@mui/material/Typography';
import Select from '@containers/common/Select';
import { useFormContext } from "react-hook-form";
import { countryOptions } from "@containers/Customers/Customers/BillingInfo/InputsTable/optionsList";
import { gettingStateOptions } from "@containers/Customers/Customers/BillingInfo/InputsTable/helpers";

import { ShippingToContainer, InputsWrapper } from "./styled";

interface ShippingToProps {
  jobIndex: number;
  shipmentIndex: number;
}

const ShippingTo = ({ jobIndex, shipmentIndex }: ShippingToProps) => {
  const { watch, register, setValue, formState: { errors } } = useFormContext();

  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].shipTo`;

  const country = watch(`${defaultKey}.country`);
  const [prevCountry, setPrevCountry] = useState(country);

  const stateOptions = gettingStateOptions(country);

  const messagesObjJobs = errors?.jobs as Record<string, any>;

  let messageShipmentShipTo = {} as Record<string, any>;

  if (messagesObjJobs && messagesObjJobs[jobIndex]) {
    messageShipmentShipTo = messagesObjJobs[jobIndex]?.shipments?.[shipmentIndex]?.shipTo;
  }

  useEffect(() => {
    if (prevCountry !== country) {
      setValue(`${defaultKey}.state`, '');
      setPrevCountry(country);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <ShippingToContainer>
      <Typography
        variant="body3"
      >
        Shipping To:
      </Typography>
      <InputsWrapper
        sx={{
          marginTop: '8px',
        }}
      >
        <Input
          isRequired
          placeholder="Enter"
          label="Phone"
          {...register(`${defaultKey}.phone`)}
          errorMessage={messageShipmentShipTo?.phone?.message}
        />
        <Input
          isRequired
          placeholder="Enter"
          label="Full Name"
          {...register(`${defaultKey}.fullName`)}
          errorMessage={messageShipmentShipTo?.fullName?.message}
        />
        <Input
          placeholder="Enter"
          label="Company"
          {...register(`${defaultKey}.company`)}
          errorMessage={messageShipmentShipTo?.company?.message}
        />
        <Input
          isRequired
          placeholder="Enter"
          label="Address 1"
          {...register(`${defaultKey}.address1`)}
          errorMessage={messageShipmentShipTo?.address1?.message}
        />
        <Input
          placeholder="Enter"
          label="Address 2"
          {...register(`${defaultKey}.address2`)}
          errorMessage={messageShipmentShipTo?.address2?.message}
        />
        <label>
          Country
          <span>*</span>
        </label>
        <Select
          label=""
          options={countryOptions}
          name={`${defaultKey}.country`}
          value={watch(`${defaultKey}.country`)}
          errorMessage={messageShipmentShipTo?.country?.message}
        />
        <Input
          isRequired
          placeholder="Enter"
          label="City"
          {...register(`${defaultKey}.city`)}
          errorMessage={messageShipmentShipTo?.city?.message}
        />
        <label>
          State
          <span>*</span>
        </label>
        <Select
          label=""
          options={stateOptions}
          name={`${defaultKey}.state`}
          value={watch(`${defaultKey}.state`)}
          errorMessage={messageShipmentShipTo?.state?.message}
        />
        <Input
          isRequired
          placeholder="Enter"
          label="Zip Code"
          {...register(`${defaultKey}.zipCode`)}
          errorMessage={messageShipmentShipTo?.zipCode?.message}
        />
      </InputsWrapper>
    </ShippingToContainer>
  );
};

export default ShippingTo;
