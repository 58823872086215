import { createSlice } from "@reduxjs/toolkit";
import { LOAD_MORE_LIMIT } from "@containers/Orders/Sets/constants";
import { setOrdersIdsData } from "@features/auth/helpers";

import { IState, OpenOrdersRequestTypeEnum } from "./types";
import { getSetsThunk } from "./actions";

const initialState: IState = {
  data: [],
  total: 0,
  ids: [],
  isLoading: true,
  errorMessage: null,
  actionLoading: true,
  loadMoreLoading: false,
  activeActionsRows: [],
  openJobsSelectedRows: [],
  isHiddenLoadMoreButton: false,
  type: OpenOrdersRequestTypeEnum.simple,
};

const setsSlice = createSlice({
  name: "sets",
  initialState,
  reducers: {
    selectRow: (state, { payload }) => {
      if (state.openJobsSelectedRows.includes(payload)) {
        state.openJobsSelectedRows = [
          ...state.openJobsSelectedRows.filter((item) => item !== payload),
        ];
      } else {
        state.openJobsSelectedRows.push(payload);
      }
    },
    selectRowsArray: (state, { payload }) => {
      state.openJobsSelectedRows = payload;
    },
    setRequestType: (state, { payload }) => {
      state.type = payload;
    },
    updateRowData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSetsThunk.pending, (state) => {
      state.total = 0;
      if (state.type === OpenOrdersRequestTypeEnum.simple) {
        state.isLoading = true;

        return;
      }

      state.loadMoreLoading = true;
    });
    builder.addCase(getSetsThunk.fulfilled, (state, { payload }) => {
      const { sets, total } = payload;

      const ids = sets.map((set) => ({
        orderId: set.orderId,
        jobId: set.setId,
      }));

      state.total = total;
      state.ids = ids;
      setOrdersIdsData(ids);
      if (state.type === OpenOrdersRequestTypeEnum.simple) {
        state.data = sets;
        state.total = total;
        state.isLoading = false;
        state.isHiddenLoadMoreButton = false;
        if (sets.length < LOAD_MORE_LIMIT) {
          state.isHiddenLoadMoreButton = true;
        }

        return;
      }

      state.data = [...state.data, ...sets];
      state.loadMoreLoading = false;

      if (sets.length < LOAD_MORE_LIMIT) {
        state.isHiddenLoadMoreButton = true;
      }
    });
    builder.addCase(getSetsThunk.rejected, (state) => {
      state.data = [];
      state.total = 0;
      state.isLoading = false;
      state.loadMoreLoading = false;
    });
  },
});

export const { selectRow, selectRowsArray, setRequestType, updateRowData } = setsSlice.actions;

export default setsSlice.reducer;
