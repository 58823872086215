export const headCells = [
  {
    label: 'Template',
  },

  {
    label: 'Template Category',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
