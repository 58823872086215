import { memo } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { IEmailLog } from '@features/customEstimates/types';
import Stack from '@mui/material/Stack';
import { ContentBox, StyledBox, StyledContainer } from '@containers/common/TitlesWithBackButton/styles';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { StyledBackBox } from '@containers/common/TitlesWithBackButton/BackButton/styled';

import { getBackUrl } from './helpers';
import LogItem from './LogItem';
import { StyledDivider } from '../../EditComponent/InputsTable/styles';

interface ILogsContainer{
  logsData?: IEmailLog[];
}

const LogsContainer = ({ logsData }: ILogsContainer) => {
  const location = useLocation();
  const { pathname } = location;

  const path = getBackUrl(pathname);

  return (
    <Stack>
      <StyledContainer>
        <Stack>
          <StyledBox />
        </Stack>
        <ContentBox>
          <Stack direction="row" flexWrap="wrap" alignItems="center" mb="40px" gap="24px">
            <Link to={path}>
              <StyledBackBox>
                <ArrowBackIosIcon />
                <Typography variant="body3">
                  Back
                </Typography>
              </StyledBackBox>
            </Link>
            <Typography variant="h4">Email Log</Typography>
          </Stack>
          {logsData?.map((item, idx) => (
            <Stack mb="24px">
              <LogItem key={item.id} {...item} />
              {(idx !== logsData.length - 1) && <StyledDivider />}
            </Stack>
          ))}
        </ContentBox>
      </StyledContainer>
    </Stack>
  );
};

export default memo(LogsContainer);
