export const imgExtensions = ['.jpg', '.jpeg', '.png'];

export const getMimeType = (filename: string): string => {
  const extension = filename.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'webp':
      return 'image/webp';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream'; // Fallback type
  }
};

export const isValidFileExtension = (file: File, fileExtensions?: string[]) => {
  const acceptedExtensions = fileExtensions || imgExtensions;
  const extension = (file.name.match(/(\.\w+)$/) || [])[0]?.toLowerCase() || '';

  return acceptedExtensions.includes(extension);
};
