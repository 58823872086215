import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData, IReorderPayload } from "@utils/globalTypes";
import { AxiosResponse } from "axios";

import { CharityPayload, ICharity } from "./types";

const prefix = "/charity";

export const getAllCharities = createAsyncThunk<
	ICharity[],
	void,
	{ rejectValue: AxiosData }
>("charity/all", async (_, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<ICharity[]>>(prefix);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const addCharity = createAsyncThunk<
	void,
	CharityPayload,
	{ rejectValue: AxiosData }
>("charity/add", async (body, thunkAPI) => {
  try {
    await http.post<CharityPayload>(prefix, body);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getCharityById = createAsyncThunk<CharityPayload, string, { rejectValue: AxiosData}>(
  'charity/id',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<CharityPayload>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editCharity = createAsyncThunk<
	void,
	CharityPayload,
	{ rejectValue: AxiosData }
>("charity/edit", async (body, thunkAPI) => {
  try {
    await http.put<CharityPayload>(`${prefix}/${body.id}`, body);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const reorderCharities = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'charities/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteCharity = createAsyncThunk<
	void,
	string,
	{ rejectValue: AxiosData }
>("charity/delete", async (id, thunkAPI) => {
  try {
    await http.delete(`${prefix}/${id}`);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
