export const headCells = [
  {
    label: 'Section',
  },
  {
    label: 'VISIBLE in section',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
