import { memo, useState } from 'react';

import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import { getAllCategories } from '@features/categories/actions';
import { selectCategories } from '@features/categories/selectors';
import EmptyState from '@containers/common/EmptyState';
import { IProductsPayload } from '@features/products/basicInfo/types';
import { getProductById } from '@features/products/basicInfo/actions';
import { selectProducts } from '@features/products/basicInfo/selectors';

import InputsTable from '../..';
import { formattingData } from '../components/InputsTable/helpers';

const EditProduct = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: categoriesLoading, allData: categories } = useAppSelector(selectCategories);

  const { id } = useParams();
  const [productsData, setProductsData] = useState<IProductsPayload | null>(null);
  const { isLoading } = useAppSelector(selectProducts);

  const getProduct = () => {
    dispatch(getProductById(id as string))
      .unwrap()
      .then((data) => {
        const formattedData = formattingData(data);

        setProductsData(formattedData);
        dispatch(getAllCategories());
      })
      .catch(() => navigate(PAGE_ROUTES.PRODUCTS));
  };

  useMount(() => {
    getProduct();
  });

  if (isLoading || categoriesLoading) {
    return <Loader />;
  }

  return (
    <>
      {(categories?.length && productsData)
        ? (
          <InputsTable
            getProduct={getProduct}
            editData={productsData}
            grandFormatOptions={productsData?.grandFormatOptions}
          />
        ) : (
          <EmptyState text="You don’t have any categories" isAdd />
        )}
    </>
  );
};

export default memo(EditProduct);
