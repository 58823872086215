import { ChangeEvent, SyntheticEvent, memo, useRef } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '@containers/common/Icons/CalendarIcon';
import { useFormContext } from 'react-hook-form';
import { parseISO, isValid, format } from 'date-fns';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT, EXTRA_DATE_FORMAT } from '@utils/helpers';

import { StyledCalendarIcon } from './styled';
import Input from '../Input';
import { IStyledDatePicker } from './types';
import { adjustDateForTimeZone } from './helpers';

const StyledDatePicker = ({ name, width = '200px', dateLabel, ...props }: IStyledDatePicker) => {
  const { watch, setValue, register, formState: { isSubmitted } } = useFormContext();

  const watchedDate = watch(name);

  const datePickerRef = useRef<DatePicker>(null);
  const handleIconClick = () => {
    datePickerRef.current!.setFocus();
  };

  const handleDateChange = (date: Date | null, event: SyntheticEvent<any> | undefined) => {
    if (event?.type === 'click') {
      // Adjust for Armenia Standard Time (GMT+4)
      const adjustedDate = date && adjustDateForTimeZone(date);
      // Convert to UTC ISO String
      const utcDateString = adjustedDate?.toISOString() || '';

      setValue(name, utcDateString, { shouldValidate: isSubmitted });
    }
  };

  const handleDateChangeRaw = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const dateFormat = EXTRA_DATE_FORMAT;
    const parsedDate = parseISO(inputValue);

    if (isValid(parsedDate) && format(parsedDate, dateFormat) === inputValue) {
    // Adjust for Armenia Standard Time (GMT+4)
      const adjustedDate = adjustDateForTimeZone(parsedDate);

      // Convert to UTC ISO String
      const utcDateString = adjustedDate.toISOString();

      setValue(name, utcDateString, { shouldValidate: isSubmitted });
    } else {
      setValue(name, '', { shouldValidate: isSubmitted });
    }
  };

  return (
    <Stack direction="row" alignItems={dateLabel ? 'flex-end' : 'flex-start'}>
      <Stack gap="5px">
        {dateLabel && <Typography variant="body3">{dateLabel}</Typography>}
        <DatePicker
          ref={datePickerRef}
          selected={watchedDate ? new Date(watchedDate) : null}
          customInput={<Input {...register(name)} {...props} width={width} />}
          onChange={handleDateChange}
          onChangeRaw={handleDateChangeRaw}
        />
      </Stack>
      <StyledCalendarIcon onClick={handleIconClick}>
        <CalendarIcon />
      </StyledCalendarIcon>
    </Stack>
  );
};

export default memo(StyledDatePicker);
