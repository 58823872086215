import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';
import { constructQueryString, Filters } from '@utils/helpers';

import {
  IDeveloperTask,
  IDeveloperTaskPayload,
  IGetDeveloperTaskPayload,
  ISearchDeveloperTasksPayload,
} from './types';

const prefix = '/tasks/brainstorm';

export const getOpenDevelopersTasks = createAsyncThunk<
  IDeveloperTask[],
  ISearchDeveloperTasksPayload,
  { rejectValue: AxiosData}
>(
  'developers-tasks/all',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData as Filters);

      const { data: { data } } = await http.get<AxiosResponse<IDeveloperTask[]>>(
        `${prefix}/all?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getClosedDevelopersTasks = createAsyncThunk<
  IDeveloperTask[],
  ISearchDeveloperTasksPayload,
  { rejectValue: AxiosData}
>(
  'developers-tasks/closed',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData as Filters);

      const { data: { data } } = await http.get<AxiosResponse<IDeveloperTask[]>>(
        `${prefix}/all/closed?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getDeveloperTaskById = createAsyncThunk<
  IDeveloperTask,
  IGetDeveloperTaskPayload,
  { rejectValue: AxiosData}
>('developer-task/single', async ({ id }, thunkAPI) => {
  try {
    const { data: { data } } = await http.get<AxiosResponse<IDeveloperTask>>(
      `${prefix}/${id}`,
    );

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const addDeveloperTask = createAsyncThunk<void, IDeveloperTaskPayload, { rejectValue: AxiosData}>(
  'Developer-tasks/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IDeveloperTaskPayload>(`${prefix}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editDeveloperTask = createAsyncThunk<void, IDeveloperTaskPayload, { rejectValue: AxiosData}>(
  'Developer-tasks/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IDeveloperTaskPayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const reorderDevelopersTasks = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'bestSellers/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
