import { useState, memo, SyntheticEvent } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext } from 'react-hook-form';
import { getOptionsArray } from '@utils/helpers';
import { useAppSelector } from '@features/app/hooks';
import { selectProducts } from '@features/products/basicInfo/selectors';
import { ISelectOptions } from '@utils/globalTypes';
import OptionsList from '@containers/common/Select/OptionsList';
import Input from '@containers/common/Input';

import { defaultOptions } from './helpers';
import { StyledPaper } from './styled';

interface IAutoComplete {
  errorMessage?: string;
}

const AutoComplete = ({ errorMessage }: IAutoComplete) => {
  const { allData: products } = useAppSelector(selectProducts);
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const [inputValue, setInputValue] = useState<ISelectOptions>(defaultOptions);
  const selectedProducts = watch('products');

  const handleAddProduct = (event: SyntheticEvent<Element, Event>, newValue: ISelectOptions | null) => {
    if (newValue && !selectedProducts.includes(newValue.value)) {
      setValue('products', [...selectedProducts, newValue.value], { shouldValidate: isSubmitted });
    }

    setInputValue({ ...defaultOptions });
  };

  const productsList = getOptionsArray(products, 'name');

  return (
    <>
      <Autocomplete
        disablePortal
        options={productsList}
        value={inputValue}
        onChange={handleAddProduct}
        getOptionLabel={(option) => option.optionName}
        renderInput={(params) => (
          <Input
            {...params.InputProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            ref={params.InputProps.ref}
            placeholder="Search..."
            errorMessage={errorMessage}
          />
        )}
        PaperComponent={(props) => (
          <StyledPaper {...props} />
        )}
      />

      {!!selectedProducts.length && (
      <OptionsList options={productsList} name="products" selectedOptions={selectedProducts} />
      )}
    </>
  );
};

export default memo(AutoComplete);
