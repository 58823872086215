import { memo } from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { StyledMuiTable, StyledTableContainer, StyledTableRow } from './styled';
import { IStyledTable } from './types';

const StyledTable = ({ headCells, children, tableTitle, colSpan, btn = null, checkboxVia }: IStyledTable) => (
  <StyledTableContainer>
    <StyledMuiTable>
      <TableHead>
        <StyledTableRow>
          {
            tableTitle ? (
              <TableCell colSpan={colSpan}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body3" textTransform="uppercase">
                    {tableTitle}
                  </Typography>
                  {btn && btn}
                </Stack>
              </TableCell>
            )
              : (headCells ? headCells.map((props) => {
                const { paramKey, ...restProps } = props;

                const { label, checkbox } = restProps;

                return (
                  <TableCell key={label} {...restProps}>
                    <Typography textTransform="uppercase" variant="body3">
                      {checkbox ? checkboxVia : label}
                    </Typography>
                  </TableCell>
                );
              }) : null)
          }
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
    </StyledMuiTable>
  </StyledTableContainer>
);

export default memo(StyledTable);
