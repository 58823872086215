import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { COLORS } from "@customTypes/global/theme/colors";

export const SetsContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr",
}));

export const BoxInformation = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
}));

export const StyledBox = styled(Box)(() => ({
  borderTop: `none`,
  borderLeft: `1px solid ${COLORS.grey[500]}`,

  "&:first-of-type": {
    borderLeft: "none",
  },
}));

export const Title = styled(Typography)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  borderBottom: `1px solid ${COLORS.grey[500]}`,
  background: COLORS.blue.light,
}));

export const DownloadLink = styled("div")(() => ({
  color: "#1D73E3",
  fontFamily: "DM Sans",
  fontWeight: 400,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  textDecoration: "underline",
}));

export const SetNameWrapper = styled(Box)(() => ({
  padding: "7px 16px",
  fontSize: "12px",
  fontWeight: 700,
  borderBottom: `1px solid ${COLORS.grey[500]}`,
  background: COLORS.blue.light,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  p: {
    color: "#788B9A",
    fontFamily: "DM Sans",
    fontsize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "grid",
  gap: "8px",
  padding: "17px 16px",
  gridTemplateColumns: "1fr",
}));

export const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  gap: "10px",
  marginBottom: 9,
  justifyContent: "flex-start",
}));

export const StyledUnderlined = styled("span")(() => ({
  cursor: "pointer",
  color: COLORS.blue.dark,
  textDecoration: "underline",
}));

export const DeleteButton = styled("span")(() => ({
  cursor: "pointer",
}));

export const MissingMessage = styled(Typography)(() => ({
  textAlign: "center",
}));

export const Colored = styled("span")(({ status }: { status: string }) => ({
  color: status === "accepted" ? "green" : "red",
}));

export const SetAndStatusWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
}));

export const StatusWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '17px',
  alignItems: 'center',
  justifyContent: 'center',

  span: {
    fontWeight: 500,
  },
  '.MuiSelect-select': {
    width: '200px',
    padding: '6px 32px 6px 12px !important',
  },
}));
