export const headCells = [
  {
    label: "Vendor name",
  },
  {
    label: "Carrier",
  },
  {
    label: "Exclude from PO report",
  },
  {
    label: "ACTIONS",
  },
];
