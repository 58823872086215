export const headCells = [
  {
    label: 'NAME',
  },
  {
    label: 'CONTACT INFO',
  },
  {
    label: 'ADDRESS',
  },
  {
    label: 'SIGN UP DATE',
  },
];

export const exportCustomersCSV = (data: Blob) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = 'customers.csv';
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
