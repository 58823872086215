import { forwardRef } from "react";

import ErrorMessage from "@containers/common/ErrorMessage";

import {
  StyledLabel,
  StyledInputWrapper,
  ErrorMessageWrapper,
} from "./styled";

interface InputProps {
  label: string;
  placeholder: string;
  errorMessage?: string;
  isRequired?: boolean;
  newRedesignView?: boolean;
  onlyBorder?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  errorMessage,
  label,
  isRequired,
  onlyBorder,
  newRedesignView,
  ...props }, ref) => {
  return (
    <>
      {label && (
      <StyledLabel>
        {label}
        <span>{isRequired ? '*' : ''}</span>
      </StyledLabel>
      )}
      <ErrorMessageWrapper errorMessage={!!errorMessage}>
        <StyledInputWrapper redesign={newRedesignView ? 1 : 0} {...props} ref={ref} />
        {!onlyBorder && errorMessage && <ErrorMessage message={errorMessage} />}
      </ErrorMessageWrapper>
    </>
  );
});

export default Input;
