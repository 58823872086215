import { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { GlobalQueryString } from '@utils/globalTypes';
import { useDebounce } from '@features/hooks/useDebounce';
import { getOrderStatusesOptions } from '@utils/orderStatuses';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectSets } from '@features/orders/sets/selectors';
import { OpenOrdersRequestTypeEnum } from '@features/orders/sets/types';
import { HandleUpdateRequestProps, UpdateShipmentPayload } from '@features/orders/order/types';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum, ShipmentStatusesEnum } from '@features/orders/order/enums';
import { selectRowsArray, setRequestType, updateRowData } from '@features/orders/sets/slice';
import {
  getSetsThunk,
  updateOrderInfo,
  updateShipmentInfo,
} from '@features/orders/sets/actions';
import { getVendorsListThunk } from '@features/vendors/actions';
import { ordersPaginationLimit } from '@utils/constants';

import { OrderKindsContext } from '../..';
import { isItOpenJobs } from '../../helper';
import { IFiltersForm } from '../SearchSection/helpers';
import { SpecificStateOfFieldEnum } from '../../enum';

export interface ValidMessageState {
  rowId: string;
  message: string;
  where: OrderListUpdateRequestFieldKeys;
}

export interface IVendorOptions {optionName: string; value: string}

export const useSetsListLogic = () => {
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();

  const params = queryString.parse(search) as GlobalQueryString;
  const { limit = ordersPaginationLimit, offset = 0 } = params as IFiltersForm;

  const jobsKind = useContext(OrderKindsContext);
  const [vendorsList, setVendorsList] = useState<IVendorOptions[]>([] as IVendorOptions[]);

  const [validMessageState, setValidMessageState] = useState<ValidMessageState>({}as ValidMessageState);

  const {
    data,
    total,
    isLoading,
    loadMoreLoading,
    openJobsSelectedRows,
    isHiddenLoadMoreButton,
  } = useAppSelector(selectSets);

  const handleInitialRequest = async () => {
    try {
      await dispatch(setRequestType(OpenOrdersRequestTypeEnum.simple));
      await dispatch(getSetsThunk({
        ...params,
        limit,
        offset,
        status: !jobsKind ? params.status : jobsKind,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getVendors = async () => {
    try {
      const vendors = await dispatch(getVendorsListThunk()).unwrap();

      const options = vendors.map((vendor) => ({
        optionName: vendor.name,
        value: vendor.id,
      }));

      setVendorsList(options);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateOrder = async ({
    jobId,
    setId,
    key,
    value = '',
    alreadyExistsCollection,
  }: HandleUpdateRequestProps) => {
    if (OrderListUpdateRequestFieldKeys.vendorJob === key) {
      // move from here
      if (value.length > 255) {
        setValidMessageState({
          rowId: setId,
          message: 'The maximum length allowed is 255 characters',
          where: OrderListUpdateRequestFieldKeys.vendorJob,
        });

        return;
      }

      let statusObj = {};

      const isNotOpenJobs = isItOpenJobs(jobsKind);

      // if select status worked from search section '!params.status'
      if (!params.status && isNotOpenJobs) {
        statusObj = { status: jobsKind };
      }

      setValidMessageState(statusObj as ValidMessageState);
    }

    // @ts-ignore
    alreadyExistsCollection[key] = value;

    try {
      const newUpdatedData = data.map((item) => {
        if (
          key === 'vendorId' &&
          value === 'Select' &&
          item.setId === setId
        ) {
          return { ...item, [key]: null };
        }

        // for update uses 'mailingPO' but get by mailPo
        if (key === 'mailingPO' && item.setId === setId) {
          return { ...item, mailPo: value };
        }

        if (item.setId === setId) {
          return { ...item, [key]: value };
        }

        return item;
      });

      if (value === 'Select' && key === OrderListUpdateRequestFieldKeys.vendorId) {
        alreadyExistsCollection.vendorId = null;
      }

      if (alreadyExistsCollection.vendorId === SpecificStateOfFieldEnum.isEditable) {
        alreadyExistsCollection.vendorId = null;
      }

      if (alreadyExistsCollection.mailingPO === SpecificStateOfFieldEnum.isEditable) {
        alreadyExistsCollection.mailingPO = null;
      }

      await dispatch(updateRowData(newUpdatedData));
      await dispatch(updateOrderInfo({ id: jobId, payload: alreadyExistsCollection })).unwrap();

      if (key === 'vendorId' && typeof value === 'string') {
        handleInitialRequest();
      } else if (key === 'status' && typeof value === 'string') {
        if (pathname !== PAGE_ROUTES.OPEN_ORDERS) {
          handleInitialRequest();

          return;
        }

        // is PAGE_ROUTES.OPEN_ORDERS route
        const fixedStatuses = [
          OrderStatusesEnum.Canceled,
          OrderStatusesEnum.Complete,
        ];

        if (fixedStatuses.includes(value as OrderStatusesEnum)) {
          handleInitialRequest();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    }
  };

  const handleUpdateOrderShipments = async (payload: UpdateShipmentPayload) => {
    const trackingCarrier = payload?.trackingCarrier === "isEditable"
    ? null
    : payload?.trackingCarrier?.toUpperCase() as ShipmentStatusesEnum;

    try {
      // in one endpoint backend require uppercase fedex and give with lowercase in another one
      const updatedPayload = {
        ...payload,
        trackingCarrier,
      };

      dispatch(updateShipmentInfo(updatedPayload)).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleAllCheckbox = () => {
    if (openJobsSelectedRows.length === data.length) {
      dispatch(selectRowsArray([]));

      return;
    }

    const ids = data.map((item) => item.setId);

    dispatch(selectRowsArray(ids));
  };

  const statusOptions = getOrderStatusesOptions();

  const executeWithDebounceSimpleChanges = useDebounce(handleUpdateOrder, 300);
  const executeWithDebounceShipmentChanges = useDebounce(handleUpdateOrderShipments, 300);

  useEffect(() => {
    handleInitialRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, jobsKind, limit, offset]);

  useEffect(() => {
    getVendors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    total,
    isLoading,
    vendorsList,
    statusOptions,
    loadMoreLoading,
    validMessageState,
    openJobsSelectedRows,
    isHiddenLoadMoreButton,
    handleAllCheckbox,
    executeWithDebounceSimpleChanges,
    executeWithDebounceShipmentChanges,
  };
};
