import { memo } from 'react';

import { useAppSelector } from '@features/app/hooks';
import { useParams } from 'react-router-dom';
import Loader from '@containers/common/Loader';
import { selectCharities } from '@features/giveBack/selectors';

import InputsTable from '../components/InputsTable';

const EditCharity = () => {
  const { id } = useParams();
  const { data, isLoading } = useAppSelector(selectCharities);

  const selectedCharitiesData = data?.find((charity) => charity.id === id);

  if (isLoading) {
    return <Loader />;
  }

  return (

    <InputsTable charitiesData={selectedCharitiesData} />
  );
};

export default memo(EditCharity);
