import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IContactFormMessages } from './types';

const prefix = '/contact-form';

export const getContactFormMessages = createAsyncThunk<IContactFormMessages[], void, { rejectValue: AxiosData}>(
  'contactFormMessages/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IContactFormMessages[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const deleteContactFormMessages = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'contactFormMessages/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
