import { memo, useEffect } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import EditCell from '@containers/common/Table/components/EditCell';
import { useLocation } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import { resetEmailTemplates } from '@features/emailTemplate/slice';
import { deleteEmailTemplate, searchEmailTemplates } from '@features/emailTemplate/actions';
import { selectEmailTemplates } from '@features/emailTemplate/selectors';
import { StyledTableRow } from '@containers/common/Table/styled';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const EmailTemplates = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { data: emailTemplates, isLoading } = useAppSelector(selectEmailTemplates);

  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '' } = params as IFiltersForm;
  const query = { searchTerm };

  const fetchData = () => {
    dispatch(searchEmailTemplates(query));
  };

  useUnMount(() => dispatch(resetEmailTemplates()));

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm],
  );

  const deleteAction = (id: string) => {
    dispatch(deleteEmailTemplate(id)).unwrap().then(() => {
      fetchData();
    }).catch(() => {});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Email Templates" btnName="Add Email Template" path={PAGE_ROUTES.ADD_EMAIL_TEMPLATE} />
      { (searchTerm || !!emailTemplates?.length) && <SearchSection /> }
      {emailTemplates?.length ? (
        <StyledTable headCells={headCells}>
          {emailTemplates.map(({ title, id }) => (
            <StyledTableRow key={id}>
              <EditCell title={title} path="EDIT_EMAIL_TEMPLATE" params={{ id }} />
              <DeleteBtn
                deleteAction={() => deleteAction(id)}
                questionText="Are you sure you want to delete this email template ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState
          text={searchTerm ? 'No search results found' : 'You don’t have any email templates'}
          isAdd={!searchTerm}
        />
      )}
    </>
  );
};

export default memo(EmailTemplates);
