import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 'calc(100% + 56px)',
  '.MuiAutocomplete-option': {
    fontSize: '12px',
  },

  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));
