import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '280px',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));
