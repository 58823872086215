import useMount from '@customHooks/useMount';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { getAllSubcategoriesProducts } from '@features/subcategories/actions';
import { resetSubcategories } from '@features/subcategories/slice';
import { selectSubcategories } from '@features/subcategories/selectors';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';

import InputsTable from './Inputs';

const ImportProductPricing = () => {
  const dispatch = useAppDispatch();
  const { dataHavingProducts, isLoading } = useAppSelector(selectSubcategories);

  useMount(() => {
    dispatch(getAllSubcategoriesProducts());

    return () => dispatch(resetSubcategories());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (dataHavingProducts?.length) ? (
    <InputsTable />
  ) : <EmptyState text="You don’t have any product categories or products" isAdd />;
};

export default ImportProductPricing;
