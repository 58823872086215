import { useEffect, useState } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import useResponsive from '@customHooks/useResponsive';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectSidebarCounts } from '@features/sidebarCounts/selectors';
import Loader from '@containers/common/Loader';
import { getEstimatesCount, getRequestsCount } from '@features/sidebarCounts/actions';

import Sidebar from './Sidebar';
import { StyledMain, StyledRoot } from './styled';
import HeaderSection from './Header';

const Layout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const isMobile = useResponsive('down', 'lg');
  const { isLoading } = useAppSelector(selectSidebarCounts);

  useEffect(() => {
    // show live counts data in sidebar
    dispatch(getRequestsCount());
    dispatch(getEstimatesCount());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <StyledRoot>
      {isMobile && <HeaderSection onOpenNav={() => setOpen(true)} />}
      <Sidebar open={!isMobile || open} onCloseNav={() => setOpen(false)} />
      <StyledMain>
        <Outlet />
      </StyledMain>
    </StyledRoot>
  );
};

export default Layout;
