import { createSlice } from '@reduxjs/toolkit';

import { getAllShippingMarkupRate, updateShippingRate } from './actions';
import { IState } from './types';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: undefined,
  errorMessage: null,
};

const shippingMarkupRateSlice = createSlice({
  name: 'shippingMarkupRate',
  initialState,
  reducers: {
    setShippingMarkupRate: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllShippingMarkupRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllShippingMarkupRate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getAllShippingMarkupRate.rejected, (state) => {
      state.isLoading = false;
      state.data = [];
    });
    builder.addCase(updateShippingRate.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(updateShippingRate.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(updateShippingRate.rejected, (state) => {
      state.actionLoading = false;
    });
  },
});

export const { setShippingMarkupRate } = shippingMarkupRateSlice.actions;

export default shippingMarkupRateSlice.reducer;
