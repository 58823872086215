import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import PAGE_ROUTES from '@routes/routingEnum';
import { getPromoCodeById } from '@features/promoCodes/actions';
import { selectPromoCodes } from '@features/promoCodes/selectors';

import InputsTable from '../components/InputsTable';
import { formattedData } from './helpers';
import { IAddDataForm } from '../components/InputsTable/types';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [editData, setEditData] = useState<IAddDataForm | null>(null);
  const { isLoading } = useAppSelector(selectPromoCodes);

  useMount(() => {
    dispatch(getPromoCodeById(id as string))
      .unwrap()
      .then((data) => {
        const newData = formattedData(data);

        setEditData(newData);
      }).catch(() => navigate(PAGE_ROUTES.PROMO_CODES));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
