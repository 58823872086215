import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { gettingStateOptions } from "@containers/Customers/Customers/BillingInfo/InputsTable/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalUpdatesMessageProps } from "@containers/common/GlobalUpdatesMessage";
import { IShipment } from "@features/orders/order/types";
import { updateOrderDropToInfo, updateOrderShipToInfo } from "@features/orders/sets/actions";
import { useAppDispatch } from "@features/app/hooks";
import { getShippingDataByOrderDetails } from "@features/orders/order/actions";
import { useParams } from "react-router-dom";
import { updateDropShipOrShipTo } from "@features/orders/order/slice";
import { SUCCESS_MESSAGE } from "@utils/messages";

import { requiredIfItIsNotMailingService } from "../../../../../utils/yupValidation";
import { dynamicAccess } from "../../../../../utils/helper";
import { IFormInterface } from "./types";

export const useShipToDropShipLogic = ({ shippingLink, type }: any) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams();
  const [shipping, setShipping] = useState<IShipment | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const methods = useForm<IFormInterface>({
    resolver: yupResolver(requiredIfItIsNotMailingService as any),
  });

  const { handleSubmit, register, formState: { errors }, watch, reset } = methods;
  const stateOptions = gettingStateOptions(watch('country'));

  const onSubmit = async (data: IFormInterface) => {
    if (!shipping) {
      return;
    }

    try {
      setActionLoading(true);
      if (type === 'SHIPPING') {
        await dispatch(updateOrderShipToInfo({ ...data, id: shipping.id }));
      } else {
        await dispatch(updateOrderDropToInfo({ ...data, id: shipping.id }));
      }

      dispatch(updateDropShipOrShipTo({ data, link: `${shippingLink}` }));
      setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });
      setActionLoading(false);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const requestToData = async () => {
    if (!orderId) {
      return;
    }

    setInitialLoading(true);

    try {
      const response = await dispatch(getShippingDataByOrderDetails({ id: orderId })).unwrap();

      const [jobIndex, shippingIndex] = shippingLink.split('-');
      const result: IShipment = dynamicAccess(response, `jobs[${jobIndex}].shipments[${shippingIndex}]`);

      reset(type === "DROP" ? { ...result.dropShip } : { ...result.shipTo });
      setShipping(result);
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestToData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    watch,
    errors,
    register,
    stateOptions,
    initialLoading,
    actionLoading,
    isSuccessMsg,
    methods,
    onSubmit,
    handleSubmit,
  };
};
