import { dateSchema, createRequiredTextWidthValidation, integerSchema } from '@utils/schemas';
import * as yup from 'yup';
import { NO_SPACE_REGEXP } from '@utils/regexp';
import { ISelectOptions } from '@utils/globalTypes';

import { DiscountTypesEnum } from '../TableRow/helpers';

export const defaultValues = {
  promoCode: '',
  discount: null,
  discountType: '',
  orderAmount: null,
  maxDiscount: null,
  startDate: '',
  endDate: '',
  oneTimeUse: false,
  dealOfTheDay: false,
  products: [],
};

export const AddDataSchema = yup.object().shape({
  promoCode: createRequiredTextWidthValidation('Promotional code', 255)
    .matches(NO_SPACE_REGEXP, 'Promo code can’t include space'),
  discount: integerSchema
    .required('Discount is required')
    .when('discountType', (discountType: any, schema: yup.NumberSchema) => (
      discountType[0] === DiscountTypesEnum.PERCENT
        ? schema.max(100, 'Discount must be 100% or less for percentage type')
        : schema.max(999999999, 'The maximum number of digits is 9'))),

  maxDiscount: integerSchema
    .min(0, 'Max Discount must not be less than 0')
    .max(999999999, 'The maximum number of digits is 9')
    .optional()
    .nullable(),
  discountType: createRequiredTextWidthValidation('Discount type', 255),
  orderAmount: integerSchema.required('Order amount is required')
    .max(999999999, 'The maximum number of digits is 9'),
  startDate: dateSchema('Start date', true),
  endDate: dateSchema('End date', true),
  products: yup.array().of(yup.string().required()).required(),
});

export const discountTypes = [
  {
    optionName: 'Discount in %',
    value: DiscountTypesEnum.PERCENT,
  },
  {
    optionName: 'Discount in $',
    value: DiscountTypesEnum.DOLLAR,
  },
];

export const deletedProductsNames = (message: string, productsList:ISelectOptions[], products: string[]) => {
  const uuidString = message;
  const uuids = uuidString?.split(',');

  const itemNames = uuids.map((uuid: string) => {
    const item = productsList.find((option) => option.value === uuid);

    return item ? item.optionName : null;
  })
    .filter((name) => name !== null).join(', ');

  const isSingle = products.length === 1;

  const msg = isSingle ? 'The product you selected is not available'
    : `The following products are not available: ${itemNames}`;

  return msg;
};
