import { MouseEvent, memo } from 'react';

import StyledTypography from '@containers/common/StyledTypography';
import EllipsisText from '@containers/common/EllipsisText';
import { getCDNImagePath } from '@utils/helpers';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import FileIcon from '@containers/common/Icons/FileIcon';

import { StyledImgContainer, StyledTitleBox, StyledUploadContainer } from '../../styles';

interface IFileDisplay {
  fileData?: File;
  errorMessage?: string;
  handleDeleteImg: (e: MouseEvent<SVGSVGElement>) => void;
  path: string;
  width?: string;
  isMultiple?: boolean;
  isFile?: boolean;
}

const FileDisplay = ({
  handleDeleteImg, isMultiple, fileData, errorMessage, path, width = '100%', isFile,
}: IFileDisplay) => (
  <Stack gap="8px">
    <StyledUploadContainer error={!!errorMessage} width={width} isMultiple={isMultiple}>
      <StyledImgContainer isMultiple={isMultiple} isFile={isFile} alignItems="center" justifyContent="center">
        {!isFile
          ? (
            <img
              src={getCDNImagePath(path)}
              alt=""
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(getCDNImagePath(path), '_blank')}
            />
          )
          : <FileIcon />}
      </StyledImgContainer>
      <StyledTitleBox flexWrap="wrap">
        { fileData && (
          <EllipsisText variant="body3" maxWidth="59px" line={1}>
            {fileData?.name}
          </EllipsisText>
        )}
        {fileData && (
          <StyledTypography variant="body3" color="grey" minWidth="65px">
            {`${(fileData.size / 1024).toFixed(2)} KB`}
          </StyledTypography>
        )}
      </StyledTitleBox>

      <CloseIcon
        fontSize="inherit"
        color="inherit"
        cursor="pointer"
        onClick={handleDeleteImg}
      />
    </StyledUploadContainer>
  </Stack>
);

export default memo(FileDisplay);
