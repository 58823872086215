import { memo } from 'react';

import TableCell from '@mui/material/TableCell';

import DndBtn, { IDndBtn } from '../DndBtn';

interface IDndCellProps extends IDndBtn {
  width?: string;
  sx?: Record<string, any>;
}

const DndCell = ({ width = '140px', ...props }: IDndCellProps) => {
  return (
    <TableCell width={width}>
      <DndBtn {...props} />
    </TableCell>
  );
};

export default memo(DndCell);
