import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  addCharity,
  editCharity, getAllCharities,
  getCharityById,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
};

const charitiesSlice = createSlice({
  name: 'charities',
  initialState,
  reducers: {
    setCharities: (state, { payload }) => {
      state.data = payload;
    },
    resetCharities: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addCharity.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addCharity.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addCharity.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editCharity.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editCharity.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editCharity.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllCharities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCharities.fulfilled, (state, { payload }) => {
      const bySortKey = payload.sort((a, b) => a.sort - b.sort);

      state.data = bySortKey;
      state.isLoading = false;
    });
    builder.addCase(getAllCharities.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCharityById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCharityById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCharityById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setCharities, resetCharities } = charitiesSlice.actions;
export default charitiesSlice.reducer;
