import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled(Box)(() => ({

}));

export const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '24px',

  svg: {
    fill: 'green',
  },
}));
