import { memo } from 'react';

import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import PAGE_ROUTES from '@routes/routingEnum';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import StyledTable from '@containers/common/Table';
import PageTitle from '@containers/common/PageTitle';
import { DropResult } from '@hello-pangea/dnd';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import { deleteBestSeller, getAllBestSellers, reorderBestSellers } from '@features/bestSellers/actions';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectBestSellers } from '@features/bestSellers/selectors';
import EmptyState from '@containers/common/EmptyState';
import { getAllSubcategories } from '@features/subcategories/actions';
import { selectSubcategories } from '@features/subcategories/selectors';
import EditCell from '@containers/common/Table/components/EditCell';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { setBestSellers } from '@features/bestSellers/slice';
import TCell from '@containers/common/Table/components/TCell';

import { headCells } from './helpers';

const BestSeller = () => {
  const dispatch = useAppDispatch();
  const { isLoading, data: bestSellers } = useAppSelector(selectBestSellers);
  const { isLoading: subcategoriesLoading, allData: subcategories } = useAppSelector(selectSubcategories);
  const deleteAction = (id: string) => {
    dispatch(deleteBestSeller(id)).unwrap().then(() => {
      dispatch(getAllBestSellers());
    }).catch(() => {});
  };

  useMount(() => {
    dispatch(getAllSubcategories());
    dispatch(getAllBestSellers());
  });

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, bestSellers);

    dispatch(reorderBestSellers(sortedData)).unwrap().then(() => {
      dispatch(setBestSellers(items));
    }).catch(() => dispatch(getAllBestSellers()));
  };

  if (isLoading || subcategoriesLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Best Sellers"
        btnName="Add Section"
        path={PAGE_ROUTES.ADD_BEST_SELLER}
        isShowBtn={!!subcategories?.length}
      />
      {(bestSellers.length && subcategories?.length) ? (
        <DndContainer reordingData={reordingData}>
          <StyledTable headCells={headCells}>
            {bestSellers.map(({ title, displayOnSite, id }, index) => (
              <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 173px  140px 70px">
                {({ providedDraggable }) => (
                  <>
                    <EditCell title={title} path="EDIT_BEST_SELLER" params={{ id }} />
                    <TCell width="173px" text={displayOnSite ? 'Yes' : 'No'} />
                    <DndBtn providedDraggable={providedDraggable} />
                    <DeleteBtn
                      deleteAction={() => deleteAction(id)}
                      questionText="Are you sure you want to delete this banner ?"
                    />
                  </>
                )}
              </ReusableDragRow>
            ))}
          </StyledTable>
        </DndContainer>
      ) : (
        <EmptyState
          text={subcategories?.length ? 'You don’t have any bestseller' : 'You don’t have any subcategory'}
          isAdd
        />
      )}
    </>
  );
};

export default memo(BestSeller);
