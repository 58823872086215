import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  addTemplateCategory, editTemplateCategory, getAllTemplateCategories,
  getTemplateCategoriesHavingTemplates, getTemplateCategoryById, searchTemplateCategories,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  allData: null,
  dataHavingTemplates: null,
  errorMessage: null,
};

const templateCategoriesSlice = createSlice({
  name: 'templateCategories',
  initialState,
  reducers: {
    setTemplateCategories: (state, { payload }) => {
      state.data = payload;
    },
    resetTemplateCategories: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addTemplateCategory.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addTemplateCategory.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addTemplateCategory.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editTemplateCategory.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editTemplateCategory.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editTemplateCategory.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllTemplateCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTemplateCategories.fulfilled, (state, { payload }) => {
      state.allData = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllTemplateCategories.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getTemplateCategoryById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTemplateCategoryById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTemplateCategoryById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchTemplateCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchTemplateCategories.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchTemplateCategories.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(getTemplateCategoriesHavingTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTemplateCategoriesHavingTemplates.fulfilled, (state, { payload }) => {
      state.dataHavingTemplates = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getTemplateCategoriesHavingTemplates.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });
  },
});

export const { setTemplateCategories, resetTemplateCategories } = templateCategoriesSlice.actions;

export default templateCategoriesSlice.reducer;
