import { memo } from 'react';

import { StyledTitleBox } from './styled';
import StyledTypography from '../StyledTypography';

interface IEmptyState {
  text: string;
  isAdd?: boolean;
}

const EmptyState = ({ text, isAdd }: IEmptyState) => (
  <StyledTitleBox>
    <StyledTypography variant="h6" color="grey">
      {`${text}`}
      {isAdd && ','}
    </StyledTypography>
    {isAdd && (
    <StyledTypography variant="h6" color="grey">
      please add new to proceed
    </StyledTypography>
    )}
  </StyledTitleBox>
);

export default memo(EmptyState);
