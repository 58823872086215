import { ChangeEvent, DragEvent, MouseEvent, memo, useState } from 'react';

import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import FileUploaderInput from '@containers/common/FileUploader/components/FileUploaderInput/index';
import ErrorMessage from '@containers/common/ErrorMessage';
import FileLoadingComponent from '@containers/common/FileUploader/components/FileLoadingComponent';
import FileDisplay from '@containers/common/FileUploader/components/FileDisplay';
import EmptyImage from '@containers/common/FileUploader/components/EmptyImage';
import { isValidFileExtension } from '@containers/common/FileUploader/helpers';

interface IFileUpload {
  name: string;
  errorMessage?: string;
  fileExtensions?: string[];
}

const FileUploader = ({ name, errorMessage, fileExtensions }: IFileUpload) => {
  const [loading, setLoading] = useState<boolean | number>(false);
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const uploadedImg = watch(name);
  const [fileData, setFileData] = useState<File | null>(null);

  const uploadFile = async (file: File) => {
    try {
      setLoading(true);

      if (file) {
        setValue(name, file, { shouldValidate: isSubmitted });
        setFileData(file);
      }
    } catch { } finally {
      setLoading(false);
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      await uploadFile(files[0]);
    }
  };

  const onDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const { files } = event.dataTransfer;

    if (files[0] && isValidFileExtension(files[0], fileExtensions)) {
      await uploadFile(files[0]);
    }
  };

  const handleDeleteImg = (event: MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    setFileData(null);
    setValue(name, null, { shouldValidate: isSubmitted });

    setLoading(false);
  };

  if (loading) {
    return <FileLoadingComponent />;
  }

  return (
    <Box>
      { uploadedImg
        ? (
          <FileUploaderInput onDrop={onDrop} onChange={onChange} fileExtensions={fileExtensions} loading={!!loading}>
            <FileDisplay
              fileData={fileData || undefined}
              errorMessage={errorMessage}
              handleDeleteImg={handleDeleteImg}
              path={uploadedImg}
              isFile={!!fileExtensions}
            />
          </FileUploaderInput>
        )
        : (
          <EmptyImage
            errorMessage={errorMessage}
            fileExtensions={fileExtensions}
            onDrop={onDrop}
            onChange={onChange}
            loading={!!loading}
          />
        )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Box>
  );
};

export default memo(FileUploader);
