/* eslint-disable max-len */
import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<any>) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.458 1.58301C13.8952 1.58301 14.2497 1.93745 14.2497 2.37467V3.16634H15.0413C16.353 3.16634 17.4163 4.22967 17.4163 5.54134V15.0413C17.4163 16.353 16.353 17.4163 15.0413 17.4163H3.95801C2.64633 17.4163 1.58301 16.353 1.58301 15.0413V5.54134C1.58301 4.22967 2.64633 3.16634 3.95801 3.16634H4.74967V2.37467C4.74967 1.93745 5.10412 1.58301 5.54134 1.58301C5.97857 1.58301 6.33301 1.93745 6.33301 2.37467V3.16634H12.6663V2.37467C12.6663 1.93745 13.0208 1.58301 13.458 1.58301ZM15.8327 9.5H3.16602V15.0417C3.16602 15.4789 3.52046 15.8333 3.95768 15.8333H15.041C15.4782 15.8333 15.8327 15.4789 15.8327 15.0417V9.5ZM13.458 11.083C13.8952 11.083 14.2497 11.4374 14.2497 11.8747V13.458C14.2497 13.8952 13.8952 14.2497 13.458 14.2497H11.8747C11.4374 14.2497 11.083 13.8952 11.083 13.458V11.8747C11.083 11.4374 11.4374 11.083 11.8747 11.083H13.458ZM4.74935 4.75H3.95768C3.52046 4.75 3.16602 5.10444 3.16602 5.54167V7.91667H15.8327V5.54167C15.8327 5.10444 15.4782 4.75 15.041 4.75H14.2493V5.54167C14.2493 5.97889 13.8949 6.33333 13.4577 6.33333C13.0205 6.33333 12.666 5.97889 12.666 5.54167V4.75H6.33268V5.54167C6.33268 5.97889 5.97824 6.33333 5.54102 6.33333C5.10379 6.33333 4.74935 5.97889 4.74935 5.54167V4.75Z" fill="#788B9A" />
  </svg>
);

export default CalendarIcon;
