import * as yup from 'yup';
import { createOptionalTextWidthValidation, createRequiredTextWidthValidation, dateSchema } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import {
  DeveloperTaskPriority,
  DeveloperTaskStatus,
  IDeveloperTaskFormData,
} from '@features/customers/developerTasks/types';

export interface IAddDataForm extends IDeveloperTaskFormData {}

export const defaultValues = {
  title: '',
  status: DeveloperTaskStatus.OPEN,
  priority: DeveloperTaskPriority.MEDIUM,
  deliveryDate: null,
  description: null,
  attachment: null,
};

export const AddDataSchema = yup.object().shape({
  title: createRequiredTextWidthValidation('Title', 255),
  description: createOptionalTextWidthValidation('Description', 500).nullable(),
  status: yup.string().required('Priority is required'),
  priority: yup.string().required('Priority is required'),
  attachment: yup.string().required('Invalid File').nullable(),
  deliveryDate: dateSchema('Invalid delivery date', false),
});

// const videoExtensions = [
//   '.mp4',
//   '.avi',
//   '.webm',
//   '.flv',
//   '.ogv',
//   '.mkv',
//   '.mpeg',
//   '.mpg',
//   'video/mp4',
//   'video/x-msvideo',
//   'video/webm',
//   'video/x-flv',
//   'video/ogg',
//   'video/x-matroska',
//   'video/mpeg',
// ];

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Task title',
    field: 'title',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Status',
    field: 'status',
    isRequired: true,
    type: InputTypes.select,
  },
  {
    label: 'Priority',
    field: 'priority',
    isRequired: true,
    type: InputTypes.select,
  },
  {
    label: 'Delivery Date',
    field: 'deliveryDate',
    type: InputTypes.calendar,
  },
  {
    label: 'Attachments',
    field: 'attachment',
    type: InputTypes.image,
    // extensions: ['.csv', '.xls', '.xlsx', '.png', '.jpg', '.jpeg', ...videoExtensions],
  },
  {
    label: 'Task Description',
    field: 'description',
    type: InputTypes.textarea,
  },
];

export const statusOptions = [
  { value: DeveloperTaskStatus.OPEN, optionName: DeveloperTaskStatus.OPEN },
  { value: DeveloperTaskStatus.CLOSED, optionName: DeveloperTaskStatus.CLOSED },
  { value: DeveloperTaskStatus.AWAITING_REVIEW, optionName: DeveloperTaskStatus.AWAITING_REVIEW },
  { value: DeveloperTaskStatus.IN_PROGRESS, optionName: DeveloperTaskStatus.IN_PROGRESS },
];

export const priorityOptions = [
  { value: DeveloperTaskPriority.LOW, optionName: DeveloperTaskPriority.LOW },
  { value: DeveloperTaskPriority.MEDIUM, optionName: DeveloperTaskPriority.MEDIUM },
  { value: DeveloperTaskPriority.HIGH, optionName: DeveloperTaskPriority.HIGH },
];
