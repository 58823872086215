import { memo } from 'react';

import TableCell from '@mui/material/TableCell';

import DeleteBtn, { IDeleteAction } from '../DeleteAction';

interface IDeleteCell extends IDeleteAction {
  width?: string;
}

const DeleteCell = (props: IDeleteCell) => {
  const { width = '70px' } = props;

  return (
    <TableCell width={width}>
      <DeleteBtn {...props} />
    </TableCell>
  );
};

export default memo(DeleteCell);
