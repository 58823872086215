import { styled } from '@mui/material/styles';
import RowComponent from '@containers/common/Table/components/RowComponent';

export const StyledRowComponent = styled(RowComponent)(({ theme }) => ({
  '.MuiTableCell-root': {
    padding: '9px 16px',
  },
  '.MuiTableCell-root.MuiTableCell-body': {
    width: '352px',
  },
  [theme.breakpoints.down('lg')]: {
    '.MuiTableCell-root.MuiTableCell-body': {
      width: '300px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '.MuiTableCell-root.MuiTableCell-body': {
      width: '250px',
    },
  },
}));
