import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import Select from '@containers/common/Select';
import PAGE_ROUTES from '@routes/routingEnum';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { constructQueryString, getOptionsArray } from '@utils/helpers';
import { useAppSelector } from '@features/app/hooks';
import SearchBtn from '@containers/common/SearchSection/SearchBtn';
import { selectSubcategories } from '@features/subcategories/selectors';
import { GlobalQueryString } from '@utils/globalTypes';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm, visibilityOptions } from './helpers';

interface ISearchSectionProps {
  onReset: () => void;
}

const SearchSection = ({ onReset }: ISearchSectionProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', visibleOnSite = '', subCategoryId = '' } = params;
  const { dataHavingProducts } = useAppSelector(selectSubcategories);
  const categoriesList = getOptionsArray(dataHavingProducts);
  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema),
    defaultValues: { searchTerm, visibleOnSite, subCategoryId },
  });

  const { handleSubmit, register } = methods;

  const onSubmit = (data: IFiltersForm) => {
    // Check if all search fields are empty
    if (!data.searchTerm && !data.subCategoryId && !data.visibleOnSite) {
      onReset(); // Reset the toggled items if search fields are empty
    } else {
      const queryParams = constructQueryString({ ...data });

      navigate(`${PAGE_ROUTES.PRODUCTS_PRODUCTS}?${queryParams}`);
    }
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Search"
            />
            <Select
              label=""
              name="subCategoryId"
              placeholderText="Search by category"
              options={categoriesList}
            />
            <Select
              label=""
              name="visibleOnSite"
              placeholderText="Search by visible on site"
              options={visibilityOptions}
            />
            <SearchBtn onReset={onReset} path={PAGE_ROUTES.PRODUCTS_PRODUCTS} />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
