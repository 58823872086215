import { styled } from '@mui/material/styles';
import { COLORS } from '@customTypes/global/theme/colors';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)<{width?: string}>(({ width }) => ({
  color: COLORS.blue.dark,
  marginLeft: '5px',
  textDecoration: 'underline',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  display: 'inline-block',
  direction: 'rtl',
  alignItems: 'center',
  width: width || '150px',
}));
