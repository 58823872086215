import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectSubcategories } from '@features/subcategories/selectors';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import CheckboxList from '@containers/Products/Products/components/Tabs/NewAddAttributes/components/CheckboxList';
import Summary from '@containers/Products/Products/components/Tabs/NewAddAttributes/components/Summary';
// eslint-disable-next-line max-len
import { LocalStyledStack } from '@containers/Products/Products/components/Tabs/NewAddAttributes/components/InputsTable/styles';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { addSubcategoryAttributes } from '@features/subcategories/actions';
import { SUCCESS_MESSAGE } from '@utils/messages';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { AddDataSchema, IAddDataForm, addingIsSelectedFlag, formattingPayload } from './helpers';
import { SectionWrapper } from './styles';

const InputsTable = () => {
  const dispatch = useAppDispatch();
  const { attributeIds, defaultAttributeIds } = useAppSelector(selectSubcategories);
  const { productAttributeCategories } = useAppSelector(selectAttributeCategories);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const { id: productId } = useParams();

  const initialState = addingIsSelectedFlag(productAttributeCategories, attributeIds, defaultAttributeIds);

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: { attributesList: initialState },
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: IAddDataForm) => {
    setIsSuccessMsg({ msg: '', status: null });

    const body = formattingPayload(data);

    dispatch(addSubcategoryAttributes({ body, id: productId as string }))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });
      });
  };

  return (
    <SectionWrapper>
      <FormProvider {...methods}>
        <LocalStyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >

          <div>
            {/* <CheckboxList grandFormatOptions={grandFormatOptions} /> */}
            <CheckboxList type="category" />
            <Box
              sx={{
                margin: '24px -300px 0px 212px',
              }}
            >
              <GlobalUpdatesMessage {...isSuccessMsg} />
            </Box>
          </div>
          <Summary type="category" />
        </LocalStyledStack>
      </FormProvider>
    </SectionWrapper>
  );
};

export default memo(InputsTable);
