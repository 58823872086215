import { usePrevScroll } from '@utils/prevState';

import Invoices from '../Invoices';

const OpenInvoices = () => {
  usePrevScroll();

  return (
    <Invoices title="Open Order Invoices" status="open" emptyText="There is no open invoices" />
  );
};

export default OpenInvoices;
