import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IAddShippingZip, IShippingZip } from './types';

const prefix = '/shipping-zips';

export const addShippingZip = createAsyncThunk<void, IAddShippingZip, { rejectValue: AxiosData}>(
  'shippingZips/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IAddShippingZip>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllShippingZips = createAsyncThunk<IShippingZip[], void, { rejectValue: AxiosData}>(
  'shippingZips/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IShippingZip[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getShippingZipById = createAsyncThunk<IShippingZip, string, { rejectValue: AxiosData}>(
  'shippingZips/getShippingZip',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IShippingZip>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editShippingZip = createAsyncThunk<void, IAddShippingZip, { rejectValue: AxiosData}>(
  'shippingZips/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddShippingZip>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteShippingZip = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'shippingZips/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
