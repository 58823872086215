import { usePrevScroll } from '@utils/prevState';

import Invoices from '../Invoices';

const CompletedInvoices = () => {
  usePrevScroll();

  return (
    <Invoices title="Completed Order Invoices" status="completed" emptyText="There is no completed invoices" />
  );
};

export default CompletedInvoices;
