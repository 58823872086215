import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ShippingToContainer = styled(Box)(() => ({
}));

export const InputsWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: 16,
  alignItems: 'center',
  gridTemplateColumns: '67px 1fr',

  '& > label': {
    display: 'flex',
    alignItems: 'center',

    '> span': {
      marginLeft: '5px',
      height: '14px',
    },
  },
}));
