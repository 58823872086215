import { memo } from 'react';

import StyledTypography from '@containers/common/StyledTypography';

interface ITaskStatus {
  status: string;
}

const TaskStatus = ({ status }: ITaskStatus) => (
  <StyledTypography
    cursor="default"
    variant="body3"
    color={status === 'closed' ? 'green' : 'red'}
    textTransform="capitalize"
  >
    {status}
  </StyledTypography>

);

export default memo(TaskStatus);
