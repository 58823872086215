import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import StyledTypography from '@containers/common/StyledTypography';

export const Colors = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  // same margin that has page title
  marginBottom: '28px',
}));

export const ColorItem = styled(StyledTypography)<{ background: string; color: string }>(({ background, color }) => ({
  color,
  background,
  borderRadius: 12,
  padding: '0px 6px 0px 6px',
  cursor: 'unset',

  fontWeight: 600,
  fontSize: '11px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  letterSpacing: '0.2px',
}));
