import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ToggleTitleWrapper = styled(Box)(() => ({
  cursor: 'pointer',
  gap: '5px',
  width: 'max-content',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  marginLeft: '-10px',
  justifyContent: 'flex-start',

  svg: {
    width: "1em",
    height: "1em",

    path: {
      width: "1em",
      height: "1em",
    },
  },
}));

export const ToggleContainer = styled(Box)<{ status: boolean }>(({ status }) => ({
  display: status ? 'block' : 'none',
  position: 'relative',
}));

export const StyledLoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  background: '#d7d7d7',
  opacity: 0.7,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledTypography = styled(Typography)(() => ({
 fontSize: '16px !important',
}));
