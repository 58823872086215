import { memo } from 'react';

import { useConfirm } from 'material-ui-confirm';
import noticeCategoryDialog from '@containers/common/NoticeCategoryDialog';

import StyledTypography from '../../../../StyledTypography';

export interface IDeleteAction {
  text?: string;
  title: string;
  desc: string[];
  btnText: string;
}

const CategoryInformDialog = ({ text = 'Delete', ...rest }: IDeleteAction) => {
  const confirm = useConfirm();
  const handleDelete = async () => {
    try {
      await confirm(noticeCategoryDialog({ ...rest }));
    } catch { }
  };

  return (
    <StyledTypography
      aria-disabled
      onClick={handleDelete}
      color="blue"
      cursor="pointer"
      variant="body3"
    >
      {text}
    </StyledTypography>
  );
};

export default memo(CategoryInformDialog);
