import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { ProofStatusEnum } from
'@containers/Orders/Order/components/Switcher/components/BasicInfo/OrderDetails/utils/enums';
import download from '@assets/images/download.svg';

import Modal from '../../../../../../../../../../../common/Modal';
import EachProof from './components/EachProof';
import UploadBox, { CertificateEnumType } from './components/UploadBox';
import { useFileUploadLogic } from './components/UploadBox/useFileUploadLogic';
import { Title } from '../../styled';
import {
  Content,
  Underlined,
  Wrapper,
} from './styled';

interface ProofProps {
  setId: string;
  jobIndex: number;
  setIndex: number;
  shipmentIndex: number;
  backProofStatus: ProofStatusEnum;
  frontProofStatus: ProofStatusEnum;
}

const Proof = ({
  setId,
  jobIndex,
  setIndex,
  shipmentIndex,
  backProofStatus,
  frontProofStatus,
}: ProofProps) => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}]`;
  const { setValue, watch, formState: { errors } } = useFormContext();
  const logic = useFileUploadLogic(defaultKey, setValue, setId);

  const frontProof = watch(`${defaultKey}.frontProof`) as string;
  const backProof = watch(`${defaultKey}.backProof`) as string;

  const messagesObjJobs = errors?.jobs as Record<string, any>;

  let messageShipmentSets = {} as Record<string, any>;

  if (messagesObjJobs && messagesObjJobs[jobIndex]) {
    messageShipmentSets = messagesObjJobs[jobIndex]?.shipments?.[shipmentIndex]?.sets?.[setIndex];
  }

  return (
    <>
    <Modal
      sx={{ padding: '24px' }}
      title="UPLOAD PROOFS"
      open={isOpenUploadModal}
      handleClose={() => {
        setIsOpenUploadModal(false);
      }}
    >
      <Wrapper>
        <UploadBox
          title="Front Side Artwork"
          desc=""
          mobileButton=""
          uploaded={frontProof}
          keyType={CertificateEnumType.frontProof}
          {...logic}
        />
        <UploadBox
          title="Back Side Artwork"
          desc=""
          mobileButton=""
          uploaded={backProof}
          {...logic}
          keyType={CertificateEnumType.backProof}
        />
      </Wrapper>
    </Modal>
    <Title>
      PROOF
      <Underlined
        onClick={() => {
          setIsOpenUploadModal(true);
        }}
      >
        <img src={download} alt={download} />
        <p>Upload proofs</p>
      </Underlined>
    </Title>
    <Content>
      <EachProof
        text="Front:"
        proof={frontProof}
        proofStatus={frontProofStatus}
        defaultKey={defaultKey}
        deleteKey="frontProof"
      />
      <EachProof
        text="Back:"
        proof={backProof}
        proofStatus={backProofStatus}
        defaultKey={defaultKey}
        deleteKey="backProof"
      />
    </Content>
    </>
  );
};

export default Proof;
