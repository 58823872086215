import { memo } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';
import EditCell from '@containers/common/Table/components/EditCell';
import { formattedDate } from '@utils/helpers';
import { ITask } from '@features/customers/tasks/types';
import TaskFor from '@containers/Administration/Tasks/components/TableRow/TaskFor';
import TaskStatus from '@containers/Administration/Tasks/components/TableRow/TaskStatus';

const TableRow = ({
  id: taskId, name, status, followUp, completedDate, created,
  creator: { firstName: creatorFirstName, lastName: creatorLastName },
  customer: { id: customerId, firstName: customerFirstName, lastName: customerLastName }, users,
}: ITask) => (
  <StyledTableRow>
    <TableCell>
      <EditCell title={name} path="EDIT_CUSTOMERS_TASK" params={{ taskId, customerId }} />
    </TableCell>
    <TableCell>
      <TaskFor users={users} />
    </TableCell>
    <TableCell>
      {formattedDate(followUp)}
    </TableCell>
    <TableCell />
    <TableCell />
    <TableCell>
      {`${customerFirstName} ${customerLastName}`}
    </TableCell>
    <TableCell>
      <TaskStatus status={status} />
    </TableCell>
    <TableCell>
      {`${creatorFirstName} ${creatorLastName}`}
    </TableCell>
    <TableCell>
      {created && formattedDate(created)}
    </TableCell>
    <TableCell>
      {completedDate && formattedDate(completedDate)}
    </TableCell>
  </StyledTableRow>
);

export default memo(TableRow);
