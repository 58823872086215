// export const countryOptions = [
//   { optionName: 'United States', value: 'United States' },
//   { optionName: 'Canada', value: 'Canada' },
//   { optionName: 'Bermuda', value: 'Bermuda' },
// ];

export const usStateOptions = [
  { optionName: 'Alabama', value: 'AL' },
  { optionName: 'Alaska', value: 'AK' },
  { optionName: 'Arizona', value: 'AZ' },
  { optionName: 'Arkansas', value: 'AR' },
  { optionName: 'California', value: 'CA' },
  { optionName: 'Colorado', value: 'CO' },
  { optionName: 'Connecticut', value: 'CT' },
  { optionName: 'Delaware', value: 'DE' },
  { optionName: 'District of Columbia', value: 'DC' },
  { optionName: 'Florida', value: 'FL' },
  { optionName: 'Georgia', value: 'GA' },
  { optionName: 'Hawaii', value: 'HI' },
  { optionName: 'Idaho', value: 'ID' },
  { optionName: 'Illinois', value: 'IL' },
  { optionName: 'Indiana', value: 'IN' },
  { optionName: 'Iowa', value: 'IA' },
  { optionName: 'Kansas', value: 'KS' },
  { optionName: 'Kentucky', value: 'KY' },
  { optionName: 'Louisiana', value: 'LA' },
  { optionName: 'Maine', value: 'ME' },
  { optionName: 'Maryland', value: 'MD' },
  { optionName: 'Massachusetts', value: 'MA' },
  { optionName: 'Michigan', value: 'MI' },
  { optionName: 'Minnesota', value: 'MN' },
  { optionName: 'Mississippi', value: 'MS' },
  { optionName: 'Missouri', value: 'MO' },
  { optionName: 'Montana', value: 'MT' },
  { optionName: 'Nebraska', value: 'NE' },
  { optionName: 'Nevada', value: 'NV' },
  { optionName: 'New Hampshire', value: 'NH' },
  { optionName: 'New Jersey', value: 'NJ' },
  { optionName: 'New Mexico', value: 'NM' },
  { optionName: 'New York', value: 'NY' },
  { optionName: 'North Carolina', value: 'NC' },
  { optionName: 'North Dakota', value: 'ND' },
  { optionName: 'Ohio', value: 'OH' },
  { optionName: 'Oklahoma', value: 'OK' },
  { optionName: 'Oregon', value: 'OR' },
  { optionName: 'Pennsylvania', value: 'PA' },
  { optionName: 'Rhode Island', value: 'RI' },
  { optionName: 'South Carolina', value: 'SC' },
  { optionName: 'South Dakota', value: 'SD' },
  { optionName: 'Tennessee', value: 'TN' },
  { optionName: 'Texas', value: 'TX' },
  { optionName: 'Utah', value: 'UT' },
  { optionName: 'Vermont', value: 'VT' },
  { optionName: 'Virginia', value: 'VA' },
  { optionName: 'Washington', value: 'WA' },
  { optionName: 'West Virginia', value: 'WV' },
  { optionName: 'Wisconsin', value: 'WI' },
  { optionName: 'Wyoming', value: 'WY' },
];

export const countryOptions = [
  { value: 'US', optionName: 'United States' },
  { value: 'CA', optionName: 'Canada' },
  { value: 'BE', optionName: 'Bermuda' },
];

export const canadianStateOptions = [
  { optionName: 'Alberta', value: 'Alberta' },
  { optionName: 'British Columbia', value: 'British Columbia' },
  { optionName: 'Manitoba', value: 'Manitoba' },
  { optionName: 'New Brunswick', value: 'New Brunswick' },
  { optionName: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { optionName: 'Nova Scotia', value: 'Nova Scotia' },
  { optionName: 'Northwest Territories', value: 'Northwest Territories' },
  { optionName: 'Nunavut', value: 'Nunavut' },
  { optionName: 'Ontario', value: 'Ontario' },
  { optionName: 'Prince Edward Island', value: 'Prince Edward Island' },
  { optionName: 'Quebec', value: 'Quebec' },
  { optionName: 'Saskatchewan', value: 'Saskatchewan' },
  { optionName: 'Yukon', value: 'Yukon' },
];

export const nonUsStateOptions = [
  { optionName: 'Non US', value: 'Non US' },
];
