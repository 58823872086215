import { memo } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@features/app/hooks';
import Button from '@containers/common/Button';
import PageTitle from '@containers/Customers/Customers/components/PageTitle';
import { WEB_API_URL } from '@config/index';
import { buildRoute } from '@routes/helpers';
import { loginAsCustomers } from '@features/customers/customers/actions';

interface IEditPageTitle {
  firstName?: string;
  lastName?: string;
}

const EditPageTitle = ({ firstName, lastName }: IEditPageTitle) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: customerId = '' } = useParams();

  const handleAdd = () => {
    const path = buildRoute(PAGE_ROUTES.ADD_CUSTOMERS_TASK, { customerId });

    navigate(path);
  };

  const handelLogin = () => {
    dispatch(loginAsCustomers(customerId)).unwrap().then(({ refreshToken }) => {
      const url = `${WEB_API_URL}/auth/login-as-admin/${refreshToken}`;

      window.open(url, '_blank', 'noopener,noreferrer');
    }).catch(() => {});
  };

  return (
    <PageTitle title={`Customer: ${firstName} ${lastName}`}>
      <Button width="120px" isOutlined onClick={handelLogin}>Login</Button>
      <Button width="120px" isOutlined onClick={handleAdd}>Add Task </Button>
    </PageTitle>
  );
};

export default memo(EditPageTitle);
