export const isOptions = [
  {
    value: 'yes',
    optionName: 'Yes',
  },
  {
    value: 'no',
    optionName: 'No',
  },
  {
    value: 'pending',
    optionName: 'Pending',
  },
];
