import { useState } from 'react';

export function useDebounce<T extends(
 ...args: any[]) => void>(
  callback: T,
  delay: number,
): (...args: Parameters<T>) => void {
  // eslint-disable-next-line no-undef
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  return function debouncedFunction(...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      callback(...args);
    }, delay);

    setTimeoutId(id);
  };
}
