import { memo } from 'react';

import Stack from '@mui/material/Stack';
import { DraggableProvided } from '@hello-pangea/dnd';
import DragAndDropIcon from '@containers/common/Icons/DragAndDrop';

import StyledTypography from '../../../../StyledTypography';

export interface IDndBtn {
  providedDraggable: DraggableProvided;
  isText?: boolean;
  sx?: Record<string, any>;
}

const DndBtn = ({ providedDraggable, isText = true, sx }: IDndBtn) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={sx}
      {...providedDraggable.dragHandleProps}
    >
      <DragAndDropIcon />
      { isText && (
        <StyledTypography
          color="blue"
          variant="body3"
          cursor="grab"
          ml="8px"
        >
          Drag to Reorder
        </StyledTypography>
      )}
    </Stack>
  );
};

export default memo(DndBtn);
