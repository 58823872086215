import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const StyledTitle = styled(Typography)(() => ({
  color: COLORS.black.light,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '20.83px',
}));

export const StyledSubTitle = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20.83px",
  textAlign: "left",
  color: COLORS.grey[300],
  marginBottom: '24px',
}));

export const StyledCancelButton = styled(Button)(() => ({
  borderRadius: '4px',
  minWidth: '248px',
  background: '#788B9A',

  "&:hover": {
    background: '#788B9A',
  },
}));
