import { usePrevScroll } from '@utils/prevState';

import InputsTable from '../components/InputsTable';

const AddComponent = () => {
  usePrevScroll();

  alert(1);

  return (
    <InputsTable />
  );
};

export default AddComponent;
