import { styled } from '@mui/material/styles';
import { DeveloperTaskStatus } from '@features/customers/developerTasks/types';

export const ColoredTxt = styled('span')<{status: DeveloperTaskStatus}>(({ status }) => {
  const styles = {
    color: '#1E1E1E',
  };

  if (status === DeveloperTaskStatus.OPEN) {
    styles.color = '#FB3838';
  } else if (status === DeveloperTaskStatus.CLOSED) {
    styles.color = '#788B9A';
  } else if (status === DeveloperTaskStatus.AWAITING_REVIEW) {
    styles.color = '#03A89E';
  }

  return styles;
});
