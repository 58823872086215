import { memo } from 'react';

import { Link, useLocation } from 'react-router-dom';
import StyledTypography from '@containers/common/StyledTypography';
import { useAppSelector } from '@features/app/hooks';
import { selectSidebarCounts } from '@features/sidebarCounts/selectors';

interface ISubMenuItem {
  path: string;
  title: string;
}

const SubMenuItem = ({ path, title }: ISubMenuItem) => {
  const { pathname } = useLocation();
  const isActive = pathname === path || pathname.startsWith(path);
  const sidbarCounts = useAppSelector(selectSidebarCounts);

  return (
    <Link to={path}>
      <StyledTypography variant="body3" color={isActive ? 'blue' : ''}>
        {title}
      </StyledTypography>
      <StyledTypography variant="body3" color="blue">
        {sidbarCounts[path] !== undefined && sidbarCounts[path] !== null && ` (${sidbarCounts[path]})`}
      </StyledTypography>
    </Link>

  );
};

export default memo(SubMenuItem);
