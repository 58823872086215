import { ICustomer } from '@features/customers/customers/types';

import BasicInfo from './Tabs/BasicInfo';
import Tasks from './Tabs/Tasks';

const tabsOptions = (editData: ICustomer | null) => [
  {
    component: BasicInfo,
    label: 'Basic Info',
    props: { editData },
  },
  {
    component: Tasks,
    label: 'Tasks',
    props: { editData },
  },
];

export default tabsOptions;
