import { useState } from "react";

import { Shipment } from "@features/orders/sets/types";
import Input from "@containers/common/Input";
import { UpdateShipmentPayload } from "@features/orders/order/types";
import { ShipmentStatusesEnum } from "@features/orders/order/enums";

import { RadioButtons, RadioLabel, SpecialBox, StyledTypography } from './styled';

interface OrderShipmentsProps {
  shipment: UpdateShipmentPayload;
  onChange: (args: UpdateShipmentPayload) => void;
}

const OrderShipments = ({ shipment, onChange }: OrderShipmentsProps) => {
  const [payload, setPayload] = useState(shipment);

  const handleChange = (key: keyof UpdateShipmentPayload, value: string) => {
    const updatedPayload = {
      ...payload,
      [key]: value,
    };

    const customPayload: UpdateShipmentPayload = {
      ...updatedPayload,
      trackingCarrier: updatedPayload?.trackingCarrier || null,
      trackingNumber: updatedPayload?.trackingNumber || null,
    };

    onChange(customPayload);
    setPayload(customPayload as Shipment);
  };

  return (
    <div>
      <SpecialBox>
        <Input
          name="test"
          defaultValue={payload?.trackingNumber}
          placeholder="Enter..."
          onChange={(e) => {
            handleChange('trackingNumber', e.target.value);
          }}
        />
        <RadioButtons>
          <RadioLabel>
            <input
              type="radio"
              name={`${payload?.id}-shipment`}
              value={ShipmentStatusesEnum.UPS}
              checked={payload?.trackingCarrier === ShipmentStatusesEnum.UPS}
              onChange={() => {
                handleChange('trackingCarrier', ShipmentStatusesEnum.UPS);
              }}
            />
            <StyledTypography>UPS</StyledTypography>
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name={`${payload?.id}-shipment`}
              value={ShipmentStatusesEnum.FEDEX}
              checked={payload?.trackingCarrier?.toUpperCase() === ShipmentStatusesEnum.FEDEX}
              onChange={() => {
                handleChange('trackingCarrier', ShipmentStatusesEnum.FEDEX);
              }}
            />
            <StyledTypography>FEDEX</StyledTypography>
          </RadioLabel>
        </RadioButtons>
      </SpecialBox>
    </div>
  );
};

export default OrderShipments;
