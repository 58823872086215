import { memo, useCallback, useEffect, useState } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import StyledTable from '@containers/common/Table';
import { DropResult } from '@hello-pangea/dnd';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import EditCell from '@containers/common/Table/components/EditCell';
import { useLocation } from 'react-router-dom';
import { nestedDragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import Box from '@mui/material/Box';
import ToggleHoc from '@containers/common/ToggleUtils/ToggleHoc';
import { selectProducts } from '@features/products/basicInfo/selectors';
import {
  deleteProduct,
  searchProducts,
  reorderProducts,
} from '@features/products/basicInfo/actions';
import { setProducts } from '@features/products/basicInfo/slice';
import { IProductsInfo, IProductsSearchInfo } from '@features/products/basicInfo/types';
import { selectSubcategories } from '@features/subcategories/selectors';
import { getAllSubcategories, getAllSubcategoriesProducts } from '@features/subcategories/actions';
import TCell from '@containers/common/Table/components/TCell';
import { ISubcategoriesInfo } from '@features/subcategories/types';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';
import { useToggleLogic } from '../../common/ToggleUtils/useToggleLogic';

function isProductsSearchInfo(
  item: IProductsInfo | IProductsSearchInfo,
): item is IProductsSearchInfo {
  return (item as IProductsSearchInfo).products !== undefined;
}

const Products = () => {
  usePrevScroll();

  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { data: productsList, isLoading } = useAppSelector(selectProducts);
  const { allData: subcategories, isLoading: subcategoryLoading } =
    useAppSelector(selectSubcategories);

  const [localLoader, setLocalLoader] = useState<string | null>(null);
  const params = queryString.parse(search);

  const {
    searchTerm = '',
    visibleOnSite: visibleOnSiteQuery = '',
    subCategoryId = '',
  } = params as IFiltersForm;

  const isSearchTerm = !!(searchTerm || visibleOnSiteQuery || subCategoryId);

  const { toggledItems, handleUpdateToggle, handleResetToggle } = useToggleLogic<ISubcategoriesInfo>({
    data: subcategories,
    isSearchTerm,
  });

  const fetchData = useCallback(() => {
    const query = {
      searchTerm,
      visibleOnSite: visibleOnSiteQuery,
      subCategoryId,
    };

    // Fetch the products based on the search term
    dispatch(searchProducts(query));
  }, [searchTerm, visibleOnSiteQuery, subCategoryId, dispatch]);

  const fetchInitialData = useCallback(() => {
    const query = {
      searchTerm: '',
      visibleOnSite: '',
      subCategoryId: '',
    };

    // Fetch default products when no search is performed
    dispatch(searchProducts(query));
  }, [dispatch]);

  const deleteAction = (id: string) => {
    dispatch(deleteProduct(id))
      .unwrap()
      .then(() => {
        fetchData();
      })
      .catch(() => {});
  };

  const reordingData = (result: DropResult, id: string) => {
    setLocalLoader(id);

    const { sortedData, items } = nestedDragSort(
      result,
      productsList as IProductsSearchInfo[],
      'products',
    );

    dispatch(reorderProducts(sortedData))
      .unwrap()
      .then(async () => {
        await dispatch(setProducts(items));
        await setLocalLoader(null);
      })
      .catch(() => fetchData());
  };

  useEffect(() => {
    if (isSearchTerm) {
      fetchData();
    } else {
      fetchInitialData();
    }
  }, [fetchData, fetchInitialData, isSearchTerm]);

  useMount(() => {
    dispatch(getAllSubcategoriesProducts());
    dispatch(getAllSubcategories());
  });

  if (isLoading || subcategoryLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Products" btnName="Add Product" path={PAGE_ROUTES.ADD_PRODUCTS} />
      {subcategories?.length ? (
        <>
          {(isSearchTerm || !!productsList?.length) && <SearchSection onReset={() => handleResetToggle()} />}
          {productsList?.length ? (
            productsList.map((item) => {
              // Use type guard to check if it's IProductsSearchInfo

              if (isProductsSearchInfo(item)) {
                const { id: catId, products, title: parentTitle } = item;
                const status = toggledItems[catId] || false;

                return (
                  <Box key={catId}>
                    <ToggleHoc
                      id={catId}
                      status={status}
                      title={parentTitle}
                      localLoader={localLoader}
                      handleUpdateToggle={handleUpdateToggle}
                    >
                      <DndContainer reordingData={(props) => reordingData(props, catId)}>
                        <StyledTable headCells={headCells}>
                          {products.map(
                            (
                              {
                                id,
                                name,
                                productSKU,
                                productType,
                                visibleOnSite,
                                fouroverProdCode,
                                quarterhouseProductCode,
                              },
                              index,
                            ) => (
                              <ReusableDragRow
                                key={id}
                                id={id}
                                index={index}
                                gridTemplateColumns="auto 100px 152px 192px 76px 140px 140px 70px"
                              >
                                {({ providedDraggable }) => (
                                  <>
                                    <EditCell
                                      title={productSKU}
                                      path="EDIT_PRODUCTS"
                                      params={{ id }}
                                    />
                                    <TCell width="100px" text={quarterhouseProductCode} />
                                    <TCell width="152px" text={fouroverProdCode} />
                                    <EditCell
                                      width="192px"
                                      title={name}
                                      path="EDIT_PRODUCTS"
                                      params={{ id }}
                                    />
                                    <TCell width="76px" text={visibleOnSite ? 'Yes' : 'No'} />
                                    <TCell width="152px" text={productType?.name} />
                                    <DndBtn providedDraggable={providedDraggable} />
                                    <DeleteBtn
                                      deleteAction={() => deleteAction(id)}
                                      questionText="Are you sure you want to delete this product?"
                                    />
                                  </>
                                )}
                              </ReusableDragRow>
                            ),
                          )}
                        </StyledTable>
                      </DndContainer>
                    </ToggleHoc>
                  </Box>
                );
              }
            })
          ) : (
            <EmptyState
              text={isSearchTerm ? 'No search results found' : 'You don’t have any products'}
              isAdd={!searchTerm}
            />
          )}
        </>
      ) : (
        <EmptyState text="You don’t have any products" isAdd />
      )}
    </>
  );
};

export default memo(Products);
