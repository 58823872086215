import { memo, useCallback, useEffect, useState } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import { DropResult } from '@hello-pangea/dnd';
import DndContainer from '@containers/common/Table/components/DndContainer';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import { deleteAttribute, reorderAttributes, searchAttributes } from '@features/attributes/actions';
import { selectAttributes } from '@features/attributes/selectors';
import EditCell from '@containers/common/Table/components/EditCell';
import { nestedDragSort } from '@containers/common/Table/components/DndContainer/helpers';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { useLocation } from 'react-router-dom';
import { resetAttributes, setAttributes } from '@features/attributes/slice';
import Box from '@mui/material/Box';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import TableCell from '@mui/material/TableCell';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteAction';
import CategoryInformDialog from '@containers/common/Table/components/TablesActions/CategoryInformDialog';
import { usePrevScroll } from '@utils/prevState';
import { useToggleLogic } from "@containers/common/ToggleUtils/useToggleLogic";
import ToggleHoc from "@containers/common/ToggleUtils/ToggleHoc";

import { IFiltersForm } from './components/SearchSection/helpers';
import SearchSection from './components/SearchSection';
import { headCells } from './helpers';

const Attribute = () => {
  usePrevScroll();

  const { data: attributesList, isLoading } = useAppSelector(selectAttributes);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const [localLoader, setLocalLoader] = useState<string | null>(null);

  const { searchTerm = '' } = params as IFiltersForm;
  const query = { searchTerm };

  const { toggledItems, handleUpdateToggle, handleResetToggle } = useToggleLogic({
    data: attributesList,
    isSearchTerm: !!searchTerm,
  });

  const fetchData = useCallback(() => {
    dispatch(searchAttributes(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm],
  );

  useUnMount(() => dispatch(resetAttributes()));

  const reordingData = (result: DropResult, id: string) => {
    setLocalLoader(id);

    const { sortedData, items } = nestedDragSort(result, attributesList, 'attributes');

    dispatch(reorderAttributes(sortedData))
    .unwrap()
      .then(async () => {
        await dispatch(setAttributes(items));
        await setLocalLoader(null);
      })
      .catch(() => fetchData());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Attributes" btnName="Add Attribute" path={PAGE_ROUTES.ADD_ATTRIBUTE} />
      { (searchTerm || !!attributesList?.length) && <SearchSection onReset={() => handleResetToggle()} /> }
      {attributesList?.length ? attributesList.map(({
          id: attributeId,
          name: attributeName,
          attributes,
      }) => {
            const status = toggledItems[attributeId] || false;

            return (
            <Box key={attributeId}>
              <ToggleHoc
                id={attributeId}
                status={status}
                title={attributeName}
                handleUpdateToggle={handleUpdateToggle}
                localLoader={localLoader}
              >
                <DndContainer reordingData={(props) => reordingData(props, attributeId)}>
                  <StyledTable headCells={headCells}>
                    {attributes.map(({ name, id, nickname, linkedProducts }, index) => {
                      return (
                          <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 250px 250px">
                            {({ providedDraggable }) => (
                                <>
                                  <EditCell title={`${name} / ${nickname}`} path="EDIT_ATTRIBUTE" params={{ id }} />
                                  <DndBtn
                                    width="270px"
                                    providedDraggable={providedDraggable}
                                    sx={{ width: 'max-content', margin: '0 auto' }}
                                  />
                                  <TableCell
                                    width="270px"
                                    sx={{
                                        textAlign: 'center',
                                      }}
                                  >
                                    {!linkedProducts?.length ? (
                                        <DeleteBtn
                                          text="Delete"
                                          deleteAction={() => {
                                              dispatch(deleteAttribute(id))
                                                  .then(() => fetchData())
                                                  .catch((error) => {
                                                    console.log('Error:', error);
                                                  });
                                            }}
                                          questionText="Are you sure you want to delete this attribute?"
                                        />
                                    ) : (
                                        <CategoryInformDialog
                                          text={`Linked to ${linkedProducts?.length} product(s)`}
                                          title="This attribute is tied to product(s) and cannot be deleted."
                                          desc={linkedProducts}
                                          btnText="Dismiss"
                                        />
                                    )}
                                  </TableCell>
                                </>
                            )}
                          </ReusableDragRow>
                      );
                    })}
                  </StyledTable>
                </DndContainer>
              </ToggleHoc>
            </Box>
        );
          })
        : (
          <EmptyState
            text={searchTerm ? 'No search results found' : 'You don’t have any attribute'}
            isAdd={!searchTerm}
          />
        )}
    </>
  );
};

export default memo(Attribute);
