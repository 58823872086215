import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IProductsAttribues, IProductAttributesByProductId, SetProductDefaultAttributePayload } from './types';

const prefix = '/products';

export const addProductsAttributes = createAsyncThunk<void, {body: IProductsAttribues; id: string}, {
  rejectValue: AxiosData;
}>(
  'productsAttributes/add',
  async ({ body, id }, thunkAPI) => {
    try {
      await http.put<IProductsAttribues>(`${prefix}/${id}/attributes`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllAttributesByProductId = createAsyncThunk<IProductAttributesByProductId, string, {
  rejectValue: AxiosData;
}>(
  'productsAttributes/all',
  async (id, thunkAPI) => {
    try {
      // eslint-disable-next-line max-len
      const { data: { data } } = await http.get<AxiosResponse<IProductAttributesByProductId>>(`${prefix}/${id}/attributes`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getDefaultAttributesByProductId = createAsyncThunk<IProductAttributesByProductId, string, {
  rejectValue: AxiosData;
}>(
  'productDefaultAttributes/all',
  async (id, thunkAPI) => {
    try {
      // eslint-disable-next-line max-len
      const { data: { data } } = await http.get<AxiosResponse<IProductAttributesByProductId>>(`${prefix}/${id}/attributes`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const setProductsDefaultAttribute = createAsyncThunk<void, {
  body: SetProductDefaultAttributePayload; id: string;
}, { rejectValue: AxiosData}>(
  'productDefaultAttribute/set',
  async ({ body, id }, thunkAPI) => {
    try {
      await http.patch<SetProductDefaultAttributePayload>(`${prefix}/${id}/attributes/default`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
