import * as yup from 'yup';
import { BillingInfo } from '@features/customers/customers/types';
import {
  EmailSchema,
  createRequiredTextWidthValidation,
  createTextWidthValidation,
  optionalEmailValidation,
  optionalPasswordValidation,
} from '@utils/schemas';

export interface IAddDataForm {
  id: string;
  email: string;
  created: string;
  IPAddress: string;
  status: string;
  affiliateRegistrationLink: string;
  whereYouHear: string;
  activatePaymentTerms: boolean;
  isReseller: boolean;
  resellerCertificate: string | null;
  isNonProfit: boolean;
  nonProfitCertificate: string | null;
  hideGeneralProducts: boolean;
  canSendFile: boolean;
  additionalEmail: string;
  commentsOrNotes: string;
  firstName: string;
  lastName: string;
  billingInfo: BillingInfo;
  password?: string;
  banned: boolean;
}

export const booleanSchema = (fieldName: string) => {
  return yup
    .boolean()
    .transform((value, originalValue) => {
      if (originalValue === '' || originalValue === null) {
        return null;
      }

      return Boolean(originalValue);
    })
    .nullable()
    .oneOf([true, false, null], `${fieldName} must be either true, false, or not set`)
    .required(`${fieldName} is required`);
};

export const AddDataSchema = yup.object().shape({
  status: createRequiredTextWidthValidation('Customer status', 255),
  additionalEmail: optionalEmailValidation(),
  email: EmailSchema,
  commentsOrNotes: createTextWidthValidation(255),
  password: optionalPasswordValidation(),
  isReseller: createRequiredTextWidthValidation('Is a Reseller', 255),
  isNonProfit: createRequiredTextWidthValidation('Is a Non-Profit', 255),
  activatePaymentTerms: yup.boolean().required(),
});

export const statusOptions = [
  { value: 'active',
    optionName: 'Active',
  },
  { value: 'disabled',
    optionName: 'Disabled',
  },
];

export const formattedPayload = (data: IAddDataForm) => {
  const payload = { ...data };

  if (!data.password) {
    delete payload.password;
  }

  return ({
    ...payload,
    additionalEmail: data.additionalEmail || null,
  });
};
