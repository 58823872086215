import { memo, useState } from 'react';

import { useAppDispatch } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import { VendorCreationPayload } from '@features/vendors/types';
import { getVendorByIdThunk } from '@features/vendors/actions';

import InputsTable from '../components/InputsTable';
import { prepareEditFormData } from '../components/InputsTable/helpers';

const EditVendor = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vendorsData, setVendorsData] = useState<VendorCreationPayload | undefined>(undefined);

  useMount(() => {
    if (!id) {
      navigate(-1);
    }

    setIsLoading(true);
    dispatch(getVendorByIdThunk(id))
      .unwrap()
      .then((data) => {
        setVendorsData(prepareEditFormData(data));
        setIsLoading(false);
      });
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <InputsTable vendorsData={vendorsData} />
  );
};

export default memo(EditVendor);
