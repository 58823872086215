import { memo, useCallback, useEffect } from 'react';

import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import Box from '@mui/material/Box';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import { deleteTemplate, reorderTemplates, searchTemplates } from '@features/templates/actions';
import { selectTemplates } from '@features/templates/selectors';
import Typography from '@mui/material/Typography';
import EditCell from '@containers/common/Table/components/EditCell';
import { nestedDragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { useLocation } from 'react-router-dom';
import { resetTemplates, setTemplates } from '@features/templates/slice';
import useMount from '@customHooks/useMount';
import { getTemplateCategoriesHavingTemplates } from '@features/templateCategories/actions';
import { GlobalQueryString } from '@utils/globalTypes';
import { resetTemplateCategories } from '@features/templateCategories/slice';
import TCell from '@containers/common/Table/components/TCell';
import { usePrevScroll } from "@utils/prevState";

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const Templates = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', templateCategory: templateCategoryQuery = '' } = params as IFiltersForm;
  const isSearchTerm = searchTerm || templateCategoryQuery;
  const query = {
    searchTerm,
    templateCategory: templateCategoryQuery,
  };

  useMount(() => {
    dispatch(getTemplateCategoriesHavingTemplates());

    return () => {
      dispatch(resetTemplates());
      dispatch(resetTemplateCategories());
    };
  });

  const fetchData = useCallback(() => {
    dispatch(searchTemplates(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, templateCategoryQuery]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, templateCategoryQuery],
  );

  const deleteAction = (id: string) => {
    dispatch(deleteTemplate(id)).unwrap().then(() => {
      fetchData();
    }).catch(() => {});
  };

  const { data: templatesList, isLoading } = useAppSelector(selectTemplates);

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = nestedDragSort(result, templatesList, 'templates');

    if (sortedData) {
      dispatch(reorderTemplates(sortedData)).unwrap().then(() => dispatch(setTemplates(items)))
        .catch(() => fetchData());
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Templates" btnName="Add Template" path={PAGE_ROUTES.ADD_TEMPLATE} />
      {(isSearchTerm || !!templatesList?.length) && <SearchSection />}
      {templatesList?.length ? templatesList.map(({ id: templateId, title, templates }) => (
        <Box mb="32px" key={templateId}>
          <Typography variant="h5" mb="16px">{ title}</Typography>
          <DndContainer reordingData={reordingData}>
            <StyledTable headCells={headCells}>
              {templates.map(({ name, id, templateCategory }, index) => (
                <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 282px  140px 70px">
                  {({ providedDraggable }) => (
                    <>
                      <EditCell title={name} path="EDIT_TEMPLATE" params={{ id }} />
                      <TCell width="282px" text={templateCategory ? templateCategory.name : 'None'} />
                      <DndBtn providedDraggable={providedDraggable} />
                      <DeleteBtn
                        deleteAction={() => deleteAction(id)}
                        questionText="Are you sure you want to delete this template ?"
                      />
                    </>
                  )}
                </ReusableDragRow>
              ))}
            </StyledTable>
          </DndContainer>
        </Box>
      )) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any template'}
          isAdd={!searchTerm}
        />
      )}
    </>
  );
};

export default memo(Templates);
