import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import { selectCategories } from '@features/categories/selectors';
import EmptyState from '@containers/common/EmptyState';
import useMount from '@customHooks/useMount';
import { getAllCategories } from '@features/categories/actions';
import { getAllShippingZips } from '@features/shippingZip/actions';
import { selectShippingZips } from '@features/shippingZip/selectors';

import InputsTable from '../..';

const AddProductCategory = () => {
  const { isLoading, allData: categories } = useAppSelector(selectCategories);
  const { isLoading: shippingZipsLoading, data: shippingData } = useAppSelector(selectShippingZips);
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(getAllCategories());
    dispatch(getAllShippingZips());
  });
  if ((!categories && isLoading) || (!shippingData && shippingZipsLoading)) {
    return <Loader />;
  }

  return categories?.length ? (
    <InputsTable />
  ) : (<EmptyState text="You don’t have any categories" isAdd />);
};

export default AddProductCategory;
