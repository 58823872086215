import { createSlice } from "@reduxjs/toolkit";

import { getConversationLastMessage, getConversations } from "./actions";
import { IState } from "./types";

const initialState: IState = {
  message: '',
  conversation: [],
  initLoader: true,
  lastMessage: '',
};

const conversationSlice = createSlice({
  name: "conversationSlice",
  initialState,
  reducers: {
    addNewMessage: (state, { payload }) => {
      state.conversation = [...state.conversation, payload];
      state.message = '';
    },
    clearMessages: (state) => {
      state.conversation = [];
      state.initLoader = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConversationLastMessage.fulfilled, (state, { payload }) => {
      if (payload?.length) {
        state.lastMessage = payload[0].message;
      }
    });
    builder.addCase(getConversations.fulfilled, (state, { payload }) => {
      if (!payload.length) {
        state.initLoader = false;
        state.message = 'There are no more comments.';

        return state;
      }

      const old = state.conversation;
      const newItems = [...payload].reverse();
      const newArray = [...newItems, ...old];

      if (!state.conversation?.length) {
        state.initLoader = false;
      }

      state.message = '';
      state.conversation = newArray;
    });
    builder.addCase(getConversations.rejected, (state) => {
      state.message = 'Something went wrong';
    });
  },
});

export const { clearMessages, addNewMessage } = conversationSlice.actions;
export default conversationSlice.reducer;
