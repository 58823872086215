import { memo } from 'react';

import { usePrevScroll } from '@utils/prevState';

import CustomEstimates from '../CustomEstimates';

const CompleteEstimates = () => {
  usePrevScroll();

  return (
    <CustomEstimates />
  );
};

export default memo(CompleteEstimates);
