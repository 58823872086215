// import { dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  searchTerm?: string;
  limit?: string | number;
  offset?: string | number;
}

export const FiltersSchema = yup.object().shape({
  dateFrom: yup.string().optional(), // dateSchema('Date from is required', false),
  dateTo: yup.string().optional(), // dateSchema('Date to is required', false),
  status: yup.string().optional(),
  searchTerm: yup.string().optional(),
});

export const statusOptions = [
  {
    optionName: 'Open',
    value: 'open',
  }, {
    optionName: 'Closed',
    value: 'closed',
  },
];
