import { integerSchema, numberSchema } from '@utils/schemas';
import * as yup from 'yup';

export const headCells = [
  {
    label: 'QUANTITY',
  },
  {
    label: 'LEVEL PRICE',
  },
  {
    label: 'PER UNIT',
  },
  {
    label: 'WAFER LEVEL',
  },
  {
    label: 'Actions',
    colSpan: 2,
  },
];

export interface IMailingServiceFees {
  id: string;
  created?: string;
  updated?: string;
  deleted?: string;
  quantity: number;
  levelPrice: number;
  perUnit: number;
  waferLevel: number;
}

export interface IAddDataForm {
  mailingServiceData: IMailingServiceFees[] | null;
}

export const AddDataSchema = yup.object().shape({
  mailingServiceData: yup.array().of(
    yup.object({
      quantity: integerSchema.required('Quantity is required').max(999999999, 'The maximum number of digits is 9'),
      levelPrice: numberSchema.required('Level price is required').max(999999999, 'The maximum number of digits is 9'),
      perUnit: numberSchema.required('Per unit is required').max(999999999, 'The maximum number of digits is 9'),
      waferLevel: numberSchema.required('Wafer level is required').max(999999999, 'The maximum number of digits is 9'),
    }),
  ),
});

export const fieldNames = [
  {
    label: 'Quantity',
    field: 'quantity',
  },
  {
    label: 'Level Price',
    field: 'levelPrice',
  },
  {
    label: 'Per Unit',
    field: 'perUnit',
  },
  {
    label: 'Wafer Level',
    field: 'waferLevel',
  },
];
