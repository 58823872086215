import Typography from '@mui/material/Typography';
import { ConfirmOptions } from 'material-ui-confirm';
import Stack from '@mui/material/Stack';
import WarningIcon from '@containers/common/Icons/WarningIcon';

import Button from '../Button';

interface IconfirmOptionsDialog {
  questionText: string;
  warningText?: string;
}

const confirmOptionsDialog = ({ questionText, warningText }: IconfirmOptionsDialog): ConfirmOptions => {
  return {
    title: '',
    content: (
      <>
        {warningText && (
          <Stack direction="row" gap="8px" justifyContent="center" mb="24px">
            <WarningIcon />
            <Typography variant="subtitle2" align="center">
              Warning
            </Typography>
          </Stack>
        )}
        <Typography variant="subtitle2" align="center">
          {questionText}
        </Typography>
        {warningText && (
        <Typography variant="body1" align="center" mt="16px">
          {warningText}
        </Typography>
        )}
      </>
    ),
    dialogProps: {
      maxWidth: 'xs',
      fullWidth: true,
      BackdropProps: {
        style: {
          backgroundColor: '#06152B40',
        },
      },
      PaperProps: {
        sx: {
          maxWidth: '308px',
          padding: '40px 32px 32px',
          margin: 0,
          borderRadius: '10px',
          '.MuiDialogContent-root': {
            padding: 0,
            marginBottom: '24px',
          },
          '.MuiDialogActions-root': {
            display: 'flex',
            padding: 0,
            justifyContent: 'center',
            button: {
              width: '120px',
              padding: 0,
            },
          },
        },
      },
    },
    cancellationText: <Button isOutlined>Cancel</Button>,
    confirmationText: <Button>Yes</Button>,
  };
};

export default confirmOptionsDialog;
