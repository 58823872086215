import { memo, useCallback } from 'react';

import StyledTable from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import { deleteContactFormMessages, getContactFormMessages } from '@features/contactFormMessages/actions';
import { resetContactFormMessages } from '@features/contactFormMessages/slice';
import { formattedDate } from '@utils/helpers';
import { selectContactFormMessages } from '@features/contactFormMessages/selectors';
import TCell from '@containers/common/Table/components/TCell';
import { usePrevScroll } from "@utils/prevState";

import { headCells } from './tableData';

const ContactFormMessage = () => {
  const dispatch = useAppDispatch();

  const { data: contactFormMessages, isLoading } = useAppSelector(selectContactFormMessages);
  const deleteAction = useCallback((id: string) => {
    dispatch(deleteContactFormMessages(id)).unwrap().finally(() => dispatch(getContactFormMessages()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    dispatch(getContactFormMessages());

    return () => dispatch(resetContactFormMessages());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Contact Form Messages" isShowBtn={false} />

      {contactFormMessages?.length ? (
        <StyledTable headCells={headCells}>
          { contactFormMessages.map(({ id, email, name, message, created, subject }) => (
            <StyledTableRow key={id}>
              <TCell width="220px" text={formattedDate(created)} />
              <TCell width="220px" text={name} />
              <TableCell width="220px">
                <Link href={`mailto:${email}?subject=${subject}`}>
                  {email}
                </Link>
              </TableCell>
              <TCell width="220px" text={subject} />
              <TCell width="440px" text={message} />
              <DeleteBtn
                deleteAction={() => deleteAction(id)}
                questionText="Are you sure you want to delete this message ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text="You don’t have any contact form messages" />
      )}
    </>
  );
};

export default memo(ContactFormMessage);
