export interface IState {
  isLoading: boolean;
  actionLoading: boolean;
  data: IDeveloperTask[] | null;
  errorMessage: string | null;
}

export interface IDeveloperTask {
  id: string;
  created: string;
  updated: string;
  deleted: string;
  title: string;
  status: string;
  priority: string;
  deliveryDate: string;
  attachment: string | null;
  complatedDate: string;
  description: string;
  user: User;
}

export interface IDeveloperTaskFormData {
  id?: string;
  title: string;
  description: string | null;
  status: string;
  priority: string;
  attachment: string | null;
  deliveryDate: any;
}

export interface IDeveloperTaskPayload extends Omit<IDeveloperTaskFormData, 'attachment'> {
  attachment: string[] | null;
}

export interface Creator {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
}

export interface User {
  id: string;
  created: string;
  updated: string;
  deleted: string;
  email: string;
  password: string;
  username: string;
  firstName: string;
  lastName: string;
  isVerified: boolean;
}

export interface ISearchDeveloperTasksPayload {
  searchTerm?: string;
}

export interface IGetDeveloperTaskPayload {
  id: string;
}

export enum DeveloperTaskStatus {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  AWAITING_REVIEW = 'Awaiting review',
  CLOSED = 'Closed',
}

export enum DeveloperTaskPriority {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}
