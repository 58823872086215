import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  addCategory, editCategory, getAllCategories, getCategoriesHavingSubcategories, getCategoryById, searchCategories,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  allData: null,
  dataHavingSubs: null,
  errorMessage: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      state.data = payload;
    },
    resetCategories: () => initialState,

  },
  extraReducers: (builder) => {
    builder.addCase(addCategory.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addCategory.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(editCategory.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(editCategory.fulfilled, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(editCategory.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, { payload }) => {
      state.allData = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCategoryById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategoryById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCategoryById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchCategories.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchCategories.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(getCategoriesHavingSubcategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategoriesHavingSubcategories.fulfilled, (state, { payload }) => {
      state.dataHavingSubs = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getCategoriesHavingSubcategories.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });
  },
});

export const { setCategories, resetCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
