export const headCells = [
  {
    label: 'LOGO',
  },

  {
    label: 'NAME',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
