import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getContactFormMessages } from './actions';

const initialState: IState = {
  isLoading: true,
  data: null,
  errorMessage: null,
  actionLoading: false,
};

const contactFormMessagesSlice = createSlice({
  name: 'contactFormMessages',
  initialState,
  reducers: {
    resetContactFormMessages: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getContactFormMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getContactFormMessages.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getContactFormMessages.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { resetContactFormMessages } = contactFormMessagesSlice.actions;

export default contactFormMessagesSlice.reducer;
