import Loader from '@containers/common/Loader';
import { Box } from '@mui/material';
import EmptyState from '@containers/common/EmptyState';

import { headCells } from '../../tableData';
import VendorItemInList from './VendorItemInList';
import { useVendorsListLogic } from './useVendorsListLogic';
import { StyledTable } from './styled'; // LoadButton

const VendorsListSection = ({ isSearchTerm }: { isSearchTerm: string }) => {
  const {
    data: vendorsList,
    isLoading,
    handleAllVendorsRequest,
  } = useVendorsListLogic();

  if (isLoading) {
    return <Loader />;
  }

  const missingVendorsMessage = 'You don’t have any Vendors';

  return (
    <Box>
      {
        isSearchTerm && !vendorsList?.length ? (
          <EmptyState text={isSearchTerm ? 'No search results found' : missingVendorsMessage} />
        ) : vendorsList?.length
          ? (
            <StyledTable
              title=""
              headCells={headCells}
            >
              {vendorsList.map(({
                id,
                name,
                carrierUsed,
                excludeFromPOReport,
              }) => {
                return (
                  <VendorItemInList
                    key={id}
                    id={id}
                    name={name}
                    carrierUsed={carrierUsed}
                    excludeFromPOReport={excludeFromPOReport}
                    handleAllVendorsRequest={handleAllVendorsRequest}
                  />
                );
              })}
            </StyledTable>
          ) : <EmptyState text={missingVendorsMessage} />
      }
    </Box>
  );
};

export default VendorsListSection;
