import { integerSchema, positiveNumberSchema } from '@utils/schemas';
import * as yup from 'yup';

export const unitDisplayList = [
  { value: 'ft',
    optionName: 'Foot',
  },
  { value: 'in',
    optionName: 'Inch',
  },
];

export const valCases = {
  isGreaterThanWidth: 'Min width is greater than Max Width',
  isGreaterThanHeight: 'Min Height is greater than Max Height',
};

const grandFormatDiscountsSchema = yup.object({
  quantity: integerSchema.required('Quantity is required').max(999999999, 'The maximum number of digits is 9'),
  discountPercent: positiveNumberSchema.required('Discount percent is required'),
});

export const GrandFormatOptionsSchema = yup.object({
  minWidth: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required('Width from is required'),
  maxWidth: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required('Width to is required'),
  minHeight: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required('Height from is required'),
  maxHeight: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required('Height to is required'),
  grandFormatDiscounts: yup.array().of(grandFormatDiscountsSchema).nullable(),
}).nullable();
