import BannedIps from '@containers/Administration/BannedIps';
import PromoCodes from '@containers/Administration/PromoCodes';
import AddPromoCode from '@containers/Administration/PromoCodes/AddComponent';
import EditPromoCode from '@containers/Administration/PromoCodes/EditComponent';
import ShippingRateMarkup from '@containers/Administration/ShippingRateMarkup';
import ShippingZips from '@containers/Administration/ShippingZips';
import Tasks from '@containers/Administration/Tasks';
import DevelopersTasks from '@containers/Administration/DevelopersTasks';
import AddDevelopersTasks from '@containers/Administration/DevelopersTasks/AddComponent';
import EditDevelopersTasks from '@containers/Administration/DevelopersTasks/EditComponent';
import Users from '@containers/Administration/Users';
import AddUser from '@containers/Administration/Users/AddComponent';
import EditUser from '@containers/Administration/Users/EditComponent';
import AddTask from '@containers/Administration/Tasks/AddComponent';
import EditTask from '@containers/Administration/Tasks/EditComponent';
import AddShippingZip from '@containers/Administration/ShippingZips/AddComponent';
import EditShippingZip from '@containers/Administration/ShippingZips/EditComponent';
import PAGE_ROUTES from '@routes/routingEnum';
import { Navigate } from 'react-router-dom';
import AdminLogIn from '@containers/Administration/AdminLogIn';

const AdministrationRoutes = {
  path: PAGE_ROUTES.ADMINISTRATION,
  children: [
    { element: <Navigate to={PAGE_ROUTES.TASKS} />, index: true },
    { path: PAGE_ROUTES.BANNED_IPS, element: <BannedIps />, index: true },
    {
      path: PAGE_ROUTES.DEVELOPERS_TASKS,
      children: [
        { element: <DevelopersTasks />, index: true },
        { path: PAGE_ROUTES.ADD_DEVELOPER_TASKS, element: <AddDevelopersTasks /> },
        { path: PAGE_ROUTES.EDIT_DEVELOPER_TASKS, element: <EditDevelopersTasks /> },
      ],
    },
    {
      path: PAGE_ROUTES.TASKS,
      children: [
        { element: <Tasks />, index: true },
        { path: PAGE_ROUTES.ADD_TASK, element: <AddTask /> },
        { path: PAGE_ROUTES.EDIT_TASK, element: <EditTask /> },
      ],
    },
    {
      path: PAGE_ROUTES.USERS,
      children: [
        { element: <Users />, index: true },
        { path: PAGE_ROUTES.ADD_USER, element: <AddUser /> },
        { path: PAGE_ROUTES.EDIT_USER, element: <EditUser /> },
      ],
    },
    {
      path: PAGE_ROUTES.PROMO_CODES,
      children: [
        { element: <PromoCodes />, index: true },
        { path: PAGE_ROUTES.ADD_PROMO_CODE, element: <AddPromoCode /> },
        { path: PAGE_ROUTES.EDIT_PROM_CODE, element: <EditPromoCode /> },
      ],
    },
    { path: PAGE_ROUTES.PROMO_CODES, element: <PromoCodes /> },
    {
      path: PAGE_ROUTES.SHIPPING_ZIPS,
      children: [
        { element: <ShippingZips />, index: true },
        { path: PAGE_ROUTES.ADD_SHIPPING_ZIP, element: <AddShippingZip /> },
        { path: PAGE_ROUTES.EDIT_SHIPPING_ZIP, element: <EditShippingZip /> },
      ],
    },
    { path: PAGE_ROUTES.SHIPPING_RATE_MARKUP, element: <ShippingRateMarkup /> },
    { path: PAGE_ROUTES.ADMIN_LOG_IN, element: <AdminLogIn /> },
  ],
};

export default AdministrationRoutes;
