export enum InvoiceStatusEnum {
  PAID = "paid",
  UNPAID = "unpaid",
  CANCELLED = "cancelled",
}

export enum InvoicePageMessageEnum {
  invoiceCreateWithEmail = "Invoice has been created and sent to the customer via email.",
  invoiceCreate = "Invoice has been created.",
  invoiceUpdateWithEmail = "The invoice has been edited and sent to the customer via email.",
  invoiceUpdate = "The invoice has been edited",
}

export interface IState {
  isLoading: boolean;
  data: IInvoice[] | [];
  total: number;
  actionLoading: boolean;
  loadMoreLoading: boolean;
  errorMessage: string | null;
  isHiddenLoadMoreButton: boolean;
  openJobsSelectedRows: string[];
}

export interface IInvoiceResponse {
  invoices: IInvoice[];
  total: number;
}

export interface IInvoice {
  id: string;
  created: string;
  updated: any;
  deleted: any;
  invoiceNumber: string;
  invoiceStatus: InvoiceStatusEnum;
  orderNumber: number;
  orderDate: string;
  dateBilled: any;
  customerId: string;
  customer: {
    id: string;
    firstName: string;
    lastName: string;
  };
  transactionId: any;
  orderId: string;
  itemDescription: string;
  itemPrice: number;
  tax: number;
  subtotal: number;
  paymentDetails: any;
  paymentMethod: any;
  orderStatus: string;
  billingStatus: string;
  paymentURLForCustomer: any;
  emailInvoiceToCustomer: boolean;
  file: any;
  isAdditional: boolean;
}
