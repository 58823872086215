import { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import { selectVendors } from '@features/vendors/selectors';
import { getVendorsThunk } from '@features/vendors/actions';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useConfirm } from 'material-ui-confirm';
import createdSuccessOptionsDialog from '@containers/common/CreatedSuccess';
import PAGE_ROUTES from '@routes/routingEnum';

export const useVendorsListLogic = () => {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [offsetCount, setOffsetCount] = useState(0);
  const params = queryString.parse(search) as GlobalQueryString;

  const { data, isLoading, loadMoreLoading, isHiddenLoadMoreButton } = useAppSelector(selectVendors);

  const handleAllVendorsRequest = async () => {
    setOffsetCount(0);

    try {
      const { created, ...restParams } = params;
      const collectedParams = { ...restParams, offset: 0, limit: 8 };

      await dispatch(getVendorsThunk(collectedParams));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const startLoadMoreRequest = async () => {
    // then need to do load more functions
    // await dispatch(setRequestType(OpenOrdersRequestTypeEnum.loadMore));
    // await setOffsetCount((prev) => prev + 1);
  };

  const handleCheckCreatedAction = async () => {
    const { created } = params;

    if (created) {
      navigate(PAGE_ROUTES.VENDORS);

      // eslint-disable-next-line max-len
      await confirm(createdSuccessOptionsDialog({ text: 'The new vendor has been created' }))
        .then(() => {
          // console.log(111);
        })
        .catch(() => {
          // console.log(222);
        })
        .finally(() => {
          // console.log(333);
        });

      // console.log(4444);
    }
  };

  useEffect(() => {
    handleCheckCreatedAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleAllVendorsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, offsetCount]);

  return {
    data,
    isLoading,
    loadMoreLoading,
    isHiddenLoadMoreButton,
    startLoadMoreRequest,
    handleAllVendorsRequest,
  };
};
