import { memo } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@containers/common/Button';
import { useAppDispatch } from '@features/app/hooks';
import { declineCustomEstimates } from '@features/customEstimates/actions';
import { useNavigate, useParams } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import { getEstimatesCount } from '@features/sidebarCounts/actions';

const Reactivate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const decline = () => {
    dispatch(declineCustomEstimates(id)).unwrap().then(() => {
      dispatch(getEstimatesCount());

      navigate(PAGE_ROUTES.REACTIVATED_ESTIMATES);
    }).catch(() => {});
  };

  return (
    <Stack direction="row" gap="12px" mb="12px">
      <Button width="120px" isOutlined onClick={decline}>Decline</Button>
      <Button width="120px" type="submit">Reactivate</Button>
    </Stack>
  );
};

export default memo(Reactivate);
