import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Company',
    field: 'company',
    type: InputTypes.text,
  },
  {
    label: 'First Name',
    field: 'firstName',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Last Name',
    field: 'lastName',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Address 1',
    field: 'address1',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Address 2',
    field: 'address2',
    type: InputTypes.text,
  },

  {
    label: 'City',
    field: 'city',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Country',
    field: 'country',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'State',
    field: 'state',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Zip Code',
    field: 'zipCode',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Phone Number',
    field: 'phone',
    type: InputTypes.text,
    isRequired: true,
  },
];
