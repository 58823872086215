import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import { getEmailLogs } from '@features/customEstimates/actions';
import { IEmailLog } from '@features/customEstimates/types';
import { selectCustomEstimates } from '@features/customEstimates/selectors';

import InputsTable from './LogsContainer';
import { getCurrentPage, getPath } from '../helpers';

const ViewEmailLog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id = '' } = useParams();
  const { pathname } = location;
  const pageName = getCurrentPage(pathname);
  const path = getPath(pageName, true);
  const { isLoading } = useAppSelector(selectCustomEstimates);

  const [logsData, setLogsData] = useState<IEmailLog[] | null>(null);

  useMount(() => {
    dispatch(getEmailLogs(id)).unwrap().then((data) => {
      setLogsData(data);
    }).catch(() => navigate(path));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {logsData && <InputsTable logsData={logsData} />}
    </>
  );
};

export default memo(ViewEmailLog);
