export const headCells = [
  {
    label: 'DATE',
  },
  {
    label: 'IP',
  },
  {
    label: 'ACTIONS',
  },
];
