import { useEffect, useLayoutEffect, useState } from "react";

export const PREV_STATE_DATA = 'PREV_STATE_DATA';

export const PrevState = {
  getAll() {
    const data = localStorage.getItem(PREV_STATE_DATA);

    if (!data) {
      return;
    }

    return JSON.parse(data);
  },
  set(value: Record<string, any>) {
    const all = this.getAll() || {};

    localStorage.setItem(
      PREV_STATE_DATA,
      JSON.stringify({ ...all, ...value }),
    );
  },
  isDo(props: boolean) {
    const all = this.getAll() || {};

    localStorage.setItem(
      PREV_STATE_DATA,
      JSON.stringify({ ...all, isDo: props }),
    );
  },
  getByKey(key: string) {
    const data = localStorage.getItem(PREV_STATE_DATA);

    if (!data) {
      return;
    }

    return JSON.parse(data)[key];
  },
  reset() {
    localStorage.removeItem(PREV_STATE_DATA);
  },
};

interface PrevStateProps {
  data?: any;
}

export function usePrevToggled({ data }: PrevStateProps) {
  const [state, setState] = useState();

  useEffect(() => {
    if (PrevState.getByKey('scroll')) {
      setState(PrevState.getByKey('collection'));

      return;
    }

    PrevState.set({ collection: data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { state };
}

export function usePrevScroll() {
  useLayoutEffect(() => {
    if (
      PrevState.getByKey('isDo') &&
      PrevState.getByKey('scroll')
    ) {
      window.scrollTo({
        top: PrevState.getByKey('scroll'),
      });
    }
  });

  useEffect(() => {
    const onScroll = function () {
      if (window.scrollY === PrevState.getByKey('scroll')) {
        window.removeEventListener('scroll', onScroll);
        PrevState.set({ isDo: false, scroll: 0 });
      }
    };

    window.addEventListener('scroll', onScroll);
  }, []);
}
