import { memo, useEffect, useState } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import { selectMailingServiceFees } from '@features/mailingServiceFees/selectors';
import { getAllMailingServiceFees } from '@features/mailingServiceFees/actions';
import { resetMailingServiceFees } from '@features/mailingServiceFees/slice';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';

import { AddDataSchema, IAddDataForm, headCells } from './helpers';
import FirstRow from './FirstRow';
import TableRow from './TableRow';

const MailingServiceFees = () => {
  const dispatch = useAppDispatch();
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const { data: mailingServiceFees, isLoading } = useAppSelector(selectMailingServiceFees);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: {
      mailingServiceData: mailingServiceFees,
    },
  });

  const { watch, setValue } = methods;

  useEffect(() => {
    setValue('mailingServiceData', mailingServiceFees);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailingServiceFees]);

  const { mailingServiceData } = watch();

  useMount(() => {
    dispatch(getAllMailingServiceFees());

    return () => dispatch(resetMailingServiceFees());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Mailing Service Fees"
        isShowBtn={false}
      />
      <FormProvider {...methods}>
        <StyledTable headCells={headCells}>
          <FirstRow />
          { mailingServiceData?.map(({ id }, idx) => (
            <TableRow key={id} idx={idx} id={id} setIsSuccessMsg={setIsSuccessMsg} />
          ))}
        </StyledTable>
        <GlobalUpdatesMessage {...isSuccessMsg} />
      </FormProvider>
    </>
  );
};

export default memo(MailingServiceFees);
