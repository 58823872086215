import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const EmailsContainer = styled(Box)(() => ({
}));

export const HeaderSection = styled(Box)(() => ({
  marginTop: '32px',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 120px',
}));

export const ListWrapper = styled(Box)(() => ({
  marginTop: '24px',
}));

export const Item = styled(Box)(() => ({
  marginBottom: '24px',
  paddingBottom: '24px',
  borderBottom: '2px dashed #d7d7d7',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
}));

export const Description = styled(Typography)(() => ({
  fontSize: '12px',
  marginTop: '16px',
}));

export const PageTitle = styled(Typography)(() => ({
  fontSize: '18px !important',
}));
