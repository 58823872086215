import { useEffect, useState } from "react";

import * as yup from 'yup';
import { GrandFormatOptions } from "@features/products/basicInfo/types";
import { useFormContext } from "react-hook-form";

import { DiscountSchemaNullable, DiscountSchemaRequired, IDiscountSchema } from "./helpers";

export const useGrandFormatDiscountsLogic = () => {
  const { setValue, watch } = useFormContext();
  const initialState = { quantity: '', discountPercent: '' };
  const [collection, setCollection] = useState(initialState);

  const [validMessages, setValidMessages] = useState<IDiscountSchema>(initialState);

  const grandFormatOptions = watch('grandFormatOptions') as GrandFormatOptions;
  const options = grandFormatOptions?.grandFormatDiscounts?.filter((item) => item.quantity) || [];

  const handleAdd = async () => {
    handleValidation('onSubmit');
  };

  const handleRemove = (index: number) => {
    const filtered = options.filter((_, i) => i !== index);

    setValue('grandFormatOptions.grandFormatDiscounts', filtered);
  };

  const handleValidation = async (type: 'onSubmit' | 'onChange') => {
    try {
      if (type === 'onSubmit') {
        const found = options.find(({ quantity }) => {
          // eslint-disable-next-line eqeqeq
          return quantity == +collection.quantity;
        });

        if (found) {
          setValidMessages((prev) => ({
            ...prev,
            quantity: 'This quantity already exists',
          }));

          return;
        }

        await DiscountSchemaRequired.validate(collection, { abortEarly: false });

        setValue('grandFormatOptions.grandFormatDiscounts', [...options, collection]);
        setCollection(initialState);
      } else {
        const validateObj = await DiscountSchemaNullable.validate(collection, { abortEarly: false });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const coll = Object.fromEntries(Object.entries(validateObj).filter(([_, value]) => value));

        if (coll.quantity) {
          setValidMessages((prev) => ({ ...prev, quantity: '' }));
        }

        if (coll.discountPercent) {
          setValidMessages((prev) => ({ ...prev, discountPercent: '' }));
        }
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationMessages = err.inner.map((error) => ({
          path: error.path,
          message: error.message,
        }));

        validationMessages.forEach((item) => {
          setValidMessages((prev) => ({ ...prev, [item.path as keyof IDiscountSchema]: item.message }));
        });

        // eslint-disable-next-line no-console
        console.log('Validation errors:', validationMessages);
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected error:', err);
      }
    }
  };

  useEffect(() => {
    handleValidation('onChange');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection.quantity, collection.discountPercent]);

  return {
    collection,
    options,
    handleAdd,
    validMessages,
    handleRemove,
    setCollection,
  };
};
