import { memo, useState } from 'react';

import Loader from '@containers/common/Loader';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectShippingMarkupRate } from '@features/shippingMarkupRate/selectors';
import { getAllShippingMarkupRate } from '@features/shippingMarkupRate/actions';
import useMount from '@customHooks/useMount';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';

import { IShippingRateFormState, IShippingRatesIds } from './FirstRateInputsTable/types';
import FirstRateInputsTable from './FirstRateInputsTable';

const ShippingRateMarkup = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectShippingMarkupRate);
  const [editData, setEditData] = useState<IShippingRateFormState | null>();
  const [rateIds, setRateIds] = useState<IShippingRatesIds>({} as IShippingRatesIds);

  useMount(() => {
    dispatch(getAllShippingMarkupRate())
      .unwrap()
      .then((list) => {
        const ratesWithIds = list.reduce((acc, next, index) => {
          const newObject = {
            ...acc,
            [`rate${index}`]: next.id,
          };

          return { ...newObject };
        }, {} as IShippingRatesIds);

        const formState = list.reduce((acc, next, index) => {
          const newObject = {
            ...acc,
            [`rate${index}`]: next.rate,
          };

          return { ...newObject };
        }, {} as IShippingRateFormState);

        setEditData(formState);
        setRateIds(ratesWithIds);
      })
      .catch(() => {
        setEditData(null);
      });
  });

  if (isLoading || editData === undefined) {
    return <Loader />;
  }

  return (
    <TitlesWithBackButton title="Shipping Markup Rate">
      <FirstRateInputsTable rateIds={rateIds} editData={editData} />
    </TitlesWithBackButton>
  );
};

export default memo(ShippingRateMarkup);
