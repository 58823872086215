// import { dateSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IFiltersForm {
  carrier?: string;
  POReport?: string;
  searchTerm?: string;
}

export const FiltersSchema = yup.object().shape({
  carrier: yup.string().optional(),
  POReport: yup.string().optional(),
  searchTerm: yup.string().optional(),
});
