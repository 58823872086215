import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import {
  IAddCustomEstimatePayload, ICustomEstimate, ICustomEstimateById, IEmailLog, ISearch,
} from './types';

const prefix = '/custom-estimates';

export const getAllCustomEstimates = createAsyncThunk<ICustomEstimate[], void, { rejectValue: AxiosData}>(
  'customEstimate/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ICustomEstimate[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getCustomEstimateById = createAsyncThunk<ICustomEstimateById, string, { rejectValue: AxiosData}>(
  'customEstimate/getCustomEstimate',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ICustomEstimateById>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editCustomEstimate = createAsyncThunk<void, IAddCustomEstimatePayload, { rejectValue: AxiosData}>(
  'customEstimate/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<
        IAddCustomEstimatePayload>(`${prefix}/${body.id}${body.status === 'reactivated' ? '/reactivate' : ''}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteCustomEstimate = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'customEstimate/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchCustomEstimates = createAsyncThunk<ISearch, Filters, { rejectValue: AxiosData}>(
  'customEstimate/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<ISearch>>(
        `${prefix}?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const declineCustomEstimates = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'customEstimate/decline',
  async (id, thunkAPI) => {
    try {
      await http.patch(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const getEmailLogs = createAsyncThunk<IEmailLog[], string, { rejectValue: AxiosData}>(
  'customEstimate/email-log',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IEmailLog[]>>(`${prefix}/${id}/email-history`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
