import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { selectShippingMarkupRate } from '@features/shippingMarkupRate/selectors';
import { updateShippingRate } from '@features/shippingMarkupRate/actions';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { inputsRows } from './tableData';
import { IShippingRateFormState, IShippingRatesIds } from './types';
import { AddDataSchema, EditDataSchema, defaultValues } from './helpers';
import { StyledRowComponent } from './styled';

interface IInputsTable {
  rateIds: IShippingRatesIds;
  editData?: IShippingRateFormState | null;
}

const FirstRateInputsTable = ({ rateIds, editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const { actionLoading } = useAppSelector(selectShippingMarkupRate);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const Schema = editData ? EditDataSchema : AddDataSchema;
  const methods = useForm<IShippingRateFormState>({
    resolver: yupResolver(Schema as any),
    defaultValues: editData ?? defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: IShippingRateFormState) => {
    const payload = {
      shippingRates: Object.keys(data).reverse().map((key) => {
        return {
          shippingRateId: rateIds[key as keyof IShippingRateFormState],
          rate: +data[key as keyof IShippingRateFormState] || 1,
        };
      }),
    };

    dispatch(updateShippingRate(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });
      })
      .catch(({ message }) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (message.length) {
          (message as string[]).forEach((mes) => {
            const number = mes.split('.')[1];
            const key = `rate${number}` as keyof IShippingRateFormState;

            methods.setError(key, { message: 'Should be a maximum of 100' });
          });
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <Typography mb={2}>% must be at least 1% for no markup at all.</Typography>
        <StyledStack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <StyledTable tableTitle="" colSpan={2}>
            {inputsRows.map((item) => ((
              <StyledRowComponent key={item.label} {...item}>
                <ReusableFields {...item} />
              </StyledRowComponent>
            )))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </Box>
    </FormProvider>
  );
};

export default memo(FirstRateInputsTable);
