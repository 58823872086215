import { memo, useCallback, useEffect } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import StyledTable from '@containers/common/Table';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { deleteCategory, reorderCategories, searchCategories } from '@features/categories/actions';
import { selectCategories } from '@features/categories/selectors';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import EditCell from '@containers/common/Table/components/EditCell';
import { useLocation } from 'react-router-dom';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { resetCategories, setCategories } from '@features/categories/slice';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import TCell from '@containers/common/Table/components/TCell';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const MenuCategories = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', visibleOnSite: visibleOnSiteQuery = '' } = params as IFiltersForm;
  const isSearchTerm = searchTerm || visibleOnSiteQuery;

  const fetchData = useCallback(() => {
    const query = {
      searchTerm,
      visibleOnSite: visibleOnSiteQuery,
    };

    dispatch(searchCategories(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleOnSiteQuery, searchTerm]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, visibleOnSiteQuery],
  );

  const deleteAction = (id: string) => {
    dispatch(deleteCategory(id)).unwrap().then(() => {
      fetchData();
    }).catch(() => {});
  };

  useUnMount(() => dispatch(resetCategories()));

  const { data: categories, isLoading } = useAppSelector(selectCategories);

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, categories);

    dispatch(reorderCategories(sortedData)).unwrap().then(() => {
      dispatch(setCategories(items));
    }).catch(() => fetchData());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Menu Categories" btnName="Add Menu Category" path={PAGE_ROUTES.ADD_MENU_CATEGORY} />
      { (isSearchTerm || !!categories?.length) && <SearchSection /> }
      {categories?.length ? (
        <DndContainer reordingData={reordingData}>
          <StyledTable headCells={headCells}>
            {categories.map(({ title, visibleOnSite, id }, index) => (
              <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 225px 140px 70px">
                {({ providedDraggable }) => (
                  <>
                    <EditCell title={title} path="EDIT_MENU_CATEGORY" params={{ id }} />
                    <TCell width="225px" text={visibleOnSite ? 'Yes' : 'No'} />
                    <DndBtn providedDraggable={providedDraggable} />
                    <DeleteBtn
                      deleteAction={() => deleteAction(id)}
                      questionText="Are you sure you want to delete this category ?"
                      warningText="All subcategories and products will be deleted as well."
                    />
                  </>
                )}
              </ReusableDragRow>
            ))}
          </StyledTable>
        </DndContainer>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any categories'}
          isAdd={!searchTerm}
        />
      )}
    </>
  );
};

export default memo(MenuCategories);
