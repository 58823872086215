import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { COLORS } from '@customTypes/global/theme/colors';

export const TasksContainer = styled(Box)(() => ({
  paddingTop: '24px',
}));

export const EditTaskIdLink = styled(Link)(() => ({
  textDecoration: 'underline',
  color: COLORS.blue.dark,
}));
