import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import StyledTable from '@containers/common/Table';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Input from '@containers/common/Input';
import StyledTypography from '@containers/common/StyledTypography';
import Stack from '@mui/material/Stack';

import { estimateSizeQuoteStatusOptions, inputsRows } from './helpers';

interface ISecondTable {
  tableIdx: number;
}

const SecondTable = ({ tableIdx }: ISecondTable) => {
  const { register, formState: { errors, defaultValues } } = useFormContext();

  const estimateSizeQuotes = defaultValues?.estimateSizeQuotes;

  return (
    <StyledTable tableTitle={`RUN SIZE ${estimateSizeQuotes[tableIdx].runSize} QUOTE`} colSpan={2}>
      <RowComponent label="Run Size" isRequired>
        <Stack direction="row" gap="24px">
          <Input
            placeholder="Run Size"
            width="165px"
            {...register(`estimateSizeQuotes[${tableIdx}].runSize`)}
            errorMessage={(errors as any)?.estimateSizeQuotes?.[tableIdx]?.runSize?.message}
          />
          <StyledTypography color="grey" variant="body3" width="130px">
            (No commas or special characters. i.e 25000)
          </StyledTypography>
        </Stack>
      </RowComponent>
      {inputsRows.map((item) => {
        const { field } = item;

        return (
          <RowComponent key={item.label} {...item}>
            <ReusableFields
              {...item}
              field={`estimateSizeQuotes[${tableIdx}].${field}`}
              selectList={[{
                field: `estimateSizeQuotes[${tableIdx}].status`,
                options: estimateSizeQuoteStatusOptions,
              }]}
              errorMessage={(errors as any)?.estimateSizeQuotes?.[tableIdx]?.[field]?.message}
            />
          </RowComponent>
        );
      })}
    </StyledTable>
  );
};

export default memo(SecondTable);
