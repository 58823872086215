import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { API_URL } from '@config/index';
import { ACCESS_TOKEN_KEY } from '@utils/constants';
import StorageManager from '@utils/storage-manager';

import {
  IProductsPayload, IProductsInfo, IProductsSearchInfo, IImportPricingPayload, Product,
} from './types';

const prefix = '/products';

export const addProduct = createAsyncThunk<void, IProductsPayload, {
  rejectValue: AxiosData;
}>(
  'products/add',
  async (body, thunkAPI) => {
    try {
      await http.post<IProductsPayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getAllProducts = createAsyncThunk<IProductsInfo[], void, {
  rejectValue: AxiosData;
}>(
  'products/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IProductsInfo[]>>(`${prefix}/all`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const copyProduct = createAsyncThunk<Product, string, { rejectValue: AxiosData }>(
  'products/copy',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.post<AxiosResponse<Product>>(`${prefix}/copy/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getProductById = createAsyncThunk<IProductsPayload, string, {
  rejectValue: AxiosData;
}>(
  'products/getProduct',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IProductsPayload>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editProduct = createAsyncThunk<void, IProductsPayload, {
  rejectValue: AxiosData;
}>(
  'products/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IProductsPayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteProduct = createAsyncThunk<void, string, {
  rejectValue: AxiosData;
}>(
  'products/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const reorderProducts = createAsyncThunk<void, IReorderPayload, {
  rejectValue: AxiosData;
}>(
  'products/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const searchProducts = createAsyncThunk<IProductsSearchInfo[], Filters, {
  rejectValue: AxiosData;
}>(
  'products/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<IProductsSearchInfo[]>>(
        `${prefix}/search?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const importPricing = createAsyncThunk<void, IImportPricingPayload, { rejectValue: AxiosData }>(
  'products/importPricing',
  async (body, thunkAPI) => {
    try {
      const token = StorageManager.getItem(ACCESS_TOKEN_KEY);

      const options: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        withCredentials: false,
      };

      await axios.patch<IImportPricingPayload>(`${API_URL}${prefix}/import-pricing`, body, options);
    } catch (error) {
      const axiosError = error as AxiosError;

      const errorInfo = customErrorHandling(axiosError.response);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getProductsBySubcategory = createAsyncThunk<Product[], string, { rejectValue: AxiosData }>(
  'products/productsBySubcategoryId',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<Product[]>>(`${prefix}/sub-category/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
