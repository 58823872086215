import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IShippingRatesResponse } from './types';

const prefix = '/shipping-rates';

export const getAllShippingMarkupRate = createAsyncThunk<IShippingRatesResponse[], void, { rejectValue: AxiosData }>(
  'shippingMarkupRate/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IShippingRatesResponse[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

interface IShippingPayload {
  shippingRates: {
    shippingRateId: string;
    rate: number;
  }[];
}

export const updateShippingRate = createAsyncThunk<void, IShippingPayload, { rejectValue: AxiosData }>(
  'shippingMarkupRate/update',
  async (body, thunkAPI) => {
    try {
      await http.patch<IShippingPayload>(prefix, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
