import Button from "@containers/common/Button";
import { styled } from "@mui/material";

export const NotUploadedContentStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledSvgTogether = styled('div')`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
`;

export const DragAndDropText = styled('p')`
`;

export const ButtonsWrapper = styled('div')<{ $hasButton: string }>`
  width: max-content;
`;

export const StyledSecondaryButton = styled(Button) <{ $hasButton: string }>`
  margin: 0;
  width: 100%;
  max-width: 214px;
  padding-left: 23px;
  padding-right: 23px;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &:last-child {
    margin-top: 10px;
  }

  &.isMobile {
    display: none;
  }

`;

export const EmptyDropDownElement = styled('div')`
  width: 166px;
  height: 166px;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  border: 1px dotted gray;
`;

export const LocalLoader = styled('label')`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
`;

export const FileInputLabel = styled('label')`
  font-weight: 800;
  text-decoration: underline;
`;
