import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
}));
