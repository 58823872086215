import { ReactNode, memo } from 'react';

import BackButton from '@containers/common/TitlesWithBackButton/BackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

import { ContentBox, StyledBox, StyledContainer } from './styles';

interface ITitlesWithBackButton {
  title: string;
  children: ReactNode;
  rightButtons?: ReactNode;
  path?: keyof typeof PAGE_ROUTES;
  params?: Record<string, string> | null;
}

const TitlesWithBackButton = ({ title, children, path, rightButtons, params }:ITitlesWithBackButton) => (
  <StyledContainer>
    <Stack>
      <StyledBox>
        {path && <BackButton path={path} params={params} />}
      </StyledBox>
    </Stack>
    <ContentBox>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
        <Typography variant="h2" mb="40px">{title}</Typography>
        {rightButtons && rightButtons}
      </Stack>
      {children}
    </ContentBox>
  </StyledContainer>
);

export default memo(TitlesWithBackButton);
