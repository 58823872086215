import { memo } from 'react';

import { ITask } from '@features/customers/tasks/types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface ITaskFor {
  users: ITask['users'];
}

const TaskFor = ({ users }: ITaskFor) => (
  <Stack>
    { users.map(({ id, firstName, lastName }, idx) => (
      <Typography key={id} variant="body3">
        {`${firstName} ${lastName}${idx === users.length - 1 ? '' : ','}`}
      </Typography>
    ))}
  </Stack>
);

export default memo(TaskFor);
