import { useEffect, useState } from "react";

import { useAppDispatch } from "@features/app/hooks";
import { useParams } from "react-router-dom";
import { getOrderInvoices, getOrderTasks } from "@features/orders/order/actions";
import { InvoiceStatusEnum } from "@features/invoices/types";

export const useRequestTasksAndInvoices = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams();

  const [areThereOpeTasks, setAreThereOpeTasks] = useState(false);
  const [areThereOpenInvoices, setAreThereOpenInvoices] = useState(false);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderInvoices({ id: orderId }))
        .unwrap()
        .then((response) => {
          const array = response.invoices.map((inv) => inv.invoiceStatus);

          if (array.includes(InvoiceStatusEnum.UNPAID)) {
            setAreThereOpenInvoices(true);
          } else {
            setAreThereOpenInvoices(false);
          }
        });

      dispatch(getOrderTasks({ id: orderId }))
        .unwrap()
        .then(((tasks) => {
          const find = tasks.find((task) => task.status === 'open');

          if (find) {
            setAreThereOpeTasks(true);
          }
        }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { areThereOpeTasks, areThereOpenInvoices };
};
