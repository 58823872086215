import { memo } from 'react';

import { StyledButton } from '@containers/common/StyledAddEditTables/styled';
import { IButtonProps } from '@containers/common/Button';

interface ISubmitBtn extends IButtonProps{
  actionLoading: boolean;
  text?: string;
  margin?: string;
}

const SubmitBtn = ({
  actionLoading,
  text = 'SUBMIT',
  margin = '40px',
  ...rest
}: ISubmitBtn) => (
  <StyledButton
    margin={margin}
    type="submit"
    disabled={actionLoading}
    isLoading={actionLoading}
    {...rest}
  >
    {text}
  </StyledButton>
);

export default memo(SubmitBtn);
