import { memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AttributeItem from '../AttributeItem';
import { IAttribute } from '../InputsTable/helpers';

interface IAttributesContainer {
  sectionTitle: string;
  isAssigned?: boolean;
  attrCategoryIdx: number;
}

const AttributesContainer = ({ sectionTitle, isAssigned, attrCategoryIdx }: IAttributesContainer) => {
  const { watch } = useFormContext();
  const attributes = watch(`attributesList.${attrCategoryIdx}.attributes`);

  const hasSelectedAttributes = attributes.some((attr:IAttribute) => attr.isSelected);

  return (!!attributes.length && isAssigned && !hasSelectedAttributes ? null : (
    <Box mt="25px">
      <Typography variant="h10">
        {sectionTitle}
      </Typography>
      <Stack
        sx={{
          display: 'grid',
          gridTemplateColumns: '290px 290px',
        }}
      >
        {attributes.map((item: IAttribute, attributeIdx: number) => (
          <AttributeItem
            key={item.id}
            {...item}
            attrCategoryIdx={attrCategoryIdx}
            attributeIdx={attributeIdx}
            isAssigned={isAssigned}
          />
        ))}
      </Stack>
    </Box>
  )

  );
};

export default memo(AttributesContainer);
