import { memo, useCallback, useEffect } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import { selectTasks } from '@features/customers/tasks/selectors';
import { getAllTasks } from '@features/customers/tasks/actions';
import PageTitle from '@containers/common/PageTitle';
import PAGE_ROUTES from '@routes/routingEnum';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import useMount from '@customHooks/useMount';
import { getAllUsers } from '@features/users/actions';
import { resetUsers } from '@features/users/slice';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './tableData';
import TableRow from './components/TableRow';
import { IFiltersForm } from './components/SearchSection/helpers';
import SearchSection from './components/SearchSection';

const Tasks = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', dateCreated = '', dateCompleted, status = '', userId = '' } = params as IFiltersForm;
  const { data: tasks, isLoading } = useAppSelector(selectTasks);
  const isSearchTerm = searchTerm || dateCreated || dateCompleted || status || userId;

  const fetchData = useCallback(() => {
    const query = { searchTerm, dateCreated, dateCompleted, status, userId };

    dispatch(getAllTasks(query));
  }, [searchTerm, dateCreated, dateCompleted, status, userId, dispatch]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, dateCreated, dateCompleted, status, userId, dispatch],
  );

  useMount(() => {
    dispatch(getAllUsers());

    return () => dispatch(resetUsers());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Tasks" btnName="Add Task" path={PAGE_ROUTES.ADD_TASK} />
      { (isSearchTerm || !!tasks?.length) && <SearchSection /> }

      { tasks?.length ? (
        <StyledTable headCells={headCells}>
          {tasks.map((item) => (
            <TableRow key={item.id} {...item} />
          ))}
        </StyledTable>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any tasks'}
          isAdd={!isSearchTerm}
        />
      )}
    </>
  );
};

export default memo(Tasks);
