import { styled } from '@mui/material/styles';
import { Box, Select, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '50px',
  alignItems: 'flex-end',

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
  },
}));

export const StyledStack = styled(Box)(() => ({
  width: '100%',
  maxWidth: '400px',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
}));

export const StyledWrapper = styled(Select)(() => ({
  padding: '5px 16px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '12px',
}));
