export interface IVendorOptions {
  optionName: string;
  value: string;
}

export enum DropShipShipToEnum {
  SHIPPING = "SHIPPING",
  DROP = "DROP",
  EMPTY = "EMPTY",
}
