import { Dispatch, SetStateAction } from "react";

import Button from "@containers/common/Button";
import { Typography } from "@mui/material";

import { StyledBox, ButtonsWrapper } from './styled';

interface DialogProps {
  setAskQuestionModal: Dispatch<SetStateAction<boolean>>;
  setIsConversationOpen: Dispatch<SetStateAction<boolean>>;
  setStopModalCancellation: Dispatch<SetStateAction<boolean>>;
}

const Dialog = ({
  setAskQuestionModal,
  setIsConversationOpen,
  setStopModalCancellation,
}: DialogProps) => {
  return (
    <div>
      <StyledBox>
        <Typography>
          Your message will not be saved.
        </Typography>
        <Typography>
          Are you sure you want to close the popup?
        </Typography>
      </StyledBox>
      <ButtonsWrapper>
        <Button
          type="button"
          onClick={() => {
            setAskQuestionModal(false);
            setStopModalCancellation(true);
          }}
        >
          Back to editing
        </Button>
        <Button
          isOutlined
          type="button"
          onClick={() => {
            setAskQuestionModal(false);
            setIsConversationOpen(false);
            setStopModalCancellation(false);
          }}
        >
          Close and discard message
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default Dialog;
