import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const DropShipContainer = styled(Box)(() => ({
}));

export const InputsWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: '4px 14px',
  gridTemplateColumns: '67px 1fr',
  marginTop: '16px',

  '& > label': {
    display: 'flex',
    alignItems: 'center',

    '> span': {
      marginLeft: '5px',
      height: '14px',
    },
  },
}));

export const StyledTypography = styled(Box)(() => ({
  color: '#06152B',
  fontFamily: "DM Sans",
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
