import { memo, useCallback, useEffect } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import StyledTable from '@containers/common/Table';
import { DropResult } from '@hello-pangea/dnd';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import {
  deleteAttributeCategory,
  reorderAttributeCategories,
  searchAttributeCategories,
} from '@features/attributeCategories/actions';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import EditCell from '@containers/common/Table/components/EditCell';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import { useLocation } from 'react-router-dom';
import { resetAttributeCategories, setAttributeCategories } from '@features/attributeCategories/slice';
import { GlobalQueryString } from '@utils/globalTypes';
import useUnMount from '@customHooks/useUnMount';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteAction';
import TableCell from '@mui/material/TableCell';
import CategoryInformDialog from '@containers/common/Table/components/TablesActions/CategoryInformDialog';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';

const AttributeCategories = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '' } = params as IFiltersForm;

  const fetchData = useCallback(() => {
    const query = { searchTerm };

    dispatch(searchAttributeCategories(query));
  }, [searchTerm, dispatch]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm],
  );
  useUnMount(() => dispatch(resetAttributeCategories()));

  const { data: attributeCategories, isLoading } = useAppSelector(selectAttributeCategories);

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, attributeCategories);

    dispatch(reorderAttributeCategories(sortedData)).unwrap().then(() => {
      dispatch(setAttributeCategories(items));
    }).catch(() => fetchData());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Attribute Categories"
        btnName="Add Attribute Category"
        path={PAGE_ROUTES.ADD_ATTRIBUTE_CATEGORIES}
      />
      { (searchTerm || !!attributeCategories?.length) && <SearchSection /> }
      {attributeCategories?.length ? (
        <DndContainer reordingData={reordingData}>
          <StyledTable headCells={headCells}>
            {attributeCategories.map(({ name, id, linkedAttributes }, index) => (
              <ReusableDragRow key={id} id={id} index={index} gridTemplateColumns="auto 140px">
                {({ providedDraggable }) => (
                  <>
                    <EditCell title={name} path="EDIT_ATTRIBUTE_CATEGORIES" params={{ id }} />
                    <DndBtn
                      width="270px"
                      sx={{ width: 'max-content', margin: '0 auto' }}
                      providedDraggable={providedDraggable}
                    />
                    <TableCell
                      width="270px"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {!linkedAttributes?.length ? (
                        <DeleteBtn
                          text="Delete"
                          deleteAction={() => {
                            dispatch(deleteAttributeCategory(id))
                              .then(() => fetchData())
                              .catch((error) => {
                                console.log('Error:', error);
                              });
                          }}
                          questionText="Are you sure you want to delete this attribute category?"
                        />
                      ) : (
                        <CategoryInformDialog
                          text={`Linked to ${linkedAttributes?.length} attribute(s)`}
                          title="This attribute category is tied to following attribute(s) and cannot be deleted."
                          desc={linkedAttributes}
                          btnText="Dismiss"
                        />
                      )}
                    </TableCell>
                  </>
                )}
              </ReusableDragRow>
            ))}
          </StyledTable>
        </DndContainer>
      ) : (
        <EmptyState
          text={searchTerm ? 'No search results found' : 'You don’t have any attribute categories'}
          isAdd={!searchTerm}
        />
      )}
    </>
  );
};

export default memo(AttributeCategories);
