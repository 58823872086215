import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { selectPromoCodes } from '@features/promoCodes/selectors';
import { addPromoCode, editPromoCode } from '@features/promoCodes/actions';
import useMount from '@customHooks/useMount';
import { getAllProducts } from '@features/products/basicInfo/actions';
import { selectProducts } from '@features/products/basicInfo/selectors';
import { getOptionsArray } from '@utils/helpers';
import Select from '@containers/common/Select';
import AutoComplete from '@containers/Administration/PromoCodes/components/InputsTable/AutoComplete';
import { resetPromoCodes } from '@features/promoCodes/slice';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { AddDataSchema, defaultValues, deletedProductsNames, discountTypes } from './helpers';
import { IInputsTable, IAddDataForm } from './types';
import { inputsRows2, inputsRows1 } from './tableData';
import { DiscountTypesEnum } from '../TableRow/helpers';
import InputAfterText from './InputAfterText';
import { StyledInput } from './styled';

const InputsTable = ({ editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectPromoCodes);
  const { allData: allProducts } = useAppSelector(selectProducts);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any), // TODO: change any
    defaultValues: editData ?? defaultValues,
  });

  const { handleSubmit, formState: { errors }, setError, register, watch } = methods;

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const { discountType, products } = watch();

  const productsList = getOptionsArray(allProducts, 'name');

  useMount(() => {
    dispatch(getAllProducts());
  });

  const onSubmit = async (data: IAddDataForm) => {
    try {
      await dispatch(editData ? editPromoCode(data) : addPromoCode(data)).unwrap();

      setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });

      if (!editData) {
        dispatch(resetPromoCodes());

        navigate(PAGE_ROUTES.PROMO_CODES);
      }
    } catch (error: any) {
      setIsSuccessMsg({ msg: '', status: null });

      if (
        Array.isArray(error?.message) &&
        error?.message?.[0] === 'startDate must be smaller than endDate'
      ) {
        setError('startDate', { message: 'Start Date must be smaller than End Date.' });
      } else if (
        Array.isArray(error?.message) &&
        error?.message?.[0] === 'Max Discount should be a maximum of 100 for percent'
      ) {
        setError('maxDiscount', { message: 'Max Discount should be a maximum of 100 for percent' });
      } else if (error.message === 'Promo code already exists!') {
        setError('promoCode', { message: error.message });
      } else {
        const msg = deletedProductsNames(error.message, productsList, products);

        setError('products', { message: msg });
      }
    }
  };

  return (
    <TitlesWithBackButton
      title={editData ? 'Edit Promotional Code' : 'Add Promotional Code'}
      path="PROMO_CODES"
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="Promotional Code" colSpan={2}>

            {inputsRows1.map(({ label, field, afterText, isRequired }) => {
              const newLabel = field === 'discount'
                ? `${discountType === DiscountTypesEnum.PERCENT ? '%' : '$'}X Discount` : label;

              return (
                <RowComponent
                  key={field}
                  label={newLabel}
                  isRequired={isRequired}
                >

                  {
                    field === 'maxDiscount' ? (
                      <InputAfterText label={afterText}>
                        <StyledInput
                          placeholder={newLabel}
                          {...register(field as keyof IAddDataForm)}
                          errorMessage={(errors as any)?.[field]?.message}
                        />
                        <div>(Enter 0 from no Limit)</div>
                      </InputAfterText>
                    ) : field === 'discountType' ? (
                      <Select
                        width="266px"
                        name="discountType"
                        options={discountTypes}
                        errorMessage={errors?.discountType?.message as string}
                      />
                    ) : (
                      <InputAfterText label={afterText}>
                        <StyledInput
                          placeholder={newLabel}
                          {...register(field as keyof IAddDataForm)}
                          errorMessage={(errors as any)?.[field]?.message}
                        />
                      </InputAfterText>
                    )
                  }

                </RowComponent>
              );
            })}
            {inputsRows2.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields {...item} />
              </RowComponent>
            ))}
            <RowComponent label="Discount on product">
              <AutoComplete errorMessage={errors?.products?.message} />
            </RowComponent>
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
