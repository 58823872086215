import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import RowComponent from '@containers/common/Table/components/RowComponent';
import Select from '@containers/common/Select';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import { useAppSelector } from '@features/app/hooks';
import { getOptionsArray } from '@utils/helpers';
import { IAttribute } from '@features/customEstimates/types';

const Attributes = () => {
  const { subAttributeCategories } = useAppSelector(selectAttributeCategories);
  const { watch, formState: { errors } } = useFormContext();
  const { attributes } = watch();

  return attributes.map(({ id: attributeId, attributeCategory: { id, name } }: IAttribute, idx: number) => {
    const attributeCategoriesList = subAttributeCategories?.find((item) => item.id === id);

    return attributeCategoriesList && (
      <RowComponent key={attributeId} label={name}>
        <Select
          name={`attributes[${idx}].id`}
          options={getOptionsArray(attributeCategoriesList.attributes, 'nickname')}
          errorMessage={(errors as any)?.attributes?.[idx]?.id?.message}
        />
      </RowComponent>
    );
  });
};

export default memo(Attributes);
