import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IAddBillingInfoPayload, IBillingInfo } from './types';

const prefix = '/billing-info';

export const getBillingInfoById = createAsyncThunk<IBillingInfo, string, { rejectValue: AxiosData}>(
  'billingInfo/getBillingInfo',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IBillingInfo>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editBillingInfo = createAsyncThunk<void, IAddBillingInfoPayload, { rejectValue: AxiosData}>(
  'billingInfo/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddBillingInfoPayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteBillingInfo = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'billingInfo/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
