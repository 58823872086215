'use client';

import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@containers/common/Button';
import { useAppDispatch } from '@features/app/hooks';
import { blockBlockedIp, getAllBlockedIps } from '@features/customers/blockedIps/actions';
import { StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import Stack from '@mui/material/Stack';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm } from './helpers';

const BlockIpSection = () => {
  const dispatch = useAppDispatch();
  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema),
    defaultValues: {
      ip: '',
    },
  });

  const { handleSubmit, register, formState: { errors }, setError } = methods;

  const onSubmit = (data: IFiltersForm) => {
    dispatch(blockBlockedIp(data.ip))
      .unwrap()
      .then(() => {
        dispatch(getAllBlockedIps());
      })
      .catch((error) => {
        setError('ip', { message: error.message });
      });
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('ip')}
              label=""
              placeholder="Enter IP to block"
              errorMessage={errors?.ip?.message}
            />
            <Button width="120px" type="submit">Block IP</Button>
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(BlockIpSection);
