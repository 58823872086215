import { memo, useCallback, useEffect } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Button from '@containers/common/Button';
import { exportCustomers, searchCustomers } from '@features/customers/customers/actions';
import { selectCustomers } from '@features/customers/customers/selectors';
import Pagination from '@containers/common/Table/components/Pagination';
import { paginationLimit } from '@utils/constants';
import { GlobalQueryString } from '@utils/globalTypes';
import { usePrevScroll } from '@utils/prevState';

import { exportCustomersCSV, headCells } from './helpers';
import SearchSection from './components/SearchSection';
import { IFiltersForm } from './components/SearchSection/helpers';
import PageTitle from './components/PageTitle';
import TableRow from './components/TableRow';

const Customers = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;
  const { searchTerm = '', limit = paginationLimit, offset = 0, startDate = '', endDate = '' } = params as IFiltersForm;
  const isSearchTerm = searchTerm || startDate || endDate;

  const { data: customers, isLoading, total } = useAppSelector(selectCustomers);
  const fetchData = useCallback(() => {
    const query = { searchTerm, limit, offset, startDate, endDate };

    dispatch(searchCustomers(query));
  }, [searchTerm, limit, offset, startDate, endDate, dispatch]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, limit, offset, startDate, endDate],
  );

  const handleExport = () => {
    dispatch(exportCustomers()).unwrap().then((data) => exportCustomersCSV(data)).catch(() => {});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={`Customers(${total})`}>
        { !!customers?.length && <Button width="auto" onClick={handleExport}>Export Customers</Button>}
      </PageTitle>
      { (isSearchTerm || !!customers?.length) && <SearchSection /> }
      {customers?.length ? (
        <>
          <StyledTable headCells={headCells}>
            {customers.map((item) => (
              <TableRow key={item.id} {...item} />
            ))}
          </StyledTable>
          { total > paginationLimit && <Pagination total={total} path="CUSTOMERS_CUSTOMERS" />}
        </>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any customers'}
        />
      )}
    </>
  );
};

export default memo(Customers);
