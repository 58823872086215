import { memo } from 'react';

import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import { useFormContext } from 'react-hook-form';
import DatePicker from '@containers/common/DatePicker';

import Input from '../../../Input';
import Textarea from '../../../Textarea';
import Checkbox from '../../../Checkbox';
import ImageUpload from '../../../FileUploader';
import ColorPickerInput from '../../../ColorPickerInput';
import Select from '../../../Select';
import { ISelectList } from './types';

interface IReusableFields extends ValidFieldNames {
  selectList?: ISelectList[];
  errorMessage?: string;
  filledData?: Record<string, string>;
  grandDisable?: boolean;
}

function ReusableFields({
  field,
  type,
  label,
  selectList,
  multiple,
  placeholder,
  extensions,
  textareaprops,
  selectprops,
  inputprops,
  errorMessage,
  filledData,
  grandDisable,
}: IReusableFields) {
  const { formState: { errors }, register } = useFormContext();

  if (type === InputTypes.filled && filledData && Object.keys(filledData || {}).length) {
    return filledData[field];
  }

  if (type === InputTypes.text) {
    return (
      <Input
        type={field === 'password' ? 'password' : 'text'}
        placeholder={placeholder || label}
        {...register(field)}
        errorMessage={errors?.[field]?.message as string || errorMessage}
        inputProps={
          { autoComplete: 'new-password' }
        }
        {...inputprops}
      />
    );
  }

  if (type === InputTypes.textarea) {
    return (
      <Textarea
        errorMessage={errors?.[field]?.message as string || errorMessage}
        placeholder={placeholder || label}
        {...register(field)}
        {...textareaprops}
      />
    );
  }

  if (type === InputTypes.checkbox) {
    return (
      <Checkbox
        name={field as string}
        disabled={grandDisable}
        errorMessage={errors?.[field]?.message as string || errorMessage}
      />
    );
  }

  if (type === InputTypes.image) {
    return (
      <ImageUpload
        name={field}
        errorMessage={errors?.[field]?.message as string || errorMessage}
      />
    );
  }

  if (type === InputTypes.colorPicker) {
    return <ColorPickerInput placeholder={label} name={field} errorMessage={errors?.[field]?.message as string} />;
  }

  if (type === InputTypes.calendar) {
    return (
      <DatePicker
        width="100px"
        name={field}
        inputProps={{ placeholder: placeholder ?? 'Date' }}
        errorMessage={errors?.[field]?.message as string || errorMessage}
      />
    );
  }

  if (type === InputTypes.select) {
    const selector = selectList?.find((item) => item.field === field);

    if (selector) {
      const { options } = selector;

      return (
        <Select
          name={field}
          options={options}
          isMultiple={multiple}
          errorMessage={errors?.[field]?.message as string || errorMessage}
          grandDisable={grandDisable}
          {...selectprops}
        />
      );
    }
  }

  return null;
}

export default memo(ReusableFields);
