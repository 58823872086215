export const headCells = [
  {
    label: 'UPLOAD DATE',
  },
  {
    label: 'NAME/COMPANY',
  },
  {
    label: 'EMAIL',
  },
  {
    label: 'PHONE',
  },
  {
    label: 'PROJECT NAME',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
