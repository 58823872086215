import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const BasicInfoContainer = styled(Box)(() => ({
}));

export const StyledTypography = styled(Typography)<{component:string}>(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20.83px',
  marginBottom: "16px",
}));

export const StyleWrapper = styled(Box)(() => ({
  marginTop: 20,

  thead: {
    tr: {
      lineHeight: '120% !important',
      padding: '10px 16px !important',
    },
  },
  th: {
    lineHeight: '120% !important',
    padding: '10px 16px !important',
  },
}));

export const StyledLink = styled('p')(() => ({
  color: '#1D73E3',
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export const StyledLinkNew = styled(Link)(() => ({
  color: '#1D73E3',
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export const StyledWrapper = styled(Box)(() => ({
  p: {
    marginBottom: '10px',
    maxWidth: '200px',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
  span: {
    color: '#788B9A',
    marginBottom: '2px',
  },
}));
