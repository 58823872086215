import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  margin: '72px auto 0',
  display: 'flex',
  justifyContent: 'center',

  '.MuiPaginationItem-root': {
    borderRadius: '8px',
    fontSize: '14px',
    margin: '0 8px',
    '&.Mui-selected': {
      background: theme.palette.grey[500],

      '&:hover': {
        background: theme.palette.grey[500],
      },
    },

    '&:hover': {
      background: theme.palette.grey[500],
    },
  },
}));
