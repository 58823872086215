import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { COLORS } from '@customTypes/global/theme/colors';

export const StyledTemplateWrapper = styled(Box)(() => ({
  p: {
    marginBottom: '8px',
    fontSize: '12px',
  },
}));

export const Wrapper = styled(Box)<{border: number}>(({ border }) => ({
  position: 'relative',
  gap: '10px',
  background: '#fff',
  border: `1px solid ${border ? 'red' : '#CFD9E0'}`,
  minHeight: '33px',
  padding: "5px 12px",
  fontSize: '12px',
  borderRadius: '4px',

  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',

  svg: {
    cursor: 'pointer',
  },
}));

export const StyledText = styled('p')(() => ({
  display: 'flex',
  gap: '10px',
  background: '#80808057',
  padding: '0px 5px 0px 10px',
  alignItems: 'center',
  borderRadius: '4px',
  wordBreak: 'break-word',
}));

export const StyledInput = styled('input')(() => ({
  height: '21px',
  display: 'inline',
  width: '100%',
  maxWidth: '100%',
  border: 0,

  '&:focus': {
    outline: 'none',
  },
}));

export const StyledLink = styled(Link)(() => ({
  color: COLORS.blue.main,
}));
