import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import PAGE_ROUTES from '@routes/routingEnum';
import { getEmailTemplateById } from '@features/emailTemplate/actions';
import { IEmailTemplate } from '@features/emailTemplate/types';
import { selectEmailTemplates } from '@features/emailTemplate/selectors';

import InputsTable from '../components/InputsTable';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const [editData, setEditData] = useState<IEmailTemplate | null>(null);
  const { isLoading } = useAppSelector(selectEmailTemplates);

  useMount(() => {
    dispatch(getEmailTemplateById(id))
      .unwrap()
      .then((data) => {
        setEditData(data);
      }).catch(() => navigate(PAGE_ROUTES.EMAIL_TEMPLATES));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {editData && <InputsTable editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
