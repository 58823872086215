export const headCells = [
  {
    label: 'DATE',
  },
  {
    label: 'MESSAGE',
  },
];
export interface IFiltersForm {
  limit?: string | number;
  offset?: string | number;
}
