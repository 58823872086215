import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { Box } from '@mui/material';
import { getOrderInvoices, getOrderDetails } from '@features/orders/order/actions';
import { useNavigate, useParams } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import Table from '@containers/common/Table';
import TCell from '@containers/common/Table/components/TCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import { selectOrderBillingAddress, selectOrderInvoices } from '@features/orders/order/selectors';
import moment from 'moment';
import Button from '@containers/common/Button';
import { priceFormatting } from '@utils/helpers';

import { billingCells, jobInvoicesCells } from '../../../../columns';
import { TableWrapper, StyledTypography, ColoredText } from './styled';
import LinkedString from '../../../common/LinkedString';

const Invoices = () => {
  const { orderId, jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(selectOrderInvoices);
  const billingAddress = useAppSelector(selectOrderBillingAddress);

  const navigateTo = (isEditable: boolean, id: string) => {
    navigate(getPath(isEditable, id));
  };

  const getPath = (isEditable: boolean, id: string) => {
    if (isEditable) {
      return `/orders/${orderId}/${jobId}/edit/invoice/${id}`;
    }

    return `/orders/${orderId}/${jobId}/view/invoice/${id}`;
  };

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails({ id: orderId }));
      dispatch(getOrderInvoices({ id: orderId }));

      return;
    }

    navigate(PAGE_ROUTES.OPEN_ORDERS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <Box sx={{ maxWidth: '1156px' }}>
      <TableWrapper>
        <StyledTypography
          variant="body1"
          component="p"
        >
          Order Invoices
        </StyledTypography>
        <Table
          title=""
          headCells={jobInvoicesCells}
        >
          {
            invoices?.map((invoice) => {
              const isEditable = invoice.invoiceStatus !== 'paid';

              return (
                <StyledTableRow
                  key={invoice.id}
                >
                  <TCell>
                    <LinkedString
                      text={invoice.invoiceNumber}
                      href={getPath(isEditable, invoice.id)}
                    />
                  </TCell>
                  <TCell text={moment(invoice.created).format('MM/DD/YY hh:mm A')} />
                  <TCell text={invoice.dateBilled ? moment(invoice.dateBilled).format('MM/DD/YY hh:mm A') : ''} />
                  <TCell>
                    {
                      invoice.invoiceStatus === 'paid'
                        ? <ColoredText>{invoice.invoiceStatus}</ColoredText>
                        : invoice.invoiceStatus
                    }
                  </TCell>

                  <TCell text={invoice.transactionId} />
                  <TCell text={priceFormatting(invoice.subtotal)} />
                  <TCell width="180px">
                    <Button
                      isOutlined={!isEditable}
                      onClick={() => navigateTo(isEditable, invoice.id)}
                    >
                      {isEditable ? 'Edit Invoice' : 'View Invoice'}
                    </Button>
                  </TCell>
                </StyledTableRow>
              );
            })
          }
        </Table>
      </TableWrapper>

      <TableWrapper>
        <StyledTypography
          variant="body1"
          component="p"
        >
          Billing Address
        </StyledTypography>
        <Table
          title=""
          headCells={billingCells}
        >
          {Object.keys(billingAddress).length ? (
            <StyledTableRow>
              <TCell text={billingAddress?.firstName} />
              <TCell text={billingAddress?.company} />
              <TCell text={billingAddress?.address1} />
              <TCell text={billingAddress?.city} />
              <TCell text={billingAddress?.created && moment(billingAddress?.created).format('MM/DD/YY hh:mm A')} />
            </StyledTableRow>
          ) : <StyledTableRow />}
        </Table>
      </TableWrapper>
    </Box>
  );
};

export default Invoices;
