import { COLORS } from '@customTypes/global/theme/colors';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const UnderlinedLink = styled(Link)<{ width: string }>(({ width }) => ({
  cursor: 'pointer',
  display: 'block',
  color: COLORS.blue.dark,
  width: width || '150px',
  direction: 'rtl',
  whiteSpace: 'pre',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
