import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import { createRequiredTextWidthValidation, positiveNumberSchema } from '@utils/schemas';
import * as yup from 'yup';

export interface IAddDataForm {
  name: string;
  zipCode: number | null;
}

export const defaultValues = {
  name: '',
  zipCode: null,
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Location name', 255),
  zipCode: positiveNumberSchema
    .min(1000, `Zip Code must be at least 4 characters`)
    .max(999999999, 'Zip Code must be at most 10 characters')
    .required('Zip Code is required'),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Location Name',
    field: 'name',
    type: InputTypes.text,
  },
  {
    label: 'Zip Code',
    field: 'zipCode',
    type: InputTypes.text,
  },
];
