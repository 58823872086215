import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledProductName = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '31.25px',
  textAlign: 'left',
  margin: '0 0 16px 0',
}));
