import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectSubcategories } from "@features/subcategories/selectors";
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { GrandFormatOptions } from "@features/products/basicInfo/types";
import { getAllAttributeCategoriesProducts } from "@features/attributeCategories/actions";
import { getAllAttributesBySubcategoryId } from "@features/subcategories/actions";
import { useParams } from "react-router-dom";
import { getAllAttributesByProductId } from "@features/products/productsAttributes/actions";

import AttributesContainer from "../AttributesContainer";
import { IAddDataForm } from "../InputsTable/helpers";
import { StyledSubTitle, StyledTitle, StyledCancelButton } from "./styles";

interface NewAddAttributesProps {
  type: 'product' | 'category';
  grandFormatOptions?: GrandFormatOptions | null;
}

const CheckboxList = ({ type, grandFormatOptions }: NewAddAttributesProps) => {
  const dispatch = useAppDispatch();
  const { id: specialId } = useParams();
  const { watch } = useFormContext<IAddDataForm>();
  const { actionLoading } = useAppSelector(selectSubcategories);

  const attributesList = watch('attributesList');

  return (
    <Box>
      <StyledTitle variant="body1">Assign Product Attributes:</StyledTitle>
      <StyledSubTitle variant="body2">Standard Business Card:</StyledSubTitle>
      {
        attributesList.map(({ id, name }, attrCategoryIdx) => {
          if (grandFormatOptions && name === 'Mailing Service') {
            return null;
          }

          return (
            <AttributesContainer
              key={id}
              sectionTitle={name}
              attrCategoryIdx={attrCategoryIdx}
            />
          );
        })
      }
      <Box
        sx={{
          margin: '24px -300px 0px 212px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          button: {
            margin: 0,
          },
        }}
      >
        <StyledCancelButton
          variant="contained"
          onClick={() => {
            if (type === 'category') {
              dispatch(getAllAttributeCategoriesProducts())
                .unwrap()
                .then((data) => {
                  if (data.length) {
                    dispatch(getAllAttributesBySubcategoryId(specialId as string));
                  }
                });

              return;
            }

            dispatch(getAllAttributeCategoriesProducts())
              .unwrap()
              .then((data) => {
                if (data.length) {
                  dispatch(getAllAttributesByProductId(specialId as string));
                }
              });
          }}
        >
          Cancel
        </StyledCancelButton>
        <SubmitBtn actionLoading={actionLoading} />
      </Box>
    </Box>
  );
};

export default CheckboxList;
