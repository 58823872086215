import { styled } from '@mui/material/styles';
import TCell from '@containers/common/Table/components/TCell';
import { Typography } from '@mui/material';

export const StyledTCell = styled(TCell)(() => ({
  width: '232px',
}));

export const ColoredText = styled(Typography)(() => ({
  color: '#03A89E',
}));
