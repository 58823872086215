import { memo } from 'react';

import PAGE_ROUTES from '@routes/routingEnum';
import PageTitle from '@containers/common/PageTitle';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectBanners } from '@features/banners/selectors';
import useMount from '@customHooks/useMount';
import { getAllBanners } from '@features/banners/actions';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import { usePrevScroll } from '@utils/prevState';

import Banners from './components/Banners';

const Homepage = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { isLoading, banners, sliders } = useAppSelector(selectBanners);

  useMount(() => {
    dispatch(getAllBanners());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Homepage" btnName="Add Banner" path={PAGE_ROUTES.ADD_BANNER} />
      {!!sliders.length && <Banners isSlider />}
      {!!banners.length && <Banners />}
      {!sliders.length && !banners.length && (
        <EmptyState text="You don’t have any banners" isAdd />)}
    </>
  );
};

export default memo(Homepage);
