import { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { ISelectOptions } from '@utils/globalTypes';
import StyledTypography from '@containers/common/StyledTypography';

export interface IOptionsListProps {
  options: ISelectOptions[];
  name: string;
  selectedOptions: any;
}

const OptionsList = ({
  name,
  options,
  selectedOptions,
}: IOptionsListProps) => {
  const { setValue, formState: { isSubmitted } } = useFormContext();

  const handleDeleteOption = (index: number) => {
    const newSelectedOptions = selectedOptions.filter((_: string, idx: number) => index !== idx);

    setValue(name, newSelectedOptions, { shouldValidate: isSubmitted });
  };

  return (
    <Stack direction="row" gap="16px" mt="10px" flexWrap="wrap">
      {selectedOptions.map((v: string, idx: number) => {
        const option = options.find((o) => o.value === v);

        return (
          <Stack key={v} direction="row" gap="8px" alignItems="center">
            <StyledTypography color="blue" variant="body3">{option?.optionName}</StyledTypography>
            <CloseIcon
              fontSize="inherit"
              color="inherit"
              cursor="pointer"
              onClick={() => handleDeleteOption(idx)}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default memo(OptionsList);
