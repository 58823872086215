import { COLORS } from '@customTypes/global/theme/colors';
import { styled } from '@mui/material/styles';

export const StyledLabel = styled('label')<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 20px;
  background: ${({ checked }) => (checked ? COLORS.grey[500] : COLORS.blue.dark)};
  display: block;
  border-radius: 100px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (checked ? "2px" : "calc(55% - 2px)")};
    top: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 20px;
    transition: 0.3s;
  }
`;
