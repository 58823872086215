import { FormEvent, memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import PAGE_ROUTES from '@routes/routingEnum';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate } from 'react-router-dom';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { addProduct, copyProduct, editProduct } from '@features/products/basicInfo/actions';
import {
  IProductsPayload,
  GrandFormatOptions as GrandFormatOptionsInterface,
} from '@features/products/basicInfo/types';
import SEO from '@containers/Products/ProductCategories/Tabs/BasicInfo/components/InputsTable/SEO';
import { selectProducts } from '@features/products/basicInfo/selectors';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { AddDataSchema, IAddDataForm, defaultValues, formattingPayload } from './helpers';
// import ProductDescription from '../ProductDescription';
import GrandFormatOptions from '../GrandFormatOptions';
import GrandFormatDiscounts from '../GrandFormatDiscounts';
import SecondRows from './components/SecondRows';
import FirstRows from './components/FirstRows';
import { StyledProductName } from './styles';

interface IInputsTable {
  getProduct?: () => void;
  editData?: IProductsPayload;
  grandFormatOptions?: GrandFormatOptionsInterface | null;
}

const InputsTable = ({ getProduct, editData }: IInputsTable) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { actionLoading } = useAppSelector(selectProducts);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData || defaultValues,
  });

  const { handleSubmit, watch, setError, setValue } = methods;

  const { grandFormatOptions, templateId } = watch();

  const handleRedirect = () => {
    if (templateId) {
      dispatch(copyProduct(templateId)).unwrap().then((res) => {
        navigate(`/products/products/edit/${res.id}`);
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err, 'error');
      });
    }
  };

  const addEditProductSubmit = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (templateId) {
      handleRedirect();
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = (data: IAddDataForm) => {
    const payload = formattingPayload(data);

    if (payload) {
      dispatch(editData ? editProduct(payload) : addProduct(payload))
        .unwrap()
        .then(() => {
          if (editData) {
            setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });
            getProduct && getProduct();

            return;
          }

          navigate(PAGE_ROUTES.PRODUCTS_PRODUCTS);
        }).catch((e) => {
          setIsSuccessMsg({ msg: '', status: null });

          if (e.message === 'Product with provided name already exists in this sub category!') {
            setError('name', { message: e.message });
          } else if (e.message === 'Product with provided product SKU already exists!') {
            setError('productSKU', { message: e.message });
          // eslint-disable-next-line max-len
          } else if (e.message === 'Product type is required because the chosen subcategory includes at least one product type') {
            setError('productTypeId', { message: e.message });
          } else {
            navigate(PAGE_ROUTES.PRODUCTS_PRODUCTS);
          }
        });
    }
  };

  useEffect(() => {
    if (!editData) {
      setValue('visibleOnSite', true);
      setValue('isDiscountable', true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  return (
    <FormProvider {...methods}>
      <StyledStack
        onSubmit={(e) => addEditProductSubmit(e)}
        component="form"
      >
        <StyledProductName
          variant="h2"
          sx={{
            mb: 3,
          }}
        >
          {editData?.name}
        </StyledProductName>
        <StyledTable
          colSpan={2}
          tableTitle="PRODUCT INFO"
        >
          <FirstRows />
          <SecondRows />
        </StyledTable>
        {/* <SEO /> */}
        {/* <ProductDescription /> */}
        {grandFormatOptions && (
          <>
            <GrandFormatOptions />
            <GrandFormatDiscounts />
          </>
        )}
        <SubmitBtn actionLoading={actionLoading} />
        <GlobalUpdatesMessage {...isSuccessMsg} />

      </StyledStack>
    </FormProvider>
  );
};

export default memo(InputsTable);
