import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getRequestsCount, searchRequests } from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  nonProfitCount: null,
  resellerCount: null,
  requestsCount: null,
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    resetRequests: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRequestsCount.pending, (state) => {});
    builder.addCase(getRequestsCount.fulfilled, (state, { payload }) => {
      const { nonProfit, reseller } = payload;

      state.nonProfitCount = nonProfit;
      state.resellerCount = reseller;
      state.requestsCount = nonProfit + reseller;
      state.isLoading = false;
    });
    builder.addCase(getRequestsCount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchRequests.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchRequests.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchRequests.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });
  },
});

export const { resetRequests } = requestsSlice.actions;

export default requestsSlice.reducer;
