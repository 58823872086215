import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData } from "@utils/globalTypes";
import { AxiosResponse } from "axios";
import { Filters, constructQueryStringExceptionWithOffer } from "@utils/helpers";

import { IVendor, VendorCreationPayload } from "./types";

const prefix = "/vendors";

export const getVendorsThunk = createAsyncThunk<
	IVendor[],
	Filters,
	{ rejectValue: AxiosData }
>("vendor/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<IVendor[]>>(`${prefix}?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const addVendorThunk = createAsyncThunk<
	void,
	VendorCreationPayload,
	{ rejectValue: AxiosData }
>("vendor/add", async (body, thunkAPI) => {
  try {
    await http.post<VendorCreationPayload>(prefix, body);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const editVendorThunk = createAsyncThunk<
	void,
	VendorCreationPayload,
	{ rejectValue: AxiosData }
>("vendor/edit", async (body, thunkAPI) => {
  try {
    await http.put<VendorCreationPayload>(`${prefix}/${body.id}`, body);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getVendorByIdThunk = createAsyncThunk<VendorCreationPayload, string, { rejectValue: AxiosData}>(
  'vendor/getById',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<VendorCreationPayload>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteVendorByIdThunk = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'vendor/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getVendorsListThunk = createAsyncThunk<
	IVendor[],
	void,
	{ rejectValue: AxiosData }
>("orders-vendor/all", async (_, thunkAPI) => {
  try {
    const { data: { data } } = await http.get<AxiosResponse<IVendor[]>>(`${prefix}/list`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
