import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Job Name',
    field: 'jobName',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Status',
    field: 'status',
    type: InputTypes.select,
    isRequired: true,
    selectprops: {
      width: '165px',
    },
  },
];

export const customEstimateStatusOptions = [
  {
    value: 'received',
    optionName: 'Received',
  },
  {
    value: 'complete',
    optionName: 'Complete',
  },
];
export const customEstimateReactivatedStatusOptions = [
  {
    value: 'received',
    optionName: 'Received',
  },
  {
    value: 'complete',
    optionName: 'Complete',
  },
  {
    value: 'reactivated',
    optionName: 'Reactivated',
  },
];
