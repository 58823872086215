import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import { getAllBlockedIps } from './actions';

const initialState: IState = {
  isLoading: true,
  data: null,
  actionLoading: false,
};

const blockedIpsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetBlockedIps: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBlockedIps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBlockedIps.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBlockedIps.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { resetBlockedIps } = blockedIpsSlice.actions;

export default blockedIpsSlice.reducer;
