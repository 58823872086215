import { styled } from '@mui/material/styles';
import { COLORS } from '@customTypes/global/theme/colors';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const EditLink = styled(Link)(() => ({
  color: COLORS.blue.main,
}));

export const ActionsWrapper = styled(Box)(() => ({
  color: COLORS.blue.main,
  display: 'grid',
  gridTemplateColumns: '55px 15px 1fr',
}));

export const VerticalLine = styled(Box)(() => ({
  position: 'relative',

  span: {
    width: '1px',
    background: '#d7d7d7',
    height: '42px',
    position: 'absolute',
    top: "-13px",
  },
}));
