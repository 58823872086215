import { memo } from 'react';

import Stack from '@mui/material/Stack';
import Checkbox from '@containers/common/Checkbox';
import StyledTypography from '@containers/common/StyledTypography';

const SendMail = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Checkbox name="sendEmailToCustomer" />
      <StyledTypography color="grey" variant="body3" ml="-10px">
        Send email to customer updating them on the status of their custom estimate
      </StyledTypography>
    </Stack>
  );
};

export default memo(SendMail);
