import React, { memo } from 'react';

import TableCell from '@mui/material/TableCell';
import { TableRowProps } from '@mui/material/TableRow';

interface ITableCell extends TableRowProps {
  text?: string | number | null;
  width?: string;
  children?: React.ReactNode;
}

const TCell = ({ text, width, children }: ITableCell) => (
  <TableCell width={width}>{children || text}</TableCell>
);

export default memo(TCell);
