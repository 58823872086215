import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  id?: string;
  name: string;
  pdfFile: string;
  aiFile: string;
  psdFile: string;
  inddFile: string;
  subCategoryId:string;
  templateCategoryId?:string;
}

export const defaultValues = {
  name: '',
  pdfFile: '',
  aiFile: '',
  psdFile: '',
  inddFile: '',
  subCategoryId: '',
  templateCategoryId: '',
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
  subCategoryId: createRequiredTextWidthValidation('Subcategory', 255),
  pdfFile: createRequiredTextWidthValidation('Pdf file', 255),
  aiFile: createRequiredTextWidthValidation('Ai file', 255),
  psdFile: createRequiredTextWidthValidation('Psd file', 255),
  inddFile: createRequiredTextWidthValidation('Indd file', 255),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Subcategory',
    field: 'subCategoryId',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Template Category',
    field: 'templateCategoryId',
    type: InputTypes.select,
    isRequired: false,
  },
  {
    label: 'Template Name',
    field: 'name',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'PDF File',
    field: 'pdfFile',
    type: InputTypes.image,
    isRequired: true,
    extensions: ['.pdf'],
  },
  {
    label: 'AI File',
    field: 'aiFile',
    type: InputTypes.image,
    isRequired: true,
    extensions: ['.ai'],
  },
  {
    label: 'PSD File',
    field: 'psdFile',
    type: InputTypes.image,
    isRequired: true,
    extensions: ['.psd'],
  },
  {
    label: 'INDD File',
    field: 'inddFile',
    type: InputTypes.image,
    isRequired: true,
    extensions: ['.indd'],
  },
];

export const formattedPayload = (data: IAddDataForm) => ({
  ...data, templateCategoryId: data.templateCategoryId || null,
});
