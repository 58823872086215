import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows1: ValidFieldNames[] = [
  {
    label: 'Promo Code',
    field: 'promoCode',
    type: InputTypes.text,
    isRequired: true,
    afterText: '(no spaces)',
  },
  {
    label: 'Discount Type',
    field: 'discountType',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: '$X Discount',
    field: 'discount',
    type: InputTypes.text,
    isRequired: true,
    afterText: 'on',
  },
  {
    label: 'Orders of $Y',
    field: 'orderAmount',
    type: InputTypes.text,
    isRequired: true,
    afterText: 'or more',
  },
  {
    label: 'Max Discount',
    field: 'maxDiscount',
    type: InputTypes.text,
  },
];

export const inputsRows2: ValidFieldNames[] = [
  {
    label: 'Start Date',
    field: 'startDate',
    type: InputTypes.calendar,
    placeholder: 'Start Date',
    isRequired: true,
  },
  {
    label: 'End Date',
    field: 'endDate',
    type: InputTypes.calendar,
    placeholder: 'End Date',
    isRequired: true,
  },
  {
    label: 'One Time Use',
    field: 'oneTimeUse',
    type: InputTypes.checkbox,
  },
  {
    label: 'Deal of the Day',
    field: 'dealOfTheDay',
    type: InputTypes.checkbox,
  },
];
